import React from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const RefundPolicy = () => {
	return (
		<>
			<Header title="Refund Policy" />
			<div className="container mt-30 mb-30">
				<h1>REFUND POLICY</h1>
				<hr />
				<p>
					At SIRKKA Health, we are committed to providing our customers with the best possible
					services. We understand that there may be times that you will need to make changes to your
					purchase, and we want to make sure that our refund policy is as clear and straightforward
					as possible. This refund policy applies to all purchases made and outlines the terms and
					conditions under which you may be eligible for a refund or exchange of services. By making
					a purchase with us, you are agreeing to the terms of this policy. Please read this policy
					carefully before making a purchase, and if you have any questions or concerns, please
					don't hesitate to contact our customer service team for assistance. This policy is split
					into different sections to help answer your questions easily.
				</p>
				<ul>
					<li>• Medical solutions (e.g. diagnostic tests, imaging, doctor consultations)</li>
					<li>• Membership</li>
					<li>• Self-test kits</li>
				</ul>
				<hr />
				<h4>Medical Solutions Refund Policy</h4>
				<ol className="policy-ordered-list">
					<li>
						<h6>Can I cancel my appointment?</h6>
					</li>
					<p>
						You may cancel your appointment at any time before the scheduled appointment time. When
						cancelling an appointment, you will be given the opportunity to reschedule your
						appointment or request a refund. If you cancel your appointment within 2 business days
						of the scheduled time, you may be subject to a cancellation/rescheduling fee. Refund
						request will be processed in line with the details outlined in this policy.
					</p>
					<li>
						<h6>Can I reschedule my appointment?</h6>
					</li>
					<p>
						You can notify us to reschedule your appointment at any time. We will try our best to
						find another appointment slot if possible. If you notify us with your intent to
						reschedule within 2 business days of your appointment time, you will be subject to a £50
						rescheduling fee or 25% of the total cost of the services, whichever is higher.
					</p>
					<li>
						<h6>How much will you refund me?</h6>
					</li>
					<p>If you cancel an appointment, we will refund the following amounts:</p>
					<p>
						• If you cancel an appointment and notify us with more than 48 hours’ notice (excluding
						weekends and public holidays), we will refund 100% of your payment, subject to a £20
						nominal fee
					</p>
					<p>
						• where you cancel within 48 hours (excluding weekends and public holidays) of your
						appointment time, we will refund 50% of your payment subject to a £30 nominal fee;
					</p>
					<p>
						• where you cancel within 24 hours (excluding weekends and public holidays) of your
						appointment time, no refund is available.{' '}
					</p>
					<li>
						<h6>How can I cancel my order?</h6>
					</li>
					<p>
						Please log into your portal to cancel/reschedule your appointments or send any
						cancellation/refund request to accounts@sirkkaltd.com and copy
						medsolutions@sirkkaltd.com. You may use the model cancellation form at the bottom of the
						policy, but this is not obligatory.{' '}
					</p>
					<li>
						<h6>When will you issue the refund?</h6>
					</li>
					<p>
						We will issue your refund within 14 business days of a validated cancellation request,
						subject to the terms and conditions of the refund policies.
					</p>
					<li>
						<h6>How will you refund me?</h6>
					</li>
					<p>
						We will issue the refund using the same payment method you used when you placed your
						order. Please note that it may take a few working days for the refund to show in your
						card or bank statement. Please note that once we have processed the refund, it is your
						responsibility to follow up with your card company or your bank if you have any
						questions regarding the timing or amount that may be refunded back to your card or bank
						account. We cannot be held liable for any delays or issues caused by your bank's
						processing time or policies.
					</p>
				</ol>

				<hr />
				<h4>Membership Refund Policy</h4>
				<ol className="policy-ordered-list">
					<li>
						<h6>Can I cancel my membership?</h6>
					</li>
					<p>
						Monthly memberships can be cancelled after the initial three months period, starting
						from the date of your order confirmation email, at any time and must give one months’
						notice. Yearly memberships may be cancelled within 14 calendar days of your order
						confirmation email, subject that you have not used our services during the 14 calendar
						days. After this period, you may cancel your membership, however no refund will be
						provided. Upon cancellation of a membership, any reward points or accumulated membership
						benefits will be forfeited from the membership.
					</p>
					<li>
						<h6>When will you issue the refund?</h6>
					</li>
					<p>
						We will issue your refund within 14 business days of a validated cancellation request,
						subject to the terms and conditions of the refund policies.
					</p>
					<li>
						<h6>How will you refund me?</h6>
					</li>
					<p>
						We will issue the refund using the same payment method you used when you placed your
						order. Please note that it may take a few working days for the refund to show in your
						card or bank statement. Please note that once we have processed the refund, it is your
						responsibility to follow up with your card company or your bank if you have any
						questions regarding the timing or amount that may be refunded back to your card or bank
						account. We cannot be held liable for any delays or issues caused by your bank's
						processing time or policies.
					</p>
				</ol>
				<hr />
				<h4>Self-Test Kits Refund Policy</h4>

				<ol className="policy-ordered-list">
					<p>
						Self-test kits are personalised in order that we can appropriately match tests to
						individuals to ensure our services can be undertaken accurately. In addition, we have
						strict health and safety policies that forbid re-using of any test kits if they have
						been sent out to a customer. On this basis, Sirkka is unable to offer any refunds for
						cancellations or returns once a purchase is made, and the standard 14 day cooling off
						period under the Consumer Contracts Regulations 2013 is not applicable.
					</p>
				</ol>

				<br />

				<h4>Model Cancellation Form</h4>
				<ol className="policy-ordered-list">
					<p>
						To Sirkka Networks Limited, First Floor, 144-146 King's Cross Road, London, WC1X 9DU,
						United Kingdom
						<br /> medsolutions@sirkkaltd.com:
						<br />
						I/We [*] hereby give notice that I/We [*] cancel my/our [*] contract for the sale of the
						following goods [*]/for the supply of the following service [*],
						<br />
						Ordered on [*]/received on [*],
						<br />
						Name of consumer(s),
						<br />
						Address of consumer(s),
						<br />
						Signature of consumer(s) (only if this form is notified on paper),
						<br />
						Date
						<br />
						[*] Delete as appropriate
					</p>
				</ol>
			</div>
			<Footer />
		</>
	);
};

export default RefundPolicy;
