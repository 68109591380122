import { useState, useEffect } from 'react';
import LocationList from '../LocationList';

const ServiceModalContent = ({ type, selectedEntityItem, selectedServiceItem, serviceData }) => {
	const [showFullModalDescription, setShowFullModalDescription] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (selectedServiceItem && selectedServiceItem.service_id && serviceData) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [selectedServiceItem, serviceData]);

	const handleToggleDescription = () => {
		setShowFullModalDescription(!showFullModalDescription);
	};

	function stringToArray(str) {
		try {
			if (!str || typeof str !== 'string') {
				return str;
			}
			const validJsonString = str.replace(/'/g, '"');
			const arr = JSON.parse(validJsonString);
			if (Array.isArray(arr)) {
				return arr;
			}
			throw new Error('The input string is not formatted as an array.');
		} catch (err) {
			console.error('Error:', err.message);
			return [];
		}
	}

	if (loading) {
		return <div>Loading service data...</div>;
	}

	const hasDescription =
		serviceData.content.first_description ||
		serviceData.content.bullets ||
		serviceData.content.description_continued;

	if (serviceData) {
		const bookingEntityId = selectedEntityItem.booking_entity_id;

		return (
			<div>
				<img
					src={`/img/icon/${serviceData.content.service_icon}.png`}
					alt={serviceData.service.service_name}
					style={{
						borderRadius: '50%',
						margin: 'auto',
						overflow: 'hidden',
						height: '140px',
						display: 'block',
					}}
				/>
				<h1 className="text-center">{serviceData.service.service_name}</h1>
				<h6
					className="text-center"
					style={{
						marginTop: '12px',
						color: '#0279a3',
						fontWeight: '500',
						fontSize: '18px',
					}}
				>
					{serviceData.service.service_price > 0 &&
						(serviceData.service.service_price / 100).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
						})}
				</h6>
				{hasDescription && (
					<>
						<div
							className="modalDescription"
							style={{
								height: showFullModalDescription ? 'auto' : `70px`,
								overflow: 'hidden',
								position: 'relative',
								boxSizing: 'border-box',
							}}
						>
							<div
								style={{
									position: 'relative',
									paddingBottom: showFullModalDescription ? 0 : '50px',
								}}
							>
								{serviceData.content.first_description &&
									stringToArray(serviceData.content.first_description).map((description, index) => (
										<p key={`top-description-${index}`}>{description}</p>
									))}
								<ul>
									{serviceData.content.bullets &&
										stringToArray(serviceData.content.bullets).map((bullet, index) => (
											<li key={`bullet-${index}`} style={{ listStyle: 'disc inside' }}>
												{bullet}
											</li>
										))}
								</ul>

								{serviceData.content.description_continued &&
									stringToArray(serviceData.content.description_continued).map(
										(description, index) => (
											<p key={`bottom-description-${index}`} style={{ margin: '12px 0 0 0' }}>
												{description}
											</p>
										)
									)}
								{serviceData.content.disclaimers &&
									stringToArray(serviceData.content.disclaimers).map((description, index) => (
										<p
											key={`bottom-description-${index}`}
											style={{ margin: '12px 0 0 0', color: '#8d8d8d', fontSize: '13px' }}
										>
											{description}
										</p>
									))}
							</div>
							{!showFullModalDescription && (
								<div
									style={{
										position: 'absolute',
										bottom: 0,
										left: 0,
										right: 0,
										height: '50px',
										backgroundImage:
											'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
									}}
								/>
							)}
						</div>
						<button
							className="btn btn-link d-block mx-auto mb-3 text-black"
							onClick={handleToggleDescription}
						>
							{showFullModalDescription ? 'Show Less' : 'Show More'}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<polyline points="6 9 12 15 18 9"></polyline>
							</svg>
						</button>
					</>
				)}
				<div>
					<LocationList
						type={type}
						service={serviceData}
						selectedServiceItem={selectedServiceItem}
						selectedEntityItem={selectedEntityItem}
						bookingEntityId={bookingEntityId}
						matchedService={serviceData}
					/>
				</div>
			</div>
		);
	} else {
		return <div>Error: No match for serviceCode {selectedServiceItem.serviceCode}</div>;
	}
};

export default ServiceModalContent;
