import { useEffect, useState, useContext } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import config from '@/config';
import { Footer, Header, Loader, Modal, Hero } from '@/components';
import CardList from '@/components/CardList';
import CardListFilter from '@/components/CardListFilter';
import CardListMap from '@/components/CardListMap';
import fetchData from '@/helpers/dataFetch';
import { Entity, ServiceData, Location } from '@/types';
import sortEntities from '../ListingPage/sortEntities';
import ServiceModalContent from '@/components/ModalContents/ServiceModalContent';
import { getRequest } from '@/services/api';

const WellbeingPage = () => {
	const entityTag = 'Wellbeing';
	const { isLoaded: isMapLoaded } = useLoadScript({
		googleMapsApiKey: config.REACT_APP_GOOGLE_MAPS_API_KEY,
	});
	const [loading, setLoading] = useState(false);
	const [entityData, setEntityData] = useState<Entity[]>([]);
	const [serviceData, setServiceData] = useState<ServiceData | null>(null);
	const [totalEntity, setTotalEntity] = useState(0);
	const [hoveredCardId, setHoveredCardId] = useState<null | string>(null);
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedEntityItem, setSelectedEntityItem] = useState<Entity | null>(null);
	const [selectedServiceItem, setSelectedServiceItem] = useState<Entity | null>(null);
	const [totalDisplayed, setTotalDisplayed] = useState(0);
	const [events, setEvents] = useState<any[]>([]);
	const [filteredEntityData, setFilteredEntityData] = useState<Entity[]>([]);

	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			try {
				const processResponse = ({ entities, total }: { entities: Entity[]; total: number }) => {
					if (entities) {
						const orderedEntityData = sortEntities(entities);
						setEntityData(orderedEntityData);
						setFilteredEntityData(orderedEntityData);
					}
				};
				await fetchData(
					`/bookings/bookingEntities/all?limit=100&page=1&type=${encodeURIComponent(entityTag)}`,
					processResponse
				);
			} catch (error) {
				console.error('Error loading data:', error);
			} finally {
				setLoading(false);
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		setTotalEntity(filteredEntityData.length || entityData.length);
	}, [filteredEntityData, entityData]);

	useEffect(() => {
		const fetchServiceData = async (serviceId: string) => {
			try {
				const service = await getRequest(`/bookings/bookingEntities/service/${serviceId}`);
				const entity: Entity = service.entities[0];
				setServiceData({
					service: {
						...entity,
						service_bookable: entity.bookable,
					},
					category: { service_category_id: entity.location_category_id },
					content: { ...entity, service_icon: entity.service_icon },
					location: { ...entity, location_category: entity.city || 'Virtual' },
				});
			} catch (error) {
				console.error('Error fetching service data:', error);
			}
		};
		if (selectedServiceItem?.service_id) fetchServiceData(selectedServiceItem.service_id);
	}, [selectedServiceItem]);

	const handleOpenModal = (serviceItem: any, entityItem: any) => {
		setSelectedEntityItem(entityItem);
		setSelectedServiceItem(serviceItem);
		setModalOpen(true);
	};

	const handleCardHover = (cardId: string | null) => setHoveredCardId(cardId);
	const handleCardLeave = () => setHoveredCardId(null);
	const handleTotalDisplayedCards = (count: number) => setTotalDisplayed(count);

	const modalContent = {
		type: 'wellbeing',
		selectedServiceItem,
		selectedEntityItem,
		serviceData,
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Header title={'Wellbeing'} fullTitle={'Wellbeing'} />
			<Hero
				type="wellbeing"
				entityData={entityData}
				events={events}
				setEvents={setEvents}
				setFilteredEntityData={setFilteredEntityData}
				isListingPage={true}
				onOpenModal={handleOpenModal}
				onCardHover={handleCardHover}
				onCardLeave={handleCardLeave}
				onTotalDisplayedCards={handleTotalDisplayedCards}
			/>
			<div className="container">
				<div className="row">
					<CardListFilter
						totalDisplayed={totalEntity}
						entityData={filteredEntityData ? filteredEntityData : entityData}
					/>
					{filteredEntityData.length === 0 && entityData.length === 0 ? (
						<div className="col-xl-7 col-lg-7 col-md-12">
							<div className="mb-4">
								<p>There are no results at the moment. Please try again later.</p>
							</div>
						</div>
					) : (
						<CardList
							type={'wellbeing'}
							entityData={filteredEntityData ? filteredEntityData : entityData}
							onTotalDisplayedCards={handleTotalDisplayedCards}
							onOpenModal={handleOpenModal}
							onCardHover={handleCardHover}
							onCardLeave={handleCardLeave}
						/>
					)}
					{isMapLoaded && (
						<CardListMap
							type={'wellbeing'}
							entityData={filteredEntityData ? filteredEntityData : entityData}
							hoveredCard={hoveredCardId}
						/>
					)}
				</div>
				<Modal
					isOpen={isModalOpen}
					onClose={() => setModalOpen(false)}
					content={<ServiceModalContent {...modalContent} />}
				/>
			</div>
			<Footer />
		</>
	);
};

export default WellbeingPage;
