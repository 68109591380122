import React, { useState, useContext, useEffect } from 'react';
import { Button, PostcodeAvailabilityMap, BookingContext } from '@sirkka-health/booking-system-ui';
import { getRequest } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { buildGeneralCartObject } from '@/helpers/addToCart';

const LocationList = ({
	type,
	service,
	selectedServiceItem,
	selectedEntityItem,
	bookingEntityId,
	matchedService,
	referrerParam,
	availableLocations = [],
}) => {
	const [locationData, setLocationData] = useState([]);
	const [isLoadingLocations, setIsLoadingLocations] = useState(false);
	const [modalStep, setModalStep] = useState(1);

	const { bookingData, setBookingData } = useContext(BookingContext);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoadingLocations(true);

				if (service.service.service_type === 'Postal') {
					// Set the locationData to a single 'By post' location for Postal service type
					setLocationData([
						{
							locationCategory: 'By post',
							building_name: '',
							street: '',
							city: '',
							postcode: '',
						},
					]);
				} else {
					let data;
					if (type === 'imaging') {
						const url = `/bookings/bookingEntities/service/${service.service.service_id}`;
						const response = await getRequest(url);
						data = response.entities;
						data.sort((a, b) => {
							if (a.booking_entity_id !== b.booking_entity_id) {
								return a.booking_entity_id - b.booking_entity_id;
							}
							return a.location_id - b.location_id;
						});

						const uniqueEntities = [];
						const seenStreets = new Set();

						data.forEach((item) => {
							const street = item.street;
							const city = item.city;
							if (!seenStreets.has(street) && city !== 'Home Visit') {
								seenStreets.add(street);
								uniqueEntities.push({
									bookingEntityId: item.booking_entity_id,
									locationId: item.location_id,
									building_name: item.building_name,
									street: item.street,
									city: item.city,
									postcode: item.postcode,
									locationCategory: item.city,
								});
							}
						});

						uniqueEntities.sort((a, b) => a.city.localeCompare(b.city));

						data = uniqueEntities;
					} else {
						const url = `/bookings/location/entity/${bookingEntityId}/service/${service.service.service_id}`;
						data = await getRequest(url);

						data = data.sort((a, b) => a.city.localeCompare(b.city));
					}

					setLocationData(data);
				}
			} catch (error) {
				console.error('Error fetching location data:', error);
			} finally {
				setIsLoadingLocations(false);
			}
		};

		fetchData();
	}, [service, bookingEntityId, type]);

	const handleDateTimeLocationSelection = (date, time, userPostcode, clinician) => {
		// Generate unique ID
		const bookingId = Date.now().toString();

		setBookingData([
			...bookingData,
			{
				booking: {
					bookingId,
					locationId: 4,
					clinicianId: clinician.user_id,
					bookingName: '',
					bookingProduct: service.service.service_code,
					appointmentLocation: userPostcode,
					appointmentRegion: 'Home visit',
					appointmentDate: date.value,
					appointmentTime: time,
					appointmentNotes: '',
					appointmentFor: 'Myself',
					virtualOrPhysical: '',
					sampleConsent: '',
					termsconditions: '',
					completed: false,
					referrer: referrerParam || '',
				},
				clinician: {
					id: clinician.user_id,
					name: clinician.full_name ? clinician.full_name : clinician.display_name,
					email: clinician.username ? clinician.username : clinician.email,
				},
				customer: {
					nameTitle: '',
					firstName: '',
					lastName: '',
					gender: '',
					dateOfBirth: '',
					phoneNumber: '',
					company: '',
					occupation: '',
					address_line_1: '',
					address_line_2: '',
					city: '',
					postcode: '',
					country: '',
					address_type: 'home',
				},
				postal: {
					postageAddressLine1: '',
					postageAddressLine2: '',
					postageCity: '',
					postagePostcode: '',
					postageCountry: '',
					postageSelection: 'yes',
				},
				service: {
					serviceName: service.service.service_name,
					serviceId: service.service.service_code,
					serviceType: service.service.service_type,
					emailTemplate: service.service.email_template,
					regionId: 4,
					price: service.service.service_price,
					icon: service.content.service_icon,
					apptStackable: service.service.appt_stackable,
					apptDuration: service.service.appt_duration,
				},
				entity: {
					entityId: bookingEntityId,
				},
				combineBooking: '',
			},
		]);

		navigate('/cart');
	};

	const sanitizedAvailableLocations = Array.isArray(availableLocations) ? availableLocations : [];

	const isLocationAvailable = (location) => {
		return (
			sanitizedAvailableLocations.length === 0 ||
			sanitizedAvailableLocations.includes(location.locationCategory)
		);
	};

	const formatAddress = (location) => {
		const parts = [location.building_name, location.street, location.city, location.postcode];
		return parts.filter((part) => part).join(', ');
	};

	const addServiceToCart = (location, selectedServiceItem, selectedEntityItem) => {
		const processed = buildGeneralCartObject(location, selectedServiceItem, selectedEntityItem);
		setBookingData([...bookingData, processed]);
		navigate('/cart');
	};

	return (
		<div>
			{service.service.service_type !== 'Postal' && modalStep === 1 && (
				<>
					{type !== 'imaging' && (
						<>
							<h5>Available Locations:</h5>
							{isLoadingLocations ? (
								<p>Loading available locations...</p>
							) : locationData.length > 0 ? (
								locationData.filter(isLocationAvailable).map((location, index) => (
									<div
										className="d-flex align-items-center"
										key={`location-${index}`}
										style={{
											backgroundColor: index % 2 === 0 ? 'transparent' : '#f2f2f2',
											padding: '12px',
										}}
									>
										<div className="col-6">
											<h6 className="m-0">{location.city}</h6>
											<p className="m-0">{formatAddress(location)}</p>
										</div>
										<div className="col-6 justify-content-end d-flex">
											{service.service.bookable === false ? (
												<Button
													label="Contact Us"
													disabled={false}
													variant="border"
													onClick={() => navigate('/contact')}
												/>
											) : (
												<Button
													label={`Select ${location.city}`}
													disabled={false}
													variant="border"
													onClick={() => {
														if (location.location_category_id === 5 || location.location_id === 4) {
															setModalStep(2);
														} else {
															addServiceToCart(location, selectedServiceItem, selectedEntityItem);
														}
													}}
												/>
											)}
										</div>
									</div>
								))
							) : (
								<p>No locations found.</p>
							)}
						</>
					)}
					{type === 'imaging' && (
						<>
							<h5>Available Locations:</h5>
							{isLoadingLocations ? (
								<p>Loading available locations...</p>
							) : locationData.length > 0 ? (
								locationData.map((location, index) => (
									<div
										className="d-flex align-items-center"
										key={`location-${index}`}
										style={{
											backgroundColor: index % 2 === 0 ? 'transparent' : '#f2f2f2',
											padding: '12px',
										}}
									>
										<div className="col-6">
											<h6 className="m-0">{location.city}</h6>
											<p className="m-0">{formatAddress(location)}</p>
										</div>
										<div className="col-6 justify-content-end d-flex">
											{service.service.bookable === false ? (
												<a
													href="https://forms.office.com/Pages/ResponsePage.aspx?id=teK2BhsA9kafJYS0elBAJATRdceoBrJMkG8eAcguCzhUMDg5UkYxODZaWDQ0UUpIVFpETTQxTTRBTi4u"
													target="_blank"
													rel="noreferrer"
												>
													<Button label="Contact Us" disabled={false} variant="border" />
												</a>
											) : (
												<Button
													label={`Select ${location.city}`}
													disabled={false}
													variant="border"
													onClick={() => {
														if (location.locationCategoryId === 5) {
															setModalStep(2);
														} else {
															addServiceToCart(location, selectedServiceItem, selectedEntityItem);
														}
													}}
												/>
											)}
										</div>
									</div>
								))
							) : (
								<p>No locations found.</p>
							)}
						</>
					)}
				</>
			)}
			{service.service.service_type === 'Postal' && (
				<>
					<h5>Service is available by post:</h5>
					<div className="d-flex align-items-center" style={{ padding: '12px' }}>
						<div className="col-6">
							<h6 className="m-0">By post</h6>
							<p className="m-0">Delivered to you to take at home.</p>
						</div>
						<div className="col-6 justify-content-end d-flex">
							<Button
								label="Select By post"
								disabled={false}
								variant="border"
								onClick={() => {
									addServiceToCart(
										{
											building_name: '',
											street: '',
											city: '',
											postcode: '',
										},
										selectedServiceItem,
										selectedEntityItem
									);
								}}
							/>
						</div>
					</div>
				</>
			)}
			{modalStep === 2 && (
				<>
					<div className="row">
						<h3>Enter appointment date and postcode</h3>
					</div>
					<PostcodeAvailabilityMap
						handleDateTimeLocationSelection={handleDateTimeLocationSelection}
					/>
				</>
			)}
		</div>
	);
};

export default LocationList;
