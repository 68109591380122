import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { OrderSummary } from '@sirkka-health/booking-system-ui';
import { getMembership } from '../HealthTestsAndSpecialists/helpers/getData';
import { ReactSession } from 'react-client-session';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom';
import showAlert from '../../helpers/alerts';
import useAuth from '../../hooks/useAuth';
import { checkLogin } from '../../services/data';
import styles from './cart.module.scss';
import { createAccount } from '../../services/data';
import { BookingUtils } from '@/utils';

export default function Cart() {
	const { services, specialists, bookingData, setBookingData, bookingDataTotal } =
		useContext(BookingContext);
	const { isAuthenticated, userAuthenticated } = useAuth();
	const [membershipData, setMembershipData] = useState(null);
	const [user, setUser] = useState(ReactSession.get('user') || null);

	const navigate = useNavigate();

	const goToBookingCompletionPage = () => {
		navigate('/complete-booking');
	};

	const goToMostRecentPage = () => {
		if (bookingData && bookingData.length > 0) {
			const mostRecentBooking = bookingData[bookingData.length - 1];
			if (mostRecentBooking.booking && mostRecentBooking.booking.bookingType) {
				const bookingTypeUrl = `/${mostRecentBooking.booking.bookingType}`;
				navigate(bookingTypeUrl);
			}
		}
	};

	const goToNextPage = () => {
		if (bookingDataTotal && bookingDataTotal > 0) {
			navigate('/checkout');
		} else {
			// This is for CHAPS event to allow booking without payment
			const bookingDetails = JSON.parse(localStorage.getItem('bookingData'));
			const customerDetails = ReactSession.get('user');
			let processedCustomerDetails;

			if (customerDetails !== undefined) {
				processedCustomerDetails = {
					customerId: customerDetails.customer_id,
					firstname: customerDetails.first_name,
					lastname: customerDetails.last_name,
					email: customerDetails.username || customerDetails.email_address,
					gender: customerDetails.gender || null,
					dateOfBirth: customerDetails.date_of_birth || null,
					notes: null,
				};
			}

			if (bookingDetails) {
				for (const cartItem of bookingDetails) {
					switch (cartItem.service.serviceType) {
						case 'Postal': {
							BookingUtils.CreatePostal(cartItem, customerDetails.customer_id);
							break;
						}
						default: {
							BookingUtils.CreateAppointment(cartItem, processedCustomerDetails);
							break;
						}
					}
				}
			}
			BookingUtils.ClearBookingCart(setBookingData);
			navigate('/booking/complete');
		}
	};

	useEffect(() => {
		if (user) {
			getMembership(user.customer_id, setMembershipData);
		}
	}, [user]);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
	}, [isAuthenticated, navigate]);

	const registerAccount = async (formData, FDname) => {
		try {
			const response = await createAccount(formData);

			if (response.status === 'success') {
				loginAccount({
					email: formData.email,
					password: formData.password,
				});
			} else {
				showAlert(response.message || 'Failed to register. Please call us on 0330 088 2538.');
			}
		} catch (error) {
			if (error.response && error.response.status === 403) {
				const errorMessage = error.response.data.message;
				showAlert(errorMessage);
			} else {
				showAlert('An unexpected error occurred. Please call us on 0330 088 2538.');
			}
		}

		return false;
	};

	const loginAccount = async (data) => {
		try {
			const response = await checkLogin(data);

			if (response.status === 'success') {
				userAuthenticated(response.user, data.remember);
				setUser(response.user);
			} else {
				showAlert(response.message || 'Failed to login. Please call us on 0330 088 2538.');
			}
		} catch (error) {
			if (error.response && error.response.status === 403) {
				const errorMessage = error.response.data.message;
				showAlert(errorMessage);
			} else {
				showAlert('An unexpected error occurred. Please call us on 0330 088 2538.');
			}
		}
	};

	return (
		<>
			<Header />
			<div className={`${styles.cart} container mt-30 mb-30`}>
				{isAuthenticated ? (
					<OrderSummary
						services={services}
						specialists={specialists}
						onEditBooking={goToBookingCompletionPage}
						onNextStep={goToNextPage}
						onPrevStep={goToMostRecentPage}
						membershipData={membershipData}
					/>
				) : null}
			</div>
			<Footer />
		</>
	);
}
