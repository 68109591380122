import countries from '../../data/countries.json';

const CountryDropdown = ({ register, required, userCountry, setUserCountry }) => {
	const handleCountryChange = (event) => setUserCountry(event.target.value);

	return (
		<div className="country-select">
			<label>
				{' '}
				Country <span className="required">*</span>{' '}
			</label>
			<select {...register} value={userCountry} onChange={handleCountryChange} required={required}>
				<option disabled> Select country </option>
				{countries.map((country) => (
					<option key={country} value={country}>
						{country}
					</option>
				))}
			</select>
		</div>
	);
};

export default CountryDropdown;
