import { useState } from 'react';

const ReviewArea = () => {
	const [activeTab, setActiveTab] = useState(1);

	const makeActive = (index) => {
		setActiveTab(index);
	};
	return (
		<>
			<section className="review-section pt-60 pb-60" style={{ background: '#f6f7fb' }}>
				<div className="container">
					<div className="row px-3 px-sm-0">
						<h2 className="mb-50">What Our Customers Say</h2>
						{/* <h5 className="mb-50">Recent reviews from our customers</h5> */}
					</div>
					<div className="row flex-column flex-md-row">
						<div className="col-12 col-md-4 flex-row px-4 px-sm-0">
							<div
								className={`tab-btn d-flex justify-content-start${
									activeTab === 1 ? ' active' : ''
								}`}
								onClick={() => makeActive(1)}
							>
								<img className="avatar" src={`/img/testimonials/testimonial-1.png`} alt="" />
								<div className="reviewer d-flex justify-content-center flex-column">
									<h5 className="name mb-0">Marc Heywood</h5>
									<p className="title mb-0">Sirkka member</p>
								</div>
							</div>
							<div
								className={`tab-btn d-flex justify-content-start${
									activeTab === 2 ? ' active' : ''
								}`}
								onClick={() => makeActive(2)}
							>
								<img className="avatar" src={`/img/icon/Individual.png`} alt="" />
								<div className="reviewer d-flex justify-content-center flex-column">
									<h5 className="name mb-0">Mandy Newbold</h5>
									<p className="title mb-0">Sirkka member</p>
								</div>
							</div>
							<div
								className={`tab-btn d-flex justify-content-start${
									activeTab === 3 ? ' active' : ''
								}`}
								onClick={() => makeActive(3)}
							>
								<img className="avatar" src={`/img/testimonials/testimonial-3.jpeg`} alt="" />
								<div className="reviewer d-flex justify-content-center flex-column">
									<h5 className="name mb-0">Tania Assi</h5>
									<p className="title mb-0">Sirkka member</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-8 col-lg-6 d-flex row mb-20">
							<div className={`tab-content${activeTab === 1 ? ' active' : ''}`}>
								<h3 className="mb-3">I would highly recommend!</h3>
								<img className="mb-4" src={`/img/icon/5-stars.svg`} alt="" />
								<p>
									My online consultation with a Sirkka doctor following the in-person health
									screening was very professional throughout. Explanations were clear and my
									questions were answered in full and in language that was easy to understand. I
									left the consultation with a strong understanding of the results of my health
									screening and with a clear idea of any action points needed. I would highly
									recommend.
								</p>
							</div>
							<div className={`tab-content${activeTab === 2 ? ' active' : ''}`}>
								<h3 className="mb-3">Fast and professional</h3>
								<img className="mb-4" src={`/img/icon/5-stars.svg`} alt="" />
								<p>
									I would recommend anyone needing private blood tests or x-rays to go to Sirkka
									Health. The whole process is very fast with results given quickly. The staff are
									very accommodating, professional and put you at ease.
								</p>
							</div>
							<div className={`tab-content${activeTab === 3 ? ' active' : ''}`}>
								<h3 className="mb-3">Friendly and informative</h3>
								<img className="mb-4" src={`/img/icon/5-stars.svg`} alt="" />
								<p>
									Have always wanted a Private Health Screening with a company that was based in UK
									and provided a comprehensive health assessment. I approached Sirkka as they are
									based in London UK and have affordable health screening packages. From the start
									Sirkka made me very welcome and introduced me to well qualified nurses and medical
									professionals who saw to my every need. I went for the comprehensive health check
									package which included a Health Screening Blood Test, Health check-up (including
									weight, height, blood sugar, blood pressure cholesterol and iron level) and
									medical imaging.
								</p>
								<p>
									I received my full comprehensive report within 12 hours and within 24 hours I had
									a private doctor consultation in UK. The doctor consultation was online, and he
									was specialist in all the areas and spent time explaining my report and actions I
									needed to take. He used no jargon and made it simple to follow. I am so pleased I
									went to Sirkka and would recommend this company to anyone wanting a Private Health
									Check
								</p>
							</div>
						</div>
						<a
							href="https://goo.gl/maps/T1LyFQxLJ7jEE3Hz8"
							className="d-block d-grid mt-auto mb-20"
						>
							<button type="button" className="button lg mx-auto">
								Read our Google reviews
							</button>
						</a>
					</div>
				</div>
			</section>
		</>
	);
};

export default ReviewArea;
