import React, { useEffect, useRef, useState } from 'react';
import { postRequest } from '../../../../services/api';
import { Button } from '@sirkka-health/booking-system-ui';
import Modal from '../Modal/Modal';
import config from '../../../../config';
import styles from './invite.module.scss';

const Invite = ({ user, membershipData }) => {
	const { customer_id, user_id, first_name, last_name } = user;
	const [email, setEmail] = useState('');
	const [createGroupSent, setCreateGroupSent] = useState(false);
	const [groupId, setGroupId] = useState(null);
	const [isGroupIdNumber, setIsGroupIdNumber] = useState(isNumber(groupId));
	const membershipId = membershipData.membership_id;
	const membershipType = membershipData.membership_type;

	const [showModal, setShowModal] = useState(false);
	const [formType, setFormType] = useState('Adult');
	const [buttonText, setButtonText] = useState('Add adult to my household');
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [error, setError] = useState(null);
	const formRef = useRef(null);

	const handleFormSubmit = async (e) => {
		e?.preventDefault();

		//Validation for forms
		const isFormValid = () => {
			let valid = true;

			if (formType === 'Adult' && !email) {
				setError('Email is required');
				valid = false;
			}

			if (formType === 'Child') {
				const formData = new FormData(formRef.current);
				if (
					!formData.get('firstName') ||
					!formData.get('lastName') ||
					!formData.get('dob') ||
					!formData.get('gender')
					// ||
					// !formData.get('document')
				) {
					setError('All fields are required');
					valid = false;
				}

				if (formData.get('relationship') === 'None') {
					setError(
						"You must be the child's parent or legal guardian to add them to your household."
					);
					valid = false;
				}
			}

			if (formType === 'Pet') {
				const formData = new FormData(formRef.current);
				if (
					!formData.get('petType') ||
					!formData.get('name') ||
					!formData.get('breed') ||
					!formData.get('petDob') ||
					!formData.get('gender') ||
					!formData.get('appearanceDetails') ||
					!formData.get('vetAddress') ||
					!formData.get('vetTelephone') ||
					!formData.get('vetTelephone')
					// ||
					// !formData.get('vetEmail')
					// ||
					// !formData.get('vetDocument')
				) {
					setError('All fields are required');
					valid = false;
				}
			}

			return valid;
		};

		//Trigger the relevant function if the form is valid
		if (!isFormValid()) {
			return;
		}
		try {
			if (formType === 'Adult') {
				await handleAdultSubmit();
				setButtonText('Successfully sent invite em');
			} else if (formType === 'Child') {
				await handleChildSubmit();
				setButtonText('Adding child to household');
			} else if (formType === 'Pet') {
				await handlePetSubmit();
				setButtonText('Adding pet to household');
			}
			setError(null);
			setButtonDisabled(true);
			setTimeout(() => {
				setShowModal(false);
				setButtonDisabled(false);
			}, 3000);
		} catch (error) {
			console.error('Form submission error:', error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${config.REACT_APP_API_URL}/users/${user_id}`);
				const data = await response.json();
				setGroupId(data.userData.group_id);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user_id]);

	useEffect(() => {
		setIsGroupIdNumber(isNumber(groupId));
	}, [groupId]);

	useEffect(() => {
		if (formType === 'Adult') {
			setButtonText('Add this adult');
		} else if (formType === 'Child') {
			setButtonText('Add this child');
		} else if (formType === 'Pet') {
			setButtonText('Add this pet');
		}
	}, [formType]);

	const handleAdultSubmit = async () => {
		const payload = {
			email: email,
			customerId: customer_id,
			customerName: `${first_name} ${last_name}`,
			groupId: groupId,
		};

		try {
			const response = await fetch(`${config.REACT_APP_API_URL}/customers/invite`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			await response.json();
		} catch (error) {
			console.error('Failed to send invite:', error);
		}
	};

	const handleChildSubmit = async () => {
		try {
			const formData = new FormData(formRef.current);
			const document = formData.get('childDocument');
			if (formData.get('relationship') === 'None') {
				setError("You must be the child's parent or legal guardian to add them to your household.");
				return false;
			}
			const response = await fetch(`${config.REACT_APP_API_URL}/customers/create-pets-children`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					firstName: formData.get('firstName'),
					lastName: formData.get('lastName'),
					gender: formData.get('gender'),
					dateOfBirth: formData.get('dob'),
					relationship: formData.get('relationship'),
					groupId: groupId,
					customerId: customer_id,
					type: 'Child',
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const returnedData = await response.json();
			if (returnedData && document) {
				const childData = JSON.stringify(returnedData.creation);
				const formData = new FormData();
				formData.append('child', childData);
				formData.append('customerId', customer_id);
				formData.append('file', document);

				// Send POST request to store document
				await postRequest('/documents/upload', formData, 'form-data');
			}
		} catch (error) {
			console.error('Failed to submit child info:', error);
		}
	};

	const handlePetSubmit = async () => {
		try {
			const formData = new FormData(formRef.current);
			const document = formData.get('vetDocument');
			const response = await fetch(`${config.REACT_APP_API_URL}/customers/create-pets-children`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					petName: formData.get('name'),
					petType: formData.get('petType'),
					petBreed: formData.get('breed'),
					gender: formData.get('gender'),
					dateOfBirth: formData.get('petDob'),
					vet: {
						address: formData.get('vetAddress'),
						telephone: formData.get('vetTelephone'),
						email: formData.get('vetEmail'),
					},
					groupId: groupId,
					details: formData.get('appearanceDetails'),
					type: 'Pet',
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const returnedData = await response.json();
			if (returnedData && document) {
				const petData = JSON.stringify(returnedData.creation);
				const formData = new FormData();
				formData.append('pet', petData);
				formData.append('customerId', customer_id);
				formData.append('file', document);

				// Send POST request to store document
				await postRequest('/documents/upload', formData, 'form-data');
			}
		} catch (error) {
			console.error('Failed to submit pet info:', error);
		}
	};

	const handleCreateGroup = async () => {
		setCreateGroupSent(true);
		const payload = {
			group_owner_id: customer_id,
			membership_id: membershipId,
		};

		try {
			const response = await postRequest('/customers/create-group', payload);
			if (response.status === 'success') {
				setIsGroupIdNumber(true);
			}
		} catch (error) {
			console.error('Failed to create group:', error);
		}
	};

	function isNumber(value) {
		return value !== null && value !== undefined && !isNaN(Number(value)) && value !== '';
	}

	return (
		<div className={styles.invite}>
			<div className={styles.cardContent}>
				{isGroupIdNumber &&
				(membershipType === 'Joint Plan' ||
					membershipType === 'Single Parent Family Plan' ||
					membershipType === 'Family Plan') ? (
					<>
						<h2>Add family/pets to your household</h2>
						<p>
							Add family members to your household to share membership benefits and collect combined
							household points.
						</p>
						<Button
							variant="primary"
							label="Add family/pets to my household"
							fullWidth
							large
							onClick={() => {
								setShowModal(true);
							}}
						/>
					</>
				) : !isGroupIdNumber &&
				  (membershipType === 'Joint Plan' ||
						membershipType === 'Single Parent Family Plan' ||
						membershipType === 'Family Plan') ? (
					<>
						<h2>Create a Household</h2>
						<p style={{ flexGrow: '1' }}>
							Create a household to share membership benefits and collect combined household points.
						</p>
						<Button
							variant="primary"
							label={createGroupSent ? 'Creating household' : 'Create a household'}
							fullWidth
							large
							onClick={handleCreateGroup}
							disabled={createGroupSent}
						/>
					</>
				) : (
					<>
						<h2>Join a Household</h2>
						<p>Only Joint or Family plan members can create households and send email invites.</p>
						<p>
							{' '}
							If you're looking to join an existing household, ask your household account holder to
							invite you to theirs. Otherwise, upgrade to a Joint or Family plan to start your own
							household.
						</p>
					</>
				)}
				{showModal && (
					<Modal
						content={
							<form ref={formRef} onSubmit={handleFormSubmit}>
								<div className={styles.addForm}>
									<label>
										Would you like to add an adult, pet or child
										<select value={formType} onChange={(e) => setFormType(e.target.value)}>
											<option value="Adult">Adult</option>
											<option value="Child">Child</option>
											<option value="Pet">Pet</option>
										</select>
									</label>
									{formType === 'Adult' && (
										<label>
											Email address
											<input
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												required
											/>
										</label>
									)}
									{formType === 'Child' && (
										<>
											<label>
												Please select your relationship to this child:
												<select name="relationship" required>
													<option value="Parent">Parent</option>
													<option value="Legal Guardian">Legal Guardian</option>
													<option value="None">None of the above</option>
												</select>
											</label>
											<label>
												First name *
												<input type="text" name="firstName" required />
											</label>
											<label>
												Last name *
												<input type="text" name="lastName" required />
											</label>
											<label>
												Date of birth *
												<input type="date" name="dob" required />
											</label>
											<label>
												Gender *
												<select name="gender" required>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Other">Other</option>
												</select>
											</label>
											<p>
												We need to verify each individual's identity. Please email a copy of the
												child's birth certificate or passport to medsolutions@sirkkaltd.com
											</p>
											<label>
												Upload birth certificate or passport *
												<input type="file" name="childDocument" accept=".png, .jpeg, .jpg, .pdf" />
											</label>
										</>
									)}
									{formType === 'Pet' && (
										<>
											<label>
												Is your pet a dog or cat? *
												<select name="petType" required>
													<option value="Dog">Dog</option>
													<option value="Cat">Cat</option>
												</select>
											</label>
											<label>
												Name *
												<input type="text" name="name" required />
											</label>
											<label>
												Breed *
												<input type="text" name="breed" required />
											</label>
											<label>
												Gender *
												<select name="gender" required>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
												</select>
											</label>
											<label>
												Date of birth *
												<input type="date" name="petDob" required />
											</label>
											<label>
												Details of visual appearance *
												<textarea name="appearanceDetails" required></textarea>
											</label>
											<label>
												Registered vet address *
												<input type="text" name="vetAddress" required />
											</label>
											<label>
												Registered vet telephone *
												<input type="tel" name="vetTelephone" required />
											</label>
											<label>
												Registered vet email address
												<input type="email" name="vetEmail" />
											</label>
											<label>
												Upload a vet registration document or vaccination card *
												<input type="file" name="vetDocument" accept=".png, .jpeg, .jpg, .pdf" />
											</label>
											<p>
												We need to verify each pet's identity and ownership. Please upload a copy of
												your pets vet registration document or vaccination card. Alternatively, you
												can send it to medsolutions@sirkkaltd.com
											</p>
										</>
									)}
								</div>

								{error && <div style={{ color: 'red' }}>{error}</div>}
							</form>
						}
						onClose={() => {
							setShowModal(false);
						}}
						onSubmit={handleFormSubmit}
						disabledStatus={buttonDisabled}
						buttonText={buttonText}
					/>
				)}
			</div>
		</div>
	);
};

export default Invite;
