import { ReactSession } from 'react-client-session';
import { useForm } from 'react-hook-form';
import { Button } from '@sirkka-health/booking-system-ui';
import styles from './Login.module.scss';
import useAuth from '../../../hooks/useAuth';
import showAlert from '../../../helpers/alerts';
import { checkLogin } from '../../../services/data';

const Login = ({ setCheckoutStep }) => {
	const { userAuthenticated } = useAuth();
	const { register, handleSubmit } = useForm();

	const onSubmitLogin = async (data) => {
		const response = await checkLogin(data);
		if (response.status === 'success') {
			userAuthenticated(response.user, data.remember);
			ReactSession.set('user', response.user);
		} else {
			showAlert('Forgotten password? Please reset on the account login page');
		}
	};
	return (
		<div className={styles.loginBox}>
			<h3
				style={{
					textAlign: 'center',
					marginBottom: '30px',
				}}
			>
				Login with existing account
			</h3>
			<form onSubmit={handleSubmit(onSubmitLogin)}>
				<label htmlFor="name">
					Email Address <span>*</span>
				</label>
				<input
					{...register('email')}
					required
					id="email"
					type="text"
					placeholder="Enter Email address..."
				/>

				<label htmlFor="pass">
					Password <span>*</span>
				</label>
				<input
					{...register('password')}
					required
					id="pass"
					type="password"
					placeholder="Enter password..."
					className={'mb-4'}
				/>

				<Button label="Login" variant="primary" type="submit" fullWidth large />
				<div className={styles.orDivide}>
					<span>or</span>
				</div>

				<Button
					label="Register"
					variant="secondary"
					onClick={() => setCheckoutStep('register')}
					fullWidth
					large
				/>
			</form>
		</div>
	);
};

export default Login;
