import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import ServiceTab from './ServiceTab';
import diagnosticData from '../../data/screening-diagnostics.json';
import doctors from '../../data/doctor-profile.json';
import { formatPrice } from '../../helpers';

const ScreeningDiagnostics = () => {
	return (
		<>
			<Header fullTitle={'Screening & Diagnostics | Sirkka Health'} />
			<section className="screening-diagnostics">
				<div className="container mt-30 screening-header">
					<div className="row">
						<div className="col-12">
							<h1>Screening &amp; Diagnostics</h1>
							<p>
								Health awareness is empowering. Choose from a range of Sirkka's health screening
								tests to gain a better understanding of your health. We provide flexible and
								convenient testing, packaged or bespoke, at our clinics as well as home or work
								place visits.
							</p>
							<p>
								<small>
									<i>
										Note: The prices for all tests that require blood draw include the phlebotomy
										cost.
									</i>
								</small>
							</p>
							<p>
								Important: For maximum accuracy, we ask that you fast for at least 6 hours before
								all blood tests. Please drink plenty of water to stay hydrated. Please follow the
								test preparation instructions in your booking confirmation email.
							</p>
							<p>
								Note: After completing your purchase, if you do not receive a booking confirmation
								email within 5-10 minutes, please check your junk/spam email folder for the booking
								confirmation email or email care@sirkkaltd.com and medsolutions@sirkkaltd.com.
							</p>
							<Link to="/contact">
								<u>Need help? Get in touch</u>
							</Link>
						</div>
						{/* <div className="col-12">
							<h1>Screening & Diagnostics</h1>
							<p>
								Health awareness is empowering. Choose from a range of Sirkka's health screening
								tests to gain a better understanding of your health. We provide flexible and
								convenient testing, packaged or bespoke, at our clinics as well as home or work
								place visits.
							</p>
							<p>
								<small>
									<i>
										Note: The prices for all tests that require blood draw include the phlebotomy
										cost.
									</i>
								</small>
							</p>
							<Link to="/contact">
								<u>Need help? Get in touch</u>
							</Link>
						</div> */}
					</div>
				</div>
				<div className="row screening-content">
					<div className="col-12">
						<ServiceTab>
							<div label="Health Screening">
								<div className="row">
									{diagnosticData.screening.map((eachService) => (
										<div className="product-card d-flex col-12 col-md-6" key={eachService}>
											<Link to={`${eachService.link}`} className="card-content w-100">
												<img
													src={`./img/icon/${eachService.icon}.png`}
													className="product-image"
													alt=""
												/>
												<div>
													<div className="d-flex flex-column flex-sm-row">
														<h4 className="product-title">{eachService.name}</h4>
														{eachService.price && (
															<h4 className="product-price ms-sm-auto">
																{formatPrice({
																	price: eachService.price,
																})}
															</h4>
														)}
													</div>

													<p className="card-content-body">{eachService.overview_text}</p>
												</div>
											</Link>
										</div>
									))}
								</div>
							</div>
							<div label="Individual Tests">
								<div className="row">
									{diagnosticData.individualTest.map((eachService) => (
										<div className="product-card d-flex col-12 col-md-6" key={eachService}>
											<Link to={`${eachService.link}`} className="card-content w-100">
												<img
													src={`./img/icon/${eachService.icon}.png`}
													className="product-image"
													alt=""
												/>
												<div>
													<div className="d-flex flex-column flex-sm-row">
														<h4 className="product-title">{eachService.name}</h4>
														{eachService.price && (
															<h4 className="product-price ms-sm-auto">
																{formatPrice({
																	price: eachService.price,
																})}
															</h4>
														)}
													</div>

													<p className="card-content-body">{eachService.overview_text}</p>
												</div>
											</Link>
										</div>
									))}
								</div>
							</div>
							<div label="Imaging">
								<div className="row">
									{diagnosticData.imaging.map((eachService) => (
										<div className="product-card d-flex col-12 col-md-6" key={eachService}>
											<Link to={`${eachService.link}`} className="card-content w-100">
												<img
													src={`./img/icon/${eachService.icon}.png`}
													className="product-image"
													alt=""
												/>
												<div>
													<div className="d-flex flex-column flex-sm-row">
														<h4 className="product-title">{eachService.name}</h4>
														{eachService.price && (
															<h4 className="product-price ms-sm-auto">
																{formatPrice({
																	price: eachService.price,
																})}
															</h4>
														)}
													</div>

													<p className="card-content-body">{eachService.overview_text}</p>
												</div>
											</Link>
										</div>
									))}
								</div>{' '}
							</div>
							<div label="GP">
								<div className="row">
									{/******************/}
									{doctors.gpDoctors.map((doctor, index) => (
										<div key={index} className="col-xl-4 col-lg-6 col-md-6">
											<div key={index}>
												<button
													type="button"
													className="unstyled-button w-100"
													data-bs-toggle="modal"
													data-bs-target={'#doctor-' + doctor.id}
												>
													<div className="team-wrapper team-box-2 text-center mb-30">
														<div className="team-thumb">
															<img src={`img/doctor/${doctor.avatar}`} alt="" />
														</div>
														<div className="team-member-info mt-20 mb-15">
															<h4 className="mb-15">{doctor.name}</h4>
															<h5 className="f-500 text-up-case letter-spacing blue-color">
																{doctor.title}
															</h5>
														</div>
														<div className="team-social-profile mb-15">
															{(doctor.tags || []).map((tag, index) => (
																<span
																	key={index}
																	className="badge badge-pill badge-light black-color m-1"
																>
																	{tag}
																</span>
															))}
														</div>
													</div>
												</button>
											</div>

											{/*** Begin modal ***/}
											<div
												className="modal fade"
												id={'doctor-' + doctor.id}
												tabIndex="-1"
												role="dialog"
												aria-labelledby="doctorProfileCenterTitle"
												aria-hidden="true"
											>
												<div className="modal-dialog modal-lg" role="document">
													<div className="modal-content">
														<div className="modal-header profile-bg justify-content-end">
															{/* <button
														type="button"
														className="unstyled-button"
														data-bs-dismiss="modal"
													>
														<i className="fas fa-arrow-left"></i>&nbsp; Back to doctor list
													</button> */}
															<button
																type="button"
																className="close text-white"
																data-bs-dismiss="modal"
																aria-label="Close"
															>
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
														<div className="modal-body profile-bg pb-50"></div>
														<div className="modal-body">
															<div className="container-fluid">
																<div className="row">
																	<div className="col-xl-12">
																		<div className="team-thumb mt-n80 mb-20 d-block">
																			<img
																				src={`img/doctor/${doctor.avatar}`}
																				alt={`${doctor.name}`}
																			/>
																		</div>
																	</div>
																	<div className="col-xl-12 text-center ">
																		<h3 className="name">{doctor.name}</h3>
																		<h4 className="title mb-15">{doctor.title}</h4>
																		<h6 className="title mb-20">{doctor.attribution}</h6>
																		{doctor.credentials ? (
																			<h5 className="credentials mb-20">
																				<i className="fas fa-solid fa-graduation-cap"> </i>

																				<ul className="d-flex flex-wrap">
																					{doctor.credentials.map((credential, index) => (
																						<li key={index} className="text-start">
																							{credential}
																						</li>
																					))}
																				</ul>
																			</h5>
																		) : null}
																		{doctor.profile.map((paragraph, index) => (
																			<p key={index} className="text-start px-sm-5">
																				{paragraph}
																			</p>
																		))}
																		{doctor.url ? (
																			<a href={doctor.url} className="d-block text-start">
																				<button type="button" className="btn btn-light btn-sm">
																					Read more
																				</button>
																			</a>
																		) : null}
																	</div>
																</div>
															</div>
														</div>
														<div className="modal-footer d-block gap-2 d-md-flex justify-content-between">
															<a href="/booking/virtual/virtualGP" className="mx-0">
																<button type="button" className="btn btn-primary w-100 mx-0">
																	Book virtual appointment
																</button>
															</a>
															<a href="/contact" className="mx-0">
																<button type="button" className="btn btn-primary w-100 mx-0">
																	Book face-to-face appointment
																</button>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
									{/******************/}
									{/* {diagnosticData.gpdoctor.map(
										(eachService) => (
											<div
												className="product-card d-flex col-12 col-md-6"
												key={eachService}
											>
												<Link
													to={`${eachService.link}`}
													className="card-content w-100"
												>
													<img
														src={`./img/icon/${eachService.icon}.png`}
														className="product-image"
														alt=""
													/>
													<div>
														<div className="d-flex flex-column flex-sm-row">
															<h4 className="product-title">
																{eachService.name}
															</h4>
															{eachService.price && (
																<h4 className="product-price ms-sm-auto">
																	{formatPrice({
																		price:
																			eachService.price,
																	})}
																</h4>
															)}
														</div>

														<p className="card-content-body">
															{eachService.overview_text}
														</p>
													</div>
												</Link>
											</div>
										)
									)} */}
								</div>{' '}
							</div>
							<div label="Phlebotomy">
								<div className="row">
									{diagnosticData.phlebotomy.map((eachService) => (
										<div className="product-card d-flex col-12 col-md-6" key={eachService}>
											<Link to={`${eachService.link}`} className="card-content w-100">
												<img
													src={`./img/icon/${eachService.icon}.png`}
													className="product-image"
													alt=""
												/>
												<div>
													<div className="d-flex flex-column flex-sm-row">
														<h4 className="product-title">{eachService.name}</h4>
														{eachService.price && (
															<h4 className="product-price ms-sm-auto">
																{formatPrice({
																	price: eachService.price,
																})}
															</h4>
														)}
													</div>

													<p className="card-content-body">{eachService.overview_text}</p>
												</div>
											</Link>
										</div>
									))}
								</div>{' '}
							</div>
						</ServiceTab>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default ScreeningDiagnostics;
