import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Order.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import formatPrice from '../../helpers/formatPrice';
import { ReactSession } from 'react-client-session';

const Order = () => {
	const orderDetails = ReactSession.get('membership') || ReactSession.get('cart') || [];
	const getTotal = (cartDetails) => {
		return cartDetails.reduce((sum, { price, qty }) => sum + price * qty, 0);
	};

	const navigate = useNavigate();

	return (
		<div className={styles.cartSummary}>
			<div className={styles.cartSummaryInner}>
				<h2>Your order</h2>
				<p>In just a few steps you'll be a Sirkka Health member!</p>
				<ul>
					{orderDetails.map((product, index) => (
						<li key={index}>
							<img
								className={styles.productImg}
								src={
									product.icon ? `/img/icon/${product.icon}.png` : '/img/icon/health_screening.png'
								}
								alt={product.name}
							/>
							<div className={styles.name}>{product.name}</div>

							<span>({product.qty === 1 ? `Annually` : `${product.qty} months`})</span>
							<div className={styles.price}>
								{formatPrice({
									price: product.price,
								})}{' '}
								{product.qty === 1 ? `` : ` /mo`}
							</div>
						</li>
					))}
				</ul>
			</div>

			<div className={styles.total}>
				<Button
					variant="border"
					label="Change membership"
					onClick={() => {
						window.location.href = '/membership/individual';
					}}
				/>
				<span>
					Total:{' '}
					{formatPrice({
						price: getTotal(orderDetails),
					})}
				</span>
			</div>
		</div>
	);
};

export default Order;
