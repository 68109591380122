import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { verifyUser } from './helper';

async function handlePromiseID(stripePromiseID) {
	return await stripePromiseID;
}
async function verifyUserIdentity(stripePromise, clientSecret) {
	return await stripePromise.verifyIdentity(clientSecret);
}

const VerifyButton = ({ stripePromiseID, type, user }) => {
	const [stripePromise, setStripePromise] = useState({});

	useEffect(() => {
		async function handlePromise() {
			setStripePromise(await handlePromiseID(stripePromiseID));
		}
		handlePromise();
	}, [stripePromiseID]);

	const [clientSecret, setClientSecret] = useState({
		secretID: undefined,
		verificationSession: undefined,
	});

	const handleVerify = async (e) => {
		e.preventDefault();

		if (clientSecret.secretID) {
			const { error } = await verifyUserIdentity(stripePromise, clientSecret.secretID);
			if (error) {
				let clientSecretRequestVerify;
				if (type === 'verify') {
					clientSecretRequestVerify = await verifyUser(user);
				}
				if (clientSecretRequestVerify) {
					setClientSecret({
						secretID: clientSecretRequestVerify.clientSecret,
						verificationSession: clientSecretRequestVerify.verificationID,
					});
				}
			} else {
				window.location.reload();
			}
		}
	};

	if (!stripePromise) {
		return;
	}

	const getSecret = async () => {
		let clientSecretRequestVerify;
		if (type === 'verify') {
			clientSecretRequestVerify = await verifyUser(user);
		}
		if (clientSecretRequestVerify) {
			setClientSecret({
				secretID: clientSecretRequestVerify.clientSecret,
				verificationSession: clientSecretRequestVerify.verificationSession,
			});
		}
	};

	if (!clientSecret.secretID) {
		getSecret();
		return;
	}

	return (
		<div style={{ width: '100% !important' }} className="test">
			<Button
				className="primary_btn theme-btn"
				onClick={(e) => handleVerify(e)}
				style={{ width: '100%' }}
				role="link"
			>
				Upload Document
			</Button>
		</div>
	);
};

export default VerifyButton;
