import { useEffect, useState } from 'react';
import { Footer, Header, Loader, Modal } from '@/components';
import CardList from '@/components/CardList';
import CardListFilter from '@/components/CardListFilter';
import { Entity, ServiceData } from '@/types';
import ServiceModalContent from '@/components/ModalContents/ServiceModalContent';
import imagingData from '../../data/imagingData.json';
import fetchData from '@/helpers/dataFetch';
import { Hero } from '@/components';

const ImagingPage = () => {
	const [loading, setLoading] = useState(false);
	const [entityData, setEntityData] = useState<Entity[]>([]);
	const [serviceData, setServiceData] = useState<ServiceData | null>(null);
	const [totalEntity, setTotalEntity] = useState(0);
	const [hoveredCardId, setHoveredCardId] = useState<null | string>(null);
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedEntityItem, setSelectedEntityItem] = useState<any>(null);
	const [selectedServiceItem, setSelectedServiceItem] = useState<any>(null);
	const [totalDisplayed, setTotalDisplayed] = useState(0);

	useEffect(() => {
		const loadData = () => {
			setLoading(true);
			try {
				const entities = imagingData as Entity[];

				const total = entities.length;

				if (total > 0) {
					setEntityData(entities);
					setTotalEntity(total);
				} else {
					console.error('No entities found in the data');
				}
			} catch (error) {
				console.error('Error loading data:', error);
			} finally {
				setLoading(false);
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		const fetchServiceData = async (serviceId: string) => {
			try {
				await fetchData(`/bookings/service/${serviceId}`, (response) => {
					if (response) {
						const service = response[0];
						setServiceData({
							service: {
								...service,
								service_bookable: service.bookable,
							},
							category: { service_category_id: service.location_category_id },
							content: { ...service, service_icon: service.service_icon },
							location: { ...service, location_category: service.city || 'Virtual' },
						});
					} else {
						console.error('No service data found for the given serviceId');
					}
				});
			} catch (error) {
				console.error('Error fetching service data:', error);
			}
		};

		if (selectedServiceItem?.service_id) {
			fetchServiceData(selectedServiceItem.service_id);
		}
	}, [selectedServiceItem]);

	const handleOpenModal = (serviceItem: any, entityItem: any) => {
		setSelectedEntityItem(entityItem);
		setSelectedServiceItem(serviceItem);
		setModalOpen(true);
	};

	const handleCardHover = (cardId: string | null) => setHoveredCardId(cardId);
	const handleCardLeave = () => setHoveredCardId(null);
	const handleTotalDisplayedCards = (count: number) => setTotalDisplayed(count);

	const modalContent = {
		type: 'imaging',
		selectedServiceItem,
		selectedEntityItem,
		serviceData,
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Header title={'Imaging'} fullTitle={'Imaging'} />
			<Hero
				type="imaging"
				setEvents={() => {}}
				entityData={entityData}
				setFilteredEntityData={() => {}}
				isListingPage={true}
				onOpenModal={handleOpenModal}
				onCardHover={handleCardHover}
				onCardLeave={handleCardLeave}
				onTotalDisplayedCards={handleTotalDisplayedCards}
			/>
			<div className="container">
				<div className="row">
					<CardListFilter totalDisplayed={totalEntity} entityData={entityData} />
					{entityData.length === 0 ? (
						<div className="col-xl-7 col-lg-7 col-md-12">
							<div className="mb-4">
								<p>There are no results at the moment. Please try again later.</p>
							</div>
						</div>
					) : (
						<CardList
							type={'imaging'}
							entityData={entityData}
							onTotalDisplayedCards={handleTotalDisplayedCards}
							onOpenModal={handleOpenModal}
							onCardHover={handleCardHover}
							onCardLeave={handleCardLeave}
						/>
					)}
				</div>
				<Modal
					isOpen={isModalOpen}
					onClose={() => setModalOpen(false)}
					content={<ServiceModalContent {...modalContent} />}
				/>
			</div>
			<Footer />
		</>
	);
};

export default ImagingPage;
