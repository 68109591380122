import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Subscribe from './Subscribe';
import Checkout from './Checkout';
import {
	subscribeFlow,
	checkoutFlow,
	makeSubscriptionProductObject,
	makeCheckoutProductObject,
	makeCustomerObject,
} from './helper';
import config from '../../../config';

// Call loadStripe outside component to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(config.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = ({ cart, price, type, user, submitButton }) => {
	const [clientSecret, setClientSecret] = useState(null);
	const [paymentIntentId, setPaymentIntentId] = useState(null);

	const customerUserDetails = makeCustomerObject(user);

	let productPrice;
	if (cart && type === 'subscription') {
		productPrice = makeSubscriptionProductObject(cart[0]);
	}
	if (cart && type === 'checkout') {
		// This should be product name, not customer name
		productPrice = makeCheckoutProductObject(cart);
	}

	const getSecret = async () => {
		let clientSecretRequest;
		if (type === 'subscription') {
			clientSecretRequest = await subscribeFlow(productPrice, customerUserDetails);
		}
		if (type === 'checkout') {
			clientSecretRequest = await checkoutFlow(productPrice, customerUserDetails);
		}

		if (clientSecretRequest) {
			const { clientSecret, paymentIntentId } = clientSecretRequest;
			setClientSecret(clientSecret);
			setPaymentIntentId(paymentIntentId);
		}
	};

	// <Elements> must be furnished with a correct client secret (i.e. cannot be empty) on its initial render.
	// Get the secret and then stop this going any further without it.
	if (!clientSecret) {
		getSecret();
		return;
	}

	return (
		<Elements
			options={{
				clientSecret,
				appearance: { theme: 'stripe' },
			}}
			stripe={stripePromise}
		>
			{type === 'subscription' && (
				<Subscribe
					membershipDetails={cart[0]}
					customerDetails={customerUserDetails}
					submitButton={submitButton}
				/>
			)}
			{type === 'checkout' && (
				<Checkout price={price} submitButton={submitButton} transactionInfo={customerUserDetails} />
			)}
		</Elements>
	);
};

export default Stripe;
