import { Link } from 'react-router-dom';
import styles from './Notifications.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const Notifications = ({ notificationData }) => {
	//TODO - To be completed once we have a notifications API

	if (!notificationData) {
		return (
			<div className={styles.notifications}>
				<h3 className={styles.title}>My notifications</h3>
				<div
					style={{ height: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px' }}
				>
					You're all caught up
				</div>
			</div>
		);
	}
	return (
		<div className={styles.notifications}>
			<h3 className={styles.title}>My notifications</h3>
			<ul>
				<li>
					<h2>Thank you for your order</h2>
					<p>
						You will now receive an email with further instructions and the details of your order.
						Please make sure to...
					</p>
					<Button variant="primary" label="Read more" fullWidth />
				</li>
				<li>
					<h2>Thank you for your order</h2>
					<p>
						You will now receive an email with further instructions and the details of your order.
						Please make sure to...
					</p>
					<Button variant="primary" label="Read more" fullWidth />
				</li>
				<li>
					<a href="#" className={styles.viewMore}>
						View more
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Notifications;
