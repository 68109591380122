import { Link } from 'react-router-dom';
import styles from './UpcomingAppointment.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const NoAppointmentMessage = () => (
	<div style={{ height: '100%' }}>
		<h3>UPCOMING APPOINTMENT</h3>
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginTop: '40px',
				flexDirection: 'column',
			}}
		>
			<div style={{ marginBottom: '65px' }}>You have no upcoming appointments</div>
			<Link to="/booking/London">
				<Button variant="primary" label="Make appointment" fullWidth />
			</Link>
		</div>
	</div>
);

const UpcomingAppointment = ({ appointmentsData }) => {
	// If appointmentsData is not an array, return the "no appointments" message
	if (!Array.isArray(appointmentsData)) {
		return (
			<div className={styles.upcomingAppointment}>
				<NoAppointmentMessage />
			</div>
		);
	}

	// Filter appointments that are in the future
	const futureAppointments = appointmentsData.filter((appointment) => {
		const appointmentDate = new Date(appointment.appt_start_dt);
		const currentDate = new Date();

		return appointmentDate >= currentDate;
	});

	// Sort the future appointments by date
	futureAppointments.sort((a, b) => new Date(a.appt_start_dt) - new Date(b.appt_start_dt));

	// Get the closest future appointment
	const closestAppointment = futureAppointments[0];

	if (!closestAppointment) {
		return (
			<div className={styles.upcomingAppointment}>
				<NoAppointmentMessage />
			</div>
		);
	}

	const formattedDate = new Date(closestAppointment.appt_start_dt).toLocaleDateString();

	return (
		<div className={styles.upcomingAppointment}>
			<h3>UPCOMING APPOINTMENT</h3>
			<h2>{formattedDate}</h2>
			{/*<ul>*/}
			{/*	<li>{closestAppointment.service_name}</li>*/}
			{/*	<li>{`${closestAppointment.service_time} - ${formattedDate}`}</li>*/}
			{/*	<li>{closestAppointment.appt_location}</li>*/}
			{/*</ul>*/}
			<Link to="/appointments">
				<Button variant="primary" label="View details" fullWidth />
			</Link>
		</div>
	);
};

export default UpcomingAppointment;
