import { ReactSession } from 'react-client-session';
import PaymentDetailsForm from './PaymentDetailsForm';
import BusinessSubscriptionPayment from './BusinessSubscriptionPayment';

const Subscribe = ({
	type,
	paymentIntentClientSecret,
	setLoading,
	submitButton,
	customerUserDetails,
	onPaymentSuccess,
}) => {
	const customerDetails = customerUserDetails || ReactSession.get('personal_details');

	return (
		<div className="price-list">
			{type === 'subscription' && (
				<PaymentDetailsForm
					paymentIntentClientSecret={paymentIntentClientSecret}
					customerDetails={customerDetails}
					setLoading={setLoading}
					submitButton={submitButton}
				/>
			)}
			{type === 'business-subscription' && (
				<BusinessSubscriptionPayment
					customerDetails={customerDetails}
					submitButton={submitButton}
					onPaymentSuccess={onPaymentSuccess}
				/>
			)}
		</div>
	);
};

export default Subscribe;
