import { Link, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import newsData from '../../../data/news.json';

const NewsPage = () => {
	const { news_key } = useParams();

	const currentNews = newsData.find((news) => news.key === news_key) || {
		key: news_key,
	};

	return (
		<>
			<Header />
			<section className="blog-area pt-120 pb-80">
				<div className="container">
					<div className="row">
						<div className="col-lg-9">
							<article className="postbox post format-image mb-40">
								<div className="postbox__text bg-none">
									<div className="postbox__thumb mb-35">
										<img src={`/img/blog/details/${currentNews.thumbnail}`} alt="blog img" />
									</div>
									<div className="post-meta mb-15">
										<span>
											<i className="far fa-calendar-check"></i>
											{currentNews.upload_date}
										</span>
									</div>
									<h3 className="blog-title">{currentNews.title}</h3>
									<div className="post-text mb-20">
										{currentNews.description.map((description) => (
											<p key={description}>{description}</p>
										))}
										{currentNews.blockquote.length > 0 && (
											<blockquote>
												<p>{currentNews.blockquote}</p>
											</blockquote>
										)}
										{currentNews.description_2.length > 0 ? (
											<>
												{currentNews.description_2.map((description) => (
													<p key={description}>{description}</p>
												))}
											</>
										) : (
											<></>
										)}
										{currentNews.blockquote_2.length > 0 && (
											<blockquote>
												<p>{currentNews.blockquote_2}</p>
											</blockquote>
										)}
										{currentNews.description_3.length > 0 ? (
											<>
												{currentNews.description_3.map((description) => (
													<p key={description}>{description}</p>
												))}
											</>
										) : (
											<></>
										)}
										<p>
											Please visit our{' '}
											<Link to="/">
												<u>website</u>
											</Link>{' '}
											for more information about all our medical services. We look forward to
											welcoming you as a Sirkka member!
										</p>
									</div>
								</div>
							</article>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="navigation-border pt-50 mt-40"></div>
						</div>
						<div className="col-xl-5 col-lg-5 col-md-5">
							<div className="bakix-navigation b-next-post text-left mb-30">
								<span>
									<Link to="/news">
										<i className="fas fa-arrow-left"></i> Back to home
									</Link>
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default NewsPage;
