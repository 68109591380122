import { useEffect } from 'react';
import PaymentDetailsForm from './PaymentDetailsForm';
import { getStripePrice } from './helper';
import { ReactSession } from 'react-client-session';

const Checkout = ({ submitButton, transactionInfo, membershipData, price }) => {
	const customerDetails = ReactSession.get('user');

	let processedCustomerDetails;

	if (customerDetails !== undefined) {
		processedCustomerDetails = {
			customerId: customerDetails.customer_id,
			firstname: customerDetails.first_name,
			lastname: customerDetails.last_name,
			email: customerDetails.username || customerDetails.email_address,
			gender: customerDetails.gender || null,
			dateOfBirth: customerDetails.date_of_birth || null,
			notes: null,
		};
	}

	useEffect(() => {
		getStripePrice();
	}, []);

	return (
		<div className="price-list">
			<PaymentDetailsForm
				customerDetails={processedCustomerDetails}
				submitButton={submitButton}
				membershipData={membershipData}
				price={price}
			/>
		</div>
	);
};

export default Checkout;
