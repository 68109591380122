import React, { createContext, useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';

export const AuthContext = createContext();
ReactSession.setStoreType('localStorage');

const AllContext = ({ children }) => {
	const [user, setUser] = useState(ReactSession.get('user'));
	const [membershipData, setMembershipData] = useState(null);
	const [promoBannerVisible, setPromoBannerVisible] = useState(true);

	const userAuthenticated = (user, remember = false) => {
		const sessionLength = remember ? 20160 : 30;
		user.loggedInAt = Date.now();
		user.loggedInExpiry = new Date(user.loggedInAt + sessionLength * 60000);
		ReactSession.set('user', user);
		setUser(user);
	};

	const isAuthenticated = Boolean(user && Object.keys(user).length);

	const logout = () => {
		ReactSession.set('user', {});
		ReactSession.remove('user');
		localStorage.removeItem('__react_session__');
		setUser(null);
	};

	if (user && user.loggedInExpiry < Date.now()) {
		logout();
	}

	// Slick ArrowLeft
	const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')}
			aria-hidden="true"
			aria-disabled={currentSlide === 0 ? true : false}
			type="button"
		>
			<i className="fas fa-arrow-left"></i>
		</button>
	);
	// Slick Arrow Right
	const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={
				'slick-next slick-arrow' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
			}
			aria-hidden="true"
			aria-disabled={currentSlide === slideCount - 1 ? true : false}
			type="button"
		>
			<i className="fas fa-arrow-right"></i>
		</button>
	);

	const [stickyMenu, setStickyMenu] = useState(false);
	// sticky
	useEffect(() => {
		const stickyMenuBar = () => {
			if (window.scrollY > 80) {
				setStickyMenu(true);
			} else {
				setStickyMenu(false);
			}
		};
		window.addEventListener('scroll', stickyMenuBar);
	}, []);

	const [isOpen, setIsOpen] = useState(false);

	const value = {
		user,
		userAuthenticated,
		promoBannerVisible,
		setPromoBannerVisible,
		isAuthenticated,
		logout,
		isOpen,
		setIsOpen,
		stickyMenu,
		SlickArrowLeft,
		SlickArrowRight,
		membershipData,
		setMembershipData,
	};
	return (
		<>
			<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
		</>
	);
};

export default AllContext;
