import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendContact } from '../../../services/data';
import showAlert from '../../../helpers/alerts';
import { ReactSession } from 'react-client-session';

const ContactFormArea = (styles) => {
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = async (data) => {
		const response = await sendContact(data);

		if (response.ok) {
			showAlert(
				'Your message has been sent to us. Our team will reach out to you shortly.',
				null,
				'success'
			);
			reset();
		} else {
			showAlert('Failed to send message. Please check your details and try again.');
		}
	};

	const [user, setUser] = useState(ReactSession.get('user') || null);

	return (
		<>
			<section className="contact-form-area mt-35 pb-100" style={styles.style}>
				<div className="container">
					<div className="form-wrapper">
						<div className="row align-items-center">
							<div className="col-xl-8 col-lg-8">
								<div className="section-title mb-35">
									<h1>Contact us</h1>
								</div>
							</div>
							<div className="col-xl-4 col-lg-3 d-none d-xl-block ">
								{/* <div className="section-link mb-80 text-end">
                  <Link to="/" className="primary_btn btn-icon ml-0">
                    <span>+</span>Make Appointment
                  </Link>
                </div> */}
							</div>
						</div>
						<div className="contact-form">
							<form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-box user-icon mb-30">
											<input
												type="text"
												placeholder="First Name"
												{...register('firstname')}
												{...(user && { defaultValue: user.first_name })}
												required
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-box user-icon mb-30">
											<input
												type="text"
												{...register('lastname')}
												{...(user && { defaultValue: user.last_name })}
												required
												placeholder="Last Name"
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-box email-icon mb-30">
											<input
												type="text"
												{...register('email')}
												{...(user && { defaultValue: user.email_address })}
												required
												placeholder="Email Address"
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-box phone-icon mb-30">
											<input
												type="text"
												{...register('phone')}
												{...(user && { defaultValue: user.contact_number })}
												required
												placeholder="Phone Number"
											/>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-box subject-icon mb-30">
											<select {...register('subject')} required>
												<option disabled selected>
													Select a subject
												</option>
												<option value="Health Screening">Health Screening</option>
												<option value="Diagnostics">Diagnostics</option>
												<option value="Doctor Consultation">Doctor Consultation</option>
												<option value="Appointment Booking">Appointment Booking</option>
												<option value="Reschedule Appointment">
													Reschedule Existing Appointment
												</option>
												<option value="Appointment Cancellation">Appointment Cancellation</option>
												<option value="Refund">Refund</option>
												<option value="Feedbacks Suggestions">Feedbacks / Suggestions</option>
												<option value="Other">Other</option>
											</select>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-box message-icon mb-30">
											<textarea
												{...register('message')}
												id="message"
												cols="30"
												rows="10"
												placeholder="Your Message"
											></textarea>
										</div>
										<div className="contact-btn text-center">
											<button className="primary_btn ml-0" type="submit">
												Send message
											</button>
										</div>
									</div>
								</div>
							</form>
							<p className="ajax-response text-center"></p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ContactFormArea;
