import Header from '../../components/Header';
import Footer from '../../components/Footer';

const PaymentSuccess = ({ message }) => {
	return (
		<>
			<Header />
			<div className="content success-page-cont mt-50 mb-50">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="card success-card">
								<div className="card-body">
									<div className="success-cont">
										<i className="fas fa-check"></i>
										<h3>Payment completed successfully!</h3>
										{/*<p>{message}</p>*/}
										{/* <Link to="/customer-dashboard" className="btn btn-primary view-inv-btn">My Account</Link> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default PaymentSuccess;
