import { useForm } from 'react-hook-form';
import React, { useContext, useState } from 'react';
import { checkDelegateAccountData } from '../../../validations/registrationForm';
import { createAccount } from '../../../services/data';
import showAlert from '../../../helpers/alerts';
import { ErrorMessage } from '@hookform/error-message';
import { formatEmail } from '../../../validations/email';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import DashboardSidebar from '../components/DashboardSidebar';
import { AuthContext } from '../../../context/AllContext';

const RegisterDelegateAccount = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const registrationType = searchParams.get('type');

	const { user } = useContext(AuthContext);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	const [passwordShown] = useState(false);

	const onSubmit = async (data) => {
		if (registrationType === 'business') {
			data.role = 'business';

			// Update delegated account indicator
			data.accountOwnerId = user.user_id;
			data.delegatedAccount = true;
			data.company = user.company;
			data.companyRegistration = user.company_reg_number;
			data.phoneNumber = user.contact_number;
		}

		const valid = checkDelegateAccountData(data);
		let errorMessage = valid;

		if (valid === 'valid') {
			const response = await createAccount(data);
			if (response.status === 'success') {
				showAlert(
					'You have successfully created an account for your staff',
					'Account Created',
					'success',
					true,
					true,
					false,
					'/customer-dashboard',
					navigate
				);
			} else {
				errorMessage = response.message;
				showAlert(errorMessage);
			}
		} else {
			showAlert(errorMessage);
		}
	};
	const onError = (error, e) => {
		console.log(error, e);
	};

	return (
		<>
			<Header title="Portal" />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<h3 className="text-center mb-30">Create an account for your staff</h3>
						<form onSubmit={handleSubmit(onSubmit, onError)} className="formGroup">
							<label htmlFor="nameTitle" className="mb-2">
								Title <span>*</span>
							</label>
							<select {...register('nameTitle', { required: true })} required>
								<option value="" disabled selected>
									Please select one
								</option>
								<option value="Mr">Mr</option>
								<option value="Miss">Miss</option>
								<option value="Mrs">Mrs</option>
								<option value="Other">Other</option>
							</select>
							<label htmlFor="firstName" className="mb-2">
								First name <span>*</span>
							</label>
							<input
								{...register('firstName', {
									required: '',
								})}
								required
								id="firstName"
								type="text"
								placeholder="Enter first name"
							/>
							<ErrorMessage errors={errors} name="firstName" />
							<label htmlFor="lastName" className="mb-2">
								Last name <span>*</span>
							</label>
							<input
								{...register('lastName', { required: true })}
								required
								id="lastName"
								type="text"
								placeholder="Enter last name"
							/>
							<ErrorMessage errors={errors} name="lastName" />
							<label htmlFor="occupation" className="mb-2">
								Position in the company
							</label>
							<input {...register('occupation')} id="occupation" name="occupation" type="text" />
							<label htmlFor="email-id" className="mb-2">
								Email address <span>*</span>
							</label>
							<input
								{...register('email', {
									setValueAs: (v) => formatEmail(v),
								})}
								required
								id="email-id"
								name="email"
								type="text"
								placeholder="Enter Username or Email address"
							/>
							<ErrorMessage errors={errors} name="email" />
							<label htmlFor="pass" className="mb-2">
								Password <span>*</span>
							</label>
							<input
								{...register('password')}
								required
								id="pass"
								name="password"
								type={passwordShown ? 'text' : 'password'}
								placeholder="Enter password"
							/>
							<ErrorMessage errors={errors} name="password" />
							<label htmlFor="pass" className="mb-2">
								Confirm password <span>*</span>
							</label>
							<input
								{...register('confirmPassword')}
								required
								id="confirm-pass"
								name="confirmPassword"
								type={passwordShown ? 'text' : 'password'}
								placeholder="Confirm password"
							/>
							<ErrorMessage errors={errors} name="confirmPassword" />
							<button type="submit" className="primary_btn w-100 mt-20 mb-20">
								Register
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegisterDelegateAccount;
