import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ProductDetails from './ProductDetails';
import Faqs from '../FAQS';
import { getProductById, getFaqsForProduct } from '../../services/data';

const Product = () => {
	const { product_key } = useParams();

	const product = getProductById(product_key);
	const faqs = getFaqsForProduct(product_key);

	return (
		<>
			<Header title={product.name} />
			{product && <ProductDetails product={product} product_key={product_key} />}
			{faqs && <Faqs faqs={faqs} />}
			<Footer />
		</>
	);
};

export default Product;
