import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import DashboardSidebar from './components/DashboardSidebar';
import {
	getMemberships,
	getUserSubscription,
	renewSubscription,
	cancelSubscription,
} from '../../services/data';
import useAuth from '../../hooks/useAuth';
import UpdateSubscription from './updateSubscription/UpdateSubscription';
import { addToStore } from '../../helpers/store';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { Button } from '@sirkka-health/booking-system-ui';
import styles from './components/SubscriptionPlan/subscriptionPlan.module.scss';

const Subscription = () => {
	const { user } = useAuth();
	const { customer_id: userID } = user;
	const membershipsData = getMemberships();
	const [membership, setMembership] = useState(null);
	const [toggle, setToggle] = useState();
	const [subscriptions, setSubscriptions] = useState([]);
	const [subscriptionID, setSubscriptionID] = useState();
	const navigate = useNavigate();
	const [membershipData, setMembershipData] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/memberships?userID=${user.customer_id}`
				);
				const data = await response.json();
				setMembershipData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id]);

	const cancelledMessage = (status) => {
		if (status === 'cancelled') {
			return 'Your subscription will be cancelled on ';
		}
	};

	const toggleChangeSubscription = (toggleState, subscriptionID) => {
		setToggle(toggleState);
		setSubscriptionID(subscriptionID);
		return true;
	};

	useEffect(() => {
		const fetchData = async () => {
			const response = await getUserSubscription(userID);
			if (response) setSubscriptions(response);
		};

		fetchData();
	}, [userID]);

	useEffect(() => {
		if (membership && Object.keys(membership).length) {
			//    if(membership.mode==="monthly") membership.qty = 3;
			// changeUserSubscription(userID, membership);
			addToStore('membership', [membership]);
			navigate('/checkout/payment');
		}
	}, [membership, navigate]);

	// if(!subscriptions.length) return <div>No subscriptions to display</div>

	return (
		<div style={{ height: '100vh' }}>
			<Header />
			<div className="container mt-25">
				<div className="row" style={{ height: '85vh' }}>
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9" style={{ height: '100%' }}>
						<div
							style={{
								height: toggle ? '100%' : 'fit-content',
							}}
						>
							<div className="card-header">
								<h4 className="card-title">Subscription</h4>
							</div>
							<div className="card-body" style={{ height: '100%' }}>
								<table className="table table-hover table-center align-middle mb-0">
									<thead>
										<tr>
											<th>Membership Plan</th>
											<th>Subscription Date</th>
											<th>Expiry Date</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{subscriptions.map((subscription) => (
											<tr key={subscription.id}>
												<td className="fw-bold">{subscription.plan}</td>
												<td>{subscription.startDate}</td>
												<td>
													{cancelledMessage(subscription.status)}
													{subscription.expiryDate}
												</td>
												{subscription.status !== 'cancelled' && (
													<td>
														<a href="/membership" className={styles.secondaryCta}>
															<Button variant="primary" label="Upgrade Membership" fullWidth />
														</a>
														{/* <div>
															<button
																className="badge bg-success"
																style={{
																	border: '2px solid green',
																}}
																onClick={() => toggleChangeSubscription(!toggle, subscription.id)}
															>
																Change
															</button>
														</div>
														<div>
															<button
																className="badge bg-warning"
																style={{ border: '2px solid #ffc107' }}
																data-bs-toggle="modal"
																data-bs-target="#renewModal"
																onClick={() => setSubscriptionID(subscription.id)}
															>
																Renew
															</button>
														</div>
														<div>
															<button
																className="badge bg-danger"
																style={{
																	border: '2px solid #dc3545',
																}}
																data-bs-toggle="modal"
																data-bs-target="#cancelModal"
																onClick={() => setSubscriptionID(subscription.id)}
															>
																Cancel
															</button>
														</div> */}
													</td>
												)}
											</tr>
										))}
									</tbody>
								</table>

								<UpdateSubscription
									membershipsData={membershipsData}
									setMembership={setMembership}
									toggle={toggle}
								/>

								{/* renew Modal */}
								<div
									className="modal fade"
									id="renewModal"
									tabIndex="-1"
									aria-labelledby="renewModalLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
										<div className="modal-content">
											<div className="modal-header">
												<h1 className="modal-title fs-5" id="exampleModalLabel">
													Renew Membership
												</h1>
												<button
													type="button"
													className="btn-close bg-danger position-static"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											<div id="pricing" className="modal-body pricing-area">
												Are you sure you want to Renew Membership?
											</div>
											<div className="modal-footer">
												<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
													No
												</button>
												<button
													type="button"
													className="btn btn-primary"
													data-bs-dismiss="modal"
													onClick={() => renewSubscription(userID)}
												>
													Yes
												</button>
											</div>
										</div>
									</div>
								</div>

								{/* cancel Modal */}
								<div
									className="modal fade"
									id="cancelModal"
									tabIndex="-1"
									aria-labelledby="renewModalLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
										<div className="modal-content">
											<div className="modal-header">
												<h1 className="modal-title fs-5" id="exampleModalLabel">
													Cancel Membership
												</h1>
												<button
													type="button"
													className="btn-close bg-danger position-static"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											<div id="pricing" className="modal-body pricing-area">
												Are you sure you want to Cancel Membership?
											</div>
											<div className="modal-footer">
												<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
													No
												</button>
												<button
													type="button"
													className="btn btn-primary"
													data-bs-dismiss="modal"
													onClick={async () =>
														setSubscriptions(await cancelSubscription(subscriptionID))
													}
												>
													Yes
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subscription;
