const formatHeading = (key, capitalize = 'first') => {
	// Specific changes
	key = key.replace('faqs', 'FAQs');

	// Generic mutations
	key = key.split('_').join(' ');

	key =
		capitalize === 'word'
			? key
					.split(' ')
					.map((word) => capitaliseFirst(word))
					.join(' ')
			: capitaliseFirst(key);

	return key;
};

const capitaliseFirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export default formatHeading;
