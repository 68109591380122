import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useURLParams = (
	dataHasLoaded,
	setSelectedServiceCategories,
	setSelectedSpecialistCategories,
	serviceCategories,
	servicesAndSpecialists,
	setServiceModalDataFromParams,
	selectedServiceCategories,
	selectedSpecialistCategories,
	disclaimerHasBeenShown,
	setDisclaimerModal,
	filters,
	setFilters,
	setSpecialistModalDataFromParams
) => {
	const query = new URLSearchParams(useLocation().search);
	const navigate = useNavigate();
	const [initialParamsSet, setInitialParamsSet] = useState(false);

	const serviceFilterParams = query.get('service');
	const specialistFilterParams = query.get('specialist');
	const serviceCodeParam = query.get('serviceCode');
	const specialistCodeParam = query.get('specialistCode');
	const availableLocationsParam = query.get('availableLocations');
	const referrerParam = query.get('referral');

	const updateURLParameters = (replace = false) => {
		const searchParams = new URLSearchParams();

		if (filters.selectedCategoryIds.length) {
			searchParams.set('service', filters.selectedCategoryIds.join(','));
		} else {
			searchParams.delete('service');
		}

		if (filters.selectedSpecialistCategories.length) {
			searchParams.set('specialist', filters.selectedSpecialistCategories.join(','));
		} else {
			searchParams.delete('specialist');
		}

		if (serviceCodeParam) {
			searchParams.set('serviceCode', serviceCodeParam);
		} else {
			searchParams.delete('serviceCode');
		}
		if (specialistCodeParam) {
			searchParams.set('specialistCode', specialistCodeParam);
		} else {
			searchParams.delete('specialistCode');
		}
		if (availableLocationsParam) {
			searchParams.set('availableLocations', availableLocationsParam);
		}
		if (referrerParam) {
			searchParams.set('referral', referrerParam);
		}

		navigate({ search: searchParams.toString() }, { replace });
	};

	// When there are params in the URL, do something with those params
	useEffect(() => {
		if (dataHasLoaded) {
			setInitialParamsSet(true);

			// Service filter params
			if (serviceFilterParams?.length) {
				const parsedService = serviceFilterParams.split(',').map(Number);
				setFilters((prevFilters) => ({
					...prevFilters,
					selectedCategoryIds: parsedService,
				}));
			}

			// Specialist filter params
			if (specialistFilterParams?.length) {
				const parsedSpecialist = specialistFilterParams.split(',');
				setFilters((prevFilters) => ({
					...prevFilters,
					selectedSpecialistCategories: parsedSpecialist,
				}));
			}

			// If service code param exists, open the modal with that service
			if (serviceCodeParam?.length) {
				const itemMatched = servicesAndSpecialists.find((item) => {
					return item.service?.service_code === serviceCodeParam;
				});
				if (itemMatched) {
					setServiceModalDataFromParams(itemMatched);
				} else {
					console.log('Error: No matching service found for modal');
				}
			}

			// If specialist code param exists, open the modal with that specialist
			if (specialistCodeParam?.length) {
				const specialistMatched = servicesAndSpecialists.find((item) => {
					if (!item?.user_id) {
						return false;
					}
					return item.user_id === Number(specialistCodeParam);
				});

				if (specialistMatched) {
					setSpecialistModalDataFromParams(specialistMatched);
				} else {
					console.log('Error: No matching specialist found for modal');
				}
			}
		}
	}, [
		serviceCategories,
		specialistFilterParams,
		serviceFilterParams,
		serviceCodeParam,
		dataHasLoaded,
		setSelectedServiceCategories,
		setSelectedSpecialistCategories,
		setServiceModalDataFromParams,
		servicesAndSpecialists,
		specialistCodeParam,
		setSpecialistModalDataFromParams,
	]);

	useEffect(() => {
		if (initialParamsSet) {
			updateURLParameters(true);
		}
	}, [filters, initialParamsSet]);

	useEffect(() => {
		// If a user selects Individual Test or Health Screening and disclaimer has not been shown, show disclaimer modal
		if (
			(selectedServiceCategories.includes('Individual Test') ||
				selectedServiceCategories.includes('Health Screening')) &&
			!disclaimerHasBeenShown
		) {
			setDisclaimerModal(true);
		} else {
			setDisclaimerModal(false);
		}
	}, [selectedServiceCategories, selectedSpecialistCategories, disclaimerHasBeenShown]);

	return {
		serviceFilterParams,
		specialistFilterParams,
		serviceCodeParam,
		updateURLParameters,
	};
};

export default useURLParams;
