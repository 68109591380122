import checkEmail from './email';

const checkCustomerData = ({
	firstname,
	lastname,
	gender,
	dateOfBirth,
	companyName,
	address1,
	address2,
	city,
	county,
	postcode,
	country,
	email,
	phone,
	notes,
	occupation,
	sampleConsent,
	termsConsent,
	emailConsent,
}) => {
	const date = new Date();
	const birthDate = new Date();
	const today = date.toISOString().slice(0, 10);

	date.setFullYear(date.getFullYear() - 125);
	const ancient = date.toISOString().slice(0, 10);

	birthDate.setFullYear(birthDate.getFullYear() - 18);
	const ageLimit = birthDate.toISOString().slice(0, 10);

	if (dateOfBirth < ancient || dateOfBirth >= today) {
		return 'Invalid date of birth';
	}

	if (dateOfBirth > ageLimit) {
		return 'Only 18 years and above are eligible to purchase';
	}

	if (/^[0-9][0-9]*[1-9][0-9]{6,13}$/.test(phone) === false) {
		return 'Phone Number must contain only numbers and not symbols or spaces';
	}

	if (!checkEmail(email)) {
		return 'Email invalid';
	}

	if (firstname.length < 2) {
		return 'First name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(firstname) === false) {
		return 'First name can only contain alphabets';
	}

	if (lastname.length < 2) {
		return 'Last name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(lastname) === false) {
		return 'Last name can only contain alphabets';
	}

	if (!gender) {
		return 'Gender is required';
	}

	if (!city) {
		return 'City is required';
	}

	if (!country) {
		return 'Country is required';
	}

	if (!address1 || !postcode) {
		return 'Your address is required.';
	}

	if (!sampleConsent) {
		return 'You must consent to allow your samples to be taken';
	}

	if (!termsConsent) {
		return 'You must accept the terms and conditions';
	}

	return 'valid';
};

export default checkCustomerData;
