import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ResetPasswordArea from './ResetPasswordArea';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import showAlert from '../../helpers/alerts';
import { checkPasswordResetToken, setNewPassword } from '../../services/data';

const ResetPassword = () => {
	const { resetKey } = useParams();
	const [user, setUser] = useState(null);
	const [resetStatus, setResetStatus] = useState(null);
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		const { password } = data;

		if (!password) {
			return false;
		}

		const response = await setNewPassword(resetKey, password);

		if (response.status === 'success') {
			setResetStatus(true);
		} else {
			showAlert('Failed to reset your password. Please try again.');
		}
	};

	useEffect(() => {
		const checkToken = async (token) => {
			const response = await checkPasswordResetToken(token);

			if (response.status === 'success') {
				setUser(response.user);
			} else {
				navigate('/login');
			}
		};
		checkToken(resetKey);
	}, []);

	if (!user) return null;

	return (
		<>
			<Header title="Reset Password" />
			<ResetPasswordArea resetKey={resetKey} resetStatus={resetStatus} onSubmit={onSubmit} />
			<Footer />
		</>
	);
};

export default ResetPassword;
