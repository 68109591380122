import FooterContact from './footerContact';
import PrivateHealthcare from './privateHealthcare';
import MoreLinks from './moreLinks';
import OpeningHours from './openingHours';
import CopyRight from './copyRight';
import { Link } from 'react-router-dom';
const Footer = () => {
	return (
		<>
			<footer>
				<div className="footer-top h4_footer blue-bg pos-rel pt-40 px-3 px-sm-0">
					<div className="container">
						<div className="row justify-content-right">
							<FooterContact />
							<PrivateHealthcare />
							<MoreLinks />
							<OpeningHours />
						</div>
					</div>
				</div>
				<div className="footer-bottom pt-25 pb-20 px-3 px-sm-0">
					<div className="container">
						<div className="footer-logos">
							<img
								src="/img/logo/ico-logo.svg"
								className="ico"
								alt="Information Comissioner's Office"
							/>
							<img src="/img/logo/logo-white.png" className="sirkkalogo" alt="" />
							<img
								src="/img/logo/cyberessentials_certification.webp"
								className="cec"
								alt="cyberessentialscertified"
							/>{' '}
							<Link to="/"></Link>
						</div>
						<CopyRight />
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
