const billingObject = (data, userEmail) => {
	const billing_details = {
		firstname: data.firstname_billing,
		lastname: data.lastname_billing,
		company: data.company,
		address1: data.address1_billing,
		address2: data.address2_billing,
		city: data.city_billing,
		county: data.county_billing,
		postcode: data.postcode_billing,
		country: data.country_billing,
		email: userEmail,
		phone: data.phone,
	};
	return billing_details;
};

const customerObject = (data, userEmail, userPassword, referralId) => {
	const personal_info = {
		firstname: data.firstname,
		lastname: data.lastname,
		gender: data.gender,
		dateOfBirth: data.dateOfBirth,
		companyName: data.companyName,
		address1: data.address1,
		address2: data.address2,
		city: data.city,
		county: data.county,
		postcode: data.postcode,
		country: data.country,
		email: userEmail,
		password: userPassword,
		phone: data.phone,
		notes: data.notes,
		sampleConsent: data.sampleConsent,
		termsConsent: data.termsConsent,
		emailConsent: data.emailConsent,
		referralId: referralId,
	};
	return personal_info;
};

const productObject = (data) => {
	const product = {
		id: data.id,
		name: data.name,
		category: data.category,
		price: data.price,
		qty: data.qty,
		discount_applied: data.discount_applied ? data.discount_applied : null,
		appointment_duration: data.appointment_duration,
	};
	return product;
};

const orderItemObject = (booking, customerId) => {
	const orderItem = {
		customer_id: customerId,
		booking_name: booking.customerName,
		booking_email: booking.customerEmail,
		appt_location: booking.serviceLocation,
		appt_start_dt: booking.startDateTime,
		appt_end_dt: booking.endDateTime,
		scheduled_by: customerId || null,
		appt_status: 'Pending',
		appt_notes: booking.serviceNotes,
	};
	return orderItem;
};

export { billingObject, customerObject, productObject, orderItemObject };
