import React, { useState } from 'react';
import Postcoder from 'react-address-lookup';
import { Button } from '@sirkka-health/booking-system-ui';
import config from '../../../config';
import styles from './Register.module.scss';
import { createAccount } from '../../../services/data';

function Register({ setCheckoutStep }) {
	const [registrationStatus, setRegistrationStatus] = useState(false);
	const [visibleAddress, setIsVisibleAddress] = useState(false);

	const [formData, setFormData] = useState({
		address_line_1: '',
		address_line_2: '',
		city: '',
		company: '',
		confirmPassword: '',
		country: '',
		dob: '',
		email: '',
		firstName: '',
		gender: '',
		lastName: '',
		nameTitle: '',
		occupation: '',
		password: '',
		phoneNumber: '',
		postcode: '',
	});

	const useAddress = ({ addressline1, addressline2, posttown, postcode, country }) => {
		setFormData({
			...formData,
			address_line_1: addressline1,
			address_line_2: addressline2 || '',
			city: posttown,
			postcode,
			country: country || 'United Kingdom',
		});

		setIsVisibleAddress(true);
	};

	const [formErrors, setFormErrors] = useState({});

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const onSubmit = async (data) => {
		let errorMessage = null;

		const response = await createAccount(data);
		if (response.status === 'success') {
			return setRegistrationStatus(true);
		}
		errorMessage = response.message;

		if (errorMessage) {
			console.log('Submit error: ', errorMessage);
		}
		return false;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		window.scrollTo(0, 0);

		// validate form values
		const errors = {};

		if (!formData.nameTitle) {
			errors.nameTitle = 'Please enter your title';
		}

		if (!formData.firstName) {
			errors.firstName = 'Please enter your first name';
		} else if (!/^[a-zA-Z\s-]+$/.test(formData.firstName)) {
			errors.firstName = 'First name can only contain letters, hyphens, and spaces';
		}

		if (!formData.lastName) {
			errors.lastName = 'Please enter your last name';
		} else if (!/^[a-zA-Z\s-]+$/.test(formData.lastName)) {
			errors.lastName = 'Last name can only contain letters, hyphens, and spaces';
		}

		if (!formData.email) {
			errors.email = 'Please enter your email address';
		}

		if (!formData.password) {
			errors.password = 'Please enter your password';
		}

		if (!formData.confirmPassword) {
			errors.confirmPassword = 'Please confirm your password';
		}

		if (formData.password !== formData.confirmPassword) {
			errors.confirmPassword = 'Passwords do not match';
		}

		if (!formData.dob) {
			errors.dob = 'Please enter your date of birth';
		} else if (!/^\d{4}-\d{2}-\d{2}$/.test(formData.dob)) {
			errors.dob = 'Date of birth must be in the format of YYYY-MM-DD';
		}

		if (!formData.gender) {
			errors.gender = 'Please select a gender';
		}

		if (!formData.phoneNumber) {
			errors.phoneNumber = 'Please enter your phone number';
		} else if (!/^\d+$/.test(formData.phoneNumber)) {
			errors.phoneNumber = 'Phone number must contain only numbers';
		}

		if (!formData.address_line_1) {
			errors.address_line_1 = 'Please enter your address';
		}

		if (!formData.city) {
			errors.city = 'Please enter your city';
		}

		if (!formData.postcode) {
			errors.postcode = 'Please enter your postcode';
		}

		if (!formData.country) {
			errors.country = 'Please enter your country';
		}

		setFormErrors(errors);

		// submit form if no errors
		if (Object.keys(errors).length === 0) {
			// submit form logic here
			console.log('event', formData);
			onSubmit(formData);
			// onNextStep(); TODO: Skip the login after register, just login automatically after successful registration
		}
	};

	if (registrationStatus) {
		return (
			<div className={styles.registerForm}>
				<div style={{ maxWidth: '500px', margin: 'auto' }}>
					<h3 className="text-center mb-30">Account successfully created</h3>
					<p className="mb-5">Welcome to Sirkka! You can now login to continue your order.</p>
					<Button
						label="Login"
						variant="secondary"
						onClick={() => setCheckoutStep('login')}
						fullWidth
						large
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.registerForm}>
			<h3
				style={{
					textAlign: 'center',
					marginBottom: '30px',
				}}
			>
				Create an account
			</h3>
			<form onSubmit={handleSubmit}>
				{Object.keys(formErrors).length !== 0 ? (
					<div className={styles.formGroup}>
						<div className={styles.errorBox}>
							<h3>Please complete the missing fields below:</h3>
							{Object.keys(formErrors).map((fieldName) => {
								const errorMessage = formErrors[fieldName];
								if (errorMessage) {
									return (
										<p key={fieldName}>
											<a href={`#${fieldName}`}>{errorMessage}</a>
										</p>
									);
								}
								return null;
							})}
						</div>
					</div>
				) : (
					''
				)}

				<div className={styles.formGroup}>
					<label htmlFor="nameTitle">
						Title<span className={styles.required}>*</span>
					</label>
					<select
						id="nameTitle"
						name="nameTitle"
						value={formData.nameTitle}
						onChange={handleInputChange}
					>
						<option value=""> </option>
						<option value="Mr">Mr</option>
						<option value="Mrs">Mrs</option>
						<option value="Miss">Miss</option>
						<option value="Ms">Ms</option>
						<option value="Dr">Dr</option>
					</select>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="firstName">
						First Name
						<span className={styles.required}>*</span>
					</label>
					<input
						type="text"
						id="firstName"
						name="firstName"
						value={formData.firstName}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="lastName">
						Last Name
						<span className={styles.required}>*</span>
					</label>
					<input
						type="text"
						id="lastName"
						name="lastName"
						value={formData.lastName}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="gender">
						Gender<span className={styles.required}>*</span>
					</label>
					<select id="gender" name="gender" value={formData.gender} onChange={handleInputChange}>
						<option value=""> </option>
						<option value="Male">Male</option>
						<option value="Female">Female</option>
						<option value="Other">Other</option>
					</select>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="dob">
						Date of Birth
						<span className={styles.required}>*</span>:
					</label>
					<input
						type="date"
						id="dob"
						name="dob"
						value={formData.dob}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="phoneNumber">
						Phone Number
						<span className={styles.required}>*</span>
					</label>
					<input
						type="tel"
						id="phoneNumber"
						name="phoneNumber"
						value={formData.phoneNumber}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="address">
						Address
						<span className={styles.required}>*</span>
					</label>

					<Postcoder
						apiKey={config.REACT_APP_POSTCODER}
						addressSelectedCallback={useAddress}
						labelText=""
						placeholder="Search your postcode"
					/>
					{visibleAddress && (
						<>
							<label htmlFor="address_line_1" className="mb-2">
								Address line 1 <span className={styles.required}>*</span>
							</label>
							<input
								required
								id="address_line_1"
								name="address_line_1"
								type="text"
								value={formData.address_line_1}
								onChange={handleInputChange}
							/>

							<label htmlFor="address_line_2" className="mb-2">
								Address line 2
							</label>
							<input
								id="address_line_2"
								name="address_line_2"
								type="text"
								value={formData.address_line_2}
								onChange={handleInputChange}
							/>

							<label htmlFor="city" className="mb-2">
								City <span className={styles.required}>*</span>
							</label>
							<input
								required
								id="city"
								name="city"
								type="text"
								value={formData.city}
								onChange={handleInputChange}
							/>

							<label htmlFor="country" className="mb-2">
								Country <span className={styles.required}>*</span>
							</label>
							<input
								required
								id="country"
								name="country"
								type="text"
								value={formData.country}
								onChange={handleInputChange}
							/>

							<label htmlFor="postcode" className="mb-2">
								Postcode <span className={styles.required}>*</span>
							</label>
							<input
								required
								id="postcode"
								name="postcode"
								type="text"
								value={formData.postcode}
								onChange={handleInputChange}
							/>
						</>
					)}
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="company">Company</label>
					<input
						type="text"
						id="company"
						name="company"
						value={formData.company}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="occupation">Occupation</label>
					<input
						type="text"
						id="occupation"
						name="occupation"
						value={formData.occupation}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="email">
						Email<span className={styles.required}>*</span>
					</label>
					<input
						type="text"
						id="email"
						name="email"
						value={formData.email}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="password">
						Password
						<span className={styles.required}>*</span>
					</label>
					<input
						type="password"
						id="password"
						name="password"
						value={formData.password}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<label htmlFor="confirmPassword">
						Confirm your password
						<span className={styles.required}>*</span>
					</label>
					<input
						type="password"
						id="confirmPassword"
						name="confirmPassword"
						value={formData.confirmPassword}
						onChange={handleInputChange}
					/>
				</div>

				<div className={styles.formGroup}>
					<Button label="Create account" onClick={handleSubmit} variant="primary" fullWidth large />
					<div className={styles.orDivide}>
						<span>or</span>
					</div>
					<Button
						label="Login"
						variant="secondary"
						onClick={() => setCheckoutStep('login')}
						type="button"
						fullWidth
						large
					/>
				</div>
			</form>
		</div>
	);
}

export default Register;
