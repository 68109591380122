import React, { useState, useEffect } from 'react';
import { Button, TwoRadioSelection } from '@sirkka-health/booking-system-ui';
import './CustomiseMarkersModal.scss';
import config from '../../../config';

const CustomiseMarkersModal = ({
	showCustomiseMarkersModal,
	onClose,
	//customisedMarkersForm,
	// setCustomisedMarkersForm,
	// onCustomiseChange,
	onSubmitCustomisedMarkers,
}) => {
	const [loading, setLoading] = useState(false);
	const [markersData, setMarkersData] = useState([]);
	const [customisedMarkersForm, setCustomisedMarkersForm] = useState({
		hospital: '',
		vials_qty: '',
		vials_type: '',
		test_instructions: '',
		markers: [],
	});

	useEffect(() => {
		const fetchMarkers = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${config.REACT_APP_API_URL}/bookings/testMarkers`);
				let fetchedMarkers = await response.json();

				// Only enabled status
				fetchedMarkers = fetchedMarkers.filter((marker) => marker.status === true);

				// Sort markers by category
				const sortedMarkers = fetchedMarkers.sort((a, b) => a.category.localeCompare(b.category));

				// Organize markers by category
				const groupedMarkers = sortedMarkers.reduce((acc, marker) => {
					const category = marker.category;
					if (!acc[category]) {
						acc[category] = [];
					}
					acc[category].push({
						name: marker.marker_name,
						price: marker.marker_price,
					});
					return acc;
				}, {});

				setMarkersData(groupedMarkers);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchMarkers();
	}, []);

	const handleFormChange = (eventOrData) => {
		let name, value;

		if (eventOrData.target) {
			const { target } = eventOrData;
			name = target.name;
			value = target.value;
		} else {
			const { name: dataName, value: dataValue } = eventOrData;
			name = dataName;
			value = dataValue;
		}

		if (name === 'markers') {
			const [category, markerName] = value.split(':');

			const marker = markersData[category].find((m) => m.name === markerName);

			setCustomisedMarkersForm((prevForm) => {
				if (marker) {
					if (prevForm.markers.some((m) => m.name === marker.name)) {
						return {
							...prevForm,
							markers: prevForm.markers.filter((m) => m.name !== marker.name),
						};
					} else {
						return { ...prevForm, markers: [...prevForm.markers, marker] };
					}
				}
				return prevForm;
			});
		} else {
			setCustomisedMarkersForm((prevForm) => ({
				...prevForm,
				[name]: value,
			}));
		}
	};

	const handleRemoveMarker = (markerName) => {
		setCustomisedMarkersForm((prevForm) => ({
			...prevForm,
			markers: prevForm.markers.filter((marker) => marker.name !== markerName),
		}));
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		onSubmitCustomisedMarkers(customisedMarkersForm);
	};

	const totalMarkerCost = customisedMarkersForm.markers.reduce(
		(total, marker) => total + marker.price,
		0
	);

	if (!showCustomiseMarkersModal) return null;

	if (showCustomiseMarkersModal)
		return (
			<div
				className="modal"
				style={{
					display: 'block',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					cursor: 'pointer',
				}}
				onClick={onClose}
			>
				<div
					className="modal-content"
					style={{
						backgroundColor: 'white',
						padding: '30px',
						margin: '20px auto',
						width: '100%',
						maxWidth: '600px',
					}}
					onClick={(e) => e.stopPropagation()}
				>
					<span className="close" onClick={onClose} style={{ float: 'right', cursor: 'pointer' }}>
						&times;
					</span>
					<h3>Additional markers</h3>
					<form onSubmit={handleFormSubmit}>
						<div className="mt-3">
							<label>Please choose a laboratory:</label>
							<select
								name="hospital"
								value={customisedMarkersForm.hospital || ''}
								onChange={handleFormChange}
								required
							>
								<option value="TDL">TDL</option>
								<option value="Salient">Salient</option>
								<option value="Nationwide">Nationwide</option>
								<option value="Alpha">Alpha</option>
								<option value="Hospital Lab">Hospital Lab</option>
							</select>
						</div>
						<div
							className="cart-section pt-3 px-4 pb-2 mb-3"
							style={{ maxHeight: '300px', overflowX: 'hidden', overflowY: 'scroll' }}
						>
							{Object.entries(markersData).map(([category, markers]) => (
								<div className="mt-1 mb-3" key={category}>
									<h6>{category}</h6>
									{markers.map((marker) => (
										<label
											key={marker.name}
											className={`checkbox-item ${
												customisedMarkersForm.markers.some((m) => m.name === marker.name)
													? 'checkbox-item-selected'
													: ''
											}`}
										>
											<div className="d-flex justify-content-between align-items-center w-100">
												{marker.name}
												<input
													type="checkbox"
													name="markers"
													value={`${category}:${marker.name}`}
													checked={customisedMarkersForm.markers.some(
														(m) => m.name === marker.name
													)}
													onChange={handleFormChange}
												/>
												{/* <span className="checkbox-label">
													{(marker.price / 100).toLocaleString('en-GB', {
														style: 'currency',
														currency: 'GBP',
													})}
												</span>
												<img
													src="/img/icon/close-icon.svg"
													style={{
														filter: 'invert(1)',
														maxWidth: '15px',
														transform: 'rotate(45deg)',
														marginLeft: '10px',
													}}
													alt="Add marker"
												/> */}
											</div>
										</label>
									))}
								</div>
							))}
						</div>

						<div>
							<label>Number of vials:</label>
							<input
								type="number"
								name="vials_qty"
								value={customisedMarkersForm.vials_qty || ''}
								onChange={handleFormChange}
							/>
						</div>
						<div>
							<label>Vial type:</label>
							<input
								type="text"
								name="vials_type"
								value={customisedMarkersForm.vials_type || ''}
								onChange={handleFormChange}
							/>
						</div>
						<div>
							<label>Test instructions:</label>
							<input
								type="text"
								name="test_instructions"
								value={customisedMarkersForm.test_instructions || ''}
								onChange={handleFormChange}
							/>
						</div>
						{customisedMarkersForm.markers.length > 0 && (
							<>
								<h6 className="mt-4">Review of your additional markers</h6>
								<div className="cart-section pt-3 px-4 pb-2 mb-3">
									{customisedMarkersForm.markers.map((marker, index) => (
										<div key={index} className="checkbox-item" style={{ cursor: 'default' }}>
											<span className="marker-name">{marker.name}</span>
											{/* <span className="checkbox-label">
												{(marker.price / 100).toLocaleString('en-GB', {
													style: 'currency',
													currency: 'GBP',
												})}
											</span> */}
											<button
												className="remove-marker-btn"
												type="button"
												onClick={() => handleRemoveMarker(marker.name)}
											>
												<img
													src="/img/icon/close-icon.svg"
													style={{
														filter: 'invert(1)',
														maxWidth: '15px',
														marginLeft: '10px',
													}}
													alt="Remove marker"
												/>
											</button>
										</div>
									))}
								</div>
							</>
						)}

						<div className="mt-3">
							<Button
								label={`Complete customisation ${
									totalMarkerCost > 0
										? `+${(totalMarkerCost / 100).toLocaleString('en-GB', {
												style: 'currency',
												currency: 'GBP',
										  })}`
										: ''
								}`}
								type={'submit'}
								fullWidth
								large
							/>
						</div>
					</form>
				</div>
			</div>
		);
};

export default CustomiseMarkersModal;
