import { useEffect, useState } from 'react';
import config from '../../config';
import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import useAuth from '../../hooks/useAuth';

const MedicalRecords = () => {
	const { user } = useAuth();
	const [documents, setDocuments] = useState([]);
	const [membershipData, setMembershipData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/memberships?userID=${user.customer_id}`
				);
				const data = await response.json();
				setMembershipData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id]);

	useEffect(() => {
		const fetchDocumentLinks = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/documents?customer=${user.customer_id}`
				);
				const data = await response.json();
				setDocuments(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchDocumentLinks();
	}, [user.customer_id]);

	const handleDownload = async (document) => {
		try {
			const response = await fetch(
				`${config.REACT_APP_API_URL}/documents/download?customer=${
					user.customer_id
				}&file=${encodeURIComponent(document.file_name)}`
			);

			if (!response) {
				throw new Error('Failed to fetch PDF file.');
			}

			const blob = await response.blob();
			const file = window.URL.createObjectURL(blob);
			window.open(file);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	if (!documents.length) {
		return (
			<>
				<Header />
				<div className="container mt-25">
					<div className="row">
						<div className="col-md-5 col-lg-4 col-xl-3">
							<DashboardSidebar user={user} membershipData={membershipData} />
						</div>
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div className="card-body ">
								<div className="card card-table mb-0">
									<div className="card-body">
										You do not have any documents available at the moment.
										<br /> Please note: Files here will be available for you to download for 30
										days, before being deleted from our system.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card card-table mb-0">
										<div className="card-body">
											Note: Files here will be available for you to download for 30 days, before
											being deleted from our system.
											<div className="table-responsive">
												<table className="table table-hover table-center mb-0">
													<thead>
														<tr>
															<th>#</th>
															<th>File Name</th>
															<th>Description</th>
															<th>Date</th>
															<th>Attachment</th>
														</tr>
													</thead>
													<tbody>
														{documents?.map((document, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{document.file_name}</td>
																	<td>{document.document_type}</td>
																	<td>{document.created_at}</td>
																	<td>
																		<button
																			onClick={() => handleDownload(document)}
																			className="btn btn-primary btn-sm"
																		>
																			<i className="fa fa-download" />
																		</button>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MedicalRecords;
