import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CountryDropdown from '../../components/Countries';
import Order from '../Order';
import { ReactSession } from 'react-client-session';
import showAlert from '../../helpers/alerts';
import checkData from '../../validations/personalDetails';
import useAuth from '../../hooks/useAuth';

const PersonalDetails = () => {
	const { register, handleSubmit } = useForm();
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth();
	const user = isAuthenticated ? ReactSession.get('user') : {};

	const onSubmit = (data) => {
		const valid = checkData(data);

		if (valid === 'valid') {
			ReactSession.set('personal_details', data);
			navigate('/checkout/payment');
		} else {
			const errorMessage = valid;
			showAlert(errorMessage);
		}
	};

	const [cart] = useState(ReactSession.get('membership') || ReactSession.get('cart'));

	return (
		<>
			<Header title="Checkout" />
			<section className="checkout-area pb-70 mt-30">
				<div className="container">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-6 order-xs-5">
								<div className="checkbox-form">
									<h2>Personal Details</h2>
									<div className="row">
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													First Name <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('firstname')}
													defaultValue={user.first_name}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													Last Name <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('lastname')}
													defaultValue={user.last_name}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="country-select">
												<label>
													Gender <span className="required">*</span>
												</label>
												<select {...register('gender')} defaultValue={user.gender} required>
													<option>Select gender</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="other">Other</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													Date of birth <span className="required">*</span>
												</label>
												<input
													type="date"
													placeholder="dd/mmy/yyyy"
													{...register('dateOfBirth')}
													defaultValue={user.date_of_birth}
													required
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>Company Name</label>
												<input
													type="text"
													placeholder=""
													{...register('companyName')}
													defaultValue={user.company}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>
													Address <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder="Street address"
													{...register('address1')}
													defaultValue={user.address_line_1}
													required
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<input
													type="text"
													placeholder="Apartment, suite, unit etc. (optional)"
													{...register('address2')}
													defaultValue={user.address_line_2}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>
													Town / City <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('city')}
													required
													defaultValue={user.city}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													State / County <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('county')}
													defaultValue={user.county}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													Postcode / Zip <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('postcode')}
													defaultValue={user.postcode}
													required
												/>
											</div>
										</div>
										<div className="col-md-12">
											<CountryDropdown
												register={register('country')}
												defaultValue={user.country}
												required={true}
											/>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													Email Address <span className="required">*</span>
												</label>
												<input
													type="email"
													placeholder=""
													{...register('email')}
													defaultValue={user.email}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>
													Phone <span className="required">*</span>
												</label>
												<input
													type="text"
													placeholder=""
													{...register('phone')}
													defaultValue={user.phone}
													required
												/>
											</div>
										</div>
										<div className="order-notes">
											<div className="checkout-form-list">
												<label>Order Notes</label>
												<textarea
													id="checkout-mess"
													cols="30"
													rows="10"
													placeholder="Notes about your order, e.g. special notes for delivery."
													{...register('notes')}
												></textarea>
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-checkbox create-acc">
												<label>
													<input
														id="emailConsent"
														{...register('emailConsent')}
														onClick={() => console.log}
														type="checkbox"
													/>
													Keep me updated by email on Sirkka Networks' offers and updates
												</label>
											</div>
											<div className="checkout-form-checkbox create-acc">
												<label>
													<input
														id="sampleConsent"
														{...register('sampleConsent')}
														onClick={() => console.log}
														type="checkbox"
														required
													/>
													I consent that my samples will be taken and tested*
												</label>
											</div>
											<div className="checkout-form-checkbox create-acc">
												<label>
													<input
														id="termsConsent"
														{...register('termsConsent')}
														onClick={() => console.log}
														type="checkbox"
														required
													/>
													I accept the{' '}
													<Link to="/legalStatement" target="_blank">
														Terms & Conditions
													</Link>
													*
												</label>
											</div>
											<div className="order-button-payment mt-20">
												<button type="submit" className="primary_btn theme-btn">
													Proceed to pay
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							{cart && (
								<div className="col-lg-6 order-xs-1">
									<Order />
								</div>
							)}
						</div>
					</form>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default PersonalDetails;
