import React, { useEffect } from 'react';
import ClinicIcon from '../clinic.svg';
import VirtualIcon from '../virtual.svg';
import HomeVisitIcon from '../home-visit.svg';
import './BusinessDetails.scss';

const BusinessDetails = ({ data, onChange, setIsValid }) => {
	useEffect(() => {
		const isEmailValid = (email) => {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		};

		setIsValid(
			(data.businessName || '').trim() !== '' &&
				(data.businessDescription || '').trim() !== '' &&
				(data.contactName || '').trim() !== '' &&
				isEmailValid((data.email || '').trim())
		);
	}, [data, setIsValid]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		onChange({ [name]: value });
	};

	const handleWebsiteChange = (hasWebsite) => {
		if (hasWebsite) {
			onChange({ website: '' });
		} else {
			onChange({ website: null });
		}
	};

	return (
		<div className="d-flex justify-content-center">
			<div className="business-details">
				<div className="form-field">
					<label>Your business name</label>
					<input
						type="text"
						name="businessName"
						placeholder="Business Name"
						value={data.businessName}
						onChange={handleChange}
					/>
				</div>

				<div className="form-field">
					<label>Business description</label>
					<textarea
						name="businessDescription"
						placeholder="Business Description"
						value={data.businessDescription}
						onChange={handleChange}
					/>
				</div>

				<div className="form-field">
					<label>What type of business is it</label>
					<div className="card-selection">
						<div
							className={`card d-flex align-items-center ${
								data.businessType === 'In-Clinic' ? 'selected' : ''
							}`}
							onClick={() => onChange({ businessType: 'In-Clinic' })}
						>
							<img src={ClinicIcon} alt="In-Clinic" />
							<span>In-Clinic</span>
						</div>
						<div
							className={`card d-flex align-items-center ${
								data.businessType === 'Virtual' ? 'selected' : ''
							}`}
							onClick={() => onChange({ businessType: 'Virtual' })}
						>
							<img src={VirtualIcon} alt="Virtual" />
							<span>Virtual</span>
						</div>
						<div
							className={`card d-flex align-items-center ${
								data.businessType === 'Home visit' ? 'selected' : ''
							}`}
							onClick={() => onChange({ businessType: 'Home visit' })}
						>
							<img src={HomeVisitIcon} alt="Home visit" />
							<span>Home visit</span>
						</div>
					</div>
				</div>

				<div className="form-field">
					<label>Does your business have a website?</label>
					<div className="card-selection" style={{ marginBottom: '12px' }}>
						<div
							className={`card ${data.website !== null ? 'selected' : ''}`}
							onClick={() => handleWebsiteChange(true)}
						>
							<span>Yes</span>
						</div>
						<div
							className={`card ${data.website === null ? 'selected' : ''}`}
							onClick={() => handleWebsiteChange(false)}
						>
							<span>No</span>
						</div>
					</div>
					{data.website !== null && (
						<input
							type="text"
							name="website"
							placeholder="Website address"
							value={data.website}
							onChange={handleChange}
						/>
					)}
				</div>

				<div className="form-field">
					<label>Your name</label>
					<input
						type="text"
						name="contactName"
						placeholder="Full name"
						value={data.contactName}
						onChange={handleChange}
					/>
				</div>

				<div className="form-field">
					<label>Your business email address</label>
					<input
						type="email"
						name="email"
						placeholder="Email address"
						value={data.email}
						onChange={handleChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default BusinessDetails;
