import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TwoRadioSelection } from '@sirkka-health/booking-system-ui';
import AppointmentCreate from './AppointmentCreate';
import './PhlebotomyBooking.scss';
import FileInput from './FileInput';
import PhlebotomyRequestDetails from './PhlebotomyRequestDetails';
import config from '../../../config';
import { AuthContext } from '../../../context/AllContext';
import { getServices } from '../../HealthTestsAndSpecialists/helpers/getData';

const PhlebotomyBooking = ({
	selectedLocation,
	availableLocations,
	handleLocationChange,
	availabilityData,
	selectedDate,
	onDateChange,
	selectedTime,
	onTimeChange,
	onContinue,
	continueBtndisabled,
	requiredDuration,
	selectedPatient,
	selectedService,
	user,
	bookingData,
	setBookingData,
	handleDateTimeLocationSelection,
	setSelectedDate,
	setSelectedTime,
	onBookingRequestSent,
}) => {
	const {
		user: { user_id: userID, customer_id: customerID },
	} = useContext(AuthContext);
	const navigate = useNavigate();

	const [isBookingRequestSent, setIsBookingRequestSent] = useState(false);
	const [isContinueDisabled, setIsContinueDisabled] = useState(true);
	const [showPRDModal, setShowPRDModal] = useState(false);
	const [services, setServices] = useState(null);
	const [phlebotomyRequestDetails, setPhlebotomyRequestDetails] = useState({
		userId: userID,
		customerId: customerID,
		phlebotomyType: '',
		numberOfCustomer: '1',
		testRequired: '',
		testCodeOrVials: '',
		phlebotomyRequired: '',
		availableParking: '',
		parkingDescription: '',
		facilities: '',
		quantityOfFacilities: '',
		specialRequirements: '',
		specialNeedsPatients: '',
		binArrangement: '',
		sampleCollection: '',
		TDLDetails: '',
		customerInfoProvisionDate: '',
		testingSiteContact: '',
		customerConsent: '',
		requestComments: '',
	});
	const [formData, setFormData] = useState({
		booking: {
			bookingId: '',
			appointmentLocation: null,
			appointmentRegion: null,
			appointmentFor: 'Myself',
			completed: false,
			virtualOrPhysical: '',
			termsconditions: '',
		},
		customer: {
			nameTitle: '',
			firstName: '',
			lastName: '',
			gender: '',
			dateOfBirth: '',
			phoneNumber: '',
			company: '',
			occupation: '',
			address_type: 'home',
		},
		postal: {
			postageAddressLine1: '',
			postageAddressLine2: '',
			postageCity: '',
			postagePostcode: '',
			postageCountry: '',
			postageSelection: 'no',
		},
		service: {
			serviceType: 'Clinic',
		},
	});

	useEffect(() => {
		// Check if the necessary steps are taken
		const areStepsCompleted =
			validatePhlebotomyRequestDetails() && selectedLocation && selectedDate && selectedTime;

		// Update the isContinueDisabled state
		setIsContinueDisabled(!areStepsCompleted);
	}, [phlebotomyRequestDetails, selectedLocation, selectedDate, selectedTime]);

	useEffect(() => {
		getServices((servicesData) => {
			setServices(servicesData);
		});
	}, []);

	const onPRDChange = (event) => {
		const { name, value } = event.target;
		setPhlebotomyRequestDetails((prevState) => {
			const updatedDetails = { ...prevState, [name]: value };
			return updatedDetails;
		});
	};

	const openPRDModal = () => {
		setShowPRDModal(true);
	};

	const closePRDModal = () => {
		setShowPRDModal(false);
	};

	const validatePhlebotomyRequestDetails = () => {
		let requiredFields = [
			'numberOfCustomer',
			'testRequired',
			'testCodeOrVials',
			'availableParking',
			'parkingDescription',
			'specialRequirements',
			'specialNeedsPatients',
			'customerInfoProvisionDate',
			'testingSiteContact',
			'customerConsent',
		];

		// Add conditional fields based on the number of customers
		if (parseInt(phlebotomyRequestDetails.numberOfCustomer) >= 4) {
			requiredFields = requiredFields.concat([
				'phlebotomyRequired',
				'facilities',
				'quantityOfFacilities',
				'binArrangement',
				'sampleCollection',
				'TDLDetails',
			]);
		}

		for (const field of requiredFields) {
			if (!phlebotomyRequestDetails[field]) {
				return false;
			}
		}

		return true;
	};

	const handleOnContinue = async () => {
		if (!validatePhlebotomyRequestDetails()) {
			alert(
				"It appears some required fields on the 'Phlebotomy Booking Details' form are incomplete. Please fill in all required fields."
			);
			return;
		}

		const formData = new FormData();

		// Exclude the file from the JSON payload
		const { file, ...details } = phlebotomyRequestDetails;

		formData.append('phlebotomy', JSON.stringify(details));
		if (file) {
			formData.append('file', file);
		}
		try {
			const response = await fetch(`${config.REACT_APP_API_URL}/bookings/phlebotomy-request`, {
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				setIsBookingRequestSent(true);
				onBookingRequestSent(true);

				if (
					phlebotomyRequestDetails.numberOfCustomer &&
					parseInt(phlebotomyRequestDetails.numberOfCustomer) < 4
				) {
					handleAddService();
				}
			} else {
				console.error('Server response error:', response.statusText);
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};

	//After successful phlebotomy booking, continue to checkout with phlebotomy service in cart
	const handleAddService = () => {
		if (!services) {
			console.error('Services not loaded');
			return;
		}
		const phlebotomyService = services.find((service) => {
			return service.service.service_id === 19;
		});

		if (!phlebotomyService) {
			console.error('Phlebotomy service not found');
			return;
		}

		const newBooking = {
			booking: {
				bookingId: Date.now().toString(),
				locationId: phlebotomyService.location.location_category_id,
				bookingName: `Phlebotomy - ${user.first_name} ${user.last_name}`,
				bookingProduct: phlebotomyService.service.service_code,
				appointmentLocation: selectedLocation,
				appointmentRegion: selectedLocation,
				appointmentDate: selectedDate,
				appointmentTime: selectedTime,
				appointmentNotes: '',
				appointmentFor: 'patient',
				sampleConsent: true,
				termsconditions: true,
				completed: true,
			},
			customer: {
				nameTitle: user.title || '',
				firstName: user.first_name,
				lastName: user.last_name,
				emailAddress: user.email_address || 'Not provided',
				gender: user.gender,
				dateOfBirth: user.date_of_birth,
				phoneNumber: user.contact_number,
				company: user.company,
				occupation: user.occupation,
				address_line_1: '',
				address_line_2: '',
				city: '',
				postcode: '',
				country: '',
			},

			postal: {
				postageAddressLine1: '',
				postageAddressLine2: '',
				postageCity: '',
				postagePostcode: '',
				postageCountry: '',
				postageSelection: '',
			},
			service: {
				serviceName: phlebotomyService.service.service_name,
				serviceId: phlebotomyService.service.service_id.toString(),
				serviceType: phlebotomyService.service.service_type,
				emailTemplate: phlebotomyService.service.email_template,
				regionId: phlebotomyService.location.location_category_id,
				price: phlebotomyService.service.service_price,
				icon: phlebotomyService.content.service_icon,
				apptStackable: phlebotomyService.service.appt_stackable,
				apptDuration: phlebotomyService.service.appt_duration,
			},
			entity: {
				entityId: 1,
			},
			combineBooking: '',
		};

		setBookingData((prevBookingData) => [...prevBookingData, newBooking]);
		navigate('/cart');
	};

	return (
		<>
			<PhlebotomyRequestDetails
				showPRDModal={showPRDModal}
				onClose={closePRDModal}
				phlebotomyRequestDetails={phlebotomyRequestDetails}
				onPRDChange={onPRDChange}
			/>

			<div className="step-one mt-5">
				<h3 className="mt-4 mb-2">1. Complete Phlebotomy Request Details</h3>

				<div className="buttonContainer">
					<Button
						label="Complete Phlebotomy Request Details"
						fullWidth
						large
						onClick={openPRDModal}
					/>
				</div>
			</div>
			<div className="step-two mt-5">
				<h3 className="mt-4 mb-2">2. Attach supporting documents</h3>

				<div className="mb-3">
					In order to process this order, we need a completed{' '}
					<a
						style={{ textDecoration: 'underline' }}
						href="https://www.tdlpathology.com/tests/request-forms/"
						target="_blank"
						rel="noreferrer"
					>
						Test Request form or relevant barcode
					</a>
				</div>

				<FileInput
					onFileSelected={(file) =>
						setPhlebotomyRequestDetails({ ...phlebotomyRequestDetails, file })
					}
				/>
			</div>

			{phlebotomyRequestDetails.numberOfCustomer &&
				parseInt(phlebotomyRequestDetails.numberOfCustomer) < 4 && (
					<div className="step-three">
						<AppointmentCreate
							selectedLocation={selectedLocation}
							availableLocations={availableLocations}
							onLocationChange={handleLocationChange}
							availabilityData={availabilityData}
							selectedDate={selectedDate}
							onDateChange={onDateChange}
							selectedTime={selectedTime}
							onTimeChange={onTimeChange}
							onContinue={handleOnContinue}
							continueBtndisabled={isContinueDisabled}
							requiredDuration={requiredDuration}
							selectedPatient={selectedPatient}
							selectedService={selectedService}
							user={user}
							bookingData={bookingData}
							setBookingData={setBookingData}
							handleDateTimeLocationSelection={handleDateTimeLocationSelection}
							setSelectedDate={setSelectedDate}
							setSelectedTime={setSelectedTime}
						/>
					</div>
				)}

			{phlebotomyRequestDetails.numberOfCustomer &&
				parseInt(phlebotomyRequestDetails.numberOfCustomer) > 3 && (
					<div className="mt-5 mb-3">
						<Button label={'Request Group Booking'} fullWidth large onClick={handleOnContinue} />
					</div>
				)}
		</>
	);
};

export default PhlebotomyBooking;
