import React from 'react';
import './styles.css';

const CookieConsent = (props) => {
	function setCookie() {
		document.cookie = `web_user = Consent Checked; path = /; max-age= ${60 * 60 * 24 * 90};`;
		props.setAcceptCookies(true);
	}

	return (
		<div id="cookiePopup" className="showConsent">
			<div>
				<h4>Cookie Consent</h4>
			</div>
			<div>
				<div>
					<p>
						This website uses cookies. We use cookies (including third-party cookies) to collect
						information about how visitors use our website. They are strictly necessary cookies to
						ensure security, prevent fraud and debug and they help us give you the best possible
						experience, continually improve our sites. We do not use cookies for the purpose of
						advertising, social media, 3rd party analytics, geolocation data, or storing/accessing
						information on a device. By clicking the "Accept and close" button, you agree to the use
						of these cookies.
					</p>
					<div className="btn-wrap">
						<button id="acceptCookie" onClick={setCookie}>
							Accept and close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookieConsent;
