import React, { useState, useEffect } from 'react';
import styles from './Filter.module.scss';

const CategoryFilter = ({ category, handleCheckboxChange, filters, title, type }) => {
	if (category.subcats.length === 0) {
		return (
			<div
				key={category.id}
				className={
					['Cardiology', 'Endocrinology', 'Neurology/Neurosurgery'].includes(category.name)
						? styles.category
						: null
				}
			>
				{['Cardiology', 'Endocrinology', 'Neurology/Neurosurgery'].includes(category.name) && (
					<h5>{category.name}</h5>
				)}
				<input
					type="checkbox"
					value={type === 'service' ? category.id : category.name}
					checked={
						type === 'service'
							? filters.selectedCategoryIds.includes(category.id)
							: filters.selectedSpecialistCategories.some((cat) => cat === category.name)
					}
					onChange={handleCheckboxChange}
					id={category.id}
				/>
				<label htmlFor={category.id}>{category.name}</label>
			</div>
		);
	} else {
		return (
			<div
				className={
					title
						? `${styles.section} ${styles[category.name.replace(/[^a-zA-Z0-9]/g, '')]}`
						: styles.category
				}
			>
				{title ? <h4>{title}</h4> : <h5>{category.name}</h5>}
				<div>
					{category.subcats.map((subcat) => (
						<CategoryFilter
							key={subcat.id}
							category={subcat}
							handleCheckboxChange={handleCheckboxChange}
							filters={filters}
							type={type}
						/>
					))}
				</div>
			</div>
		);
	}
};

const ServicesFilter = ({
	filters,
	serviceCategoriesTransformed,
	setFilters,
	possibleLocationCategories,
	selectedLocationCategory,
	onLocationChange,
	onToggleVisibility,
	filterVisible,
	setFilterVisible,
	userHasChosenLocation,
}) => {
	// Toggle is for service/specialist switch on mobile
	// const [toggleState, setToggleState] = useState(window.innerWidth <= 768 ? 'Services' : '');

	// useEffect(() => {
	// 	if (!serviceCategoriesTransformed || serviceCategoriesTransformed.length === 0) return;
	// 	if (serviceCategoriesTransformed && serviceCategoriesTransformed.length > 0) {
	// 		if (toggleState === 'Services') {
	// 			const servicesObj = serviceCategoriesTransformed.find((cat) => cat.id === 1);
	// 			if (servicesObj && servicesObj.subcats) {
	// 				const subcatIds = servicesObj.subcats.map((subcat) => subcat.id);
	// 				setFilters((prevFilters) => ({
	// 					...prevFilters,
	// 					selectedCategoryIds: subcatIds,
	// 					selectedSpecialistCategories: [],
	// 				}));
	// 			}
	// 		} else if (toggleState === 'Specialists') {
	// 			const specialistsObj = serviceCategoriesTransformed.find((cat) => cat.id === 2);
	// 			if (specialistsObj && specialistsObj.subcats) {
	// 				const subcatNames = [];

	// 				specialistsObj.subcats.forEach((subcat) => {
	// 					subcatNames.push(subcat.name);
	// 					if (subcat.subcats) {
	// 						subcat.subcats.forEach((childSubcat) => {
	// 							subcatNames.push(childSubcat.name);
	// 						});
	// 					}
	// 				});

	// 				setFilters((prevFilters) => ({
	// 					...prevFilters,
	// 					selectedCategoryIds: [],
	// 					selectedSpecialistCategories: subcatNames,
	// 				}));
	// 			}
	// 		}
	// 	}
	// }, [toggleState, serviceCategoriesTransformed]);

	const handleByServiceChange = (e) => {
		const { value } = e.target;
		const numericValue = Number(value);

		setFilters((prevFilters) => {
			if (prevFilters.selectedCategoryIds.includes(numericValue)) {
				return {
					...prevFilters,
					selectedCategoryIds: prevFilters.selectedCategoryIds.filter((id) => id !== numericValue),
					selectedSpecialistCategories: [],
				};
			} else {
				return {
					...prevFilters,
					selectedCategoryIds: [...prevFilters.selectedCategoryIds, numericValue],
					selectedSpecialistCategories: [],
				};
			}
		});
		// setToggleState('');
	};

	// const handleBySpecialtyChange = (e) => {
	// 	const { value } = e.target;

	// 	setFilters((prevFilters) => {
	// 		if (prevFilters.selectedSpecialistCategories.includes(value)) {
	// 			return {
	// 				...prevFilters,
	// 				selectedSpecialistCategories: prevFilters.selectedSpecialistCategories.filter(
	// 					(category) => category !== value
	// 				),
	// 				selectedCategoryIds: [],
	// 			};
	// 		} else {
	// 			return {
	// 				...prevFilters,
	// 				selectedSpecialistCategories: [...prevFilters.selectedSpecialistCategories, value],
	// 				selectedCategoryIds: [],
	// 			};
	// 		}
	// 	});
	// 	// setToggleState('');
	// };

	// const handleLocationClick = (value) => {
	// 	if (value === selectedLocationCategory) {
	// 		onLocationChange(value);
	// 	}
	// };

	const handleVisibility = (state) => {
		console.log('handleVisibility');
		onToggleVisibility();
		setFilterVisible(state ? state : !filterVisible);
	};

	return (
		<>
			<div className={`${styles.filterButtons} container`}>
				<div className={styles.locationButtonGroup}>
					{/* <button onClick={() => handleVisibility()}>
						<i className="fas fa-globe" aria-hidden="true"></i>
						{userHasChosenLocation ? selectedLocationCategory : 'Select location'} ▾
					</button> */}
					<button onClick={() => handleVisibility()}>
						<i className="fas fa-filter" aria-hidden="true"></i>
						Filters ▾
					</button>
				</div>
				{filterVisible && (
					<button onClick={() => handleVisibility(false)}>
						<i className="fas fa-times" aria-hidden="true"></i>
					</button>
				)}
			</div>
			{filterVisible && (
				<div className={`${styles.filter} container`}>
					<div className={`${styles.options} d-flex`}>
						{/* Location filter */}
						{/* <div className={`${styles.section} ${styles.location}`}>
							<h4>By Location</h4>
							{possibleLocationCategories.map((loc) => (
								<div key={loc}>
									<input
										type="radio"
										id={loc}
										name="location"
										value={loc}
										checked={selectedLocationCategory === loc}
										onChange={(e) => onLocationChange(e.target.value)}
										onClick={() => handleLocationClick(loc)}
									/>
									<label htmlFor={loc}>{loc}</label>
								</div>
							))}
						</div> */}

						{/* Service filter */}
						{Array.isArray(serviceCategoriesTransformed) &&
							serviceCategoriesTransformed.length > 0 &&
							serviceCategoriesTransformed
								.filter((cat) => cat.id === 1)
								.map((cat) => (
									<CategoryFilter
										key={cat.id}
										category={cat}
										type="service"
										handleCheckboxChange={handleByServiceChange}
										filters={filters}
										title="By Service"
									/>
								))}
						{/* Specialist filter */}
						{/* {Array.isArray(serviceCategoriesTransformed) &&
							serviceCategoriesTransformed.length > 0 &&
							serviceCategoriesTransformed
								.filter((cat) => cat.id === 2)
								.map((cat) => (
									<CategoryFilter
										key={cat.id}
										category={cat}
										type="specialist"
										handleCheckboxChange={handleBySpecialtyChange}
										filters={filters}
										title="By Specialty"
									/>
								))} */}
					</div>

					<div className={styles.noReferralFilterMessage}>
						<p>
							We do not take any referral fees from third parties listed on our website, nor have
							any financial interests in referring customers to doctors or clinics. Our medical
							assistance services are for Sirkka members only.
						</p>
						<p>
							{' '}
							The information and details of third parties are provided directly by third party
							providers. These details are based upon the best judgement of Sirkka and does not
							constitute an endorsement or recommendation regarding the quality of their services
							nor shall Sirkka be liable for any consequences arising out of the services provided
							by these third parties. Sirkka shall not be held responsible for service continuity
							and other issues caused by changes of policies implemented by a country, relevant
							organizations and/or key service providers.
						</p>
					</div>
				</div>
			)}

			{/* Mobile-only toggle switch to show either services or specialists */}
			{/* <div className={`${styles.toggleContainer} container`}>
				<input
					type="radio"
					id="Services"
					name="toggle"
					checked={toggleState === 'Services'}
					onChange={() => setToggleState('Services')}
				/>
				<label htmlFor="Services">Services</label>

				<input
					type="radio"
					id="Specialists"
					name="toggle"
					checked={toggleState === 'Specialists'}
					onChange={() => setToggleState('Specialists')}
				/>
				<label htmlFor="Specialists">Specialists</label>
			</div> */}
		</>
	);
};

export default ServicesFilter;
