const postalObject = (data, customerId) => {
	const postageData = {
		customerId: customerId,
		firstName: data.customer.firstName,
		lastName: data.customer.lastName,
		emailAddress: data.customer.emailAddress,
		gender: data.customer.gender,
		dateOfBirth: data.customer.dateOfBirth,
		phoneNumber: data.customer.phoneNumber,
		serviceId: data.service.serviceId,
		serviceName: data.service.serviceName,
		serviceType: data.service.serviceType,
		emailTemplate: data.service.emailTemplate,
		postageAddressLine1: data.postal.postageAddressLine1,
		postageAddressLine2: data.postal.postageAddressLine2,
		postageCity: data.postal.postageCity,
		postagePostcode: data.postal.postagePostcode,
		postageCountry: data.postal.postageCountry,
		eventId: data.booking.eventId || null,
		entityId: data.entity.entityId || null,
		entityName: data.entity.entityName ? data.entity.entityName : null,
	};
	return postageData;
};

export default postalObject;
