import React from 'react';
import styles from './householdMembers.module.scss';

function HouseholdMembers({ members, user, membershipData }) {
	return (
		<div className={styles.householdMembers}>
			<div className={styles.lastOrderInner}>
				<h3>Household members</h3>
				<ul>
					{members &&
						members.map((member) => (
							<li key={member.id}>
								<div className={styles.name}>
									{member.first_name
										? `${member.first_name} ${member.last_name}`
										: `${member.pet_name} - ${member.pet_type}`}
								</div>
								<div className={styles.points}>
									{member.reward_points ? member.reward_points : 0} points
								</div>
							</li>
						))}
					{members.length === 0 && (
						<li key={user.user_id}>
							<div className={styles.name}>
								{user.first_name} {user.last_name}
							</div>
							<div className={styles.points}>
								{membershipData.reward_points ? membershipData.reward_points : 0} points
							</div>
						</li>
					)}
				</ul>
			</div>
		</div>
	);
}

export default HouseholdMembers;
