import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

const ServiceSelect = ({
	services,
	bookingData,
	setBookingData,
	selectedServiceValue,
	onServiceChange,
	onAddService,
	onRequiredDurationChange,
	openCustomiseMarkersModal,
	setSelectedLocation,
}) => {
	const handleRemoveBooking = (index) => {
		setBookingData((prevBookingData) => prevBookingData.filter((_, i) => i !== index));
		setSelectedLocation(null);
	};

	const calculateTotalAppointmentDuration = () => {
		if (bookingData.length === 0) {
			console.log('No bookings in bookingData');
			return 0;
		}

		// Separate stackable and non-stackable services
		const stackableServices = bookingData.filter((bookingItem) => {
			return bookingItem.service.apptStackable !== 'false';
		});
		const nonStackableServices = bookingData.filter((bookingItem) => {
			return bookingItem.service.apptStackable === 'false';
		});

		// Find the longest duration among stackable services
		const maxStackableDuration = stackableServices.reduce((max, bookingItem) => {
			return Math.max(max, bookingItem.service.apptDuration);
		}, 0);

		// Sum up the durations of all non-stackable services
		const totalNonStackableDuration = nonStackableServices.reduce((total, bookingItem) => {
			return total + parseInt(bookingItem.service.apptDuration, 10);
		}, 0);

		// Total duration is the sum of the longest stackable duration and the total non-stackable duration
		const totalDuration = maxStackableDuration + totalNonStackableDuration;
		onRequiredDurationChange(totalDuration);
		return totalDuration;
	};

	const calculateTotalMarkerPrice = (bookingId) => {
		if (bookingData.length === 0) {
			console.log('No bookings in bookingData');
			return 0;
		}

		const totalMarkerPrice = bookingData.reduce((total, bookingItem) => {
			if (
				bookingItem.booking.bookingId === bookingId &&
				bookingItem.service.customisedMarkers &&
				bookingItem.service.customisedMarkers.markers
			) {
				const markerPrices = bookingItem.service.customisedMarkers.markers.map(
					(marker) => marker.price
				);
				return total + markerPrices.reduce((sum, price) => sum + price, 0);
			}
			return total;
		}, 0);

		return totalMarkerPrice;
	};

	const calculateTotalPrice = () => {
		if (bookingData.length === 0) {
			console.log('No bookings in bookingData');
			return 0;
		}

		const totalServicePrice = bookingData.reduce((total, bookingItem) => {
			return total + bookingItem.service.price;
		}, 0);

		const totalMarkerPrice = bookingData.reduce((total, bookingItem) => {
			if (bookingItem.service.customisedMarkers && bookingItem.service.customisedMarkers.markers) {
				const markerPrices = bookingItem.service.customisedMarkers.markers.reduce(
					(sum, marker) => sum + marker.price,
					0
				);
				return total + markerPrices;
			}
			return total;
		}, 0);

		return (totalServicePrice + totalMarkerPrice) / 100;
	};

	if (!services) return null;
	if (services && services.length > 0)
		return (
			<>
				<h3 className="mt-5">2. Add products</h3>
				{bookingData.length > 0 && (
					<div className="cart-section pt-3 px-4 pb-2">
						{bookingData.map((bookingItem, index) => {
							return (
								<div
									key={index}
									className="mb-3 d-flex justify-content-between align-items-center"
									style={{ paddingBottom: '15px', borderBottom: '1px solid #c5c5e4' }}
								>
									<div className="col-11">
										<div className="d-flex justify-content-between">
											<span>{bookingItem.service.serviceName}</span>
											{bookingItem.service.price && !Number.isNaN(bookingItem.service.price) ? (
												<span className="me-2">
													{(bookingItem.service.price / 100).toLocaleString('en-GB', {
														style: 'currency',
														currency: 'GBP',
													})}
												</span>
											) : null}
										</div>
										{bookingItem.service.customisedMarkers && (
											<>
												<div
													className="p-2 mt-2"
													style={{ background: '#E8E8E8', borderRadius: '6px' }}
												>
													<div className="d-flex justify-content-between">
														<span style={{ fontSize: '14px', fontWeight: 500 }}>
															Customisations
														</span>
														<span>
															+
															{(
																calculateTotalMarkerPrice(bookingItem.booking.bookingId) / 100
															).toLocaleString('en-GB', {
																style: 'currency',
																currency: 'GBP',
															})}
														</span>
													</div>
													<ul>
														<li style={{ fontSize: '14px' }}>
															Laboratory:{' '}
															<span style={{ fontWeight: 500 }}>
																{bookingItem.service.customisedMarkers.hospital}
															</span>
														</li>
														<li style={{ fontSize: '14px' }}>
															Additional markers:{' '}
															{bookingItem.service.customisedMarkers.markers.map(
																(marker, index) => (
																	<>
																		<span
																			key="index"
																			style={{
																				border: '1px solid #b7b7b7',
																				padding: '2px 4px',
																				borderRadius: '4px',
																				fontWeight: 500,
																			}}
																		>
																			{marker.name}
																		</span>{' '}
																	</>
																)
															)}
														</li>
													</ul>
												</div>
											</>
										)}
									</div>
									<div className="col-1 align-self-start">
										<div className="d-flex display-inline-block">
											<button
												type="button"
												style={{ border: 'none', background: 'none' }}
												onClick={() => openCustomiseMarkersModal(bookingItem.booking.bookingId)}
											>
												<img
													src="/img/icon/pencil.png"
													style={{ maxWidth: '13px', marginRight: '10px' }}
													alt="remove from cart"
												/>
											</button>
											<button
												type="button"
												style={{ border: 'none', background: 'none' }}
												onClick={() => handleRemoveBooking(index)}
											>
												<img
													src="/img/icon/close-icon.svg"
													style={{ filter: 'invert(1)', maxWidth: '15px' }}
													alt="remove from cart"
												/>
											</button>
										</div>
									</div>
								</div>
							);
						})}
						<div className="d-flex justify-content-between align-items-center ">
							<h6 className="text-align-right pe-3 display-inline-block">
								Total appointment time: {calculateTotalAppointmentDuration()} mins
							</h6>
							<div className="d-flex display-inline-block">
								<h6 className="text-align-right pe-3 display-inline-block">
									Total:{' '}
									{calculateTotalPrice().toLocaleString('en-GB', {
										style: 'currency',
										currency: 'GBP',
									})}
								</h6>
								<button type="button" style={{ border: 'none', visibility: 'hidden' }}>
									<img
										src="/img/icon/close-icon.svg"
										style={{ filter: 'invert(1)', maxWidth: '15px' }}
										alt="remove from cart"
									/>
								</button>
							</div>
						</div>
					</div>
				)}
				<div>
					<select className="mt-3 mb-3" value={selectedServiceValue} onChange={onServiceChange}>
						<option value="" disabled>
							Select service
						</option>
						{services
							.filter(
								(service) =>
									service.service.service_type !== 'Postal' &&
									service.service.service_status === 'true' &&
									service.service.service_bookable === true
							)
							.map((service, index) => (
								<option key={index} value={service.service.service_id}>
									{service.service.service_name}
								</option>
							))}
					</select>
					<Button label="Add service" onClick={onAddService} fullWidth large />
				</div>
			</>
		);
};

export default ServiceSelect;
