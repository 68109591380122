import checkEmail from './email';

const checkBillingData = ({
	firstname,
	lastname,
	company,
	address1,
	address2,
	city,
	county,
	postcode,
	country,
	email,
	phone,
}) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() - 125);

	if (/^[0-9][0-9]*[1-9][0-9]{6,13}$/.test(phone) === false) {
		return 'Phone Number must contain only numbers and not symbols or spaces';
	}

	if (!checkEmail(email)) {
		return 'Email invalid';
	}

	if (firstname.length < 2) {
		return 'First name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(firstname) === false) {
		return 'First name can only contain alphabets';
	}

	if (lastname.length < 2) {
		return 'Last name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(lastname) === false) {
		return 'Last name can only contain alphabets';
	}

	if (!city) {
		return 'City is required';
	}

	if (!country) {
		return 'Country is required';
	}

	if (!address1 || !postcode) {
		return 'Your address is required.';
	}

	return 'valid';
};

export default checkBillingData;
