const getCurrentEnvironment = (currentURL = window.location.host) => {
	let stagingUrlHost = '';

	try {
		const stagingUrl = new URL(process.env.REACT_APP_URL_STAGING);
		stagingUrlHost = stagingUrl.host;
	} catch (error) {
		console.error('Invalid URL in REACT_APP_URL_STAGING:', error);
	}

	const environment = currentURL === stagingUrlHost ? 'staging' : 'production';
	return environment;
};

export default getCurrentEnvironment;
export { getCurrentEnvironment };
