import React, { useContext } from 'react';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import { Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { ReactSession } from 'react-client-session';
import Collapsible from 'react-collapsible';
import Searchbar from '../../../components/Header/Searchbar';

const Sidebar = ({ show, handleClose, navConfig }) => {
	const cart = ReactSession.get('cart');
	const { isAuthenticated, logout } = useAuth();
	const { selectedLocationCategory } = useContext(BookingContext);

	// const navConfig = [
	// 	{
	// 		name: 'Diagnostic tests',
	// 		link: '/booking',
	// 		subMenu: [
	// 			{
	// 				category: 'General Health',
	// 				links: [
	// 					{ label: 'Standard Health Screening', serviceCode: 'standardHealthScreening' },
	// 					// { label: 'Kidney & Liver Function', serviceCode: 'kidneyLiverFunction' },
	// 					// { label: 'Liver Function', serviceCode: 'liverFunction' },
	// 					// { label: 'Full Blood Count', serviceCode: 'fullBloodCount' },
	// 					{ label: 'Iron Panel', serviceCode: 'ironPanel' },
	// 					{ label: 'Urine', serviceCode: 'Urine' },
	// 					{ label: 'Understand Your Weight', serviceCode: 'weightManagement' },
	// 					{ label: 'Vitamin D', serviceCode: 'vitaminD' },
	// 					{ label: 'Chronic Fatigue package', serviceCode: 'chronicFatiguePackage' },
	// 					// { label: 'Immunisation Tests', serviceCode: 'immunisationTests' },
	// 					{ label: 'Allergy Testing', serviceCode: 'allergyTest' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Heart Health',
	// 				links: [
	// 					{ label: 'Heart Health', serviceCode: 'heartHealth' },
	// 					{ label: 'PLAC', serviceCode: 'PLAC' },
	// 					// { label: 'LIPIDs', serviceCode: 'lipids' },
	// 					{ label: 'Resting ECG', serviceCode: 'restingECG' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Dr Oliver Guttmann', specialistCode: '191' },
	// 					{ label: 'Dr Jonathan Clague', specialistCode: '192' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Mental Health',
	// 				links: [
	// 					{ span: 'Counsellor' },
	// 					{ label: 'Gigi Dryer', specialistCode: '194' },
	// 					{ label: 'Agneta Lindberg', specialistCode: '200' },
	// 					{ span: 'Psychologist' },
	// 					{ label: 'Elaine McClement', specialistCode: '195' },
	// 					{ label: 'Dr. Johanna Flack', specialistCode: '196' },
	// 					{ label: 'Ms. Ritu Kalsi', specialistCode: '197' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Gut Health',
	// 				links: [
	// 					{ label: 'Gut Health', serviceCode: 'gutHealth' },
	// 					{ label: 'Calprotectin', serviceCode: 'Calprotectin' },
	// 					{ label: 'QFIT', serviceCode: 'QFIT' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Dr Natalie Direkze', specialistCode: '201' },
	// 					{ label: 'Mr James Kinross', specialistCode: '184' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Sports Health',
	// 				links: [
	// 					{ label: 'Sport Health Package', serviceCode: 'sportHealthPackage' },
	// 					{ label: 'Heart Health', serviceCode: 'heartHealth' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Endocrinology',
	// 				links: [
	// 					{ label: 'Male Hormone Screening', serviceCode: 'maleHormoneScreening' },
	// 					{ label: 'Female Hormone Screening', serviceCode: 'femaleHormoneScreening' },
	// 					{ label: 'Thyroid Screening', serviceCode: 'thyroidScreening' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Prof. Maria Kyrgiou', specialistCode: '187' },
	// 					{ label: 'Prof. Christopher Nutting', specialistCode: '189' },
	// 					{ label: 'Ms Melanie Tipples', specialistCode: '190' },
	// 					{ label: 'Dr Mark Vanderpump', specialistCode: '193' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Cancer Screening',
	// 				links: [
	// 					{ label: 'Cancer Markers', serviceCode: 'cancerMarkers' },
	// 					{ label: 'PSA', serviceCode: 'PSA' },
	// 					{ label: 'QFIT', serviceCode: 'QFIT' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Prof. Christopher Nutting', specialistCode: '189' },
	// 					{ label: 'Mr Iain Nixon', specialistCode: '188' },
	// 					{ label: 'Dr Dimitri Hadjiminas', specialistCode: '185' },
	// 					{ label: 'Dr Crispin Hiley', specialistCode: '203' },
	// 					{ label: 'Mr James Kinross', specialistCode: '184' },
	// 					{ label: 'Dr Natalie Direkze', specialistCode: '201' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Women’s Health',
	// 				links: [
	// 					{ label: 'Female Hormone Screening', serviceCode: 'femaleHormoneScreening' },
	// 					{ label: 'HIV', serviceCode: 'HIV' },
	// 					{ label: 'Syphillis', serviceCode: 'Syphilis' },
	// 					{ label: 'HPV', serviceCode: 'HPV' },
	// 					{ span: 'GP' },
	// 					{ label: 'Dr Simon Smail', specialistCode: '198' },
	// 					{ label: 'Dr Mariam Hameed', specialistCode: '199' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Dr Maria Kyrgiou', specialistCode: '187' },
	// 					{ label: 'Dr Melanie Tipples', specialistCode: '190' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Men’s Health',
	// 				links: [
	// 					{ label: 'Male Hormone Screening', serviceCode: 'maleHormoneScreening' },
	// 					{ label: 'PSA', serviceCode: 'PSA' },
	// 					{ label: 'HIV', serviceCode: 'HIV' },
	// 					{ label: 'Syphillis', serviceCode: 'Syphilis' },
	// 					{ span: 'GP' },
	// 					{ label: 'Dr Simon Smail', specialistCode: '198' },
	// 					{ label: 'Dr Mariam Hameed', specialistCode: '199' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Pre-Fertility',
	// 				links: [
	// 					{ label: 'Pre-Fertility Treatment Tests', serviceCode: 'preFertilityTreatment' },
	// 					{ span: 'Specialist' },
	// 					{ label: 'Dr Vishakha Tripathi', specialistCode: '186' },
	// 				],
	// 			},
	// 			{
	// 				category: 'Other Tests',
	// 				links: [
	// 					{
	// 						label: 'Travel & Pre-Employment Medicals',
	// 						serviceCode: 'travelPreEmploymentTests',
	// 					},
	// 					{ label: 'COVID-19 PCR', serviceCode: 'chinaboundPCR24hr' },
	// 				],
	// 			},
	// 			,
	// 			{
	// 				category: 'Imaging',
	// 				links: [
	// 					{ label: 'Resting ECG', serviceCode: 'restingECG' },
	// 					{ label: 'Ultrasound Scan', serviceCode: 'ultrasoundScan' },
	// 					{ label: 'Mammogram', serviceCode: 'Mammogram' },
	// 					{ label: 'X-Ray', serviceCode: 'xRay' },
	// 					{ label: 'CT & MRI Scan', serviceCode: 'ctMRI' },
	// 				],
	// 			},
	// 		],
	// 	},
	// 	{
	// 		name: 'Treatments Abroad',
	// 		link: '/treatments-abroad',
	// 	},
	// 	{
	// 		name: 'Gift Card',
	// 		link: '/gift-cards',
	// 	},
	// 	{
	// 		name: 'Medical Assistance',
	// 		link: '/contact',
	// 	},
	// 	{
	// 		name: 'Business',
	// 		link: '/business',
	// 	},
	// 	{
	// 		name: 'Occupational Health',
	// 		link: '/occupational-health',
	// 	},
	// ];

	return (
		<>
			<div>
				<Offcanvas
					show={show}
					onHide={handleClose}
					placement="end"
					className="side__bar"
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
					}}
				>
					<Offcanvas.Header>
						<button type="button" className="close-canvas" aria-label="Close" onClick={handleClose}>
							<i
								className="fas fa-times"
								aria-hidden="true"
								style={{
									color: '#fff',
								}}
							></i>
						</button>
						{/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Searchbar />
						{navConfig.map((item, index) => {
							if (item.name === 'Diagnostic Services') {
								return (
									<Collapsible
										key={index}
										triggerTagName="div"
										triggerOpenedClassName="icon_close"
										triggerClassName="iconAdd"
										open={false}
										trigger={item.name}
									>
										<li>
											<Link to={`/booking/`}>All Diagnostic Tests</Link>
										</li>
										{item.subMenu.map((menuCategory, i) => (
											<div key={i}>
												<Collapsible
													triggerTagName="div"
													triggerOpenedClassName="icon_close"
													triggerClassName="iconAdd"
													open={false}
													trigger={menuCategory.category}
												>
													<ul>
														{menuCategory.links.map((linkItem, j) => (
															<li key={j} className={linkItem.span ? 'link-title-section' : ''}>
																{linkItem.span ? (
																	<span>{linkItem.span}</span>
																) : (
																	<NavLink
																		to={`/booking/${
																			linkItem.serviceCode
																				? `?serviceCode=${linkItem.serviceCode}`
																				: `?specialistCode=${linkItem.specialistCode}`
																		}`}
																	>
																		{linkItem.label}
																	</NavLink>
																)}
															</li>
														))}
													</ul>
												</Collapsible>
											</div>
										))}
									</Collapsible>
								);
							} else {
								return (
									<li key={index}>
										<NavLink to={item.link}>{item.name}</NavLink>
									</li>
								);
							}
						})}
						<li>
							<Link to="/membership">Membership</Link>
						</li>
						<li>
							<Link to="/cart">Cart</Link>
						</li>

						{!isAuthenticated && (
							<>
								<li>
									<Link to="/business" className="search-cta primary-nav-btn">
										Business Partners
									</Link>
								</li>
								<li>
									<Link className="primary-nav-btn" type="button" to="/login">
										Login / Register
									</Link>
								</li>
							</>
						)}
						{isAuthenticated && (
							<>
								<li>
									<Link className="primary-nav-btn" type="button" to="/customer-dashboard">
										Portal
									</Link>
								</li>
								<li>
									<Link className="secondary-nav-btn" type="button" to="#" onClick={logout}>
										Logout
									</Link>
								</li>
							</>
						)}
					</Offcanvas.Body>
				</Offcanvas>
			</div>
		</>
	);
};

export default Sidebar;
