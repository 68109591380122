import { useState } from 'react';

const ServiceTab = ({ children }) => {
	const [currentTab, setCurrentTab] = useState(children[0].props.label);

	const handleClick = (e, otherTab) => {
		e.preventDefault();
		setCurrentTab(otherTab);
	};

	return (
		<div className="container service-tab">
			<ul className="tabs">
				{children.map((tab) => {
					const label = tab.props.label;
					return (
						<a
							key={tab}
							href={tab}
							className={
								label === currentTab
									? 'col-3 col-lg-3 col-xl-2 text-center current'
									: 'col-3 col-lg-3 col-xl-2 text-center'
							}
							onClick={(e) => handleClick(e, label)}
						>
							<li className={label === currentTab ? 'current' : ''} key={label}>
								{label}
							</li>
						</a>
					);
				})}
			</ul>
			{children.map((tabContent) => {
				if (tabContent.props.label === currentTab) {
					return (
						<div key={tabContent.props.label} className="content">
							{tabContent.props.children}
						</div>
					);
				} else {
					return <></>;
				}
			})}
		</div>
	);
};

export default ServiceTab;
