import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContactFormArea from './ContactFormArea';
import ContactMap from './ContactMap';
import ContactInformation from './ContactInformation';

const Contact = () => {
	return (
		<>
			<Header title="Contact Us" />
			<ContactInformation />
			<ContactFormArea />
			<ContactMap />
			<Footer />
		</>
	);
};

export default Contact;
