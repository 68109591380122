import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const CorporateCTA = () => {
	return (
		<>
			<Header title="Corporate" />
			<section className="fact-area fact-map pos-rel pt-90 pb-60" id="corporate-only">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-10">
							<div className="section-title pos-rel mb-45">
								<div className="section-text pos-rel">
									<h1>For corporate</h1>
								</div>
							</div>
							<div className="section-button section-button-left mb-30">
								<Link to="/contact" className="primary_btn btn-icon ml-0">
									<span>+</span>Chat with us
								</Link>
							</div>
						</div>
						<div className="col-lg-6 col-lg-6 col-md-8">
							<div className="cta-satisfied pos-rel">
								<div className="mb-50">
									<h5>
										{' '}
										<i className="fas fa-user"></i> Satisfied Customers
									</h5>
									<p>
										"Outstanding service, both in terms of speed and efficiency of testing and
										clarity of communication. We have used Sirkka Networks on a business level for
										the past six months and cannot speak highly enough of the team."
									</p>

									<p>
										<b>
											<i>- The British Bobsleigh and Skeleton Association</i>
										</b>
									</p>
								</div>
								<div className="mb-30">
									<h5>
										<i className="far fa-thumbs-up"></i> Accreditations & Awards
									</h5>
									<p>
										• UKAS accredited Testing Laboratory (No.22231) to ISO/IEC 17025:2017
										<u>
											<a
												href="https://www.ukas.com/find-an-organisation/?q=sirkka"
												target="_blank"
												rel="noreferrer"
											>
												{' '}
												Search Accredited Organisations - UKAS
											</a>
										</u>
									</p>
									<p>
										• Sirkka Networks support Sinosafe’s travel insurance card winning the
										“Experience Award for Innovative Insurance Products” at the 3rd InsurTech
										Innovation Congress China 2019.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default CorporateCTA;
