import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ServiceBox from '../../components/ServiceBox';
import serviceData from '../../data/services.json';

const Services = () => {
	return (
		<>
			<Header />
			<section className="services-area h5_services gray-bg pos-rel pt-100 pb-120">
				<div className="container">
					<div className="row">
						<div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
							<div className="section-title text-center pos-rel mb-75">
								<div className="section-text-small pos-rel">
									<h1>Our Services</h1>
								</div>
							</div>
						</div>
					</div>
					{serviceData.map((service, index) => (
						<ServiceBox key={index} {...service} />
					))}
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Services;
