import Stripe from '@/components/Stripe';
import PaymentWrapper from '@/pages/Checkout/PaymentForm/PaymentWrapper';
import React, { useState, useEffect } from 'react';
import { Button } from '@sirkka-health/booking-system-ui';
import { Spinner } from 'react-bootstrap';
import './AccountSetup.scss';

const AccountSetup = ({ data, onChange, setIsValid, onPaymentSuccess }) => {
	const [paymentFormVisible, setPaymentFormVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [paymentIntentId, setPaymentIntentId] = useState(null);
	const [errors, setErrors] = useState({});

	const handleUserDataChange = (e) => {
		const { name, value } = e.target;
		onChange({ accountUser: { ...data.accountUser, [name]: value } });
		setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
	};

	const validateForm = () => {
		const newErrors = {};

		if (!data.accountUser.firstName) newErrors.firstName = 'First name is required';
		if (!data.accountUser.lastName) newErrors.lastName = 'Last name is required';
		if (!data.accountUser.email) newErrors.email = 'Email is required';
		if (!data.accountUser.dob) newErrors.dob = 'Date of birth is required';
		if (!data.accountUser.password) newErrors.password = 'Password is required';
		if (data.accountUser.password !== data.accountUser.confirmPassword) {
			newErrors.confirmPassword = 'Passwords do not match';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0; // Return true if no errors
	};

	const handleSave = () => {
		if (validateForm()) {
			setPaymentFormVisible(true);
		}
	};

	const submitPayment = (
		<div className="order-button-payment mt-20">
			<Button
				id="submit"
				type="submit"
				className="primary_btn theme-btn"
				label="Subscribe Now"
				onClick={() => setIsValid(true)}
			/>
		</div>
	);

	const handlePaymentSuccess = () => {
		onPaymentSuccess(); // Move to the next step on successful payment
	};

	return (
		<div className="d-flex justify-content-center">
			<div className="account-setup">
				<div className="form-field">
					{loading ? (
						<Spinner />
					) : (
						<>
							<div className="form-field">
								<label>First Name</label>
								<input
									type="text"
									name="firstName"
									placeholder="First Name"
									value={data.accountUser.firstName}
									onChange={handleUserDataChange}
								/>
								{errors.firstName && <span className="error-text">{errors.firstName}</span>}
							</div>

							<div className="form-field">
								<label>Last Name</label>
								<input
									type="text"
									name="lastName"
									placeholder="Last Name"
									value={data.accountUser.lastName}
									onChange={handleUserDataChange}
								/>
								{errors.lastName && <span className="error-text">{errors.lastName}</span>}
							</div>

							<div className="form-field">
								<label>Email Address</label>

								<input
									type="email"
									name="email"
									placeholder="Email Address"
									value={data.accountUser.email}
									onChange={handleUserDataChange}
								/>
								{errors.email && <span className="error-text">{errors.email}</span>}
							</div>

							<div className="form-field">
								<label>Date of Birth</label>

								<input
									type="date"
									name="dob"
									placeholder="Date of birth (YYYY-MM-DD)"
									value={data.accountUser.dob}
									onChange={handleUserDataChange}
								/>
								{errors.dob && <span className="error-text">{errors.dob}</span>}
							</div>

							<div className="form-field">
								<label>Create Password</label>
								<input
									type="password"
									name="password"
									placeholder="Create Password"
									value={data.accountUser.password}
									onChange={handleUserDataChange}
								/>
								{errors.password && <span className="error-text">{errors.password}</span>}
							</div>

							<div className="form-field">
								<label>Confirm Password</label>
								<input
									type="password"
									name="confirmPassword"
									placeholder="Confirm Password"
									value={data.accountUser.confirmPassword}
									onChange={handleUserDataChange}
								/>
								{errors.confirmPassword && (
									<span className="error-text">{errors.confirmPassword}</span>
								)}

								{!paymentFormVisible && (
									<div className="mt-3">
										<Button
											variant="primary"
											label="Save and Continue to Payment"
											onClick={handleSave}
											fullWidth
										/>
									</div>
								)}

								{paymentFormVisible && (
									<div className="stripe">
										<PaymentWrapper>
											<Stripe
												cart={[data.selectedPlan]}
												submitButton={submitPayment}
												user={data.accountUser}
												type="business-subscription"
												setPaymentIntentId={setPaymentIntentId}
												onPaymentSuccess={handlePaymentSuccess}
											/>
										</PaymentWrapper>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AccountSetup;
