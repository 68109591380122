import styles from './howItWorks.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const HowItWorks = () => {
	return (
		<div className={styles.howItWorks}>
			<div className={styles.cardContent}>
				<h2>How it works</h2>
				<p>
					Add someone to your household to share your member benefits with them and to allow them to
					share theirs with you.
				</p>

				<p>
					Points are pooled together as a household balance, which means you can book services for
					your children or household members easily.
				</p>

				<p>
					Points are first deducted from your own balance first and then household balance
					thereafter.
				</p>
			</div>
		</div>
	);
};

export default HowItWorks;
