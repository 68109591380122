const MembershipTerms = () => {
	return (
		<>
			<div className="membership-terms container mt-70 mb-70">
				<h5>Terms and Conditions</h5>
				<p>
					For all Memberships, the monthly subscription requires a minimum of 3-months’ commitment,
					and a payment of three months’ subscription fee is charged at the point of signing up for
					the membership. Subscriptions can be cancelled after the initial three months at any time
					with one month's notice. Monthly payments will be automatically collected from the
					registered debit/credit card starting from the fourth month unless you have notified us of
					cancellation one month in advance. To create an account with Sirkka Health, individuals
					must be of 18 years of age or older.
				</p>
				<p>
					For Joint Membership and Family Membership, the benefits can be shared by the Joint
					members, and by the Family members including children & pets (Cats and/or Dogs).
					Households can have up to 2 adults and 4 children/pets with no additional membership
					charge.
				</p>
				<p>
					FREE services for members such as GP consultations, interpreter services and specialist
					services are exclusive to members who have purchased the annual membership.
				</p>
				<p>
					Members can claim household benefits of FREE services for cats and/or dogs through a cash
					refund to the payment card that is used for the membership subscription, subject to the
					following terms and information required:
				</p>

				<ul>
					<li>Vet’s registration document</li>
					<li>
						Pet’s record of the services received including receipt, vet’s letters/notes if
						applicable on a case-by-case basis.
					</li>
					<li>
						Please note that there is a cap for pet’s use of free services, which is £100 per
						routine care, vaccination and standard grooming and £65 per vet consultation and these
						will come out of the household allowances that are received with the purchase of Annual
						Family Memberships ONLY.
					</li>
				</ul>

				<p>
					These terms and conditions are subject to change at any time without prior notice. We
					advise all members to regularly review the terms and conditions of their accounts to stay
					updated with any changes. For more information on our policies, please visit here:{' '}
					<a href="https://sirkkahealth.com/refundPolicy">
						{' '}
						<u>Refund Policy | Sirkka Health</u>{' '}
					</a>{' '}
				</p>
				<p>
					Please address all questions to
					<a href="mailto:info@sirkkaltd.com">
						{' '}
						<u>info@sirkkaltd.com</u>{' '}
					</a>
				</p>
			</div>
		</>
	);
};

export default MembershipTerms;
