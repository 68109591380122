import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

const EditPatientModal = ({ showEditModal, onClose, editedPatient, onEditChange, onSubmit }) => {
	if (!showEditModal) return null;

	if (showEditModal)
		return (
			<div
				className="modal"
				style={{
					display: 'block',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					cursor: 'pointer',
				}}
				onClick={onClose}
			>
				<div
					className="modal-content"
					style={{
						backgroundColor: 'white',
						padding: '30px',
						margin: '20px auto',
						width: '100%',
						maxWidth: '600px',
					}}
					onClick={(e) => e.stopPropagation()}
				>
					<span className="close" onClick={onClose} style={{ float: 'right', cursor: 'pointer' }}>
						&times;
					</span>
					<h2>Edit patient record</h2>
					<form onSubmit={onSubmit}>
						<div>
							<label>First Name:</label>
							<input
								type="text"
								name="first_name"
								value={editedPatient.first_name || ''}
								onChange={onEditChange}
							/>
						</div>
						<div>
							<label>Last Name:</label>
							<input
								type="text"
								name="last_name"
								value={editedPatient.last_name || ''}
								onChange={onEditChange}
							/>
						</div>
						<div>
							<label>Gender:</label>
							<select name="gender" value={editedPatient.gender || ''} onChange={onEditChange}>
								<option value="" disabled>
									Select Gender
								</option>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div>
							<label>Date of Birth:</label>
							<input
								type="date"
								name="date_of_birth"
								// Convert ISO date string to 'YYYY-MM-DD' format for the date input
								value={editedPatient.date_of_birth ? editedPatient.date_of_birth.split('T')[0] : ''}
								onChange={onEditChange}
							/>
						</div>
						<div>
							<label>Phone Number:</label>
							<input
								type="tel"
								name="contact_number"
								value={editedPatient.contact_number || ''}
								onChange={onEditChange}
							/>
						</div>
						<div>
							<label>Email Address:</label>
							<input
								type="email"
								name="email_address"
								value={editedPatient.email_address || ''}
								onChange={onEditChange}
							/>
						</div>
						<div className="mt-3">
							<Button label="Edit patient" type={'submit'} fullWidth large />
						</div>
					</form>
				</div>
			</div>
		);
};

export default EditPatientModal;
