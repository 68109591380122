import styles from './Card.module.scss';

const Card = ({ contents, fullWidth, last, doubleWidth, halfWidth }) => {
	const calculateClassName = () => {
		let className = 'col-md-12 mb-30';

		if (last || fullWidth) {
			className += ' col-lg-12';
		} else if (doubleWidth) {
			className += ' col-lg-8';
		} else {
			className += ' col-lg-6';
		}

		if (fullWidth) {
			className += ' col-xl-12';
		} else if (doubleWidth) {
			className += ' col-xl-8';
		} else if (halfWidth) {
			className += ' col-xl-6';
		} else {
			className += ' col-xl-4';
		}

		return className;
	};

	return (
		<div className={calculateClassName()}>
			<div className={`card ${styles.card}`}>
				<div className="card-body d-flex">{contents}</div>
			</div>
		</div>
	);
};

export default Card;
