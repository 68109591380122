import React, { useState } from 'react';
import './FileInput.scss';

const FileInput = ({ onFileSelected }) => {
	const [fileLabel, setFileLabel] = useState('Drag and drop file here or');

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setFileLabel(`Selected file: ${file.name}`);
		onFileSelected(file);
	};

	return (
		<div className="file-upload-wrapper">
			<input
				type="file"
				id="file-upload"
				className="file-upload-input"
				onChange={handleFileChange}
				hidden
			/>
			<label htmlFor="file-upload" className="file-upload-label">
				{fileLabel} <span className="file-upload-btn">browse</span>
			</label>
		</div>
	);
};

export default FileInput;
