import React from 'react';
import styles from './LastOrder.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import ISOtoDateOnly from '../../../../helpers/formatDate';

function LastOrder({ appointmentsData, servicesData }) {
	if (!appointmentsData || Object.keys(appointmentsData).length === 0) {
		return (
			<div className={styles.lastOrder}>
				<div className={styles.lastOrderInner}>
					<h3>Your order history</h3>
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
						Here you'll see details of your order history once you have made your first order
					</div>
				</div>
			</div>
		);
	}

	// Enrich appointmentsData with information from servicesData
	const enrichedAppointmentsData = appointmentsData.map((appointment) => {
		const service = servicesData.find(
			(service) => service.service_code === appointment.service_id_booking
		);

		if (service) {
			return {
				...appointment,
				service_price: service.service_price,
				service_name: service.service_name,
				service_icon: service.service_icon,
			};
		}

		return appointment;
	});

	// Calculate total of all appointment prices
	const total = enrichedAppointmentsData.reduce((total, appointment) => {
		return total + (appointment.service_price || 0);
	}, 0);

	return (
		<div className={styles.lastOrder}>
			<div className={styles.lastOrderInner}>
				<h3>Your order history</h3>
				<ul>
					{enrichedAppointmentsData &&
						enrichedAppointmentsData
							.sort((a, b) => a.appt_start_dt.localeCompare(b.appt_start_dt))
							.slice(0, 3)
							.map((appointment) => (
								<li key={appointment.appointment_id}>
									{appointment.service_icon && (
										<img
											className={styles.productImg}
											src={
												appointment.service_icon
													? `/img/icon/${appointment.service_icon}.png`
													: `/img/doctor/${appointment.avatar}`
											}
											alt={appointment.service_name}
										/>
									)}

									<div className={styles.name}>{appointment.service_name}</div>

									<label htmlFor={appointment.service_name}>Quantity:</label>
									{appointment.service_price ? (
										<div className={styles.price}>
											{(appointment.service_price / 100).toLocaleString('en-GB', {
												style: 'currency',
												currency: 'GBP',
											})}
										</div>
									) : (
										<div className={styles.price}>{ISOtoDateOnly(appointment.appt_start_dt)}</div>
									)}
								</li>
							))}
				</ul>
			</div>

			<div className={styles.total}>
				<Button variant="border" label="View older transactions" onClick={() => 'services'} />
				<span>
					Total:{' '}
					{(total / 100).toLocaleString('en-GB', {
						style: 'currency',
						currency: 'GBP',
					})}
				</span>
			</div>
		</div>
	);
}

export default LastOrder;
