export default function CardListFilter({ totalDisplayed, entityData }) {
	return (
		<div className="d-flex justify-content-end">
			<div className="col-6 col-sm-8 col-xl-7 col-lg-7 d-flex align-items-center">
				{entityData && entityData.length && entityData[0].id === 'no-results' ? (
					<p className="mb-0">Displaying 0 results</p>
				) : (
					<>
						{totalDisplayed === 1 && <p className="mb-0">Displaying 1 result</p>}
						{totalDisplayed > 1 && <p className="mb-0">Displaying {totalDisplayed} results</p>}
					</>
				)}
			</div>
			<div className="col-6 col-sm-4 col-xl-5 col-lg-5">
				<div className="my-3 justify-content-end d-flex">
					<button className="btn" style={{ border: '2px solid black' }} type="button">
						<svg
							style={{ width: '22px', marginRight: '8px' }}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" />
						</svg>
						Sort and filter
					</button>
				</div>
			</div>
		</div>
	);
}
