import { Link } from 'react-router-dom';
import styles from './householdAllowances.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const HouseholdAllowances = ({ householdMembers }) => {
	if (!householdMembers || householdMembers.length === 0) {
		return <div>Loading...</div>;
	}

	const aggregatedData = householdMembers.reduce(
		(acc, member) => {
			acc.total_gp += member.total_num_free_gp || 0;
			acc.used_gp += member.gp_service_used || 0;
			acc.total_specialist += member.total_num_free_specialist || 0;
			acc.used_specialist += member.specialist_service_used || 0;
			acc.total_interpreter += member.total_num_interpreter || 0;
			acc.used_interpreter += member.medical_interpreter_used || 0;

			return acc;
		},
		{
			total_gp: 0,
			used_gp: 0,
			total_specialist: 0,
			used_specialist: 0,
			total_interpreter: 0,
			used_interpreter: 0,
		}
	);

	const remainingGP = aggregatedData.total_gp - aggregatedData.used_gp;
	const remainingSpecialist = aggregatedData.total_specialist - aggregatedData.used_specialist;
	const remainingInterpreter = aggregatedData.total_interpreter - aggregatedData.used_interpreter;

	let gpAppointments = `${remainingGP} of ${aggregatedData.total_gp}`;
	let specialistServices = `${remainingSpecialist} of ${aggregatedData.total_specialist}`;
	let interpreterServices = `${remainingInterpreter} of ${aggregatedData.total_interpreter}`;

	return (
		<div className={styles.householdAllowances}>
			<h2 className={styles.title}>Household Allowances</h2>
			<div className="row">
				<div className="col-xl-4 col-lg-6 col-md-12">
					<div className={styles.cardContent}>
						<h3>GP Appointments</h3>
						<h2>{gpAppointments}</h2>
						<h4>Remaining</h4>
					</div>
				</div>
				<div className="col-xl-4 col-lg-6 col-md-12">
					<div className={styles.cardContent}>
						<h3>Specialist Services</h3>
						<h2>{specialistServices}</h2>
						<h4>Remaining</h4>
					</div>
				</div>
				<div className="col-xl-4 col-lg-12 col-md-12">
					<div className={styles.cardContent}>
						<h3>Interpreter Services</h3>
						<h2>{interpreterServices}</h2>
						<h4>Remaining</h4>
					</div>
					<a href="/booking/London" className={styles.secondaryCta}>
						<Button variant="primary" label="Make appointment" fullWidth />
					</a>
				</div>
			</div>
		</div>
	);
};

export default HouseholdAllowances;
