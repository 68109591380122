const PaymentWrapper = ({ children }) => {
	return (
		<div className="accordion" id="accordionExample">
			<div className="card">
				<div className="card-header" id="headingOne">
					<h5 className="mb-0">
						<button
							className="btn-link"
							type="button"
							data-toggle="collapse"
							data-target="#collapseOne"
							aria-expanded="true"
							aria-controls="collapseOne"
						>
							Payment Details
						</button>
					</h5>
				</div>
				{children}
			</div>
		</div>
	);
};

export default PaymentWrapper;
