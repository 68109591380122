import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContactFormArea from '../Contact/ContactFormArea';
import ContactMap from '../Contact/ContactMap';
import { Button } from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

const Business = () => {
	const navigate = useNavigate();
	return (
		<>
			<Header title="Contact Us" />
			<div className="container">
				<div className="row">
					<div className="col-lg-9 mx-auto">
						<div className="px-5 pos-rel mb-70 mt-70">
							<div className="section-text pos-rel">
								<p className="overseas-hero-text">
									Sirkka provides a dedicated portal for business partners and organizations to
									efficiently manage their appointment bookings and services.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
						<Card
							className="p-sm-5 p-4 flex-column flex-sm-row"
							style={{
								backgroundColor: '#fff',
								gap: '20px',
								alignItems: 'baseline',
								justifyContent: 'space-evenly',
								border: '1px solid #0000000f',
							}}
						>
							<div className="w-100">
								<h5>Ready to get started?</h5>
								<Button
									variant="primary"
									label={'Register for business'}
									large
									fullWidth
									onClick={() => navigate('/business-signup')}
								/>
							</div>
							<div className=" w-100">
								<h5>Already registered?</h5>
								<Button
									variant="secondary"
									label={'Login for business'}
									large
									fullWidth
									onClick={() => navigate('/business-login')}
								/>
							</div>
						</Card>
						<div className="or-divide" style={{ marginTop: '50px' }}>
							<span style={{ background: '#f6f7fb' }}>or</span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<ContactFormArea style={{ marginTop: '0px' }} />
						<ContactMap />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Business;
