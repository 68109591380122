import { Link } from 'react-router-dom';
import styles from './medicalDocuments.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const MedicalDocuments = ({ documentsData }) => {
	if (!documentsData) {
		return (
			<div className={styles.medicalDocuments}>
				<div className={styles.cardContent}>
					<h3>RECENT MEDICAL DOCUMENTS</h3>
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
						We don't have any documents for you yet
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.medicalDocuments}>
			<div className={styles.cardContent}>
				<h3>RECENT MEDICAL DOCUMENTS</h3>
				{documentsData.map((document, index) => (
					<div key={index}>
						<h4>{document.file_name}</h4>
					</div>
				))}
			</div>

			{/* <a href="#" className={styles.secondaryCta}>
				View FAQ
			</a> */}
			<Link to="/medical-records">
				<Button variant="primary" label="My medical documents" fullWidth />
			</Link>
		</div>
	);
};

export default MedicalDocuments;
