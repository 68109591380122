const MembershipFeatures = ({ benefits, notAvailable }) => {
	return (
		<ul>
			{benefits &&
				benefits.map((benefit, index) => (
					<li key={index} className="blue-color">
						<i className="fas fa-check"></i>
						<b>{benefit}</b>
					</li>
				))}
			{notAvailable &&
				notAvailable.map((benefit, index) => (
					<li key={index} className="price-del">
						<i className="fas fa-check"></i>
						<del>{benefit}</del>
					</li>
				))}
		</ul>
	);
};

export default MembershipFeatures;
