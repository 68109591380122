import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

export default function DisclaimerModal({ onDisclaimerModalClose }) {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.69)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 9999,
			}}
			onClick={onDisclaimerModalClose}
		>
			<div
				style={{
					backgroundColor: '#fff',
					padding: '40px',
					borderRadius: '8px',
					minWidth: '400px',
					maxWidth: '800px',
				}}
				onClick={(e) => e.stopPropagation()}
			>
				<h4 className="mb-4">Notice</h4>
				<p>Please note that we provide Resting ECG only in these locations:</p>
				<p>
					<b>London, Glasgow, Cardiff, Belfast and Home Visit.</b>
				</p>
				<p className="mb-4">
					If you would like to have your blood tests and resting ECG at the same time, please
					contact us via{' '}
					<u>
						<a href="https://forms.office.com/e/RsmndD1E3v">
							https://forms.office.com/e/RsmndD1E3v
						</a>
					</u>
					.
				</p>
				<Button onClick={onDisclaimerModalClose} variant="primary" label="Close" fullWidth />
			</div>
		</div>
	);
}
