/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react';
import styles from './paymentForm.module.scss';
import { useForm } from 'react-hook-form';
import CountryDropdown from '../../../components/Countries';
import { ReactSession } from 'react-client-session';
import Stripe from '../Stripe';
import getMembershipTotal from '../../../helpers/getMembershipTotal';
import PaymentWrapper from './PaymentWrapper';
import { billingObject } from '../../../helpers/buildSessionObjects';
import checkBillingData from '../../../validations/billingDetails';
import showAlert from '../../../helpers/alerts';
import { sendCheckoutData } from '../../../services/data';

function PaymentForm({ onNextStep, membershipData, userData }) {
	const { register, handleSubmit } = useForm();
	const [userCountry, setUserCountry] = useState({});
	let checkoutType = 'subscription';
	const paymentButtonText = checkoutType === 'subscription' ? 'Subscribe now' : 'Pay now';
	const membershipDetails = ReactSession.get('membership');

	const inputRef = useRef(null);
	let formValid = null;
	ReactSession.set('form_validity', formValid);

	const submitForm = (event) => {
		event.target.form.requestSubmit();
	};

	const processForms = (event) => {
		console.log('Processing forms here');
		inputRef.current?.click();

		if (formValid === false) {
			console.log('Forms input are invalid');
			event.preventDefault();
		}
	};

	const onSubmit = async (data) => {
		const userId = userData.user_id;
		const userEmail = userData.email_address;
		data.phone = userData.contact_number;
		const personalDetails = {
			firstname: userData.first_name,
			lastname: userData.last_name,
			email: userEmail,
		};

		const billing_details = billingObject(data, userEmail);
		const billing_valid = checkBillingData(billing_details);

		if (billing_valid !== 'valid') {
			formValid = false;
			const billingErrorMessage = billing_valid;
			showAlert(billingErrorMessage);
		}

		if (billing_valid === 'valid') {
			formValid = true;
			ReactSession.set('billing_details', billing_details);
			ReactSession.set('form_validity', formValid);

			const checkoutData = {
				personal_details: personalDetails,
				customer_id: Object.keys(userData).length ? userData.customer_id : null,
				billing_details,
			};

			const sendData = async () => await sendCheckoutData(checkoutData);
			const response = sendData();

			if (response.status === 'success') {
				return response.message;
			}
			const errorMessage = response.message;
			if (errorMessage) showAlert(errorMessage);
		}
	};

	const submitButton = (
		<div className="order-button-payment mt-20">
			<button
				id="submit"
				type="submit"
				className="primary_btn theme-btn"
				onClick={(event) => processForms(event)}
			>
				<span id="button-text">{paymentButtonText}</span>
			</button>
		</div>
	);

	return (
		<div className={styles.paymentForm}>
			<section className="checkout-area">
				<form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
					<h3>Billing Address</h3>
					<div className="row">
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									First Name <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('firstname_billing')}
									defaultValue={null}
									required
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									Last Name <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('lastname_billing')}
									defaultValue={null}
									required
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<label>
									Address <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder="Street address"
									{...register('address1_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<input
									type="text"
									placeholder="Apartment, suite, unit etc. (optional)"
									{...register('address2_billing')}
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<label>
									Town / City <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('city_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									State / County <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('county_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									Postcode / Zip <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('postcode_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<CountryDropdown
								register={register('country_billing')}
								required={true}
								userCountry={userCountry}
								setUserCountry={setUserCountry}
							/>
						</div>
					</div>
					<input type="hidden" ref={inputRef} onClick={(event) => submitForm(event)} />
				</form>
				<div className="payment-method">
					<h3 className={'mb-3'}>Pay by card</h3>
					<Stripe
						type={checkoutType}
						cart={membershipData}
						user={userData && userData}
						price={getMembershipTotal(membershipData)}
						submitButton={submitButton}
					/>
				</div>
			</section>
		</div>
	);
}

export default PaymentForm;
