import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

export default function SignupFooter({
	onBack,
	onNext,
	currentStep,
	isBackEnabled,
	isNextEnabled,
}) {
	const handleNextClick = () => {
		if (currentStep === 7) {
			localStorage.clear();
			window.location.href = '/';
		} else {
			onNext();
		}
	};

	return (
		<div
			className="d-flex justify-content-between align-items-center mt-4 bg-white py-4 px-4"
			style={{ borderTop: '2px solid #ebedff' }}
		>
			{[1, 4, 5, 7].includes(currentStep) ? (
				<div></div>
			) : (
				<Button variant="border" label={'Back'} onClick={onBack} disabled={!isBackEnabled} />
			)}
			{currentStep !== 3 && (
				<Button
					className="ml-auto"
					variant="primary"
					label={currentStep === 7 ? 'Go to homepage' : 'Continue'}
					onClick={handleNextClick}
					disabled={currentStep !== 7 && !isNextEnabled}
				/>
			)}
		</div>
	);
}
