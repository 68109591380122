import React from 'react';
import DoctorBox from './DoctorBox';

const DoctorsList = (props) => {
	const { doctors } = props;

	return (
		<>
			<section className="team-area pb-90">
				<div className="container">
					<div className="row">
						{doctors.specialist.map((doctorData, index) => (
							<DoctorBox key={index} {...doctorData} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default DoctorsList;
