import React from 'react';
import policy_data from '../../../data/policy.json';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const PrivacyPolicy = () => {
	return (
		<>
			<Header title="Privacy Policy" />
			<div className="container mt-30 mb-30">
				{policy_data.privacy_policy.map((privacy) => {
					return (
						<div className="col-xl-12 col-lg-7" key={privacy}>
							<h1>{privacy.privacyPolicyTitle}</h1>
							<hr />
							<p>{privacy.privacyPolicyParagraph1}</p>
							<ol className="policy-ordered-list">
								<li>
									<h6>{privacy.privacyPolicyLayered}</h6>
									<p>{privacy.privacyPolicyLayeredParagraph1}</p>
									<p>
										<ul className="policy-unordered-list">
											<li>{privacy.privacyPolicyLayeredParagraph2}</li>
											<li>{privacy.privacyPolicyLayeredParagraph3}</li>
										</ul>
									</p>
								</li>
								<li>
									<h6>{privacy.privacyPolicyInfoWeCollect}</h6>
									<p>{privacy.privacyPolicyInfoWeCollectParagraph1}</p>
									<p>
										<ul className="policy-unordered-list">
											<li>{privacy.privacyPolicyInfoWeCollectParagraph2}</li>
											<ul className="policy-unordered-list">
												<li>{privacy.privacyPolicyInfoWeCollectParagraph3}</li>
												<li>{privacy.privacyPolicyInfoWeCollectParagraph4}</li>
											</ul>
											<li>{privacy.privacyPolicyInfoWeCollectParagraph5}</li>
											<li>{privacy.privacyPolicyInfoWeCollectParagraph6}</li>
										</ul>
									</p>
								</li>
								<li>
									<h6>{privacy.privacyPolicyWhyWeCollect}</h6>
									<p>{privacy.privacyPolicyWhyWeCollectParagraph1}</p>
									<p>{privacy.privacyPolicyWhyWeCollectParagraph2}</p>
									<p>{privacy.privacyPolicyWhyWeCollectParagraph3}</p>
									<p>
										<ul className="policy-unordered-list">
											<li>{privacy.privacyPolicyWhyWeCollectParagraph4}</li>
											<li>{privacy.privacyPolicyWhyWeCollectParagraph5}</li>
											<li>{privacy.privacyPolicyWhyWeCollectParagraph6}</li>
											<li>{privacy.privacyPolicyWhyWeCollectParagraph7}</li>
										</ul>
									</p>
								</li>

								<li>
									<h6>{privacy.privacyPolicySharingData}</h6>
									<p>{privacy.privacyPolicySharingDataParagraph1}</p>
									<ol>
										<li>
											{privacy.privacyPolicySharingDataOurGroup}
											<p>{privacy.privacyPolicySharingDataOurGroupParagraph1}</p>
										</li>
										<li>
											{privacy.privacyPolicySharingDataAuthorised}
											<p>{privacy.privacyPolicySharingDataAuthorisedParagraph1}</p>
										</li>
										<li>
											{privacy.privacyPolicySharingDataGovernmental}
											<p>{privacy.privacyPolicySharingDataGovernmentalParagraph1}</p>
										</li>
									</ol>
								</li>

								<li>
									<h6>{privacy.privacyPolicyYourRights}</h6>
									<p>{privacy.privacyPolicyYourRightsParagraph1}</p>
									<p>
										<ul className="policy-unordered-list">
											<li>
												<a
													href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-be-informed/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph2Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-of-access/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph3Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-rectification/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph4Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-erasure/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph5Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-restrict-processing/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph6Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-data-portability/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph7Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-object/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph8Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/consent/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph9Link}
												</a>
											</li>
											<li>
												{' '}
												<a
													href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/rights-related-to-automated-decision-making-including-profiling/"
													target="_blank"
													rel="noreferrer"
												>
													{privacy.privacyPolicyYourRightsParagraph10Link}
												</a>
											</li>
										</ul>
									</p>
									<p>{privacy.privacyPolicyYourRightsParagraph11}</p>
									<p>
										{privacy.privacyPolicyYourRightsParagraph12}{' '}
										<a href="https://ico.org.uk/" target="_blank" rel="noreferrer">
											{privacy.privacyPolicyYourRightsParagraph13}
										</a>{' '}
										{privacy.privacyPolicyYourRightsParagraph14}
									</p>
									<p>{privacy.privacyPolicyYourRightsParagraph15}</p>
									<p>{privacy.privacyPolicyYourRightsParagraph16}</p>
									<p>{privacy.privacyPolicyYourRightsParagraph17}</p>
								</li>

								<li>
									<h6>{privacy.privacyPolicyOtherWeb}</h6>
									<p>{privacy.privacyPolicyOtherWebParagraph1}</p>
								</li>
								<li>
									<h6>{privacy.privacyPolicyChanges}</h6>
									<p>{privacy.privacyPolicyChangesParagraph1}</p>
								</li>
								<li>
									<h6>{privacy.privacyPolicyComplain}</h6>
									<p>{privacy.privacyPolicyComplainParagraph1}</p>
								</li>
							</ol>
						</div>
					);
				})}
			</div>
			<Footer />
		</>
	);
};

export default PrivacyPolicy;
