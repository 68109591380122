import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const LinkElements = (props) => {
	const [linkElements, setLinkElements] = useState([]);

	useEffect(() => {
		setLinkElements(props.data);
	}, [props]);

	return (
		<>
			<div className="footer-widget h4footer-widget mb-sm-4 mb-md-5">
				<div className="footer-title">
					<h3>{props.heading}</h3>
				</div>
				<div className="footer-menu footer-menu-2 h4footer-menu">
					{linkElements.map((data, index) => (
						<ul key={index}>
							<li>
								<Link to={data.link}>{data.text}</Link>
							</li>
						</ul>
					))}
				</div>
			</div>
		</>
	);
};

export default LinkElements;
