import React, { useState, useEffect, useCallback } from 'react';
import './SignupHeader.scss';

function SignupHeader({ step }) {
	const [prevStep, setPrevStep] = useState(step);
	const [visibleSteps, setVisibleSteps] = useState([]);
	const steps = [
		{ id: 1, label: 'Listing type', title: 'How would you like to use Sirkka?' },
		{ id: 2, label: 'Your business', title: 'Tell us about your business' },
		{ id: 3, label: 'Account setup', title: `Let's create your account ` },
		{ id: 4, label: 'Payment successful', title: `Payment successful` },
		{ id: 5, label: 'Further details', title: 'Tell us more about your business' },
		{ id: 6, label: 'Services setup', title: 'Tell us about your services' },
		{ id: 7, label: 'Completion', title: `We have received your information!` },
	];

	useEffect(() => {
		setPrevStep(step);
	}, [step]);

	useEffect(() => {
		const progressBar = document.querySelector('.progress-bar');
		const startWidth = ((prevStep - 1) / steps.length) * 100;
		const endWidth = (step / steps.length) * 100;
		progressBar.style.width = `${startWidth}%`;
		progressBar.style.transition = 'none';
		requestAnimationFrame(() => {
			progressBar.style.transition = 'width 0.8s ease-in-out';
			progressBar.style.width = `${endWidth}%`;
		});
	}, [step, prevStep, steps.length]);

	const updateVisibleSteps = useCallback(() => {
		const container = document.querySelector('.step-indicator');
		if (!container) return;

		const containerWidth = container.clientWidth || 0;

		const stepWidth = 170;

		const stepsThatFit = Math.floor(containerWidth / stepWidth);

		const start = Math.max(step - 2, 0); // Always show at least one previous step
		const end = Math.min(step + (stepsThatFit - 2), steps.length); // Show current step and at least 2 future steps

		const newVisibleSteps = steps.slice(start, end);
		if (JSON.stringify(newVisibleSteps) !== JSON.stringify(visibleSteps)) {
			setVisibleSteps(newVisibleSteps);
		}
	}, [step, steps, visibleSteps]);

	useEffect(() => {
		updateVisibleSteps();
		window.addEventListener('resize', updateVisibleSteps);

		return () => window.removeEventListener('resize', updateVisibleSteps);
	}, [updateVisibleSteps]);

	return (
		<>
			<div className="header d-flex justify-content-between align-items-center py-4 px-2 mb-0 bg-white">
				<a href="https://sirkkahealth.com">
					<img
						src="https://sirkkahealth.com/img/logo/sirkka-health-logo.png"
						alt="SIRKKA HEALTH Logo"
						className="logo"
					/>
				</a>
				{/* Mobile Stepper */}
				<div className="mobile-progress d-flex flex-column align-items-center d-md-none me-3">
					{/* <div className="radial-progress">
						<svg>
							<circle cx="50" cy="50" r="45"></circle>
							<circle
								cx="50"
								cy="50"
								r="45"
								style={{ strokeDasharray: `${(step / steps.length) * 283}, 283` }}
							></circle>
						</svg>
						<div className="step-circle">
							{step} of {steps.length}
						</div>
					</div> */}
					<div className="mobile-step-title">
						<span className="step-counter">
							{step} of {steps.length}
						</span>
						{steps[step - 1].label}
						{step < steps.length && <span className="next-step">Next: {steps[step].label}</span>}
					</div>
				</div>
				{/* Desktop Stepper */}
				<div className="step-indicator d-none d-md-flex mb-0 w-100">
					{visibleSteps.length > 0 ? (
						visibleSteps.map((s) => (
							<div
								key={s.id}
								className={`step ${s.id < step ? 'completed' : ''} ${
									s.id === step ? 'current' : ''
								}`}
								style={{ cursor: 'default' }}
							>
								<span className="step-number">{s.id < step ? '✔' : s.id}</span> {s.label}
							</div>
						))
					) : (
						<div className="no-steps"></div>
					)}
				</div>
			</div>
			<div className="progress-bar-container mb-5">
				<div className="progress-bar"></div>
			</div>

			<h2 className="mb-4 px-4 header-text">{steps[step - 1].title}</h2>
		</>
	);
}

export default SignupHeader;
