import styles from './myAllowances.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const MyAllowances = ({ membershipData }) => {
	if (!membershipData) {
		return <div>Loading...</div>;
	}

	let gpAppointments = membershipData.free_gp_consultation
		? membershipData.free_gp_consultation
		: 0;

	let freeScreeningTests = membershipData.free_screening_test
		? membershipData.free_screening_test
		: 0;

	return (
		<div className={styles.myAllowances}>
			<h2 className={styles.title}>My allowances</h2>
			{Object.keys(membershipData).length === 0 ? (
				<>
					<h3 className="mt-10 mb-10">Subscribe to our membership to get more benefits</h3>
				</>
			) : (
				<>
					<div className="row">
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className={styles.cardContent}>
								<h3>GP Appointments</h3>
								<h2>{gpAppointments}</h2>
								<h4>Remaining</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className={styles.cardContent}>
								<h3>Services Discount</h3>
								<h2>{membershipData.service_discount}%</h2>
								<h4>(Excluding imaging)</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className={styles.cardContent}>
								<h3>Free Screening Test</h3>
								<h2>{freeScreeningTests}</h2>
								<h4>Remaining</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className={styles.cardContent}>
								<h3>Referral For Specialists</h3>
								<h2>
									<i className="fa fa-check" data-unicode="f00c"></i>
								</h2>
								<h4>(Available upon request)</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className={styles.cardContent}>
								<h3>Reward Points</h3>
								<h2>
									<i className="fa fa-check" data-unicode="f00c"></i>
								</h2>
								<h4>(Collect and use)</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-12 col-md-6">
							<div className={styles.cardContent}>
								<h3>Interpreter Services</h3>
								<h2>
									<i className="fa fa-check" data-unicode="f00c"></i>
								</h2>
								<h4>(For non-English speakers)</h4>
							</div>
							<a href="/booking/London" className={styles.secondaryCta}>
								<Button variant="primary" label="Make appointment" fullWidth />
							</a>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default MyAllowances;
