import React, { useState, useContext } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ReactSession } from 'react-client-session';
import {
	BookingSubmissionForm,
	CartSummary,
	BookingContext,
} from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom';

export default function BookingCompletion() {
	const [user, setUser] = useState(ReactSession.get('user') || null);

	const { bookingData, currentBookingId } = useContext(BookingContext);

	const navigate = useNavigate();

	const goToOrderSummary = () => {
		navigate('/cart');
	};

	const goToMostRecentPage = () => {
		if (bookingData && bookingData.length > 0) {
			const mostRecentBooking = bookingData[bookingData.length - 1];
			if (mostRecentBooking.booking && mostRecentBooking.booking.bookingType) {
				const bookingTypeUrl = `/${mostRecentBooking.booking.bookingType}`;
				navigate(bookingTypeUrl);
			}
		}
	};

	const isViewingCurrentBooking = (bookingId) => {
		return bookingId === currentBookingId;
	};

	return (
		<>
			<Header />
			<div
				className={'container mt-30 mb-30 flex-sm-row flex-column'}
				style={{
					display: 'flex',
					alignItems: 'start',
					justifyContent: 'space-between',
					gap: '20px',
				}}
			>
				<BookingSubmissionForm registeredUser={user} onComplete={goToOrderSummary} />
				<CartSummary
					onPrevStep={goToMostRecentPage}
					hideRemove={isViewingCurrentBooking(currentBookingId)}
				/>
			</div>
			<Footer />
		</>
	);
}
