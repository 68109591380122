import { Link } from 'react-router-dom';

const ServiceBox = ({ thumbnail, title, subtitle, overview_text, link }) => {
	return (
		<div className="h4service-item">
			<div className="h4service-box white-bg mb-30">
				<div className="service-thumb pos-rel mb-0">
					<img src={`/img/services/${thumbnail}`} alt="" />
					{/* <Link className="h4services-tag green-bg white-color text-uppercase f-700" to="/servicesDetails">{serviceTitle}</Link> */}
				</div>
				<div className="service-content h4services-content h6services-content">
					<h3>
						<Link to={`${link}`}>{subtitle}</Link>
					</h3>
					<p className="mb-20">{overview_text}</p>
					<Link className="service-link" to={`${link}`}>
						Learn More
						<i className="fal fa-long-arrow-right"></i>
					</Link>
					{/* <div className="h5services-bottom">
                   <span><i><img src="/img/icon/h5manage__icon.png" alt="" /></i><span className="f-500">07 Specialist Doctors</span></span>
                </div> */}
				</div>
			</div>
		</div>
	);
};

export default ServiceBox;
