import MembershipFeatures from './MembershipFeatures';
import formatPrice from '../../helpers/formatPrice';
import { useLocation } from 'react-router-dom';

const MembershipPricing = ({
	title,
	price,
	yearlyPrice,
	pink_bg,
	green_bg_color,
	features,
	notAvailable,
	setMembership,
	mode,
}) => {
	price = mode === 'monthly' ? price : yearlyPrice;

	const membershipProduct = {
		name: title,
		price,
		mode,
		qty: 1,
	};

	const { pathname } = useLocation();

	return (
		<div className="col-xl-4 col-lg-4 col-md-6">
			<div className="price-box-flat mb-30">
				<div className={`pricing-title ${pink_bg && pink_bg}`}>
					<h6 className="white-color text-up-case letter-spacing">{title}</h6>
				</div>
				<div className="price-content">
					<div className="price-heading">
						<h1
							style={{
								fontSize: pathname === '/subscription' ? '40px' : '70px',
							}}
						>
							{/* <span className="pink-color">£</span> */}
							{formatPrice({
								amount: price,
								currency: 'gbp',
							})}
							{mode === 'monthly' && '*'}
						</h1>
					</div>
					<div className="pricing-list">
						<MembershipFeatures benefits={features} notAvailable={notAvailable} />
					</div>
				</div>
				<div className="price-btn-2">
					<button onClick={() => setMembership(membershipProduct)} className="subscribe_btn">
						Subscribe now
					</button>
				</div>
			</div>
		</div>
	);
};

export default MembershipPricing;
