import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AuthenticationFormWrapper from '../Register/AuthenticationFormWrapper';

const ResetPasswordArea = ({ resetStatus, onSubmit }) => {
	const { register, handleSubmit } = useForm();
	// const navigate = useNavigate();

	if (resetStatus) {
		// navigate(location.state?.from?.pathname || "/login");
	}

	const heading = resetStatus ? 'Password successfully reset' : 'Set a new password';

	return (
		<AuthenticationFormWrapper>
			<h3 className="text-center mb-30">{heading}</h3>
			<form onSubmit={handleSubmit(onSubmit)}>
				{!resetStatus && (
					<>
						<label htmlFor="pass" className="mb-2">
							New password <span>*</span>
						</label>
						<input
							{...register('password')}
							required
							id="pass"
							type="password"
							placeholder="Enter your new password"
						/>
						<input
							{...register('passwordConfirm')}
							required
							id="passConfirm"
							type="password"
							placeholder="Confirm your new password"
						/>

						<button type="submit" className="primary_btn w-100">
							Save new password
						</button>
					</>
				)}
				{resetStatus && (
					<Link to="/login">
						<button className="primary_btn w-100">Go to Login</button>
					</Link>
				)}
			</form>
		</AuthenticationFormWrapper>
	);
};

export default ResetPasswordArea;
