import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

const PatientSelect = ({
	patients,
	selectedPatientValue,
	onPatientChange,
	onOpenAddModal,
	onOpenEditModal,
}) => {
	return (
		<>
			<div className="">
				<h3 className="mt-5 mb-2">1. Select patient</h3>
			</div>
			<div className="patient">
				{patients && patients.length > 0 && (
					<>
						<select value={selectedPatientValue} onChange={onPatientChange}>
							<option value="" disabled>
								Select existing patient
							</option>
							{patients.map((patient, index) => (
								<option key={index} value={patient.record_id}>
									{patient.first_name} {patient.last_name}
								</option>
							))}
						</select>
						{selectedPatientValue && (
							<div className="mt-3">
								<Button label="Edit patient record" onClick={onOpenEditModal} fullWidth large />
							</div>
						)}
						<div className="or-divide">
							<span>or</span>
						</div>
					</>
				)}
				<Button label="Create new patient record" onClick={onOpenAddModal} fullWidth large />
			</div>
		</>
	);
};

export default PatientSelect;
