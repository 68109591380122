/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ServicesFilter from './ServicesFilter';
import { CardList, SpecialistModal, BookingContext } from '@sirkka-health/booking-system-ui';
import transformLocationCategoryData from './transformers/locationCategoriesTransformer';
import transformServiceCategoryData from './transformers/serviceCategoryTransformer';
import markObjectAsChecked from './helpers/markObjectAsChecked';
import {
	getServiceCategories,
	getServices,
	getSpecialists,
	getLocationCategories,
} from './helpers/getData';
import styles from './HealthTestsAndSpecialists.module.scss';
import DisclaimerModal from './DisclaimerModal';
import useNavigateOnLocationChange from './hooks/useNavigateOnLocationChange';
import useFiltering from './hooks/useFiltering';
import useURLParams from './hooks/useURLParams';
import Loader from './../../components/Loader';
import useFetchMembershipData from '../../hooks/useFetchMembershipData';
import Modal from '../../components/Modal';
import LocationList from '../../components/LocationList';
import { buildGeneralCartObject } from '@/helpers/addToCart';

const HealthTestsAndSpecialists = () => {
	const {
		services,
		setServices,
		specialists,
		setSpecialists,
		servicesAndSpecialists,
		setServicesAndSpecialists,
		serviceCategories,
		setServiceCategories,
		selectedServiceCategories,
		setSelectedServiceCategories,
		selectedSpecialistCategories,
		setSelectedSpecialistCategories,
		selectedLocationCategory,
		setSelectedLocationCategory,
		userHasChosenLocation,
		setUserHasChosenLocation,
		bookingData,
		setBookingData,
	} = useContext(BookingContext);
	const { location: locationURLParam } = useParams();
	const navigate = useNavigate();

	const [possibleLocationCategories, setPossibleLocationCategories] = useState([]);
	const [locationCategories, setLocationCategories] = useState([]);
	const [serviceModalDataFromParams, setServiceModalDataFromParams] = useState(null);
	const [specialistModalDataFromParams, setSpecialistModalDataFromParams] = useState(null);
	const [isModalOpenedManually, setIsModalOpenedManually] = useState(false);
	const [disclaimerModal, setDisclaimerModal] = useState(false);
	const [disclaimerHasBeenShown, setDisclaimerHasBeenShown] = useState(false);
	const [dataHasLoaded, setDataHasLoaded] = useState(false);
	const [filterVisible, setFilterVisible] = useState(true);
	const [loading, setLoading] = useState(true);
	const [matchedService, setMatchedService] = useState(null);

	const [serviceCategoriesTransformed, setServiceCategoriesTransformed] = useState([]);
	const [filters, setFilters] = useState({
		selectedCategoryIds: [],
		selectedSpecialistCategories: [],
	});

	const membershipData = useFetchMembershipData();

	//Transforms the service categories into a more usable format
	useEffect(() => {
		if (!serviceCategories || !serviceCategories.length) {
			return;
		}

		const transformCategories = (categories, parentId = null) => {
			const result = [];

			categories.forEach((category) => {
				if (category.parent_id === parentId) {
					const subcategories = transformCategories(categories, category.category_id);
					result.push({
						id: category.category_id,
						name: category.category_name,
						subcats: subcategories,
					});
				}
			});

			return result;
		};

		const transformedCategories = transformCategories(serviceCategories);
		setServiceCategoriesTransformed(transformedCategories);
	}, [serviceCategories]);

	const filterItems = (items) => {
		return items.filter((item) => {
			if (filters.country && item.location?.country !== filters.country) {
				return false;
			}

			const isGP = item.metadata?.title?.includes('GP');

			if (filters.selectedCategoryIds.length > 0) {
				if (item.category) {
					const categoryId = item.category.service_category_id;
					const isCategorySelected = filters.selectedCategoryIds.includes(categoryId);

					if (!isCategorySelected) {
						return false;
					}
				} else if (!isGP || !filters.selectedCategoryIds.includes(3)) {
					return false;
				}
			}

			if (filters.selectedSpecialistCategories.length > 0) {
				if (item.category) {
					return false;
				}

				let tags = item.metadata?.tags;
				if (typeof tags === 'string') {
					try {
						tags = tags.replace(/'/g, '"');
						tags = JSON.parse(tags);
					} catch (error) {
						console.warn('Failed to parse tags:', error);
						return false;
					}
				}

				if (Array.isArray(tags)) {
					const isSpecialistCategorySelected = tags.some((tag) =>
						filters.selectedSpecialistCategories.includes(tag)
					);

					if (!isSpecialistCategorySelected) {
						return false;
					}
				} else {
					return false;
				}
			}

			return true;
		});
	};

	useEffect(() => {
		setFilteredServices(filterItems(servicesAndSpecialists));
	}, [services, filters, servicesAndSpecialists]);

	useNavigateOnLocationChange(selectedLocationCategory, locationURLParam, navigate);
	const {
		filteredServices,
		filteredSpecialists,
		setFilteredServices,
		setFilteredSpecialists,
		resetFilters,
	} = useFiltering(
		services,
		setServices,
		specialists,
		setSpecialists,
		selectedServiceCategories,
		setSelectedServiceCategories,
		serviceCategories,
		setServiceCategories,
		selectedSpecialistCategories,
		setSelectedSpecialistCategories,
		selectedLocationCategory,
		locationCategories,
		locationURLParam,
		navigate,
		setUserHasChosenLocation,
		setDisclaimerHasBeenShown,
		setSelectedLocationCategory
	);

	const changeLocationFilters = (chosenLocation) => {
		const useLocation =
			!selectedLocationCategory || chosenLocation === selectedLocationCategory
				? locationURLParam
				: chosenLocation;
		const changeSuccessful = markObjectAsChecked(locationCategories, useLocation, true);

		if (changeSuccessful) {
			setUserHasChosenLocation(true);
		}
		setSelectedLocationCategory(useLocation);
	};

	useEffect(() => {
		changeLocationFilters(locationURLParam);
		getLocationCategories(setPossibleLocationCategories);
	}, [locationURLParam]);

	useEffect(() => {
		setLocationCategories(transformLocationCategoryData(possibleLocationCategories));
	}, [possibleLocationCategories]);

	useEffect(() => {
		setLoading(true);
		const fetchSpecialistsAndCategories = Promise.all([
			getSpecialists(setSpecialists),
			getServiceCategories(selectedLocationCategory, setServiceCategories),
		]);

		const fetchServices = getServices()
			.then((data) => {
				setServices(data);
			})
			.catch((error) => {
				console.error(error);
			});

		Promise.all([fetchSpecialistsAndCategories, fetchServices])
			.catch((error) => console.error(error))
			.finally(() => {
				setLoading(false);
			});

		setFilteredServices(null);
	}, [selectedLocationCategory]);

	useURLParams(
		dataHasLoaded,
		setSelectedServiceCategories,
		setSelectedSpecialistCategories,
		serviceCategories,
		servicesAndSpecialists,
		setServiceModalDataFromParams,
		selectedServiceCategories,
		selectedSpecialistCategories,
		disclaimerHasBeenShown,
		setDisclaimerModal,
		filters,
		setFilters,
		setSpecialistModalDataFromParams
	);
	useEffect(() => {
		if (services.length && specialists.length) {
			// Separate 'gpConsultation' services
			const gpConsultationServices = services.filter(
				(service) => service.service.service_code === 'gpConsultation'
			);
			const otherServices = services.filter(
				(service) => service.service.service_code !== 'gpConsultation'
			);

			// Filter specialists to get only GPs
			const gpSpecialists = specialists.filter((specialist) =>
				specialist.metadata?.title?.includes('GP')
			);

			// Combine other services with filtered GPs
			const combined = [...otherServices, ...gpSpecialists];

			setDataHasLoaded(true);
			setServicesAndSpecialists(combined);
		}
	}, [services, specialists]);

	// when the service modal opens, add the service code to the URL
	const handleServiceModalOpen = (service) => {
		const itemMatched = servicesAndSpecialists.find((item) => {
			return item.service?.service_code === service.service_code;
		});

		if (itemMatched) {
			setMatchedService(itemMatched);
		} else {
			console.log('Error: No matching service found for modal');
		}

		setIsModalOpenedManually(true);
		navigate(`?serviceCode=${service.service_code}`, {
			replace: true,
		});
	};

	// when the service modal closes, remove the service code from the URL
	const handleServiceModalClose = () => {
		setIsModalOpenedManually(false);
		navigate(`?`, { replace: true });
		setServiceModalDataFromParams(null);
	};

	// // when the specialist modal opens, add the user_id to the URL
	const handleSpecialistModalOpen = (userId) => {
		setIsModalOpenedManually(true);
		navigate(`?specialistCode=${userId}`, {
			replace: true,
		});
	};

	// // when the service modal closes, remove the service code from the URL
	const handleSpecialistModalClose = () => {
		setIsModalOpenedManually(false);
		navigate(`?`, { replace: true });
		setSpecialistModalDataFromParams(null);
	};

	const onDisclaimerModalClose = () => {
		setDisclaimerModal(false);
		setDisclaimerHasBeenShown(true);
	};

	const goToCartPage = () => {
		navigate('/cart');
	};

	const onToggleVisibility = () => {
		setFilterVisible(!filterVisible);
	};

	const query = new URLSearchParams(useLocation().search);
	const availableLocationsParam = query.get('availableLocations');
	const referrerParam = query.get('referral');
	const serviceCodeParam = query.get('serviceCode');

	let availableLocationsArray;
	if (availableLocationsParam) {
		// If availableLocations exists, split it into an array
		availableLocationsArray = availableLocationsParam.split(',');
	} else {
		// If availableLocations doesn't exist, create an empty array
		availableLocationsArray = null;
	}

	if (loading) {
		return (
			<div>
				<Loader />
			</div>
		);
	}

	const addToCart = async (location, matchedService) => {
		const selectedServiceItem = matchedService.service;
		const selectedEntityItem = {
			booking_entity_id: 1,
			display_name: 'Sirkka Health',
		};

		const processed = buildGeneralCartObject(location, selectedServiceItem, selectedEntityItem);

		setBookingData([...bookingData, processed]);

		navigate('/cart');
	};

	function parseArrayString(str) {
		try {
			if (!str || typeof str !== 'string') {
				return str;
			}
			const validJsonString = str.replace(/'/g, '"');
			const arr = JSON.parse(validJsonString);
			if (Array.isArray(arr)) {
				return arr;
			}
			throw new Error('The input string is not formatted as an array.');
		} catch (err) {
			console.error('Error:', err.message);
			return [];
		}
	}

	return (
		<>
			{/* Disclaimer Modal */}
			{disclaimerModal && <DisclaimerModal onDisclaimerModalClose={onDisclaimerModalClose} />}
			<Header title="Services" />

			{/* <h1>{availableLocationFromUrl}</h1> */}
			<div className={`${styles.filterSection} ${filterVisible ? styles.active : null}`}>
				<ServicesFilter
					filters={filters}
					serviceCategoriesTransformed={serviceCategoriesTransformed}
					setFilters={setFilters}
					possibleLocationCategories={possibleLocationCategories}
					selectedLocationCategory={selectedLocationCategory}
					onLocationChange={changeLocationFilters}
					onToggleVisibility={onToggleVisibility}
					filterVisible={filterVisible}
					setFilterVisible={setFilterVisible}
					userHasChosenLocation={userHasChosenLocation}
				/>
			</div>
			<div className={`${styles.bookingContainer} container mt-20 mb-30 pr-0`}>
				<div className={styles.filterContainer}>
					<div className={styles.resultsColumn}>
						{/* // Only show modal if it has been opened via param */}
						{!isModalOpenedManually && serviceModalDataFromParams && (
							<>
								<Modal
									isOpen={true}
									onClose={() => {
										navigate(`?`, { replace: true });
										setServiceModalDataFromParams(null);
									}}
									content={
										<div>
											<img
												src={`/img/icon/${serviceModalDataFromParams.content.service_icon}.png`}
												alt={serviceModalDataFromParams.service.service_name}
												style={{
													borderRadius: '50%',
													margin: 'auto',
													overflow: 'hidden',
													height: '140px',
													display: 'block',
												}}
											/>
											<h1 className="text-center">
												{serviceModalDataFromParams.service.service_name}
											</h1>
											{serviceModalDataFromParams.service.service_price > 0 && (
												<>
													<h6
														className="text-center"
														style={{
															marginTop: '12px',
															color: '#0279a3',
															fontWeight: '500',
															fontSize: '18px',
														}}
													>
														{(
															serviceModalDataFromParams.service.service_price / 100
														).toLocaleString('en-GB', {
															style: 'currency',
															currency: 'GBP',
														})}
													</h6>
												</>
											)}
											{serviceModalDataFromParams.content.top_description && (
												<div className="mb-3">
													{parseArrayString(serviceModalDataFromParams.content.top_description).map(
														(description, index) => (
															<p key={`top-description-${index}`}>{description}</p>
														)
													)}
												</div>
											)}
											{serviceModalDataFromParams.content.bullets && (
												<ul>
													{parseArrayString(serviceModalDataFromParams.content.bullets).map(
														(bullet, index) => (
															<li key={`bullet-${index}`} style={{ listStyle: 'disc inside' }}>
																{bullet}
															</li>
														)
													)}
												</ul>
											)}
											{serviceModalDataFromParams.content.bottom_description && (
												<div className="mt-3">
													{parseArrayString(
														serviceModalDataFromParams.content.bottom_description
													).map((description, index) => (
														<p key={`bottom-description-${index}`}>{description}</p>
													))}
												</div>
											)}
											{serviceModalDataFromParams.content.disclaimers && (
												<div className="mt-3 text-muted" style={{ fontSize: '14px' }}>
													{parseArrayString(serviceModalDataFromParams.content.disclaimers).map(
														(disclaimer, index) => (
															<p key={`disclaimer-${index}`}>{disclaimer}</p>
														)
													)}
												</div>
											)}
											<LocationList
												type={'diagnostic-services'}
												service={serviceModalDataFromParams}
												bookingEntityId={1}
												selectedEntityItem={{ booking_entity_id: 1, display_name: 'Sirkka Health' }}
												selectedServiceItem={{
													...serviceModalDataFromParams.service,
													...serviceModalDataFromParams.content,
												}}
												referrerParam={referrerParam}
												availableLocations={availableLocationsArray}
											/>
										</div>
									}
								/>
							</>
						)}
						{isModalOpenedManually && matchedService && (
							<>
								<Modal
									isOpen={true}
									onClose={() => {
										navigate(`?`, { replace: true });
										setServiceModalDataFromParams(null);
										setIsModalOpenedManually(false);
									}}
									content={
										<div>
											<img
												src={`/img/icon/${matchedService.content.service_icon}.png`}
												alt={matchedService.service.service_name}
												style={{
													borderRadius: '50%',
													margin: 'auto',
													overflow: 'hidden',
													height: '140px',
													display: 'block',
												}}
											/>
											<h1 className="text-center">{matchedService.service.service_name}</h1>
											<h6
												className="text-center"
												style={{
													marginTop: '12px',
													color: '#0279a3',
													fontWeight: '500',
													fontSize: '18px',
												}}
											>
												{matchedService.service.service_price > 0 &&
													(matchedService.service.service_price / 100).toLocaleString('en-GB', {
														style: 'currency',
														currency: 'GBP',
													})}
											</h6>
											{matchedService.content.top_description && (
												<div className="mb-3 top-description">
													{parseArrayString(matchedService.content.top_description).map(
														(description, index) => (
															<p key={`top-description-${index}`}>{description}</p>
														)
													)}
												</div>
											)}
											{matchedService.content.bullets && (
												<ul className="bullet-list">
													{parseArrayString(matchedService.content.bullets).map((bullet, index) => (
														<li key={`bullet-${index}`} style={{ listStyle: 'disc inside' }}>
															{bullet}
														</li>
													))}
												</ul>
											)}
											{matchedService.content.bottom_description && (
												<div className="mt-3 bottom-description">
													{parseArrayString(matchedService.content.bottom_description).map(
														(description, index) => (
															<p key={`bottom-description-${index}`}>{description}</p>
														)
													)}
												</div>
											)}
											{matchedService.content.disclaimers && (
												<div className="mt-3 text-muted" style={{ fontSize: '14px' }}>
													{parseArrayString(matchedService.content.disclaimers).map(
														(disclaimer, index) => (
															<p key={`disclaimer-${index}`}>{disclaimer}</p>
														)
													)}
												</div>
											)}
											<LocationList
												type={'diagnostic-services'}
												bookingEntityId={1}
												service={matchedService}
												selectedEntityItem={{ booking_entity_id: 1, display_name: 'Sirkka Health' }}
												selectedServiceItem={{
													...matchedService.service,
													...matchedService.content,
												}}
												referrerParam={referrerParam}
												availableLocations={availableLocationsArray}
											/>
										</div>
									}
								/>
							</>
						)}
						{!isModalOpenedManually && specialistModalDataFromParams && (
							<>
								<SpecialistModal
									type="specialist"
									specialist={specialistModalDataFromParams}
									onClose={() => {
										// when modal closes, remove the param from URL
										navigate(`?`, { replace: true });
										setSpecialistModalDataFromParams(null);
									}}
									onGoToCart={goToCartPage}
									referrerParam={referrerParam}
									membershipType={membershipData?.membership_type || null}
								/>
							</>
						)}
						{services && services.length > 0 && (
							<>
								<CardList
									type="serviceAndSpecialistItem"
									data={filteredServices ? filteredServices : services}
									userHasChosenLocation={userHasChosenLocation}
									// When modal is opened via click and not param
									onModalOpen={handleServiceModalOpen}
									onModalClose={handleServiceModalClose}
									onSpecialistModalOpen={handleSpecialistModalOpen}
									onSpecialistModalClose={handleSpecialistModalClose}
									onGoToCart={goToCartPage}
									membershipType={membershipData?.membership_type || null}
									referrerParam={referrerParam}
								/>
							</>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default HealthTestsAndSpecialists;
