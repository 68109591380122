const OpeningHours = () => {
	return (
		<>
			<div className="col-xl-3 col-lg-3 col-md-6">
				<div className="footer-widget h4footer-widget mb-40">
					<div className="footer-title">
						<h3>Opening Hours</h3>
					</div>
					<div className="h4events-list mb-3">
						<ul>
							<li>
								<i className="fas fa-clock"></i>
								<span>
									Monday - Friday <span>09:00 am - 17:30 pm</span>
								</span>
							</li>
							{/* <li><i className="fal fa-times-square"></i><span className="close-days">Saturday & Sunday Closed</span></li> */}
						</ul>
					</div>
					<div className="h4footer-social mb-3">
						<ul className="list-inline">
							<li>
								<a href="https://www.instagram.com/sirkkahealth/">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/sirkkahealth/">
									<i className="fab fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="https://twitter.com/sirkkanetworks?lang=en">
									<i className="fab fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/sirkka-networks">
									<i className="fab fa-linkedin"></i>
								</a>
							</li>
						</ul>
					</div>
					<div className="footer-payment-icons pt-2 px-0">
						<div className="container px-0">
							<div className="footer-icons">
								<img
									src="/img/logo/payicon5.png"
									className="payicons"
									alt="payicons"
									style={{ maxWidth: '185px' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OpeningHours;
