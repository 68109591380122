export const formatDateToYYYYMMDD = (isoDateString) => {
	if (!isoDateString) return '';
	const date = new Date(isoDateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${year}-${month}-${day}`;
};

export const fetchPatients = async (userID, apiUrl) => {
	try {
		const response = await fetch(`${apiUrl}/customers/business-partner/${userID}/customers`);
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		const data = await response.json();
		return data.customers;
	} catch (error) {
		console.error('Error fetching customers:', error);
		return [];
	}
};

export const fetchAvailabilityData = async (locationId, apiUrl, apptDuration) => {
	try {
		let response;
		if (locationId === 5) {
			response = null;
		} else {
			response = await fetch(
				`${apiUrl}/bookings/availability/${locationId}?timeInterval=${apptDuration}`
			);
		}
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		console.error('Error fetching availability data:', error);
		throw error;
	}
};

export const handleNewPatientSubmit = async (newPatient, userID, apiUrl, onSuccess, onError) => {
	const customerData = {
		userId: userID,
		firstName: newPatient.firstName,
		lastName: newPatient.lastName,
		gender: newPatient.gender,
		dob: formatDateToYYYYMMDD(newPatient.dateOfBirth),
		phoneNumber: newPatient.phoneNumber,
		address: `${newPatient.address_line_1}, ${newPatient.city}, ${newPatient.postcode}, ${newPatient.country}`,
		company: newPatient.company,
		occupation: newPatient.occupation,
		email: newPatient.emailAddress,
	};

	try {
		const response = await fetch(`${apiUrl}/customers/business-partner/customers`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(customerData),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const responseData = await response.json();
		onSuccess(responseData);
	} catch (error) {
		onError(error);
	}
};

export const handleEditSubmit = async (apiUrl, userID, editedPatient, setShowEditModal) => {
	const url = `${apiUrl}/customers/business-partner/${userID}/customers/${editedPatient.record_id}`;
	const requestBody = {
		firstName: editedPatient.first_name,
		lastName: editedPatient.last_name,
		gender: editedPatient.gender,
		dob: formatDateToYYYYMMDD(editedPatient.date_of_birth),
		phoneNumber: editedPatient.contact_number,
		company: editedPatient.company,
		occupation: editedPatient.occupation,
		email: editedPatient.email_address,
	};

	try {
		const response = await fetch(url, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(requestBody),
		});

		if (response.ok) {
			// Handle success
			console.log('Patient record updated successfully');
			setShowEditModal(false);
		} else {
			// Handle errors
			console.error('Error updating patient record:', response.statusText);
		}
	} catch (error) {
		console.error('Error:', error);
	}
};

export const handleAddService = (
	selectedService,
	selectedPatient,
	selectedLocation,
	selectedDate,
	selectedTime,
	updateBookingData,
	user
) => {
	if (!selectedService || !selectedPatient) {
		alert('Please select a patient and then a service.');
		return;
	}

	const newBooking = {
		booking: {
			bookingId: Date.now().toString(),
			locationId: selectedService.location.location_category_id,
			bookingName: `${selectedPatient.first_name} ${selectedPatient.last_name}`,
			bookingProduct: selectedService.service.service_code,
			appointmentLocation: selectedLocation,
			appointmentRegion: selectedLocation,
			appointmentDate: selectedDate,
			appointmentTime: selectedTime,
			appointmentNotes: '',
			appointmentFor: 'patient',
			sampleConsent: true,
			termsconditions: true,
			completed: true,
		},
		customer: {
			nameTitle: selectedPatient.title || '',
			firstName: selectedPatient.first_name,
			lastName: selectedPatient.last_name,
			emailAddress: user.email_address || 'Not provided',
			patientEmailAddress: selectedPatient.email_address || 'Not provided',
			gender: selectedPatient.gender,
			dateOfBirth: selectedPatient.date_of_birth,
			phoneNumber: selectedPatient.contact_number,
			company: selectedPatient.company,
			occupation: selectedPatient.occupation,
			address_line_1: selectedPatient.address,
			address_line_2: '',
			city: selectedPatient.address,
			postcode: selectedPatient.address,
			country: selectedPatient.address,
		},

		postal: {
			postageAddressLine1: '',
			postageAddressLine2: '',
			postageCity: '',
			postagePostcode: '',
			postageCountry: '',
			postageSelection: '',
		},
		service: {
			serviceName: selectedService.service.service_name,
			serviceId: selectedService.service.service_id.toString(),
			serviceType: selectedService.service.service_type,
			emailTemplate: selectedService.service.email_template,
			regionId: selectedService.location.location_category_id,
			price: selectedService.service.service_price,
			icon: selectedService.content.service_icon,
			apptStackable: selectedService.service.appt_stackable,
			apptDuration: selectedService.service.appt_duration,
		},
		entity: {
			entityId: 1,
		},
		combineBooking: '',
	};

	updateBookingData((prevBookingData) => [...prevBookingData, newBooking]);
};

export const updateBookingDetails = (bookingData, date, time, location, setBookingData) => {
	const updatedBookingData = bookingData.map((booking) => ({
		...booking,
		booking: {
			...booking.booking,
			appointmentLocation: location,
			appointmentRegion: location,
			appointmentDate: date,
			appointmentTime: time,
		},
	}));

	setBookingData(updatedBookingData);
};

export const changePatientDetailsInBookingData = (bookingData, patient, setBookingData) => {
	if (!patient) return;

	const updatedBookingData = bookingData.map((booking) => ({
		...booking,
		booking: {
			...booking.booking,
			bookingName: `${patient.first_name} ${patient.last_name}`,
		},
		customer: {
			...booking.customer,
			nameTitle: patient.title || '',
			firstName: patient.first_name,
			lastName: patient.last_name,
			emailAddress: patient.email_address || 'Not provided',
			gender: patient.gender,
			dateOfBirth: patient.date_of_birth,
			phoneNumber: patient.contact_number,
			company: patient.company,
			occupation: patient.occupation,
			address_line_1: patient.address,
			city: patient.address,
			postcode: patient.address,
			country: patient.address,
		},
	}));

	setBookingData(updatedBookingData);
};

export const handleDateTimeLocationSelection = (
	bookingData,
	setBookingData,
	user,
	date,
	time,
	userPostcode,
	clinicianUserId,
	setSelectedDate,
	setSelectedTime
) => {
	const updatedBookingData = bookingData.map((booking) => ({
		...booking,
		booking: {
			...booking.booking,
			clinicianId: clinicianUserId,
			appointmentDate: date.value,
			appointmentTime: time,
			appointmentRegion: 'Home visit',
			appointmentLocation: userPostcode,
		},
	}));

	setBookingData(updatedBookingData);
	setSelectedDate(date.value);
	setSelectedTime(time);
};
