const calculateTotalAppointmentDuration = () => {
	const bookingData = JSON.parse(localStorage.getItem('bookingData'));

	if (bookingData.length === 0) {
		console.log('No bookings in bookingData');
		return 0;
	}

	// Separate stackable and non-stackable services
	const stackableServices = bookingData.filter((booking) => {
		return booking.service.apptStackable !== 'false';
	});
	const nonStackableServices = bookingData.filter((booking) => {
		return booking.service.apptStackable === 'false';
	});

	// Find the longest duration among stackable services
	const maxStackableDuration = stackableServices.reduce((max, booking) => {
		return Math.max(max, booking.service.apptDuration);
	}, 0);

	// Sum up the durations of all non-stackable services
	const totalNonStackableDuration = nonStackableServices.reduce((total, booking) => {
		return total + parseInt(booking.service.apptDuration, 10);
	}, 0);

	// Total duration is the sum of the longest stackable duration and the total non-stackable duration
	const totalDuration = maxStackableDuration + totalNonStackableDuration;
	return totalDuration;
};

export default calculateTotalAppointmentDuration;
