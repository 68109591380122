import React from 'react';

const ContactInformation = () => {
	return (
		<>
			<div className="col-lg-9 mx-auto">
				<div className="px-5 pos-rel mb-70 mt-70">
					<div className="section-text pos-rel">
						<p className="overseas-hero-text">
							Sirkka provides medical assistance services to Sirkka Members, and the scope of
							services varies depending on the level of membership. The services include booking
							fast-track appointments with leading specialists, medical insurance claim assistance,
							medical interpretation, prescription delivery and many more. If you need more
							information or have questions before purchasing a membership, please complete the
							Contact Us form below.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactInformation;
