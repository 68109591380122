/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './paymentForm.module.scss';
import { useForm } from 'react-hook-form';
import CountryDropdown from '../../../components/Countries';
import { ReactSession } from 'react-client-session';
import Stripe from '../../../components/Stripe';
import PaymentWrapper from './PaymentWrapper';
import { billingObject } from '../../../helpers/buildSessionObjects';
import checkBillingData from '../../../validations/billingDetails';
import showAlert from '../../../helpers/alerts';
import { sendCheckoutData } from '../../../services/data';
import { Button } from '@sirkka-health/booking-system-ui';
import config from '../../../config';
import { BookingUtils } from '@/utils';

function PaymentForm({
	onNextStep,
	bookingData,
	setBookingData,
	bookingDataTotal,
	userData,
	membershipData,
}) {
	const { register, handleSubmit } = useForm();
	const [userCountry, setUserCountry] = useState({});
	const [formData, setFormData] = useState({ bookingData });
	let checkoutType = 'checkout';
	const cartBasketBeingUsed = true;
	const [paymentIntentId, setPaymentIntentId] = useState(null);
	const paymentButtonText = checkoutType === 'subscription' ? 'Subscribe now' : 'Pay now';

	const navigate = useNavigate();
	const inputRef = useRef(null);
	let formValid = null;
	ReactSession.set('form_validity', formValid);

	const submitForm = (event) => {
		event.target.form.requestSubmit();
	};

	const processForms = (event) => {
		console.log('Processing forms here');
		inputRef.current?.click();

		if (formValid === false) {
			console.log('Forms input are invalid');
			event.preventDefault();
		}
	};

	const onSubmit = async (data) => {
		const userId = userData.user_id;
		const userEmail = userData.email_address;
		data.phone = userData.contact_number;

		const billing_details = billingObject(data, userEmail);
		const billing_valid = checkBillingData(billing_details);

		if (billing_valid !== 'valid') {
			formValid = false;
			const billingErrorMessage = billing_valid;
			showAlert(billingErrorMessage);
		}

		if (billing_valid === 'valid') {
			formValid = true;
			ReactSession.set('billing_details', billing_details);
			ReactSession.set('form_validity', formValid);

			const checkoutData = {
				user_id: Object.keys(userData).length ? userId : '',
				customer_id: Object.keys(userData).length ? userData.customer_id : null,
				billing_details,
			};

			const sendData = async () => await sendCheckoutData(checkoutData);
			const response = sendData();

			if (response.status === 'success') {
				return response.message;
			}
			const errorMessage = response.message;
			if (errorMessage) showAlert(errorMessage);
		}
	};

	const submitButton = (
		<div className="order-button-payment mt-20">
			<button
				id="submit"
				type="submit"
				className="primary_btn theme-btn"
				onClick={(event) => processForms(event)}
			>
				<span id="button-text">{paymentButtonText}</span>
			</button>
		</div>
	);

	const handleZeroTotalCheckout = () => {
		const customerDetails = ReactSession.get('user');
		let processedCustomerDetails;

		if (customerDetails !== undefined) {
			processedCustomerDetails = {
				customerId: customerDetails.customer_id,
				firstname: customerDetails.first_name,
				lastname: customerDetails.last_name,
				email: customerDetails.username || customerDetails.email_address,
				gender: customerDetails.gender || null,
				dateOfBirth: customerDetails.date_of_birth || null,
				notes: null,
			};
		}

		const bookingDetails = JSON.parse(localStorage.getItem('bookingData'));
		if (bookingDetails) {
			for (const cartItem of bookingDetails) {
				console.log('Cart item:', cartItem);
				switch (cartItem.service.serviceType) {
					case 'giftcardPurchase': {
						const giftcardPostBody = {
							customerId: customerDetails.customer_id,
							amount: cartItem.service.price,
							recipientName: cartItem.service.giftcardRecipientName,
							recipientEmail: cartItem.service.giftcardRecipientEmail,
							senderName: cartItem.service.giftcardSenderName,
							senderEmail: cartItem.service.giftcardSenderEmail,
							giftMessage: cartItem.service.giftcardMessage,
						};
						console.log('Gift card post body:', giftcardPostBody);
						fetch(`${config.REACT_APP_API_URL}/products/gift-card`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(giftcardPostBody),
						})
							.then((res) => {
								if (res.ok) {
									return res.json();
								} else {
									throw new Error('Network response was not ok.');
								}
							})
							.then((data) => {
								if (data.status === 'success') {
									console.log('Gift card created');
								} else {
									console.error('Gift card creation was not successful:', data.message);
								}
							})
							.catch((err) => {
								console.error('Error creating gift card:', err.message);
							});
						break;
					}

					case 'giftcardRedemption': {
						const giftcardRedemptionPostBody = {
							uuid: cartItem.service.giftcardId,
							balance: 0,
							last_used: new Date().toISOString(),
						};

						fetch(`${config.REACT_APP_API_URL}/payments/gift-card`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(giftcardRedemptionPostBody),
						})
							.then((res) => {
								if (res.ok) {
									return res.json();
								} else {
									throw new Error('Network response was not ok.');
								}
							})
							.then((data) => {
								if (data.status === 'success') {
									console.log('Gift card redeemed');
								} else {
									console.error('Gift card redemption was not successful:', data.message);
								}
							})
							.catch((err) => {
								console.error('Error redeeming gift card:', err.message);
							});
						break;
					}

					case 'Postal': {
						BookingUtils.CreatePostal(cartItem, customerDetails.customer_id);
						break;
					}
					default: {
						BookingUtils.CreateAppointment(cartItem, processedCustomerDetails);
						//After appointment is created, remove the referrer to prevent future repeat referred bookings
						ReactSession.remove('referrer');
						break;
					}
				}
			}
			// Assume updateMemberBenefits and setBookingData are functions defined elsewhere in your code.
			// updateMemberBenefits(bookingDetails);
			setBookingData([]);
		}
		return navigate('/checkout/complete');
	};

	return (
		<div className={styles.paymentForm}>
			<section className="checkout-area">
				<form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
					<h3>Billing Address</h3>
					<div className="row">
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									First Name <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('firstname_billing')}
									defaultValue={null}
									required
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									Last Name <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('lastname_billing')}
									defaultValue={null}
									required
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<label>
									Address <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder="Street address"
									{...register('address1_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<input
									type="text"
									placeholder="Apartment, suite, unit etc. (optional)"
									{...register('address2_billing')}
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="checkout-form-list">
								<label>
									Town / City <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('city_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									State / County <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('county_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="checkout-form-list">
								<label>
									Postcode / Zip <span className="required">*</span>
								</label>
								<input
									type="text"
									placeholder=""
									{...register('postcode_billing')}
									required
									defaultValue={null}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<CountryDropdown
								register={register('country_billing')}
								required={true}
								userCountry={userCountry}
								setUserCountry={setUserCountry}
							/>
						</div>
					</div>
					<input type="hidden" ref={inputRef} onClick={(event) => submitForm(event)} />
				</form>
				<div className="payment-method">
					{bookingDataTotal === 0 && (
						<Button
							label="Complete booking"
							onClick={() => handleZeroTotalCheckout()}
							fullWidth
							large
						/>
					)}
					{bookingDataTotal > 0 && (
						<>
							<h3>Pay by card</h3>
							<PaymentWrapper>
								<Stripe
									type={checkoutType}
									cart={bookingData}
									user={userData}
									price={bookingDataTotal}
									setPaymentIntentId={setPaymentIntentId}
									submitButton={submitButton}
									membershipData={membershipData}
								/>
							</PaymentWrapper>
						</>
					)}
				</div>
			</section>
		</div>
	);
}

PaymentForm.propTypes = {
	onNextStep: PropTypes.func.isRequired,
};

export default PaymentForm;
