import AccordionFAQ from '../../components/AccordionFAQ';

const Faqs = ({ faqs, heading = 'FAQ' }) => {
	return (
		<div className="container">
			<div className="row">
				<div className="about-title mt-45">
					<h3>{heading}</h3>
				</div>
				<div className="faq-right-box">
					<div id="accordion">
						{faqs &&
							faqs.map((faq, index) => (
								<AccordionFAQ
									number={index}
									question={faq.question}
									answer={faq.answer}
									fistClassAdd="collapsed"
									key={index}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Faqs;
