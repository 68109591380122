const AuthenticationFormWrapper = ({ children }) => {
	return (
		<section className="login-area pt-120 pb-120">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 offset-lg-2">
						<div className="basic-login">{children}</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AuthenticationFormWrapper;
