import { Link } from 'react-router-dom';
import { Button } from '@sirkka-health/booking-system-ui';

const LoginButton = (authType) => {
	let loginLink;
	if (authType === 'business') {
		loginLink = '/business-login';
	} else {
		loginLink = '/login';
	}

	return (
		<Link to={loginLink}>
			<button type="submit" className="secondary_btn w-100">
				Login
			</button>
		</Link>
	);
};

export default LoginButton;
