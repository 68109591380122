import { useEffect } from 'react';

function useNavigateOnLocationChange(selectedLocationCategory, locationURLParam, navigate) {
	useEffect(() => {
		const waitForLocationChecked = setTimeout(() => {
			if (
				['London', 'Edinburgh', 'Manchester'].includes(selectedLocationCategory) &&
				selectedLocationCategory !== locationURLParam
			) {
				navigate(`/booking/${selectedLocationCategory}`);
			}
		}, 500);

		return () => clearTimeout(waitForLocationChecked);
	}, [selectedLocationCategory, locationURLParam, navigate]);

	// useEffect(() => {
	// 	if (!selectedLocationCategory) {
	// 		navigate(`/booking/London`);
	// 	}
	// }, [selectedLocationCategory, navigate]);

	useEffect(() => {
		if (selectedLocationCategory === 'Home Visit' || selectedLocationCategory === 'Virtual') {
			navigate('/contact');
		}
	}, [selectedLocationCategory]);
}

export default useNavigateOnLocationChange;
