import React, { useState, useEffect } from 'react';
import Modal from '@/components/Modal';
import './ServiceDetails.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const ServiceDetails = ({ data, onChange, setIsValid }) => {
	const [services, setServices] = useState(data.services || []);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [locations, setLocations] = useState([]);
	const [newService, setNewService] = useState({
		name: '',
		previewDescription: '',
		price: '',
		duration: '',
		serviceCode: '',
		firstDescription: '',
		bullets: '',
		descriptionContinued: '',
		tags: '',
		disclaimers: '',
		selectedLocations: [],
	});
	const [editIndex, setEditIndex] = useState(null);

	useEffect(() => {
		const isValid = data.services.length > 0;

		setIsValid(isValid);
	}, [data, setIsValid]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewService({ ...newService, [name]: value });
	};

	const handleLocationChange = (location) => {
		setNewService((prevState) => {
			const { selectedLocations } = prevState;
			if (selectedLocations.includes(location)) {
				return {
					...prevState,
					selectedLocations: selectedLocations.filter((loc) => loc !== location),
				};
			} else {
				return {
					...prevState,
					selectedLocations: [...selectedLocations, location],
				};
			}
		});
	};

	const handleAddService = () => {
		if (editIndex !== null) {
			const updatedServices = services.slice();
			updatedServices[editIndex] = newService;
			setServices(updatedServices);
			onChange({ services: updatedServices });
			setEditIndex(null);
		} else {
			setServices([...services, newService]);
			onChange({ services: [...services, newService] });
		}

		setNewService({
			name: '',
			previewDescription: '',
			price: '',
			duration: '',
			serviceCode: '',
			firstDescription: '',
			bullets: '',
			descriptionContinued: '',
			tags: '',
			disclaimers: '',
			selectedLocations: [],
		});
		setIsModalOpen(false);
	};

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => {
		setIsModalOpen(false);
		setEditIndex(null);
	};

	const handleEditService = (index) => {
		setNewService(services[index]);
		setEditIndex(index);
		openModal();
	};

	useEffect(() => {
		setLocations(data.moreBusinessDetails.locations || []);
	}, [data.moreBusinessDetails.locations]);

	return (
		<div className="serviceDetails">
			{services.length === 0 ? (
				<p>
					To get started, press the button below to start adding all of the service that you offer.
					Your services will show here once you add them.
				</p>
			) : (
				services.map((service, index) => (
					<div key={index} className="serviceItem">
						<h3>{service.name}</h3>
						<p>{service.previewDescription}</p>
						<p>Price: £{service.price}</p>
						<p>Duration: {service.duration} minutes</p>
						<ul>
							Locations:
							{service.selectedLocations.map((loc) => (
								<li key={loc.buildingName}>{`${loc.buildingName}, ${loc.street}, ${loc.city}`}</li>
							))}
						</ul>
						<Button
							onClick={() => handleEditService(index)}
							label="Edit"
							variant="primary"
							fullWidth
						/>
					</div>
				))
			)}
			<Button
				label="Add a service"
				variant="primary"
				fullWidth
				onClick={openModal}
				disabled={services.length >= 5}
			/>

			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				content={
					<div className="modalContent">
						<div className="form-field">
							<label>Service Name</label>
							<input
								type="text"
								name="name"
								placeholder="Service Name"
								value={newService.name}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Preview Description</label>
							<input
								type="text"
								name="previewDescription"
								placeholder="Preview Description"
								value={newService.previewDescription}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Service Price</label>
							<input
								type="number"
								name="price"
								placeholder="Service Price"
								value={newService.price}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Appointment Duration</label>
							<input
								type="number"
								name="duration"
								placeholder="Appointment Duration"
								value={newService.duration}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Service Code</label>
							<input
								type="text"
								name="serviceCode"
								placeholder="Service Code"
								value={newService.serviceCode}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>First Description</label>
							<input
								type="text"
								name="firstDescription"
								placeholder="First Description"
								value={newService.firstDescription}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Bullets</label>
							<textarea
								name="bullets"
								placeholder="Bullets"
								value={newService.bullets}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Description Continued</label>
							<textarea
								name="descriptionContinued"
								placeholder="Description Continued"
								value={newService.descriptionContinued}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Tags</label>
							<input
								type="text"
								name="tags"
								placeholder="Tags"
								value={newService.tags}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form-field">
							<label>Disclaimers</label>
							<textarea
								name="disclaimers"
								placeholder="Disclaimers"
								value={newService.disclaimers}
								onChange={handleInputChange}
							/>
						</div>

						<div className="multiSelect">
							<p>Select Locations:</p>
							{locations.map((location, index) => (
								<label key={index}>
									<input
										type="checkbox"
										checked={newService.selectedLocations.includes(location)}
										onChange={() => handleLocationChange(location)}
									/>
									{location.buildingName} ({location.city})
								</label>
							))}
						</div>
						<Button
							label={editIndex !== null ? 'Save Changes' : 'Add Service'}
							variant="primary"
							onClick={() => handleAddService()}
							fullWidth
						/>
					</div>
				}
			/>
		</div>
	);
};

export default ServiceDetails;
