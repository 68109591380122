const ContactMap = () => {
	return (
		<>
			<section className="map-area gray-bg d-flex justify-content-center pb-30">
				<div style={{ width: '70%' }}>
					<iframe
						title="Contact"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.058558251037!2d-0.11943518422918778!3d51.53048577963901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761bb1b8f6886f%3A0xc0f67d171583e7b6!2sSirkka%20Networks!5e0!3m2!1sen!2suk!4v1661848051236!5m2!1sen!2suk"
						width="600"
						allowFullScreen=""
						referrerPolicy="no-referrer-when-downgrade"
						style={{ width: '100%', border: '0' }}
						height={600}
						loading="lazy"
					></iframe>
				</div>
			</section>
		</>
	);
};

export default ContactMap;
