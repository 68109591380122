import React from 'react';
import './style.css';

const DoctorBox = ({ avatar, name, tags, attribution, credentials, id, title, profile, url }) => {
	return (
		<>
			<div className="col-xl-4 col-lg-6 col-md-6">
				<button
					type="button"
					className="unstyled-button w-100"
					data-bs-toggle="modal"
					data-bs-target={'#doctor-' + id}
				>
					<div className="team-wrapper team-box-2 text-center mb-30">
						<div className="team-thumb">
							<img src={`img/doctor/${avatar}`} alt="" />
						</div>
						<div className="team-member-info mt-20 mb-15">
							<h4 className="mb-15">{name}</h4>
							<h5 className="f-500 text-up-case letter-spacing blue-color">{title}</h5>
						</div>
						<div className="team-social-profile mb-15">
							{(tags || []).map((tag, index) => (
								<span key={index} className="badge badge-pill badge-light black-color m-1">
									{tag}
								</span>
							))}
						</div>
					</div>
				</button>
			</div>

			{/*** Begin modal ***/}
			<div
				className="modal fade"
				id={'doctor-' + id}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="doctorProfileCenterTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header profile-bg justify-content-end">
							{/* <button
                type="button"
                className="unstyled-button"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-arrow-left"></i>&nbsp; Back to doctor list
              </button> */}
							<button
								type="button"
								className="close text-white"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body profile-bg pb-50"></div>
						<div className="modal-body">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xl-12">
										<div className="team-thumb mt-n80 mb-20 d-block">
											<img src={`img/doctor/${avatar}`} alt={`${name}`} />
										</div>
									</div>
									<div className="col-xl-12 text-center ">
										<h3 className="name">{name}</h3>
										<h4 className="title mb-15">{title}</h4>
										<h6 className="title mb-20">{attribution}</h6>
										{credentials ? (
											<h5 className="credentials mb-20">
												<i className="fas fa-solid fa-graduation-cap"> </i>

												<ul className="d-flex flex-wrap">
													{credentials.map((credential, index) => (
														<li key={index} className="text-start">
															{credential}
														</li>
													))}
												</ul>
											</h5>
										) : null}
										{profile.map((paragraph, index) => (
											<p key={index} className="text-start px-sm-5">
												{paragraph}
											</p>
										))}
										{url ? (
											<a href={url} className="d-block text-start">
												<button type="button" className="btn btn-light btn-sm">
													Read more
												</button>
											</a>
										) : null}
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer d-block gap-2 d-md-flex">
							<a href="/contact" className="mx-0">
								<button type="button" className="btn btn-primary w-100 mx-0">
									Contact us to make appointment
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DoctorBox;
