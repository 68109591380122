import Swal from 'sweetalert2';

const showAlert = (
	text,
	title = null,
	type = 'error',
	allowOutsideClick = true,
	allowEscapeClick = true,
	backdrop = false,
	redirectURL = null,
	navigate = null,
	logout = null
) =>
	Swal.fire({
		icon: type,
		title,
		text: text,
		allowOutsideClick,
		allowEscapeClick,
		backdrop,
	}).then((result) => {
		if (result.isConfirmed && redirectURL) {
			navigate(redirectURL);
		}
		if (result.isConfirmed && logout) {
			logout();
		}
	});

export default showAlert;
