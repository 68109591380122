import Header from '../../components/Header';
import Footer from '../../components/Footer';
import RegistrationForm from './RegistrationForm';

const Register = () => {
	return (
		<>
			<Header title="Register" />
			<RegistrationForm />
			<Footer />
		</>
	);
};

export default Register;
