import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ServiceAbout from './ServiceAbout';
import serviceData from '../../data/services.json';

const ServicePage = () => {
	const { service_name } = useParams();

	const currentService = serviceData.find((service) => service.key === service_name) || {
		key: service_name,
	};

	return (
		<>
			<Header title={currentService.subtitle} />
			<ServiceAbout service={currentService} />
			{/* <ServiceCTA/> */}
			<Footer />
		</>
	);
};

export default ServicePage;
