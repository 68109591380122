import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LoginArea from './LoginArea';

const Login = () => {
	return (
		<>
			<Header title="Login" />
			<LoginArea />
			<Footer />
		</>
	);
};

export default Login;
