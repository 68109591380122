import React, { useCallback, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DoctorsList from './DoctorsList';
import doctorData from '../../data/doctor-profile.json';
import DoctorsFilters from './DoctorsFilters/DoctorsFilters';

const Doctor = () => {
	const unsortedCategories = doctorData.specialist.map((doctor) => doctor.category);
	const CATEGORIES = [...new Set(unsortedCategories)];

	const [state, setState] = useState({
		doctors: doctorData,
		filterCategory: new Set(),
		filtersubCategory: new Set(),
		filterVirtual: false,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleClearFilter = useCallback(() => {
		setState(() => {
			let doctors = Object.assign({}, doctorData);
			let filterCategory = new Set();
			let filtersubCategory = new Set();
			document.querySelectorAll('input').forEach((item) => {
				item.checked = false;
			});
			return {
				filterCategory,
				filtersubCategory,
				doctors,
			};
		});
	});

	const handleFilter = useCallback(
		(event) => {
			//Toggle face-to-face filter in state
			if (event.target.parentNode.getAttribute('data-filter-type') === 'filter-virtual') {
				setState((previousState) => ({
					...previousState,
					filterVirtual: !previousState.filterVirtual,
				}));
			}

			//Clear all subcategory checkboxes if a Category checkbox is clicked
			if (
				event.target.checked &&
				event.target.parentNode.getAttribute('data-filter-type') === 'filter-category'
			) {
				document
					.querySelectorAll('[data-filter-type="filter-subcategory"] input')
					.forEach((item) => {
						item.checked = false;
					});
				setState((previousState) => ({
					...previousState,
					filtersubCategory: new Set(),
				}));
			}

			//Clear all category checkboxes if a subcategory checkbox is clicked
			if (
				event.target.checked &&
				event.target.parentNode.getAttribute('data-filter-type') === 'filter-subcategory'
			) {
				document.querySelectorAll('[data-filter-type="filter-category"] input').forEach((item) => {
					item.checked = false;
				});

				setState((previousState) => ({
					...previousState,
					filterCategory: new Set(),
				}));
			}

			//Filter doctors JSON data and update in state
			setState((previousState) => {
				let doctors = Object.assign({}, doctorData);
				let filterCategory = new Set(previousState.filterCategory);
				let filtersubCategory = new Set(previousState.filtersubCategory);
				let filterVirtual = previousState.filterVirtual;

				//Add or remove Category to filterCategory set
				if (
					event.target.checked &&
					event.target.closest('label').getAttribute('data-filter-type') === 'filter-category'
				) {
					filterCategory.add(event.target.value);
				} else if (
					!event.target.checked &&
					event.target.closest('label').getAttribute('data-filter-type') === 'filter-category'
				) {
					filterCategory.delete(event.target.value);
				}

				//Add or remove Subcategory to filtersubCategory set
				if (
					event.target.checked &&
					event.target.closest('label').getAttribute('data-filter-type') === 'filter-subcategory'
				) {
					filtersubCategory.add(event.target.value);
				} else if (
					!event.target.checked &&
					event.target.closest('label').getAttribute('data-filter-type') === 'filter-subcategory'
				) {
					filtersubCategory.delete(event.target.value);
				}

				//Begin filtering
				let filteredResults = doctorData.specialist;

				//Only add doctors which allow face-to-face in JSON
				if (filterVirtual === true) {
					filteredResults = doctorData.specialist.filter((item) => {
						return item.physical;
					});
				}

				//Only add doctors which match a category in JSON
				if (filterCategory.size) {
					filteredResults = filteredResults.filter((item) => {
						return filterCategory.has(item.category);
					});
				}

				//Only add doctors which match a subcategory in JSON
				if (filtersubCategory.size) {
					filteredResults = filteredResults.filter((item) => {
						if (item.subcategory) {
							for (const arr of item.subcategory) {
								if (filtersubCategory.has(arr.toString())) {
									return true;
								}
							}
						}
						return null;
					});
				}

				doctors.specialist = filteredResults;

				return {
					filterCategory,
					filtersubCategory,
					filterVirtual,
					doctors,
				};
			});
		},
		[setState]
	);

	return (
		<>
			<Header />
			<div className="container">
				<div className="row">
					<div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
						<div className="section-title text-center pos-rel mb-70 mt-70">
							<div className="section-text pos-rel">
								<h1>Specialists</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4 col-md-12">
						<DoctorsFilters
							categories={CATEGORIES}
							handleFilter={handleFilter}
							handleClearFilter={handleClearFilter}
							filterVirtual={state.filterVirtual}
						/>
					</div>
					<div className="col-xl-8 col-lg-8 col-md-12">
						<section className="team-area pb-90">
							<div className="container">
								<div className="row">
									<DoctorsList doctors={state.doctors} />
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Doctor;
