import checkEmail from './email';

const checkProfileEditData = ({
	first_name,
	last_name,
	email,
	contact_number,
	company,
	occupation,
}) => {
	if (/^[0-9][0-9]*[1-9][0-9]{6,13}$/.test(contact_number) === false) {
		return 'Phone Number must contain only numbers and not symbols or spaces';
	}

	if (!checkEmail(email)) {
		return 'Email invalid';
	}

	if (first_name.length < 2) {
		return 'First name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(first_name) === false) {
		return 'First name can only contain alphabets';
	}

	if (last_name.length < 2) {
		return 'Last name is required';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(last_name) === false) {
		return 'Last name can only contain alphabets';
	}

	return 'valid';
};

export default checkProfileEditData;
