import { useContext } from 'react';
import { AuthContext } from '../context/AllContext';

const useAuth = () => {
	const { user, userAuthenticated, isAuthenticated, logout } = useContext(AuthContext);
	return {
		user,
		userAuthenticated,
		isAuthenticated,
		logout,
	};
};

export default useAuth;
