import { useEffect } from 'react';
import styles from './Modal.module.scss';

const Modal = ({ content, isOpen, onClose }) => {
	useEffect(() => {
		document.body.style.overflow = isOpen ? 'hidden' : '';
		return () => {
			document.body.style.overflow = '';
		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<div onClick={onClose} className={styles.modalBackground}>
			<div onClick={(e) => e.stopPropagation()} className={styles.modalContainer}>
				<button
					onClick={onClose}
					type="button"
					className="close"
					style={{ position: 'absolute', top: '10px', right: '20px' }}
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
				{content}
			</div>
		</div>
	);
};

export default Modal;
