import Header from '../../components/Header';
import NewsBox from './NewsBox';
import Footer from '../../components/Footer';
import newsData from '../../data/news.json';

const News = () => {
	return (
		<>
			<Header title="News" />
			<section className="blog-area pt-120 pb-80">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 offset-lg-2">
							{newsData.map((news, index) => (
								<NewsBox key={index} {...news} />
							))}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default News;
