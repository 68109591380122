import React, { useEffect } from 'react';
import { getRequest } from '../../services/api';
import useAuth from '../../hooks/useAuth';
import ProfileEdit from './ProfileEdit';
import './styles.css';
import { useState } from 'react';
import ProfileDetails from './ProfileDetails';

const Profile = () => {
	const { user } = useAuth();
	const [membershipData, setMembershipData] = useState({});

	useEffect(() => {
		const fetchMembership = async () => {
			const fetchedMembership = await getRequest(`/memberships/`, { userID: user.customer_id });
			setMembershipData(fetchedMembership);
		};
		fetchMembership();
	}, [user.customer_id]);

	const [edit, setEdit] = useState(false);

	return edit ? (
		<ProfileEdit user={user} membershipData={membershipData} switchEditMode={setEdit} />
	) : (
		<ProfileDetails user={user} membershipData={membershipData} switchEditMode={setEdit} />
	);
};

export default Profile;
