import PaymentDetailsForm from './PaymentDetailsForm';

const Subscribe = ({ membershipDetails, customerDetails, submitButton }) => {
	return (
		<div className="price-list">
			<PaymentDetailsForm
				membershipDetails={membershipDetails}
				customerDetails={customerDetails}
				submitButton={submitButton}
			/>
		</div>
	);
};

export default Subscribe;
