import { Link } from 'react-router-dom';
import formatPrice from '../../helpers/formatPrice';

const SingleService = ({ icon, title, text, link, price, border_class }) => {
	return (
		<>
			{title === 'Individual Tests' ? (
				<div className="col-xl-4 col-lg-6 col-md-6">
					<div
						className={
							border_class
								? `service-box ${border_class} text-center mb-30`
								: 'service-box text-center mb-30'
						}
					>
						<div className="service-thumb">
							<img src={`/img/icon/${icon}.png`} alt="" />
						</div>
						<div className="service-content">
							<h3>
								<Link to={`${link}`}>{title}</Link>
							</h3>
							<p>{text}</p>
							<h4>from {formatPrice({ price: price })}</h4>
							<Link className="service-link" to={`${link}`}>
								Read More
							</Link>
						</div>
					</div>
				</div>
			) : (
				<div className="col-xl-4 col-lg-6 col-md-6">
					<div
						className={
							border_class
								? `service-box ${border_class} text-center mb-30`
								: 'service-box text-center mb-30'
						}
					>
						<div className="service-thumb">
							<img src={`/img/icon/${icon}.png`} alt="" />
						</div>
						<div className="service-content">
							<h3>
								<Link to={`${link}`}>{title}</Link>
							</h3>
							<p>{text}</p>
							{price !== '' ? <h4>{formatPrice({ price: price })}</h4> : ''}
							<Link className="service-link" to={`${link}`}>
								Read More
							</Link>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SingleService;
