const SirkkaLogo = ({ className, alt }) => {
	return (
		<>
			<img
				src="/img/logo/sirkka-health-logo.png"
				alt={alt || 'Sirkka Logo'}
				className={className}
			/>
		</>
	);
};

export default SirkkaLogo;
