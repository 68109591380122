import { useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { CardListMapProps } from '@/types';

const CardListMap = ({ type, entityData, events, hoveredCard }: CardListMapProps) => {
	const [activeMarker, setActiveMarker] = useState(null);
	const [map, setMap] = useState(null);

	const handleOnLoad = (map: any) => {
		setMap(map);
		const data =
			type === 'group-testing'
				? events
				: entityData?.map(({ entity_with_services }) => entity_with_services?.booking_entity) || [];
		if (data && data.length > 0) {
			setMapBounds(map, data);
		}
	};

	const setMapBounds = (map: any, data: any) => {
		const bounds = new window.google.maps.LatLngBounds();
		data.forEach((item: { [key: string]: any | null }) => {
			if (item?.latitude !== null && item?.longitude !== null) {
				bounds.extend(
					new window.google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude))
				);
			}
		});
		if (data.length === 1) {
			map.setCenter(bounds.getCenter());
			map.setZoom(10); // Default zoom level for a single marker
		} else {
			map.fitBounds(bounds);
			map.setZoom(map.getZoom() - 1); // Decrease the zoom level by 1
		}
	};

	const renderMarkers = (data: any) => {
		return data.map((item: { [key: string]: any | null }, index: number) => {
			const id = item?.booking_entity_id || item?.event_id;
			const key = `${id}-${index}`; // Ensures uniqueness
			return (
				<Marker
					key={key}
					position={{
						lat: parseFloat(item?.latitude),
						lng: parseFloat(item?.longitude),
					}}
					icon={hoveredCard === id ? hoveredIcon : defaultIcon}
				>
					{activeMarker === id ? (
						<InfoWindow onCloseClick={() => setActiveMarker(null)}>
							<div>
								<h6>{item?.display_name || item?.event_name}</h6>
							</div>
						</InfoWindow>
					) : null}
				</Marker>
			);
		});
	};

	const defaultIcon = {
		path: 'M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z',
		fillColor: 'white',
		fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 2,
		scale: 0.07,
		anchor: new window.google.maps.Point(192, 384),
	};

	const hoveredIcon = { ...defaultIcon, fillColor: 'black' };

	const data =
		type === 'group-testing'
			? events
			: entityData?.map(({ entity_with_services }) => entity_with_services?.booking_entity) || [];

	return (
		<>
			<div className="col-12 col-lg-4">
				<div style={{ WebkitMaskPosition: 'sticky', position: 'sticky', top: 0 }}>
					{data && data.length > 0 && (
						<GoogleMap
							onLoad={handleOnLoad}
							onClick={() => setActiveMarker(null)}
							mapContainerStyle={{ width: '100%', height: '100vh' }}
							options={{
								zoomControl: false,
								mapTypeControl: false,
								scaleControl: false,
								streetViewControl: false,
								rotateControl: false,
								fullscreenControl: false,
							}}
						>
							{renderMarkers(data)}
						</GoogleMap>
					)}
				</div>
			</div>
		</>
	);
};

export default CardListMap;
