import React from 'react';
import policy_data from '../../../data/policy.json';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const LegalStatement = () => {
	return (
		<>
			<Header title="Legal Statement" />
			<div className="container mt-30 mb-30">
				{policy_data.legal_statement.map((legal) => {
					return (
						<div className="col-xl-12 col-lg-7" key={legal}>
							<h1>{legal.legalStatementTitle}</h1>
							<hr />
							<p>{legal.legalStatementParagraph1}</p>
							<p>{legal.legalStatementParagraph2}</p>
							<p>{legal.legalStatementParagraph3}</p>
							<p>{legal.legalStatementParagraph4}</p>

							<h4>{legal.legalStatementAlterations}</h4>
							<p>{legal.legalStatementAlterationsParagraph1}</p>
							<p>{legal.legalStatementAlterationsParagraph2}</p>

							<h4>{legal.legalStatementApplications}</h4>
							<p>{legal.legalStatementApplicationsParagraph1}</p>

							<h4>{legal.legalStatementAvailability}</h4>
							<p>{legal.legalStatementAvailabilityParagraph1}</p>
							<p>{legal.legalStatementAvailabilityParagraph2}</p>
							<p>{legal.legalStatementAvailabilityParagraph3}</p>
							<p>{legal.legalStatementAvailabilityParagraph4}</p>

							<h4>{legal.legalStatementCopyright}</h4>
							<p>{legal.legalStatementCopyrightParagraph1}</p>
							<p>{legal.legalStatementCopyrightParagraph2}</p>
							<p>{legal.legalStatementCopyrightParagraph3}</p>
							<p>{legal.legalStatementCopyrightParagraph4}</p>

							<h4>{legal.legalStatementChanges}</h4>
							<p>{legal.legalStatementChangesParagraph1}</p>

							<h4>{legal.legalStatementPrivacy}</h4>
							<p>{legal.legalStatementPrivacyParagraph1}</p>

							<h4>{legal.legalStatementMonitoring}</h4>
							<p>{legal.legalStatementMonitoringParagraph1}</p>

							<h4>{legal.legalStatementLiability}</h4>
							<p>{legal.legalStatementLiabilityParagraph1}</p>
							<ul className="policy-unordered-list">
								<li>
									<p>{legal.legalStatementLiabilityParagraph2}</p>
								</li>
								<li>
									<p>{legal.legalStatementLiabilityParagraph3}</p>
								</li>
							</ul>
							<p>{legal.legalStatementLiabilityParagraph4}</p>
							<p>{legal.legalStatementLiabilityParagraph5}</p>
							<p>{legal.legalStatementLiabilityParagraph6}</p>
							<p>{legal.legalStatementLiabilityParagraph7}</p>

							<h4>{legal.legalStatementInformation}</h4>
							<p>{legal.legalStatementInformationParagraph1}</p>
							<p>{legal.legalStatementInformationParagraph2}</p>
							<p>{legal.legalStatementInformationParagraph3}</p>

							<h4>{legal.legalStatementLinking}</h4>
							<p>{legal.legalStatementLinkingParagraph1}</p>
							<p>{legal.legalStatementLinkingParagraph2}</p>
							<p>{legal.legalStatementLinkingParagraph3}</p>
							<p>{legal.legalStatementLinkingParagraph4}</p>

							<h4>{legal.legalStatementUploading}</h4>
							<p>{legal.legalStatementUploadingParagraph1}</p>
							<p>{legal.legalStatementUploadingParagraph2}</p>
							<p>{legal.legalStatementUploadingParagraph3}</p>
							<p>{legal.legalStatementUploadingParagraph4}</p>

							<h4>{legal.legalStatementLinks}</h4>
							<p>{legal.legalStatementLinksParagraph1}</p>

							<h4>{legal.legalStatementOwnership}</h4>
							<p>{legal.legalStatementOwnershipParagraph1}</p>
							<p>{legal.legalStatementOwnershipParagraph2}</p>

							<h4>{legal.legalStatementProduct}</h4>
							<p>{legal.legalStatementProductParagraph1}</p>

							<h4>{legal.legalStatementViruses}</h4>
							<p>{legal.legalStatementVirusesParagraph1}</p>
							<p>{legal.legalStatementVirusesParagraph2}</p>
							<p>{legal.legalStatementVirusesParagraph3}</p>
							<p>{legal.legalStatementVirusesParagraph4}</p>

							<h4>{legal.legalStatementAcceptable}</h4>
							<p>{legal.legalStatementAcceptableParagraph1}</p>
							<ul className="policy-unordered-list">
								<li>
									<p>{legal.legalStatementAcceptableParagraph2}</p>
								</li>
								<li>
									<p>{legal.legalStatementAcceptableParagraph3}</p>
								</li>
								<li>
									<p>{legal.legalStatementAcceptableParagraph4}</p>
								</li>
								<li>
									<p>{legal.legalStatementAcceptableParagraph5}</p>
								</li>
							</ul>

							<h4>{legal.legalStatementContributions}</h4>
							<p>{legal.legalStatementContributionsParagraph1}</p>
							<p>{legal.legalStatementContributionsParagraph2}</p>
							<p>{legal.legalStatementContributionsParagraph3}</p>
							<p>{legal.legalStatementContributionsParagraph4}</p>
							<p>{legal.legalStatementContributionsParagraph5}</p>
							<p>{legal.legalStatementContributionsParagraph6}</p>
							<p>{legal.legalStatementContributionsParagraph7}</p>
							<p>{legal.legalStatementContributionsParagraph8}</p>
							<p>{legal.legalStatementContributionsParagraph9}</p>
							<ul className="policy-unordered-list">
								<li>
									<p>{legal.legalStatementContributionsParagraph10}</p>
								</li>
								<li>
									<p>{legal.legalStatementContributionsParagraph11}</p>
								</li>
								<li>
									<p>{legal.legalStatementContributionsParagraph12}</p>
								</li>
							</ul>

							<h4>{legal.legalStatementSecurity}</h4>
							<p>{legal.legalStatementSecurityParagraph1}</p>

							<h4>{legal.legalStatementTrademarks}</h4>
							<p>{legal.legalStatementTrademarksParagraph1}</p>

							<h4>{legal.legalStatementGoverning}</h4>
							<p>{legal.legalStatementGoverningParagraph1}</p>
						</div>
					);
				})}
			</div>
			<Footer />
		</>
	);
};

export default LegalStatement;
