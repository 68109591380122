import React, { useEffect, useContext } from 'react';
import config from '../config';
import { AuthContext } from '../context/AllContext';

const useFetchMembershipData = () => {
	const { user, membershipData, setMembershipData } = useContext(AuthContext);

	useEffect(() => {
		if (membershipData || !user) return;

		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/memberships?userID=${user.customer_id}`
				);
				const data = await response.json();
				setMembershipData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user?.customer_id, membershipData]);

	return membershipData;
};

export default useFetchMembershipData;
