import { getRequest } from '../../../services/api';

const getServices = async (callback) => {
	const services = await getRequest(`/bookings/service/entity/1`);

	if (services && Array.isArray(services)) {
		services.sort((a, b) => {
			// Check if either display_order is null, and handle accordingly
			if (a.content.display_order === null && b.content.display_order === null) {
				return 0; // Both are null, keep original order
			} else if (a.content.display_order === null) {
				return 1; // Push a to the bottom
			} else if (b.content.display_order === null) {
				return -1; // Push b to the bottom
			}
			// Both are numbers, proceed with normal subtraction sort
			return a.content.display_order - b.content.display_order;
		});
	}

	callback(services);
};

const getSpecialists = async (callback) => {
	const specialists = await getRequest(`/bookings/service/doctor`);

	callback(specialists);
};

const getServiceCategories = async (location, callback) => {
	const serviceCategories = await getRequest(`/bookings/serviceCategory`);

	callback(serviceCategories);
};

const getLocationCategories = async (callback) => {
	const locationCategories = await getRequest('/bookings/locationCategory');

	callback(locationCategories);
};

const getMembership = async (customerId, callback) => {
	const membership = await getRequest(`/memberships?userID=${customerId}`);

	callback(membership);
};

const getData = async (location, callback) => {
	const specialists = await getRequest(`/bookings/users?role=Clinician`);
	const services = location
		? await getRequest(`/bookings/service?location=${location}`)
		: await getRequest(`/bookings/service`);
	// const serviceCategoryFilter = location
	// 	? await getRequest(`/bookings/serviceCategory?locationCategory=${location}`)
	// 	: await getRequest(`/bookings/serviceCategory`);
	const serviceCategories = await getRequest(`/bookings/serviceCategory`);
	// TEMP until above fetch Service categroy by location is fix - object returned from the fetch is not in the same structure as object fetch for all service catwegories

	console.log({
		specialists,
		services,
		serviceCategories,
	});

	callback({
		specialists,
		services,
		serviceCategories,
	});
};

export default getData;
export { getServices, getSpecialists, getServiceCategories, getLocationCategories, getMembership };
