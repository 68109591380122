import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AllContext';

import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import Card from './components/Card';
import { getUserAppointments, getTasks, getNotifications } from '../../services/data';
import UpcomingAppointment from './components/UpcomingAppointment';
import MedicalDocuments from './components/MedicalDocuments';
import SubscriptionPlan from './components/SubscriptionPlan';
import MyAllowances from './components/MyAllowances';
import Notifications from './components/Notifications';
import LastOrder from './components/LastOrder';
import config from '../../config';
import useFetchMembershipData from '../../hooks/useFetchMembershipData';

const CustomerDashboard = () => {
	const { user, userAuthenticated, isAuthenticated, logout } = useContext(AuthContext);
	const {
		user: { userID, username: email, first_name },
	} = useContext(AuthContext);

	const [tasks, setTasks] = useState();
	const [notifications, setNotifications] = useState();
	const [appointmentsData, setAppointmentsData] = useState();
	const [servicesData, setServicesData] = useState([]);
	const [documentsData, setDocumentsData] = useState([]);

	const membershipData = useFetchMembershipData();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${config.REACT_APP_API_URL}/bookings/service`);
				const data = await response.json();
				setServicesData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id]);

	useEffect(() => {
		const fetchAppointments = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/bookings/appointments/customer/${user.customer_id}`
				);
				const data = await response.json();
				setAppointmentsData(data);
			} catch (error) {
				console.error('Error fetching appointments:', error);
			}
		};

		if (user.customer_id) {
			fetchAppointments();
		}
	}, [user.customer_id]);

	useEffect(() => {
		const fetchDocumentLinks = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/documents?customer=${user.customer_id}`
				);
				const data = await response.json();
				setDocumentsData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchDocumentLinks();
	}, [user.customer_id]);

	useEffect(() => {
		const fetchData = async () => {
			setTasks(await getTasks(userID));
			setNotifications(await getNotifications(userID));
		};
		fetchData();
	}, [userID, email]);

	if (!membershipData) {
		return null;
	}

	return (
		<>
			<Header title="Portal" />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="row">
							<Card contents={<UpcomingAppointment appointmentsData={appointmentsData} />} />
							<Card contents={<MedicalDocuments documentsData={documentsData} />} />
							<Card contents={<SubscriptionPlan membershipData={membershipData} />} last />
						</div>
						<div className="row">
							<Card contents={<MyAllowances membershipData={membershipData} />} fullWidth />
						</div>
						<div className="row">
							<Card contents={<Notifications />} />
							<Card
								contents={
									<LastOrder appointmentsData={appointmentsData} servicesData={servicesData} />
								}
								doubleWidth
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomerDashboard;
