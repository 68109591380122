import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

export default function DisclaimerModal({ onDisclaimerModalClose }) {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.69)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 9999,
			}}
			onClick={onDisclaimerModalClose}
		>
			<div
				style={{
					backgroundColor: '#fff',
					padding: '40px',
					borderRadius: '8px',
					minWidth: '400px',
					maxWidth: '800px',
				}}
				onClick={(e) => e.stopPropagation()}
			>
				<h4 className="mb-4">Disclaimer</h4>
				<p>
					The following information provides for common uses only. The presence or level of markers
					detected may indicate health issues unrelated to cancer, different types of cancer, or
					their usage for alternative purposes.
				</p>
				<p>
					It is crucial to consult with a medical doctor or specialist for accurate interpretation
					and guidance regarding individual indications. Self-diagnosis or reliance solely on the
					information provided herein is strongly discouraged. Professional medical advice should
					always be sought for accurate diagnosis, treatment, and ongoing healthcare management.{' '}
				</p>
				<p className="mb-4">
					The content provided is not intended to substitute professional medical advice, diagnosis,
					or treatment. The responsibility lies with the reader to use this information at their own
					discretion and consult with qualified healthcare professionals for individualised medical
					care.
				</p>
				<Button onClick={onDisclaimerModalClose} variant="primary" label="Close" fullWidth />
			</div>
		</div>
	);
}
