const sortEntities = (entities: any) =>
	entities.sort(
		(
			a: {
				entity_with_services: {
					booking_entity: { booking_entity_id: number; ranking: any };
				};
			},
			b: {
				entity_with_services: {
					booking_entity: { booking_entity_id: number; ranking: any };
				};
			}
		) => {
			const aPriority = a.entity_with_services.booking_entity.booking_entity_id === 1;
			const bPriority = b.entity_with_services.booking_entity.booking_entity_id === 1;
			if (aPriority && !bPriority) return 1;
			if (!aPriority && bPriority) return -1;
			return (
				(b.entity_with_services.booking_entity.ranking || 0) -
				(a.entity_with_services.booking_entity.ranking || 0)
			);
		}
	);

export default sortEntities;
