// import React from 'react';
// import Header from '../../components/Header';
// import Footer from '../../components/Footer';
// import './occupationalHealth.scss';
// import heroImage from './occupational-health-banner.png';

// const CardContent = ({ title, paragraph, content, image, logo, link }) => {
// 	const logoStyle = logo ? { maxWidth: '300px' } : {};

// 	return (
// 		<>
// 			<div className="col-12 row">
// 				<div
// 					style={{
// 						backgroundColor: 'rgb(255, 255, 255)',
// 						padding: 0,
// 						paddingBottom: '18px',
// 						borderRadius: '8px',
// 						border: '1px solid rgba(203, 205, 215, 0.231372549)',
// 					}}
// 				>
// 					{image && (
// 						<div
// 							style={{
// 								height: '200px',
// 								overflow: 'hidden',
// 								display: 'flex',
// 								alignItems: 'center',
// 								justifyContent: 'center',
// 							}}
// 						>
// 							<img
// 								src={image}
// 								alt=""
// 								style={{
// 									objectFit: 'cover',
// 									display: 'block',
// 									transition: 'all 0s ease-out 0s',
// 									...logoStyle,
// 								}}
// 							/>
// 						</div>
// 					)}
// 					<div className={`px-5 pb-4 ${logo ? '' : 'pt-5'}`}>
// 						<h4 className="mb-15 text-center">{title}</h4>
// 						<p className="">{paragraph}</p>
// 						{link && (
// 							<a
// 								href={link}
// 								style={{ textAlign: 'right', display: 'block' }}
// 								target="_blank"
// 								rel="noopener noreferrer"
// 							>
// 								<button className="btn btn-primary" type="button">
// 									Find out more
// 								</button>
// 							</a>
// 						)}
// 					</div>
// 				</div>

// 				{content}
// 			</div>
// 		</>
// 	);
// };

// const OccupationalHealth = () => {
// 	const cards = {
// 		default: {
// 			title: 'Occupational Health',
// 			image: heroImage,
// 			logo: false,
// 			link: '',
// 			paragraph:
// 				'Sirkka Health has a team of highly experienced occupational health and medical professionals, each with an average of more than 20 years of expertise. Our lab-based test partners are UKAS ISO15189 accredited, and our medical professionals have GMC, NMC, HCPC and HSE registrations. We take great pride in providing customised solutions tailored to each company’s requirements and delivering high quality services that prioritise the wellbeing of every staff member for our clients. While we provide bespoke packages for each of our clients, the general areas of occupational health services that we cover include the following:',
// 			content: (
// 				<>
// 					<div className="col-md-12 p-0">
// 						<div className="occupational-health-product">
// 							<h5>Health surveillance - Specialist Tests (depending on industry)</h5>

// 							<p className="pt-3">
// 								These health checks are required by law or health & safety regulations for employees
// 								whose job functions may expose them to workplace hazards such as noise, ionising
// 								radiation, fumes, dusts, infectious disease or biological agents. It is important to
// 								conduct regular tests and surveillance to identify risks and prevent any negative
// 								physical or psychological impact on employees caused by workplace hazards.
// 							</p>
// 							<ul>
// 								<li>Blood tests (depending on industry)</li>
// 								<li>Hearing test</li>
// 								<li>Vision test</li>
// 								<li>Hand arm vibration syndrome (HAVS) assessment</li>
// 								<li>Respiratory medical examination</li>
// 								<li>Lung function test</li>
// 								<li>Driver medical exam (HGV, counterbalance, and others)</li>
// 								<li>Alcohol and drug testing</li>
// 								<li>Skin surveillance</li>
// 								<li>
// 									Access to mental health resources including self-assessment and self-monitoring
// 									tools
// 								</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-6 p-0 pe-md-2">
// 						<div className="occupational-health-product">
// 							<h5>Health Promotion and wellbeing</h5>

// 							<ul>
// 								<li>Mental health survey</li>
// 								<li>Support mental wellbeing</li>
// 								<li>Change behaviour (smoking, alcohol, and substance use)</li>
// 								<li>Increase physical activity awareness</li>
// 								<li>Promote healthy diet</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-6 p-0 ps-md-3">
// 						<div className="occupational-health-product">
// 							<h5>Workplace Safety</h5>

// 							<ul>
// 								<li>Workstation risk assessment</li>
// 								<li>
// 									Head/neck/shoulder/back stress relief therapies (i.e., integrative medicine)
// 								</li>
// 								<li>Physiotherapies</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-6 p-0 pe-md-2">
// 						<div className="occupational-health-product">
// 							<h5>General health Assessment for staff</h5>

// 							<ul>
// 								<li>Blood tests (depending on requirements)</li>
// 								<li>Immunisation</li>
// 								<li>Health questionnaire</li>
// 								<li>
// 									Biometric analysis (body mass index, blood pressure, pulse, waist circumference)
// 								</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-6 p-0 ps-md-2">
// 						<div className="occupational-health-product">
// 							<h5>Occupational Advisor Consultation</h5>

// 							<ul>
// 								<li>General</li>
// 								<li>Mental Health</li>
// 								<li>Physio</li>
// 								<li>Specialist</li>
// 							</ul>
// 						</div>
// 					</div>
// 				</>
// 			),
// 		},
// 	};

// 	return (
// 		<>
// 			<Header title="Occupational Health" />
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-lg-8 mx-auto">
// 						<div className="px-5 pos-rel mb-70 mt-70">
// 							<div className="section-text pos-rel">
// 								<h5>
// 									We offer bespoke Occupational Health Services to suit each organisation's needs
// 								</h5>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 				<div className="row">
// 					{/* Filter area */}
// 					<div className="col-xl-4 col-lg-4 col-md-12">
// 						<div
// 							className="mb-4 ms-2 me-4 mb-lg-0 me-lg-0"
// 							style={{
// 								backgroundColor: 'rgb(255, 255, 255)',
// 								padding: 0,
// 								paddingBottom: '18px',
// 								borderRadius: '8px',
// 								border: '1px solid rgba(203, 205, 215, 0.231372549)',
// 							}}
// 						>
// 							<div className="px-5 pb-4 pt-5">
// 								<h4 className="mb-15">Made for your organisation</h4>
// 								<p>
// 									Get a customised solution for your organisation by contacting us below to discuss
// 									your specific organisational requirements.
// 								</p>
// 								<a
// 									href="/contact"
// 									style={{ textAlign: 'right', display: 'block' }}
// 									target="_blank"
// 									rel="noopener noreferrer"
// 								>
// 									<button className="btn btn-primary" style={{ width: '100%' }} type="button">
// 										Contact us
// 									</button>
// 								</a>
// 							</div>
// 						</div>
// 					</div>
// 					{/* Product area  */}
// 					<div className="col-xl-8 col-lg-8 col-md-12">
// 						<section className="team-area pb-90">
// 							<div className="container">
// 								<div className="row">
// 									<section className="team-area pb-90">
// 										<div className="container">
// 											<div className="row">
// 												<CardContent
// 													title={cards.default.title}
// 													paragraph={cards.default.paragraph}
// 													content={cards.default.content}
// 													image={cards.default.image}
// 													logo={cards.default.logo}
// 													link={cards.default.link}
// 												/>
// 											</div>
// 										</div>
// 									</section>
// 								</div>
// 							</div>
// 						</section>
// 					</div>
// 				</div>
// 			</div>
// 			<Footer />
// 		</>
// 	);
// };

// export default OccupationalHealth;

import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './occupationalHealth.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import heroImage from './occupational-health-banner.png';
import { Button } from '@sirkka-health/booking-system-ui';

// Assuming this is your apiData with sample product data
const apiData = [
	{
		title: 'GP Appointments',
		paragraph: 'These health checks are required by law...',
		tags: ['gp', 'doctor'],
		image: heroImage, // Using imported heroImage for demonstration
		bookingUrl: 'https://www.salus-wellness.com/bookings',
	},
	{
		title: 'Health Surveillance',
		paragraph: 'These health checks are required by law...',
		tags: ['health', 'surveillance', 'legal'],
		image: heroImage,
		bookingUrl: 'https://www.salus-wellness.com/bookings',
	},
	{
		title: 'Drug Testing',
		paragraph: 'These health checks are required by law...',
		tags: ['drug', 'hair', 'urine', 'blood', 'alcohol', 'saliva'],
		image: heroImage,
		bookingUrl: 'https://www.salus-wellness.com/bookings',
	},
	// Add more products here
];

const CardContent = ({ title, paragraph, tags, image, bookingUrl }) => (
	<div className="col-12 col-md-6 col-lg-4 mb-4">
		<div
			style={{
				backgroundColor: 'rgb(255, 255, 255)',
				padding: '20px',
				borderRadius: '8px',
				border: '1px solid rgba(203, 205, 215, 0.231372549)',
			}}
		>
			{image && (
				<div
					style={{
						height: '200px',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginBottom: '20px',
					}}
				>
					<img src={image} alt="" style={{ width: '100%', height: 'auto' }} />
				</div>
			)}
			<h4>{title}</h4>
			<p>{paragraph}</p>
			{tags && (
				<div className="tags">
					{tags.map((tag) => (
						<span key={tag} className="tag">
							{tag}
						</span>
					))}
				</div>
			)}
			{bookingUrl && (
				<a href={bookingUrl} target="_blank" rel="noopener noreferrer" className="d-block mt-3">
					<Button variant="primary" type="button" label="Book now" fullWidth />
				</a>
			)}
		</div>
	</div>
);

const OccupationalHealth = () => {
	const [selectedOption, setSelectedOption] = useState(null);

	const customFilterOption = (candidate, input) => {
		const labelMatch = candidate.label.toLowerCase().includes(input.toLowerCase());
		const tagMatch = candidate.data.tags.some((tag) =>
			tag.toLowerCase().includes(input.toLowerCase())
		);
		return labelMatch || tagMatch;
	};

	const options = apiData.map((data) => ({
		value: data.title,
		label: data.title,
		tags: data.tags,
	}));

	const animatedComponents = makeAnimated();

	// Filter products based on selection or show all products
	const filteredProducts = selectedOption
		? apiData.filter((product) => product.title === selectedOption.label)
		: apiData; // If no option is selected, show all products

	return (
		<>
			<Header title="Occupational Health" />
			<div className="container">
				<div className="row">
					<div className="col-lg-8 mx-auto">
						<div className="px-5 pos-rel mb-70 mt-70">
							<div className="section-text pos-rel">
								<h5>
									We offer bespoke Occupational Health Services to suit each organisation's needs
								</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4 col-md-12">
						<div
							className="mb-4 ms-2 me-4 mb-lg-0 me-lg-0"
							style={{
								backgroundColor: 'rgb(255, 255, 255)',
								padding: 0,
								paddingBottom: '18px',
								borderRadius: '8px',
								border: '1px solid rgba(203, 205, 215, 0.231372549)',
							}}
						>
							<div className="px-5 pb-4 pt-5">
								<h4 className="mb-15">Search</h4>
								<Select
									components={animatedComponents}
									options={options}
									onChange={setSelectedOption}
									isSearchable={true}
									isClearable={true}
									placeholder="Search Products..."
									filterOption={customFilterOption}
								/>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8 col-md-12">
						<section className="team-area pb-90">
							<div className="container">
								<div className="row">
									{filteredProducts.map(
										(
											product,
											index // Use filteredProducts here
										) => (
											<CardContent
												key={index}
												title={product.title}
												paragraph={product.paragraph}
												tags={product.tags}
												image={product.image}
												bookingUrl={product.bookingUrl}
											/>
										)
									)}
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default OccupationalHealth;
