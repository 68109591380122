const formatPrice = ({ amount, price, currency = 'gbp', quantity = 1 }) => {
	if (!amount && price) amount = price;

	if (!currency) return convertPenceToPounds(amount, '');

	const numberFormat = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
		currencyDisplay: 'symbol',
	});
	const parts = numberFormat.formatToParts(amount);
	let zeroDecimalCurrency = true;
	for (let part of parts) {
		if (part.type === 'decimal') {
			zeroDecimalCurrency = false;
		}
	}
	amount = zeroDecimalCurrency ? amount : amount / 100;
	const total = (quantity * amount).toFixed(2);
	return numberFormat.format(total);
};

export default formatPrice;

function convertPenceToPounds(value, currencySymbol = '£') {
	value = value / 100;

	return currencySymbol + (Number(value) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export { convertPenceToPounds };
