import LinkElements from './linkElements';

const MoreLinks = () => {
	const moreLinksData = [
		{ link: '/refundPolicy', text: 'Refund Policy' },
		{ link: '/privacyPolicy', text: 'Privacy Policy' },
		{ link: '/legalStatement', text: 'Legal Statement' },
	];

	return (
		<>
			<div className="col-xl-2 col-lg-3 col-md-6">
				<LinkElements heading="More Links" data={moreLinksData} />
			</div>
		</>
	);
};

export default MoreLinks;
