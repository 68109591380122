import React, { useState, useEffect } from 'react';
import DashboardSidebar from '../CustomerPortal/components/DashboardSidebar';
import Header from '../../components/Header';
import Faqs from './';
import { getAllFaqs } from '../../services/data';
import formatHeading from '../../helpers/format-heading';
import useAuth from '../../hooks/useAuth';
import config from '../../config';

const FaqListing = () => {
	const allFaqs = getAllFaqs();

	const { user } = useAuth();
	const [membershipData, setMembershipData] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/memberships?userID=${user.customer_id}`
				);
				const data = await response.json();
				setMembershipData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id]);

	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="row patient-graph-col">
							<div className="col-12">
								<div className="card">
									{allFaqs &&
										Object.keys(allFaqs).map((faqCategory, index) => (
											<Faqs
												key={index}
												heading={formatHeading(faqCategory, 'word')}
												faqs={allFaqs[faqCategory]}
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FaqListing;
