import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingType.scss';
import { formatPrice } from '@/helpers';
import FreePlanIcon from './Free-plan.svg';
import DiscoveryPlanIcon from './Disovery-plan.svg';
import ProPlanIcon from './Pro-plan.svg';

const ListingType = ({ data, onChange, setIsValid }) => {
	const [activeIndex, setActiveIndex] = useState(null);
	const [billingPeriod, setBillingPeriod] = useState('monthly');

	useEffect(() => {
		setIsValid(data.selectedPlan.planType !== '');
	}, [data, setIsValid]);

	const plans = [
		{
			title: 'List only',
			subtitle: 'Discovery Plan',
			description: 'Increase visibility of your business, with a link to your website',
			prices: {
				monthly: 2400,
				quarterly: 7200,
				yearly: 27360,
			},
			icon: DiscoveryPlanIcon,
			included: 'Listing on our website with a link to your site.',
			planType: 'EntityBasic',
		},
		{
			title: 'List + enable customers to book online',
			subtitle: 'Pro Plan',
			description: 'Manage your availability and allow instant customer booking online',
			prices: {
				monthly: 7200,
				quarterly: 21600,
				yearly: 82080,
			},
			icon: ProPlanIcon,
			included: 'Manage bookings, customer records, and more.',
			planType: 'EntityPro',
		},
		{
			title: 'Book on behalf of my customers',
			description:
				'Purchase diagnostic tests directly from our partner laboratories integrated with Sirkka’s system',
			prices: {
				monthly: 0,
				quarterly: 0,
				yearly: 0,
			},
			icon: FreePlanIcon,
			included: 'Direct integration with diagnostic labs.',
			planType: 'Corporate',
		},
	];

	const handleBillingPeriodChange = (event) => {
		setBillingPeriod(event.target.value);
		onChange({
			selectedPlan: {
				...data.selectedPlan,
				mode: event.target.value,
			},
		});
	};

	const handleChange = (e, index, plan) => {
		onChange({
			selectedPlan: {
				planType: plan.planType,
				price: plan.prices[billingPeriod],
				mode: billingPeriod,
			},
		});
		setActiveIndex(index);
	};

	const handleCardClick = (index, plan) => {
		onChange({
			selectedPlan: {
				planType: plan.planType,
				price: plan.prices[billingPeriod],
				mode: billingPeriod,
			},
		});
		setActiveIndex(index);
	};

	const handleHover = (index) => {
		setActiveIndex(index);
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-3 billing-period-toggle">
				<p className="m-0" style={{ fontWeight: '500' }}>
					I would like to
				</p>
				<div className="btn-group" role="group">
					<input
						type="radio"
						className="btn-check"
						id="monthly"
						value="monthly"
						checked={billingPeriod === 'monthly'}
						onChange={handleBillingPeriodChange}
					/>
					<label className="btn btn-outline-primary fw-normal" htmlFor="monthly">
						Monthly
					</label>

					<input
						type="radio"
						className="btn-check"
						id="quarterly"
						value="quarterly"
						checked={billingPeriod === 'quarterly'}
						onChange={handleBillingPeriodChange}
					/>
					<label className="btn btn-outline-primaryy fw-normal" htmlFor="quarterly">
						Quarterly
					</label>

					<input
						type="radio"
						className="btn-check"
						id="yearly"
						value="yearly"
						checked={billingPeriod === 'yearly'}
						onChange={handleBillingPeriodChange}
					/>
					<label className="btn btn-outline-primaryy fw-normal" htmlFor="yearly">
						Yearly
					</label>
				</div>
			</div>

			{plans.map((plan, index) => (
				<div
					className={`plan-card mb-3`}
					onMouseEnter={() => handleHover(index)}
					onClick={() => handleCardClick(index, plan)}
					key={index}
				>
					<div
						className={`card h-100 position-relative ${
							data.selectedPlan.planType === plan.planType ? 'selected' : ''
						}`}
					>
						<div className="card-body d-flex align-items-start">
							{/* Column 1: Icon */}
							<div className="d-flex align-items-start me-3 ">
								<img src={plan.icon} className="plan-icon" alt="plan-icon" width="30" height="30" />
							</div>

							{/* Column 2: Title, Subtitle, and Description */}
							<div className="plan-details flex-grow-1 text-start">
								<strong className="plan" style={{ display: 'block', marginRight: '100px' }}>
									{plan.title}
								</strong>
								<p className="subtitle mb-1">{plan.subtitle}</p>
								<p className="description mb-0">{plan.description}</p>
							</div>

							<label className="form-check-label ms-3 align-self-center">
								<input
									type="radio"
									className="form-check-input"
									name="listingType"
									value={plan.title}
									checked={data.selectedPlan.planType === plan.planType}
									onChange={(e) => handleChange(e, index, plan)}
								/>
							</label>
						</div>

						{/* Price */}
						<div className="price-info position-absolute text-end" style={{ right: '12px' }}>
							<span className="plan-price d-block">
								{plan.prices[billingPeriod] <= 0
									? 'Free'
									: `${formatPrice({ price: plan.prices[billingPeriod] })} / ${billingPeriod}`}
							</span>
							{plan.prices[billingPeriod] > 0 ? <strong className="vat">Includes VAT</strong> : ''}
						</div>
					</div>

					{activeIndex === index && (
						<div className="hover-info">
							<div className="arrow"></div>
							<h5>What's included</h5>
							<p>{plan.included}</p>
						</div>
					)}
				</div>
			))}
		</>
	);
};

export default ListingType;
