const markObjectAsChecked = (data, nameToFind, excludeOthers = false) => {
	let changeSuccessful = false;

	data.forEach((object) => {
		if (object?.category_name === nameToFind) {
			object['checked'] = true;
			changeSuccessful = true;
		} else if (object?.category_name && excludeOthers) {
			delete object['checked'];
			changeSuccessful = true;
		} else if (object?.category?.category_name === nameToFind) {
			object.category['checked'] = true;
			changeSuccessful = true;
		} else if (excludeOthers) {
			delete object.category['checked'];
			changeSuccessful = true;
		}

		if (object.subcategories && object.subcategories.length) {
			const subcategoriesChangeSuccessful = markObjectAsChecked(
				object.subcategories,
				nameToFind,
				excludeOthers
			);

			if (subcategoriesChangeSuccessful) {
				changeSuccessful = true;
			}
		}
	});

	return changeSuccessful;
};

export default markObjectAsChecked;
