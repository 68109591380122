import { getCurrentEnvironment } from './helpers/getCurrentEnvironment';
import { Config } from '@/types';

const environment = getCurrentEnvironment().toUpperCase();

const config: Config = process.env as any;

config.REACT_APP_API_URL ??= (config as any)[`REACT_APP_API_URL_${environment}`];
config.REACT_APP_STRIPE_PUBLIC_KEY ??= (config as any)[
	`REACT_APP_STRIPE_PUBLIC_KEY_${environment}`
];

window.envConfig = config;

export default config;
