import React, { useState } from 'react';
import './Card.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import ReadMore from '../ReadMore';

export default function ImagingCard({ type, entityItem, onOpenModal, onHover, onLeave }) {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		onHover(entityItem.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};

	const handleOpenModal = (service, bookingEntity) => {
		onOpenModal(service, bookingEntity);
	};

	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	return (
		<div
			className="px-3 py-3 mb-3"
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="d-flex flex-column flex-md-row card-content-container">
				<div className="col-12 col-sm-12 col-md-5 col-xl-4">
					<div className="image-container">
						<img src={entityItem.image_url} alt={entityItem.service_name} />
					</div>
				</div>
				<div className="col-12 col-sm-12 col-md-7 col-xl-8 ps-sm-3 pt-4 pt-md-0">
					<div className="d-flex align-items-center mb-15">
						<div className="col-9">
							<h4 className="mb-0">{entityItem.service_name}</h4>
						</div>
						<div className="col-3">
							{entityItem.website_url && (
								<a href={entityItem.website_url} target="_blank" rel="noopener noreferrer">
									<Button
										label={entityItem.website_url.startsWith('mailto') ? 'Email' : 'Website'}
										fullWidth
										variant="border"
									/>
								</a>
							)}
						</div>
					</div>
					<ReadMore content={entityItem.description} />
					<div className="d-flex justify-content-end mt-2">
						<Button
							label="Details"
							onClick={() => handleOpenModal(entityItem, entityItem)}
							fullWidth
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
