import React from 'react';
import styles from './householdBenefits.module.scss';

function HouseholdBenefits({ householdMembers }) {
	const freeMemberBenefits = [
		'Become a member or join a household with a member to enjoy all Sirkka benefits',
	];

	const basicMemberBenefits = [
		'Earn reward points with every purchase ( £1 spent = 1 point; 150 points = £10)',
		'Redeem Reward Points to get discounts on your purchases',
		'Fast-track appointments',
		'5% discount on service bookings (Excluding imaging)',
		'Assistance for treatments within private hospitals',
		'Private prescription delivery Service included',
		'Medical insurance claims assistance',
	];

	const silverMemberBenefits = [
		'Earn reward points with every purchase ( £1 spent = 1 point; 150 points = £10)',
		'Redeem Reward Points to get discounts on your purchases',
		'Fast-track appointments',
		'2 Free GP Consultations Per Year (1 per 6 Months)',
		'10% discount on service bookings (Excluding imaging)',
		'Assistance for treatments within private hospitals',
		'Private prescription delivery Service included',
		'Medical insurance claims assistance',
	];
	const goldMemberBenefits = [
		'Earn reward points with every purchase ( £1 spent = 1 point; 150 points = £10)',
		'Redeem Reward Points to get discounts on your purchases',
		'Fast-track appointments',
		'4 Free GP Consultations Per Year (2 per 6 Months)',
		'15% discount on service bookings (Excluding imaging)',
		'Assistance for treatments within private hospitals',
		'Private prescription delivery Service included',
		'Medical insurance claims assistance',
	];

	const getMembershipPriority = (type) => {
		switch (type) {
			case 'Gold Plan':
				return 3;
			case 'Silver Plan':
				return 2;
			case 'Basic Plan':
				return 1;
			default:
				return 0;
		}
	};

	// Find the household member with the highest membership
	const highestMembershipMember = householdMembers.reduce(
		(highest, member) => {
			return getMembershipPriority(member.membership_type) >
				getMembershipPriority(highest.membership_type)
				? member
				: highest;
		},
		{ membership_type: 'Free Plan' }
	);

	let displayedBenefits;
	switch (highestMembershipMember.membership_type) {
		case 'Basic Plan':
			displayedBenefits = basicMemberBenefits;
			break;
		case 'Silver Plan':
			displayedBenefits = silverMemberBenefits;
			break;
		case 'Gold Plan':
			displayedBenefits = goldMemberBenefits;
			break;
		default:
			displayedBenefits = freeMemberBenefits;
			break;
	}

	return (
		<div className={styles.householdBenefits}>
			<div className={styles.lastOrderInner}>
				<h3>Household benefits</h3>
				<p>
					These are all of the benefits available to you based on the combined membership levels of
					your household
				</p>
				<ul>
					{displayedBenefits.map((benefit, index) => (
						<li key={index}>
							<div className={styles.name}>{benefit}</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default HouseholdBenefits;
