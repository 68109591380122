import React, { useState } from 'react';

const ReadMore = ({ content, limit = 260 }) => {
	const [isFullTextShown, setIsFullTextShown] = useState(false);

	const toggleTextDisplay = () => {
		setIsFullTextShown(!isFullTextShown);
	};

	const getText = () => {
		if (isFullTextShown || content?.length <= limit) {
			return content;
		}
		return content?.substring(0, limit) + '...';
	};

	return (
		<div className="readmore">
			<div style={{ display: 'inline' }}>{getText()}</div>
			{content?.length > limit && (
				<a
					onClick={toggleTextDisplay}
					className="text-decoration-underline text-black ms-2"
					style={{ cursor: 'pointer' }}
				>
					{isFullTextShown ? 'Read Less' : 'Read More'}
				</a>
			)}
		</div>
	);
};

export default ReadMore;
