import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AllContext';
import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import Card from './components/Card';
import HouseholdAllowances from './components/HouseholdAllowances';
import HowItWorks from './components/HowItWorks';
import HouseholdInvite from './components/HouseholdInvite';
import HouseholdBalance from './components/HouseholdBalance';
import HouseholdBenefits from './components/HouseholdBenefits';
import HouseholdMembers from './components/HouseholdMembers';
import { getRequest } from '../../services/api';

const MyHousehold = () => {
	const { user } = useContext(AuthContext);
	const [householdMembershipData, setHouseholdMembershipData] = useState([]);
	const [membershipData, setMembershipData] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				let userMembershipData;

				// Check if the user has a group_id, and use the appropriate endpoint to get the membership data
				if (user.group_id) {
					userMembershipData = await getRequest(`/memberships/group/${user.group_id}`);
				} else {
					userMembershipData = await getRequest(`/memberships/`, { userID: user.customer_id });
				}

				setMembershipData(userMembershipData.groupMembership || userMembershipData);

				// If group_id exists, use the members data from the new endpoint response
				if (user.group_id) {
					const allHouseholdData = [
						...(userMembershipData.groupMembership.members || []),
						...(userMembershipData.groupMembership.pets || []),
						...(userMembershipData.groupMembership.child || []),
					];
					setHouseholdMembershipData(allHouseholdData);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id, user.group_id]);

	const NoHouseholdMessage = () => (
		<div>
			<h3>My household</h3>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
				Upgrade to a membership plan to start a household, or ask your household account holder to
				invite you to theirs.
			</div>
		</div>
	);

	if (!membershipData.membership_type) {
		return (
			<>
				<Header title="Portal" />
				<div className="container mt-25">
					<div className="row">
						<div className="col-md-5 col-lg-4 col-xl-3">
							<DashboardSidebar
								user={user}
								membershipData={householdMembershipData.find(
									(m) => m.customer_id === user.customer_id
								)}
							/>
						</div>
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div className="row">
								<NoHouseholdMessage />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Header title="Portal" />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar
							user={user}
							membershipData={householdMembershipData.find(
								(m) => m.customer_id === user.customer_id
							)}
						/>
						{console.log(householdMembershipData.find((m) => m.customer_id === user.customer_id))}
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="row">
							<Card
								contents={
									<HouseholdMembers
										members={householdMembershipData}
										user={user}
										membershipData={membershipData}
									/>
								}
								doubleWidth
							/>
							<Card
								contents={
									<HouseholdBalance
										members={householdMembershipData}
										membershipData={membershipData}
									/>
								}
								last
							/>
						</div>
						<div className="row">
							<Card
								contents={<HouseholdInvite user={user} membershipData={membershipData} />}
								halfWidth
							/>
							<Card contents={<HowItWorks />} halfWidth />
						</div>
						<div className="row">
							{householdMembershipData.length > 0 && (
								<Card
									contents={<HouseholdAllowances householdMembers={householdMembershipData} />}
									fullWidth
								/>
							)}
							<Card
								contents={<HouseholdBenefits householdMembers={householdMembershipData} />}
								fullWidth
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyHousehold;
