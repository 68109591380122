import React from 'react';
import {
	Button,
	TimePicker,
	DatePicker,
	PostcodeAvailabilityMap,
} from '@sirkka-health/booking-system-ui';

const AppointmentCreate = ({
	selectedLocation,
	availableLocations,
	onLocationChange,
	availabilityData,
	selectedDate,
	onDateChange,
	selectedTime,
	onTimeChange,
	onContinue,
	continueBtndisabled,
	requiredDuration,
	user,
	bookingData,
	setBookingData,
	handleDateTimeLocationSelection,
	setSelectedDate,
	setSelectedTime,
	clearSelectedTime,
	setClearSelectedTime,
	clearSelectedDate,
	setClearSelectedDate,
}) => {
	return (
		<div>
			<h3 className="mt-5">3. Create appointment</h3>
			<select onChange={onLocationChange}>
				<option value="" disabled selected>
					Select location
				</option>
				{availableLocations.map((location, index) => (
					<option key={index} value={location}>
						{location}
					</option>
				))}
			</select>

			{selectedLocation && (
				<>
					{selectedLocation === 'Home Visit' ? (
						<>
							<b className="d-block mt-4">Enter patient postcode</b>
							<PostcodeAvailabilityMap
								isStyledForForms={true}
								handleDateTimeLocationSelection={(
									selectedDate,
									selectedTime,
									userPostcode,
									clinicianUserId
								) =>
									handleDateTimeLocationSelection(
										bookingData,
										setBookingData,
										user,
										selectedDate,
										selectedTime,
										userPostcode,
										clinicianUserId,
										setSelectedDate,
										setSelectedTime
									)
								}
							/>
						</>
					) : (
						<>
							<DatePicker
								name="appointmentDate"
								onDateChange={onDateChange}
								availabilityData={availabilityData}
								label="Choose a date and time"
								clearSelectedDate={clearSelectedDate}
								setClearSelectedDate={setClearSelectedDate}
							/>
							{selectedDate && (
								<TimePicker
									name="appointmentTime"
									onTimeChange={onTimeChange}
									label="Available times"
									selectedDate={selectedDate}
									availabilityData={availabilityData}
									requiredDuration={requiredDuration}
									clearSelectedTime={clearSelectedTime}
									setClearSelectedTime={setClearSelectedTime}
								/>
							)}
						</>
					)}
				</>
			)}
			<div className="my-3">
				<Button
					label="Continue to Cart"
					onClick={onContinue}
					fullWidth
					large
					disabled={continueBtndisabled}
				/>
			</div>
		</div>
	);
};

export default AppointmentCreate;
