import { postRequest } from './api';

const getServiceInfo = async (calendarEmail, productName) => {
	const service = await postRequest('/bookings/services', {
		calendarEmail,
		productName,
	});

	return {
		serviceId: service.id,
		serviceName: service.displayName,
		serviceNotes: service.notes,
		serviceLocation: service.location,
	};
};

const getStaffIdsForCalendar = async (calendarEmail) => {
	const staffMemberIds = await postRequest('/bookings/staff', {
		calendarEmail,
	});

	return staffMemberIds;
};

const getAvailableSlots = async (calendarEmail, startTime, endTime) => {
	const response = await postRequest('/bookings/availability', {
		calendarEmail,
		startTime,
		endTime,
	});

	const { result } = response;

	return result;
};

const createAppointment = async (requestData) => {
	await postRequest('/bookings/appointments', requestData);
};

const createPostalDelivery = async (postalData) => {
	await postRequest('/bookings/delivery', postalData);
};

export {
	getServiceInfo,
	getStaffIdsForCalendar,
	getAvailableSlots,
	createAppointment,
	createPostalDelivery,
};
