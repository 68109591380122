import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MembershipTerms from './MembershipTerms';
import './membership.css';
import { addToStore } from '../../helpers/store';
import { useParams, useNavigate } from 'react-router-dom';
import useFetchMembershipData from '../../hooks/useFetchMembershipData';

const Membership = () => {
	const features = [
		'FREE Standard Health screening / year (Annual membership only) ',
		'Get 150 bonus points worth £10',
		'Membership Discount (Except Imaging)',
		'FREE GP/Vet consultations (Annual membership only)',
		'Earn reward points with every purchase ( £1 spent = 1 point; 150 points = £10)',
		'Enrol your family & friends and earn 150 points each',
		'Rapid referral to Specialists & imaging services',
		'Access to medical interpreter (Non-english speakers)',
		'Medical insurance claims assistance',
		'All benefits are sharable within the family (Available in Joint and Family memberships)',
	];
	const familyFeatures = [
		'FREE Standard Health screening / year (Annual membership only) ',
		'Get 150 bonus points worth £10',
		'Membership Discount (Except Imaging)',
		'FREE GP/Vet consultations (Annual membership only)',
		'FREE home visit /year for blood test (subject to availability)',
		'Earn reward points with every purchase ( £1 spent = 1 point; 150 points = £10)',
		'Enrol your family & friends and earn 150 points each',
		'Rapid referral to Specialists & imaging services',
		'Access to medical interpreter (Non-english speakers)',
		'Medical insurance claims assistance',
		'All benefits are sharable within the family (Available in Joint and Family memberships)',
	];

	const individualPlans = [
		{
			title: 'Basic Plan',
			//description: 'Our lite plan',
			highlighted: false,
			priceMonthly: '£9.00*',
			priceAnnually: '£99.00',
			priceAnnuallyWithoutDiscount: '£108.00',
			headline: '1 month free with annual plan',
			features: [
				false,
				false,
				'5%',
				'0',
				true,
				true,
				true,
				'Available at extra cost',
				false,
				false,
			],
		},
		{
			title: 'Silver Plan',
			//description: 'Best for most people',
			highlighted: true,
			priceMonthly: '£29.00*',
			priceAnnually: '£304.50',
			priceAnnuallyWithoutDiscount: '£348.00',
			headline: '1.5 months free with annual plan',
			features: ['1', true, '10%', '2', true, true, true, true, false, false],
		},
		{
			title: 'Gold Plan',
			//description: 'Our most advanced plan',
			highlighted: false,
			priceMonthly: '£49.00*',
			priceAnnually: '£490.00',
			priceAnnuallyWithoutDiscount: '£588.00',
			headline: '2 months free with annual plan',
			features: ['1', true, '15%', '4', true, true, true, true, true, false],
		},
	];

	const jointPlans = [
		{
			title: 'Joint Plan',
			description: 'For two people',
			highlighted: false,
			priceMonthly: '£39.00',
			priceAnnually: '£390.00',
			priceAnnuallyWithoutDiscount: '£468.00',
			headline: '2 months free with annual plan',
			features: ['2', true, '15%', '2', true, true, true, true, true, true],
		},
	];

	const familyPlans = [
		{
			title: 'Single Parent Family Plan',
			description: 'Children under 18 and pets included for free*',
			highlighted: false,
			priceMonthly: '£39.00',
			priceAnnually: '£390.00',
			priceAnnuallyWithoutDiscount: '£468.00',
			headline: '2 months free with annual plan',
			features: ['2', true, '15%', '2', '1', true, true, true, true, true],
		},
		{
			title: 'Family Plan',
			description: 'Children under 18 and pets included for free*',
			highlighted: false,
			priceMonthly: '£49.00',
			priceAnnually: '£539.00',
			priceAnnuallyWithoutDiscount: '£588.00',
			headline: '1 month free with annual plan',
			features: ['2', true, '15%', '2', '1', true, true, true, true, true],
		},
	];

	const [showAnnualPrice, setShowAnnualPrice] = useState(false);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
	const [selectedMembership, setSelectedMembership] = useState(null);
	const [plans, setPlans] = useState(individualPlans);
	const [selectedValue, setSelectedValue] = useState('individualPlans');
	const navigate = useNavigate();
	const { membershipType } = useParams();
	const membershipData = useFetchMembershipData();

	// Update selectedValue based on URL
	useEffect(() => {
		if (membershipType === 'family' && selectedValue !== 'familyPlans') {
			setSelectedValue('familyPlans');
			setPlans(familyPlans);
		} else if (membershipType === 'joint' && selectedValue !== 'jointPlans') {
			setSelectedValue('jointPlans');
			setPlans(jointPlans);
		} else if (membershipType === undefined && selectedValue !== 'individualPlans') {
			setSelectedValue('individualPlans');
			setPlans(individualPlans);
		}
	}, [membershipType, selectedValue]);

	useEffect(() => {
		if (selectedMembership) {
			const membershipProduct = {
				name: selectedMembership.title,
				price: showAnnualPrice
					? parseInt(selectedMembership.priceAnnually.replace(/[^\d]/g, ''))
					: parseInt(selectedMembership.priceMonthly.replace(/[^\d]/g, '')),
				mode: showAnnualPrice ? 'yearly' : 'monthly',
				qty: 1,
			};

			if (showAnnualPrice === false) {
				membershipProduct.qty = 3;
			}

			addToStore('membership', [membershipProduct]);
			navigate('/checkout/subscription');
		}
	}, [selectedMembership, showAnnualPrice]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const renderFeature = (state) => {
		if (state === true) {
			return <i className="fa fa-check" data-unicode="f00c"></i>;
		} else if (state === false) {
			return <i className="fa fa-times" data-unicode="f00d"></i>;
		} else {
			return <span>{state}</span>;
		}
	};

	function handleChange(event) {
		const newValue = event.target.value;
		setSelectedValue(newValue);

		if (newValue === 'familyPlans') {
			navigate('/membership/family', { replace: true });
		} else if (newValue === 'jointPlans') {
			navigate('/membership/joint', { replace: true });
		} else if (newValue === 'individualPlans') {
			navigate('/membership/individual', { replace: true });
		}

		setPlans(
			newValue === 'individualPlans'
				? individualPlans
				: newValue === 'jointPlans'
				? jointPlans
				: familyPlans
		);
	}

	return (
		<>
			<Header title="Membership" />
			<div className="container">
				<div className="container-fluid">
					<div className="col-lg-10 mx-auto text-center">
						<div className="px-5 pos-rel mb-70 mt-70">
							<div className="section-text pos-rel">
								<h2 className="membership-title mb-4">
									Premium-service plans (annual or short-term)
								</h2>
								<p className="membership-subtitle">
									Sirkka Health provides a variety of membership plans tailored to meet the needs of
									individuals, families, and businesses. Annual membership fees can be paid monthly
									or in a single annual payment, and there is an option for short-term membership.
								</p>
								<p className="membership-legal">
									<b>*IMPORTANT</b>: For all Memberships, the monthly subscription requires a
									minimum of 3-months’ commitment, and a payment of three months’ subscription fee
									is charged at the point of signing up for the membership. Subscriptions can be
									cancelled after the initial three months at any time with one month's notice.
									Monthly payments will be automatically collected from the registered debit/credit
									card starting from the fourth month unless you have notified us of cancellation
									one month in advance. To create an account with Sirkka Health, individuals must be
									of 18 years of age or older.
								</p>
							</div>
						</div>
					</div>
					<div className="membershipsSwitch">
						<input
							type="radio"
							name="individualPlans"
							id="individualPlans"
							checked={selectedValue === 'individualPlans'}
							value="individualPlans"
							onChange={handleChange}
						/>
						<label htmlFor="individualPlans" className={``}>
							<span>Individual</span>
						</label>
						<input
							type="radio"
							name="jointPlans"
							id="jointPlans"
							checked={selectedValue === 'jointPlans'}
							value="jointPlans"
							onChange={handleChange}
						/>
						<label htmlFor="jointPlans" className={``}>
							<span>Joint</span>
						</label>
						<input
							type="radio"
							name="familyPlans"
							id="familyPlans"
							value="familyPlans"
							checked={selectedValue === 'familyPlans'}
							onChange={handleChange}
						/>
						<label htmlFor="familyPlans" className={``}>
							<span>Family</span>
						</label>
					</div>

					<>
						<div className="toggleAnnual">
							Monthly{' '}
							<label className="switch">
								<input type="checkbox" onChange={() => setShowAnnualPrice(!showAnnualPrice)} />
								<span className="slider round"></span>
							</label>{' '}
							Annually
						</div>
						{!isMobileView ? (
							<table className="pricing-plan">
								<thead>
									<tr>
										<th className="description-column"></th>
										{plans.map((plan, index) => (
											<th key={index} className="plan-column">
												{plan.highlighted ? (
													<div className="featured-plan">Ideal starter plan</div>
												) : (
													<div
														style={{
															padding: '10px 0',
														}}
													>
														&nbsp;
													</div>
												)}
												<div
													className="plan-card-content plan-card-top"
													style={
														plan.highlighted
															? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
															: {}
													}
												>
													{membershipData?.membership_type === plan.title && (
														<span className="your-plan">Your current plan</span>
													)}
													<span className="plan-title">{plan.title}</span>
													{plan.description ? (
														<span className="plan-description">{plan.description}</span>
													) : (
														''
													)}
													<span className="plan-price">
														{showAnnualPrice ? (
															<>
																<span className="price-before">
																	{plan.priceAnnuallyWithoutDiscount}
																</span>
																{plan.priceAnnually}{' '}
																{plan.title === 'Single parent and children' ? (
																	<span className="price-descriptor">/year for 1 adult</span>
																) : plan.title === 'Both parents and children' ||
																  plan.title === 'Joint Plan' ? (
																	<span className="price-descriptor">/year for 2 adults</span>
																) : (
																	<span className="price-descriptor">/year</span>
																)}
															</>
														) : (
															<>
																{plan.priceMonthly}
																{plan.title === 'Single parent and children' ? (
																	<span className="price-descriptor">/month for 1 adult</span>
																) : plan.title === 'Both parents and children' ||
																  plan.title === 'Joint Plan' ? (
																	<span className="price-descriptor">/month for 2 adults</span>
																) : (
																	<span className="price-descriptor">/month</span>
																)}
															</>
														)}
													</span>
													<span className="plan-headline">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															className="mBhTDc"
														>
															<path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02zM8 6.5C8 7.33 7.33 8 6.5 8S5 7.33 5 6.5 5.67 5 6.5 5 8 5.67 8 6.5z"></path>
															<path d="M0 0h24v24H0z" fill="none"></path>
														</svg>
														{plan.headline}
													</span>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{selectedValue !== 'familyPlans' &&
										features.map((feature, index) => (
											<tr key={index}>
												<td className="description-column">{feature}</td>
												{plans.map((plan) => (
													<td key={plan.title} className="plan-column">
														<div className="plan-card-content">
															{renderFeature(plan.features[index])}
														</div>
													</td>
												))}
											</tr>
										))}
									{selectedValue === 'familyPlans' &&
										familyFeatures.map((feature, index) => (
											<tr key={index}>
												<td className="description-column">{feature}</td>
												{plans.map((plan) => (
													<td key={plan.title} className="plan-column">
														<div className="plan-card-content">
															{renderFeature(plan.features[index])}
														</div>
													</td>
												))}
											</tr>
										))}
								</tbody>
								<tfoot>
									<tr>
										<th className="description-column"></th>
										{plans.map((plan, index) => (
											<th key={index} className="plan-column">
												<div className="plan-card-content plan-card-bottom">
													{selectedValue !== 'comingSoon' ? (
														<button
															onClick={() => setSelectedMembership(plan)}
															className="btn btn-primary"
															style={{ width: '100%' }}
														>
															Choose {plan.title}
														</button>
													) : (
														<a href="/contact" style={{ width: '100%' }}>
															<button className="btn btn-primary" style={{ width: '100%' }}>
																Coming soon - Contact us
															</button>
														</a>
													)}
												</div>
											</th>
										))}
									</tr>
								</tfoot>
							</table>
						) : (
							<table className="pricing-plan">
								<tbody>
									{plans.map((plan, index) => (
										<React.Fragment key={index}>
											<tr>
												<td colSpan="2">
													{plan.highlighted ? (
														<div className="featured-plan">Ideal starter plan</div>
													) : (
														''
													)}
													<div
														className="plan-card-content plan-card-top"
														style={
															plan.highlighted
																? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
																: {}
														}
													>
														<span className="plan-title">{plan.title}</span>
														{plan.description && (
															<span className="plan-description">{plan.description}</span>
														)}
														<span className="plan-price">
															{showAnnualPrice ? (
																<>
																	<span className="price-before">
																		{plan.priceAnnuallyWithoutDiscount}
																	</span>
																	{plan.priceAnnually}{' '}
																	{plan.title === 'Single parent and children' ? (
																		<span className="price-descriptor">/year for 1 adult</span>
																	) : plan.title === 'Both parents and children' ||
																	  plan.title === 'Joint Plan' ? (
																		<span className="price-descriptor">/year for 2 adults</span>
																	) : (
																		<span className="price-descriptor">/year</span>
																	)}
																</>
															) : (
																<>
																	{plan.priceMonthly}
																	{plan.title === 'Single parent and children' ? (
																		<span className="price-descriptor">/month for 1 adult</span>
																	) : plan.title === 'Both parents and children' ||
																	  plan.title === 'Joint Plan' ? (
																		<span className="price-descriptor">/month for 2 adults</span>
																	) : (
																		<span className="price-descriptor">/month</span>
																	)}
																</>
															)}
														</span>
														<span className="plan-headline">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																className="mBhTDc"
															>
																<path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02zM8 6.5C8 7.33 7.33 8 6.5 8S5 7.33 5 6.5 5.67 5 6.5 5 8 5.67 8 6.5z"></path>
																<path d="M0 0h24v24H0z" fill="none"></path>
															</svg>
															{plan.headline}
														</span>
													</div>
												</td>
											</tr>
											{selectedValue !== 'familyPlans' &&
												features.map((feature, featureIndex) => (
													<tr key={featureIndex}>
														<td className="description-column">{feature}</td>
														<td className="plan-column">
															<div className="plan-card-content">
																{renderFeature(plan.features[featureIndex])}
															</div>
														</td>
													</tr>
												))}
											{selectedValue === 'familyPlans' &&
												familyFeatures.map((feature, index) => (
													<tr key={index}>
														<td className="description-column">{feature}</td>
														<td key={plan.title} className="plan-column">
															<div className="plan-card-content">
																{renderFeature(plan.features[index])}
															</div>
														</td>
													</tr>
												))}
											<tr>
												<td colSpan="2">
													{console.log('selectedValue', selectedValue)}
													<div className="plan-card-content plan-card-bottom">
														{selectedValue !== 'comingSoon' ? (
															<button
																onClick={() => setSelectedMembership(plan)}
																className="btn btn-primary"
																style={{ width: '100%' }}
															>
																Choose {plan.title}
															</button>
														) : (
															<a href="/contact" style={{ width: '100%' }}>
																<button className="btn btn-primary" style={{ width: '100%' }}>
																	Coming soon - Contact us
																</button>
															</a>
														)}
													</div>
													<div className="plan-divider"></div>
												</td>
											</tr>
										</React.Fragment>
									))}
								</tbody>
							</table>
						)}
					</>
				</div>
			</div>
			<MembershipTerms />
			<Footer />
		</>
	);
};

export default Membership;
