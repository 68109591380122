const AccordionFAQ = ({
	number,
	question,
	answer,
	condition = false,
	fistClassAdd,
	secondClassAdd,
}) => {
	return (
		<>
			<div className="card">
				<div className="card-header" id={`heading${number}`}>
					<h5 className="mb-0">
						<a
							href="/#"
							className={`btn-link ${fistClassAdd && fistClassAdd}`}
							data-bs-toggle="collapse"
							data-bs-target={`#collapse${number}`}
							aria-controls={`collapse${number}`}
							aria-expanded={condition}
						>
							{question}
						</a>
					</h5>
				</div>
				<div
					id={`collapse${number}`}
					className={`collapse ${secondClassAdd && secondClassAdd}`}
					aria-labelledby={`heading${number}`}
					data-bs-parent="#accordion"
				>
					<div className="card-body">
						<p>{answer}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default AccordionFAQ;
