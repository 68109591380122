import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from './GiftCards.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import { BookingContext } from '@sirkka-health/booking-system-ui';

const GiftCards = () => {
	const [amount, setAmount] = useState(50);
	const [recipientName, setRecipientName] = useState('');
	const [recipientEmail, setRecipientEmail] = useState('');
	const [senderName, setSenderName] = useState('');
	const [senderEmail, setSenderEmail] = useState('');
	const [senderCopy, setSenderCopy] = useState(false);
	const [message, setMessage] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [errors, setErrors] = useState({});
	const { bookingData, setBookingData } = useContext(BookingContext);

	const navigate = useNavigate();
	const errorBoxRef = useRef(null);

	const validateForm = () => {
		let newErrors = {};

		// Email validation
		const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

		// Validation
		if (!recipientName.trim())
			newErrors.recipientName = ['Recipient name is required', 'recipientName'];
		if (!recipientEmail.trim() || !isValidEmail(recipientEmail))
			newErrors.recipientEmail = ['Recipient email appears to be incorrect', 'recipientEmail'];
		if (!senderName.trim()) newErrors.yourName = ['Your name is required', 'yourName'];
		if (!message.trim()) newErrors.message = ['Message is required', 'message'];
		if (senderCopy && (!senderEmail.trim() || !isValidEmail(senderEmail)))
			newErrors.senderEmail = ['Your email appears to be incorrect', 'senderEmail'];
		if (!termsAccepted)
			newErrors.termsAccepted = ['You must accept the terms and conditions', 'termsAccepted'];

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	function generateUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!validateForm()) {
			// Scroll to error box
			errorBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
			return;
		}

		// Handle the form submission
		console.log({
			amount,
			recipientName,
			recipientEmail,
			senderName,
			senderCopy,
			senderEmail,
			message,
			termsAccepted,
		});

		setBookingData([
			...bookingData,
			{
				booking: {
					bookingId: Date.now().toString(),
					locationId: 0,
					bookingName: 'Giftcard',
					bookingProduct: 'giftcard',
					appointmentLocation: '',
					appointmentRegion: '',
					appointmentDate: '',
					appointmentTime: '',
					appointmentFor: '',
					sampleConsent: true,
					termsconditions: true,
					completed: true,
				},
				customer: {
					nameTitle: '',
					firstName: senderName,
					lastName: senderName,
					gender: '',
					dateOfBirth: '',
					phoneNumber: '',
					company: '',
					occupation: '',
					address_line_1: '',
					address_line_2: '',
					city: '',
					postcode: '',
					country: '',
					address_type: '',
					emailAddress: senderEmail,
				},
				postal: {
					postageAddressLine1: '',
					postageAddressLine2: '',
					postageCity: '',
					postagePostcode: '',
					postageCountry: '',
					postageSelection: 'no',
				},
				service: {
					serviceName: 'Giftcard',
					serviceId: 'giftcard',
					giftcardId: generateUUID(),
					giftcardMessage: message,
					giftcardRecipientName: recipientName,
					giftcardRecipientEmail: recipientEmail,
					giftcardSenderName: senderName,
					giftcardSenderEmail: senderEmail,
					giftcardSendCopy: senderCopy,
					serviceType: 'giftcardPurchase',
					emailTemplate: '',
					regionId: 0,
					price: amount * 100,
					icon: 'health_screening',
				},
				entity: {
					entityId: 1,
				},
				combineBooking: '',
			},
		]);

		navigate('/cart');
	};

	useEffect(() => {
		// Scroll to error box if errors exist on component mount
		if (Object.keys(errors).length > 0) {
			errorBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [errors]);

	return (
		<>
			<Header title="Services" />
			<div className="container mt-30 mb-30">
				<div className="text-center mb-40">
					<div className={styles.giftCard}>
						<div className={styles.amount}>£{amount}</div>
					</div>
					<p>The full amount of this giftcard will need to be redeemed in one transaction</p>
					<h3>Give the gift of health</h3>
				</div>

				<div className={styles.giftCardForm}>
					{/* Form */}
					<form onSubmit={handleSubmit}>
						{/* Error box */}
						{Object.keys(errors).length > 0 && (
							<div ref={errorBoxRef} className={styles.errorBox}>
								{Object.entries(errors).map(([key, [error, inputRef]], index) => (
									<p key={index}>
										<a
											href={`#${inputRef}`}
											onClick={() => document.getElementById(inputRef).focus()}
										>
											{error}
										</a>
									</p>
								))}
							</div>
						)}

						<div>
							<h6>Select amount</h6>
							<input
								type="range"
								id="amount"
								min="10"
								max="200"
								step="1"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
							/>
							<p className="text-center">£{amount} giftcard</p>
						</div>

						<label>
							<h6>Recipient's name</h6>
							<input
								type="text"
								id="recipientName"
								value={recipientName}
								onChange={(e) => setRecipientName(e.target.value)}
								placeholder="Name"
								required
							/>
						</label>

						<label>
							<h6>Recipient's email</h6>

							<input
								type="email"
								id="recipientEmail"
								value={recipientEmail}
								onChange={(e) => setRecipientEmail(e.target.value)}
								placeholder="Email address"
								required
							/>
						</label>

						<label>
							<h6>Your name</h6>
							<input
								type="text"
								id="senderName"
								value={senderName}
								onChange={(e) => setSenderName(e.target.value)}
								placeholder="Your name"
								required
							/>
						</label>
						<label>
							<h6>Your email</h6>
							<input
								type="email"
								id="senderEmail"
								value={senderEmail}
								onChange={(e) => setSenderEmail(e.target.value)}
								placeholder="Email address"
								required
							/>
						</label>

						<label>
							<h6>Your message</h6>
							<textarea
								id="message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								placeholder="Message"
								required
							/>
						</label>

						<div>
							<input
								type="checkbox"
								id="senderCopy"
								checked={senderCopy}
								name="senderCopy"
								className={styles.hidden}
								onChange={() => setSenderCopy(!senderCopy)}
							/>
							<label htmlFor="senderCopy" className={styles.checkbox}>
								Send a copy to myself
							</label>
						</div>

						<div>
							<input
								type="checkbox"
								id="termsAccepted"
								checked={termsAccepted}
								name="termsAccepted"
								className={styles.hidden}
								onChange={() => setTermsAccepted(!termsAccepted)}
							/>
							<label htmlFor="termsAccepted" className={styles.checkbox}>
								I accept the terms and conditions below
							</label>
						</div>

						<Button type="submit" label={'Purchase Gift Card'} fullWidth />
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default GiftCards;
