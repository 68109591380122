import { Link } from 'react-router-dom';
import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { getTransactions } from '../../services/data';
import config from '../../config';

const Invoices = () => {
	const { user } = useAuth();
	const { user_id: userID } = user;
	const [transactions, setTransactions] = useState([]);
	const [membershipData, setMembershipData] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/memberships?userID=${user.customer_id}`
				);
				const data = await response.json();
				setMembershipData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user.customer_id]);

	useEffect(() => {
		const fetchTransactions = async () => {
			const response = await getTransactions(userID);
			console.log(response);
			if (Array.isArray(response)) setTransactions(response);
		};
		fetchTransactions();
	}, [userID]);

	if (!transactions.length) {
		return (
			<>
				<Header />
				<div className="container mt-25">
					<div className="row">
						<div className="col-md-5 col-lg-4 col-xl-3">
							<DashboardSidebar user={user} membershipData={membershipData} />
						</div>
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div className="card-body ">
								<div className="card card-table mb-0">
									<div className="card-body">No transactions to display</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="card-body ">
							<div className="card card-table mb-0">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover table-center mb-0">
											<thead>
												<tr>
													<th>#</th>
													<th>Invoice Id</th>
													<th>Purpose</th>
													<th>Date</th>
													<th>Status</th>
												</tr>
											</thead>
											<tbody>
												{transactions?.map((transaction, index) => {
													return (
														<tr key={index}>
															<td>{index}</td>
															<td>{transactions.payment_ref}</td>
															{/* <td><Link to="/pages/invoice-view">{transactions.payment_ref}</Link></td> */}
															<td>Health Screening</td>
															<td>{transaction.payment_datetime}</td>
															<td>
																<span className="badge bg-success">
																	{transaction.transaction_status}
																</span>
															</td>
															<td className="text-end">
																<div className="table-action">
																	<Link to="#0" className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View Invoice
																	</Link>
																</div>
															</td>
														</tr>
													);
												})}
												;
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Invoices;
