import SingleService from '../../components/SingleService';
import productData from '../../data/products.json';

const ServiceDescription = ({ description }) => {
	return (
		<div className="about-text">
			<p className="theme-color">{description}</p>
		</div>
	);
};

const ServiceOverviewSection = ({ title, description, imagePath, alt }) => {
	return (
		<div className="row">
			<div className="col-xl-6 col-lg-5">
				<div className="h4about-thumb pos-rel">
					{imagePath && <img src={`/img/services/${imagePath}`} alt={alt} />}
				</div>
			</div>
			<div className="col-xl-6 col-lg-7">
				<div className="about-right-side h4about-right mb-150 pt-55">
					<div className="about-title mb-20">
						<h1>{title}</h1>
					</div>
					{description && <ServiceDescription description={description} />}
				</div>
			</div>
		</div>
	);
};

const ServiceAbout = ({ service }) => {
	const { title, description, image, key } = service || {};
	const { url, alt } = image || {};
	const products = productData[`${key}_products`] || [];
	const imagePath = url || image;

	return (
		<section className="about-area pt-120 pb-90">
			<div className="container">
				{title && (
					<ServiceOverviewSection
						title={title}
						description={description}
						imagePath={imagePath}
						alt={alt}
					/>
				)}
				<div className="row mt-35">
					{products.map(({ icon, name, link, description, overview_text, price }, index) => (
						<SingleService
							key={index}
							icon={icon}
							title={name}
							link={link}
							text={overview_text}
							price={price}
							border_className="service-box-border"
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default ServiceAbout;
