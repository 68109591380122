import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useForm } from 'react-hook-form';
import { checkLogin } from '../../services/data';
import useAuth from '../../hooks/useAuth';
import showAlert from '../../helpers/alerts';
import AuthenticationFormWrapper from '../Register/AuthenticationFormWrapper';
import sendPasswordResetEmail from '../../helpers/sendPasswordResetEmail';
import { Button } from '@sirkka-health/booking-system-ui';

const LoginArea = () => {
	const { isAuthenticated, userAuthenticated } = useAuth();
	const [loginStatus, setLoginStatus] = useState(isAuthenticated);
	const { register, handleSubmit, watch, setValue } = useForm();
	const [resetLogin, toggleResetLogin] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (loginStatus) {
			const redirectTo = location.state?.from?.pathname || '/cart';
			navigate(redirectTo);
		}
	}, [loginStatus, navigate, location]);

	const onSubmit = async (data) => {
		try {
			const response = await checkLogin(data);

			if (response.status === 'success') {
				userAuthenticated(response.user, data.remember);
				setLoginStatus(true);
				ReactSession.set('billing_address', response.billingDetails);
			} else if (
				response.status === 'error' &&
				response.message === 'Wrong authentication channel.'
			) {
				showAlert(
					'You have attempted to login with a business account on the regular user portal.'
				);
			} else if (response.status === 'error') {
				showAlert('Incorrect username/password. Please check your details and try again.');
			} else {
				showAlert(
					'Unable to connect to authentication server. Please refresh your browser and try again.'
				);
			}
		} catch (error) {
			showAlert('An error occurred during login. Please try again.');
		}
	};

	const handleEmailChange = (e) => {
		const lowerCaseEmail = e.target.value.toLowerCase();
		setValue('email', lowerCaseEmail);
	};

	const heading = resetLogin ? 'Reset Login' : 'Account Login';

	return (
		<AuthenticationFormWrapper>
			<h3 className="text-center mb-60">{heading}</h3>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="email" className="mb-2">
					Email Address <span>**</span>
				</label>
				<input
					{...register('email')}
					required
					id="email"
					type="text"
					placeholder="Enter Email address..."
					onChange={handleEmailChange}
				/>

				{!resetLogin && (
					<>
						<label htmlFor="pass" className="mb-2">
							Password <span>**</span>
						</label>
						<input
							{...register('password')}
							required
							id="pass"
							type="password"
							placeholder="Enter password..."
						/>
					</>
				)}

				<div className="login-action mb-20 fix">
					{!resetLogin && (
						<span className="log-rem f-left">
							<input {...register('remember')} id="remember" type="checkbox" />
							<label htmlFor="remember">Remember me!</label>
						</span>
					)}

					<span className="forgot-login f-right">
						<span onClick={() => toggleResetLogin(!resetLogin)}>Lost your password?</span>
					</span>
				</div>

				{!resetLogin && (
					<>
						<Button type="submit" label="Login" fullWidth large />
						<div className="or-divide">
							<span>or</span>
						</div>
						<Link to="/register">
							<Button type="button" label="Register" variant="secondary" fullWidth large />
						</Link>
					</>
				)}

				{resetLogin && (
					<Button
						type="button"
						label="Send reset email"
						fullWidth
						large
						onClick={() => sendPasswordResetEmail(watch('email'))}
					/>
				)}
			</form>
		</AuthenticationFormWrapper>
	);
};

export default LoginArea;
