import React, { useState } from 'react';
import { Button } from '@sirkka-health/booking-system-ui';
import './Card.scss';
import ReadMore from '../ReadMore';
import { SirkkaBrowseCardProps } from '@/types';

export default function SirkkaBrowseCard({ entityItem, onHover, onLeave }: SirkkaBrowseCardProps) {
	const [isHovered, setIsHovered] = useState(false);

	const bookingEntity = entityItem.entity_with_services.booking_entity;

	const handleMouseEnter = () => {
		onHover(bookingEntity.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};

	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	return (
		<div
			className="px-3 py-3 mb-3"
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="d-flex flex-column flex-md-row card-content-container">
				<div className="col-12 col-sm-12 col-md-5 col-xl-4">
					<div className="image-container">
						<img src={'/img/home/Medical-Consultation.jpg'} alt="Sirkka Health" />
					</div>
				</div>
				<div className="col-12 col-sm-12 col-md-7 col-xl-8 ps-sm-3 pt-4 pt-md-0">
					<div className="d-flex align-items-center mb-15">
						<div className="col-9">
							<h4 className="mb-0">Sirkka Health</h4>
						</div>
						<div className="col-3">
							<a href="https://www.sirkkahealth.com" target="_blank" rel="noopener noreferrer">
								<Button label="Website" fullWidth variant="border" />
							</a>
						</div>
					</div>
					<ReadMore content="Quickly schedule online routine/diagnostic health tests and specialist appointments. Our clinicians can provide tests at home, in our clinics or at other group-testing locations, with a network of leading specialists who can see you quickly virtually or in-person." />
				</div>
			</div>
			<div className="card-service d-flex px-3 mt-3">
				<div className="col-9 col-lg-10">
					<h6 style={{ fontSize: '16px', marginBottom: '3px' }}>Browse Diagnostic Tests</h6>
					<p className="mb-0 pe-3">
						Providing over 40 tests products. Get results quickly and with in-depth feedback from a
						medical practitioner
					</p>
				</div>
				<div className="col-3 col-lg-2 justify-content-end text-end align-self-center">
					<Button label="Details" onClick={() => window.open('/booking')} fullWidth />
				</div>
			</div>
		</div>
	);
}
