import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactSession } from 'react-client-session';
import { updateStripeTables } from './helper';
import config from '../../../config';
import { createMembership } from '../../../services/data';

const PaymentDetailsForm = ({ membershipDetails, customerDetails, submitButton }) => {
	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	// Helper for displaying status messages.
	const [messages, _setMessages] = useState();
	const setMessage = (message) => _setMessages(`${message}`);

	// Track payment intent
	const [paymentIntent, setPaymentIntent] = useState();

	// Stripe.js has not loaded yet. Make sure to disable
	// form until Stripe.js has loaded.
	if (!stripe || !elements) {
		return '';
	}

	// When the subscribe-form is submitted:
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (messages) {
			_setMessages();
		}
		if (!ReactSession.get('form_validity')) {
			return false;
		}
		let returnUrl =
			config.REACT_APP_PUBLIC_URL || window.location.protocol + '//' + window.location.hostname;
		returnUrl = returnUrl + '/checkout/complete';

		const confirmPayment = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Payment completion page
				return_url: returnUrl,
				payment_method_data: {
					billing_details: {
						name: customerDetails.name,
						email: customerDetails.email,
					},
				},
			},
			redirect: 'if_required',
		});

		const { error, paymentIntent } = confirmPayment;

		if (error) {
			// show error and collect new card details.
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, customer will be redirected to `return_url`.
			// For some payment methods like iDEAL, customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (
				error.type === 'card_error' ||
				error.type === 'validation_error' ||
				error.type === 'api_error' ||
				error.type === 'invalid_request_error'
			) {
				setMessage(error.message);
			} else {
				console.log(error.message);
			}

			return;
		}
		// const orderID = ReactSession.get('order_ids');
		// const orderId = orderID ? orderID.orderId : null;
		setPaymentIntent(paymentIntent);
		// getPaymentIntentDetails(paymentIntent.id, customerDetails.notes, orderId);
		//getStripeSubscription();
	};

	const addSignupBonusPoints = async (customerId) => {
		//Set bonus points here
		const points = 150;

		return await fetch(`${config.REACT_APP_API_URL}/memberships/update-points/${customerId}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ reward_points: points }),
		});
	};

	if (paymentIntent && paymentIntent.status === 'succeeded') {
		if (membershipDetails) {
			const data = {
				customerDetails,
				membershipDetails,
				paymentIntentId: paymentIntent.id,
			};
			createMembership(data)
				.then((value) => {
					if (membershipDetails.name !== 'Basic Plan') {
						return addSignupBonusPoints(customerDetails.customer_id);
					} else {
						/* No sign up bonus points for basic plan */
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
		updateStripeTables();
		return navigate('/checkout/complete', {
			state: { paymentIntent },
		});
	}

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			{messages && <div id="payment-message">{messages}</div>}
			{submitButton}
		</form>
	);
};

export default PaymentDetailsForm;
