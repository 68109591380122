import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import { useForm } from 'react-hook-form';
import { updateProfile } from '../../services/data';
import showAlert from '../../helpers/alerts';
import './styles.css';
import checkProfileEditData from '../../validations/profileEdit';

const Profile = ({ user, switchEditMode, membershipData }) => {
	const { register, handleSubmit } = useForm();
	const {
		first_name,
		last_name,
		email_address,
		contact_number,
		company,
		occupation,
		user_id,
		address_line_1,
		address_line_2,
		city,
		country,
	} = user;
	const onSubmit = async (data) => {
		const valid = checkProfileEditData(data);
		// const valid="valid";
		let errorMessage = valid;

		if (valid === 'valid') {
			const response = await updateProfile(data);

			if (response.status === 'success') {
				window.location.reload();
				return response.message;
			}

			errorMessage = response.message;
		}

		if (errorMessage) showAlert(errorMessage);
	};

	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					{/* <ProfileImage /> */}
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="p-3 py-5">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h4 className="text-right">Edit Profile Details</h4>
							</div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-md-6 mt-3">
										<label className="labels">First Name</label>
										<input
											type="text"
											className="form-control"
											placeholder="first name"
											defaultValue={first_name}
											{...register('first_name', {
												required: true,
											})}
											required
											readOnly
										/>
									</div>
									<div className="col-md-6 mt-3">
										<label className="labels">Last Name</label>
										<input
											type="text"
											className="form-control"
											placeholder="surname"
											defaultValue={last_name}
											{...register('last_name', {
												required: true,
											})}
											required
											readOnly
										/>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<label className="labels">Email Address</label>
										<input
											type="text"
											className="form-control"
											placeholder="Enter email address"
											defaultValue={email_address}
											{...register('email', {
												required: true,
											})}
											required
										/>
									</div>
									{/* <div className="col-md-12"><label className="labels">Gender</label>
                                        <input type="text" className="form-control" placeholder="Enter email address" defaultValue={gender} {...register("gender", { required: true })} required  />
                                    </div> */}
									<div className="col-md-12 mt-3">
										<label className="labels">Mobile Number</label>
										<input
											type="text"
											className="form-control"
											placeholder="enter phone number"
											defaultValue={contact_number}
											{...register('contact_number', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Company</label>
										<input
											type="text"
											className="form-control"
											placeholder="Company"
											defaultValue={company}
											{...register('company', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Occupation</label>
										<input
											type="text"
											className="form-control"
											placeholder="Occupation"
											defaultValue={occupation}
											{...register('occupation', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Address line 1</label>
										<input
											type="text"
											className="form-control"
											placeholder="Address line 1"
											defaultValue={address_line_1}
											{...register('address_line_1', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Address line 2</label>
										<input
											type="text"
											className="form-control"
											placeholder="Address line 2"
											defaultValue={address_line_2}
											{...register('address_line_2', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">City</label>
										<input
											type="text"
											className="form-control"
											placeholder="City"
											defaultValue={city}
											{...register('city', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Country</label>
										<input
											type="text"
											className="form-control"
											placeholder="Country"
											defaultValue={country}
											{...register('country', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Address line 1</label>
										<input
											type="text"
											className="form-control"
											placeholder="Address line 1"
											defaultValue={address_line_1}
											{...register('address_line_1', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Address line 2</label>
										<input
											type="text"
											className="form-control"
											placeholder="Address line 2"
											defaultValue={address_line_2}
											{...register('address_line_2', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">City</label>
										<input
											type="text"
											className="form-control"
											placeholder="City"
											defaultValue={city}
											{...register('city', {
												required: true,
											})}
											required
										/>
									</div>
									<div className="col-md-12 mt-3">
										<label className="labels">Country</label>
										<input
											type="text"
											className="form-control"
											placeholder="Country"
											defaultValue={country}
											{...register('country', {
												required: true,
											})}
											required
										/>
									</div>
								</div>
								{/* <div className="row mt-3">
                                    <div className="col-md-12">
                                        <label className="labels">Address Line 1</label>
                                        <input type="text" className="form-control" placeholder="enter address line 1" defaultValue={""} />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="labels">Address Line 2</label>
                                        <input type="text" className="form-control" placeholder="enter address line 2" defaultValue="" />
                                    </div>
                                    <div className="col-md-12"><label className="labels">Postcode</label>
                                        <input type="text" className="form-control" placeholder="enter address line 2" defaultValue="" />
                                    </div>
                                    <div className="col-md-12"><label className="labels">State</label>
                                        <input type="text" className="form-control" placeholder="enter address line 2" defaultValue="" />
                                    </div>
                                    <div className="col-md-12"><label className="labels">Area</label>
                                        <input type="text" className="form-control" placeholder="enter address line 2" defaultValue="" />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label className="labels">Country</label>
                                        <input type="text" className="form-control" placeholder="country" defaultValue="" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">State/Region</label>
                                        <input type="text" className="form-control" defaultValue="" placeholder="state" />
                                    </div>
                                </div> */}
								<input
									type="hidden"
									value={user_id}
									{...register('user_id', {
										required: true,
									})}
								/>
								<div className="mt-5 text-center">
									<button className="btn btn-primary profile-button" type="submit">
										Save Profile
									</button>
									<div className="button-divider"></div>
									<button
										className="btn btn-secondary profile-button"
										type="button"
										onClick={() => switchEditMode(false)}
									>
										Cancel
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
