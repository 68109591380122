import LinkElements from './linkElements';

const privateHealthcare = () => {
	const privateHealthcareData = [
		// New links: Wellbeing, Treatments Abroad, Diagnostic Services, Specialists, Gift Cards
		{ link: '/wellbeing', text: 'Wellbeing' },
		{ link: '/international-treatments', text: 'International Treatments' },
		{ link: '/booking', text: 'Diagnostic Services' },
		{ link: '/specialists', text: 'Specialists' },
		{ link: '/gift-cards', text: 'Gift Cards' },
		{ link: '/membership', text: 'Membership' },
		//{ link: '/about', text: 'About' },
	];

	return (
		<>
			<div className="col-xl-3 col-lg-3 col-md-6 ms-auto">
				<LinkElements heading="Private Healthcare" data={privateHealthcareData} />
			</div>
		</>
	);
};

export default privateHealthcare;
