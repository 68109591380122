import React, { useState, useEffect } from 'react';
import Modal from '@/components/Modal';
import './MoreBusinessDetails.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const MoreBusinessDetails = ({ data, onChange, setIsValid }) => {
	const [currentLocations, setCurrentLocations] = useState([{}]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
		const isEntityPro = data.selectedPlan.planType === 'EntityPro';
		const isValid =
			data.moreBusinessDetails.businessDescription.trim() !== '' &&
			data.moreBusinessDetails.businessAddress.trim() !== '' &&
			data.moreBusinessDetails.businessPhoneNumber.trim() !== '' &&
			(!isEntityPro || data.moreBusinessDetails.locations.length > 0);

		setIsValid(isValid);
	}, [data, setIsValid]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		onChange({ moreBusinessDetails: { ...data.moreBusinessDetails, [name]: value } });
	};

	const handleLocationChange = (index, e) => {
		const { name, value } = e.target;
		const updatedLocations = [...currentLocations];
		updatedLocations[index] = { ...updatedLocations[index], [name]: value };
		setCurrentLocations(updatedLocations);
	};

	const addLocationInput = () => {
		setCurrentLocations([...currentLocations, {}]);
	};

	const saveLocations = () => {
		const updatedLocations =
			editingIndex !== null
				? data.moreBusinessDetails.locations.slice()
				: [...data.moreBusinessDetails.locations];
		if (editingIndex !== null) {
			updatedLocations[editingIndex] = currentLocations[0];
		} else {
			updatedLocations.push(...currentLocations);
		}
		onChange({ moreBusinessDetails: { ...data.moreBusinessDetails, locations: updatedLocations } });
		setCurrentLocations([{}]);
		setEditingIndex(null);
		setModalIsOpen(false);
	};

	const openModal = (index = null) => {
		if (index !== null) {
			setCurrentLocations([data.moreBusinessDetails.locations[index]]);
			setEditingIndex(index);
		} else {
			setCurrentLocations([{}]);
			setEditingIndex(null);
		}
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		setEditingIndex(null);
	};

	const locationInputs = currentLocations.map((location, index) => (
		<div key={index} className="location-inputs">
			<div className="location-fields">
				<div>
					<div className="form-field">
						<label>Building Name</label>
						<input
							type="text"
							name="buildingName"
							placeholder="Building Name"
							value={location.buildingName || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>Street</label>
						<input
							type="text"
							name="street"
							placeholder="Street"
							value={location.street || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>City</label>
						<input
							type="text"
							name="city"
							placeholder="City"
							value={location.city || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>County</label>
						<input
							type="text"
							name="county"
							placeholder="County"
							value={location.county || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
				</div>
				<div>
					<div className="form-field">
						<label>Postcode</label>
						<input
							type="text"
							name="postcode"
							placeholder="Postcode"
							value={location.postcode || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>Country</label>
						<input
							type="text"
							name="country"
							placeholder="Country"
							value={location.country || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>Latitude</label>
						<input
							type="text"
							name="latitude"
							placeholder="Latitude"
							value={location.latitude || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
					<div className="form-field">
						<label>Longitude</label>
						<input
							type="text"
							name="longitude"
							placeholder="Longitude"
							value={location.longitude || ''}
							onChange={(e) => handleLocationChange(index, e)}
						/>
					</div>
				</div>
			</div>
		</div>
	));

	return (
		<div className="MoreBusinessDetails">
			<div className="form-field">
				<label>Business Description</label>
				<input
					type="text"
					name="businessDescription"
					placeholder="Business Description"
					value={data.moreBusinessDetails.businessDescription}
					onChange={handleChange}
				/>
			</div>
			<div className="form-field">
				<label>Business Address</label>
				<input
					type="text"
					name="businessAddress"
					placeholder="Business Address"
					value={data.moreBusinessDetails.businessAddress}
					onChange={handleChange}
				/>
			</div>
			<div className="form-field">
				<label>Business Phone Number</label>
				<input
					type="text"
					name="businessPhoneNumber"
					placeholder="Business Phone Number"
					value={data.moreBusinessDetails.businessPhoneNumber}
					onChange={handleChange}
				/>
			</div>
			<div className="form-field">
				<label>Tags</label>
				<input
					type="text"
					name="tags"
					placeholder="Tags"
					value={data.moreBusinessDetails.tags}
					onChange={handleChange}
				/>
			</div>

			{data.selectedPlan && data.selectedPlan.planType === 'EntityPro' && (
				<>
					<div className="form-field">
						<h5>Locations associated with your business</h5>
						<div>
							{data.moreBusinessDetails.locations.length === 0 ? (
								<div>Your locations will show here</div>
							) : (
								<div className="location-cards">
									{data.moreBusinessDetails.locations.map((location, index) => (
										<div key={index} className="location-card">
											<div className="location-data-item">
												<label>Building Name</label>
												<p>{location.buildingName}</p>
											</div>
											<div className="location-data-item">
												<label>Street</label>
												<p>{location.street}</p>
											</div>
											<div className="location-data-item">
												<label>City</label>
												<p>{location.city}</p>
											</div>
											<div className="location-data-item">
												<label>County</label>
												<p>{location.county}</p>
											</div>
											<div className="location-data-item">
												<label>Postcode</label>
												<p>{location.postcode}</p>
											</div>
											<div className="location-data-item">
												<label>Country</label>
												<p>{location.country}</p>
											</div>
											<div className="location-data-item">
												<label>Latitude</label>
												<p>{location.latitude}</p>
											</div>
											<div className="location-data-item">
												<label>Longitude</label>
												<p>{location.longitude}</p>
											</div>

											<div className="businessSignupButton">
												<Button
													label={'Edit'}
													variant="primary"
													onClick={() => openModal(index)}
													fullWidth
												/>
											</div>
										</div>
									))}
								</div>
							)}
							<Button
								label={'Add new location'}
								variant="primary"
								onClick={() => openModal(null)}
								large
							/>
						</div>
					</div>

					<Modal
						content={
							<div>
								<h2>
									{editingIndex !== null
										? `Edit Location ${editingIndex + 1}`
										: 'Create New Location'}
								</h2>
								{locationInputs}
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button variant="border" label={'Close'} onClick={closeModal} />
									<Button variant="primary" label={'Save'} onClick={saveLocations} />
								</div>
							</div>
						}
						isOpen={modalIsOpen}
						onClose={closeModal}
					/>
				</>
			)}
		</div>
	);
};

export default MoreBusinessDetails;
