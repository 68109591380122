import { useEffect, useState } from 'react';
import Card from '../Card';
import ImagingCard from '../ImagingCard';
import EventCard from '../EventCard';
import { CardListProps } from '@/types';
import SirkkaBrowseCard from '../SirkkaBrowseCard';
import SpecialistCard from '../SpecialistCard';

/**
 * @typedef {Object} CardListProps
 * @property {string} type
 * @property {Array} entityData
 * @property {Array} listingData
 * @property {function} onOpenModal
 * @property {function} onCardHover
 * @property {function} onCardLeave
 * @property {function} onTotalDisplayedCards
 * @property {Array} events
 */

export default function CardList({
	type,
	entityData,
	onOpenModal,
	onCardHover,
	onCardLeave,
	onTotalDisplayedCards,
	events,
}: CardListProps) {
	const [renderedCardsCount, setRenderedCardsCount] = useState(0);

	useEffect(() => {
		onTotalDisplayedCards(renderedCardsCount);
	}, [renderedCardsCount, onTotalDisplayedCards]);

	useEffect(() => {
		const dataToRender = type === 'group-testing' ? events : entityData;
		const count = dataToRender ? dataToRender.length : 0;
		setRenderedCardsCount(count);
		onTotalDisplayedCards(count);
	}, [type, entityData, events, onTotalDisplayedCards]);

	const dataToRender = type === 'group-testing' ? events : entityData;

	const renderCards = () => {
		switch (type) {
			case 'group-testing':
				return dataToRender?.map((item, index) => (
					<EventCard
						key={index}
						eventItem={item}
						onOpenModal={onOpenModal}
						onHover={onCardHover}
						onLeave={onCardLeave}
					/>
				));

			case 'imaging':
				return dataToRender?.map((item, index) => (
					<ImagingCard
						key={index}
						entityItem={item}
						type={type}
						onOpenModal={onOpenModal}
						onHover={onCardHover}
						onLeave={onCardLeave}
					/>
				));

			case 'specialists':
				return dataToRender?.map((item, index) => (
					<SpecialistCard
						key={index}
						entityItem={item}
						onHover={onCardHover}
						onLeave={onCardLeave}
					/>
				));
			case 'wellbeing':
			case 'international-treatments':
			case 'mental-health':
				return dataToRender?.map((item, index) =>
					item.entity_with_services?.booking_entity?.booking_entity_id === 1 ? (
						<SirkkaBrowseCard
							key={index}
							entityItem={item}
							onHover={onCardHover}
							onLeave={onCardLeave}
						/>
					) : (
						<Card
							key={index}
							entityItem={item}
							type={type}
							onOpenModal={onOpenModal}
							onHover={onCardHover}
							onLeave={onCardLeave}
						/>
					)
				);
			default:
				return dataToRender?.map((item, index) => (
					<Card
						key={index}
						entityItem={item}
						type={type}
						onOpenModal={onOpenModal}
						onHover={onCardHover}
						onLeave={onCardLeave}
					/>
				));
		}
	};

	return (
		<div className={type === 'imaging' ? 'col-12' : 'col-xl-8 col-lg-8 col-md-12'}>
			<div className="mb-4 me-0 me-md-lg mb-lg-0 me-lg-0">{dataToRender && renderCards()}</div>
		</div>
	);
}
