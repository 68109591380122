import { Link } from 'react-router-dom';

const NewsBox = ({ thumbnail, title, upload_date, overview_text, link }) => {
	return (
		<>
			<article className="postbox post format-image mb-40">
				<div className="postbox__thumb">
					<Link to={`${link}`}>
						<img src={`/img/blog/details/${thumbnail}`} alt="blog img" />
					</Link>
				</div>
				<div className="postbox__text p-50">
					<div className="post-meta mb-15">
						<span>
							<i className="far fa-calendar-check"></i> {upload_date}
						</span>
					</div>
					<h3 className="blog-title">
						<Link to={`${link}`}>{title}</Link>
					</h3>
					<div className="post-text mb-20">
						<p>{overview_text}</p>
					</div>
					<div className="read-more mt-30">
						<Link to={`${link}`} className="primary_btn theme-btn">
							read more
						</Link>
					</div>
				</div>
			</article>
		</>
	);
};

export default NewsBox;
