import checkEmail from './email';

const checkData = (
	{
		nameTitle,
		firstName,
		lastName,
		gender,
		address_line_1,
		address_line_2,
		city,
		postcode,
		company,
		companyRegistration,
		occupation,
		dob,
		phoneNumber,
		email,
		password,
		confirmPassword,
	},
	type
) => {
	// General essential validation
	if (/^[0-9][0-9]*[1-9][0-9]{6,13}$/.test(phoneNumber) === false) {
		return 'Phone Number must contain only numbers and not symbols or spaces';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(firstName) === false) {
		return 'First name can only contain alphabets';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(lastName) === false) {
		return 'Last name can only contain alphabets';
	}

	if (!checkEmail(email)) {
		return 'Email invalid';
	}

	if (password.length < 6) {
		return 'Password must be at least 6 characters';
	}

	if (password !== confirmPassword) {
		return "Passwords don't match.";
	}

	if (!address_line_1 || !postcode) {
		return 'Your address is required.';
	}

	// Validation applicable for personal accounts
	if (type === 'personal') {
		const date = new Date();
		const birthDate = new Date();

		const today = date.toISOString().slice(0, 10);

		date.setFullYear(date.getFullYear() - 125);
		const ancient = date.toISOString().slice(0, 10);

		birthDate.setFullYear(birthDate.getFullYear() - 18);
		const ageLimit = birthDate.toISOString().slice(0, 10);

		if (dob < ancient || dob >= today) {
			return 'Invalid date of birth';
		}

		if (dob > ageLimit) {
			return 'Only 18 years and above can open an account';
		}
	}

	// Validation applicable for business accounts
	if (type === 'business') {
		if (!companyRegistration) {
			return 'Company registration number needed for business account';
		}
	}

	// Validation passed
	return 'valid';
};

const checkDelegateAccountData = ({
	firstName,
	lastName,
	company,
	email,
	password,
	confirmPassword,
}) => {
	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(firstName) === false) {
		return 'First name can only contain alphabets';
	}

	if (/^[A-Za-z']+([ -][A-Za-z']+)*$/.test(lastName) === false) {
		return 'Last name can only contain alphabets';
	}

	if (!checkEmail(email)) {
		return 'Email invalid';
	}

	if (password.length < 6) {
		return 'Password must be at least 6 characters';
	}

	if (password !== confirmPassword) {
		return "Passwords don't match.";
	}

	// Validation passed
	return 'valid';
};

export default checkData;
export { checkDelegateAccountData };
