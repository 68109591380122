import { useSearchParams } from 'react-router-dom';
import { ReactSession } from 'react-client-session';

const Referral = () => {
	const [searchParams] = useSearchParams();

	const referralId = searchParams.get('referral');

	if (referralId) {
		ReactSession.set('referralId', referralId);
	}

	return <></>;
};

export default Referral;
