/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

function Modal({ content, buttonText, onClose, onOpen, onSubmit, disabledStatus }) {
	const handleSubmit = () => {
		onSubmit();
	};

	const handleClose = (e) => {
		if (e.target === e.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		if (onOpen) {
			onOpen();
		}
	}, [onOpen]);

	return (
		<div onClick={handleClose} className={styles.backdrop}>
			<div className={styles.modal}>
				<div className={styles.close} onClick={handleClose}>
					X
				</div>
				<div className={styles.modalInner}>
					{content}
					<div className={styles.next}>
						<a href="#" onClick={handleClose}>
							Cancel
						</a>
						<Button disabled={disabledStatus} onClick={handleSubmit} label={buttonText} />
					</div>
				</div>
			</div>
		</div>
	);
}

Modal.propTypes = {
	service: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		title: PropTypes.string,
		avatar: PropTypes.string,
		category: PropTypes.string,
		subcategory: PropTypes.arrayOf(PropTypes.string),
		attribution: PropTypes.string,
		credentials: PropTypes.arrayOf(PropTypes.string),
		location: PropTypes.arrayOf(PropTypes.string),
		accepted_patient_age: PropTypes.arrayOf(PropTypes.string),
		profile: PropTypes.arrayOf(PropTypes.string),
		url: PropTypes.string,
		icon: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.string),
		virtual: PropTypes.bool,
		physical: PropTypes.bool,
		availability: PropTypes.arrayOf(PropTypes.string),
		first_description: PropTypes.string,
		bullets: PropTypes.arrayOf(PropTypes.string),
		description_continued: PropTypes.arrayOf(PropTypes.string),
		price: PropTypes.number,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onOpen: PropTypes.func,
};

Modal.defaultProps = {
	onOpen: () => {},
};

export default Modal;
