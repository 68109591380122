import React, { useContext, useEffect, useState } from 'react';
import styles from './Checkout.module.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getMembership } from '../HealthTestsAndSpecialists/helpers/getData';
import { ReactSession } from 'react-client-session';
import { BookingContext, Button, CartSummary } from '@sirkka-health/booking-system-ui';
import PaymentForm from '../Checkout/PaymentForm';
import { Link, useNavigate } from 'react-router-dom';

export default function Checkout() {
	const { bookingData, setBookingData, bookingDataTotal } = useContext(BookingContext);
	const [membershipData, setMembershipData] = useState(null);
	const [user, setUser] = useState(ReactSession.get('user') || null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const showSuccess = () => {
		setShowSuccessMessage(true);
	};

	useEffect(() => {
		getMembership(user?.customer_id, setMembershipData);
	}, [user]);

	const navigate = useNavigate();

	useEffect(() => {
		// Redirect user to cart page if not all bookingData items are complete
		if (!bookingData.every((item) => item.booking.completed)) {
			navigate('/cart');
		}
	}, [bookingData]);

	const goToMostRecentPage = () => {
		if (bookingData && bookingData.length > 0) {
			const mostRecentBooking = bookingData[bookingData.length - 1];
			if (mostRecentBooking.booking && mostRecentBooking.booking.bookingType) {
				const bookingTypeUrl = `/${mostRecentBooking.booking.bookingType}`;
				navigate(bookingTypeUrl);
			}
		}
	};

	return (
		<>
			<Header />
			<div className={'container mt-30 mb-30'}>
				{/* Show PaymentForm if showSuccessMessage is false, otherwise show success message */}
				{!showSuccessMessage ? (
					<>
						<div style={{ width: '100%', align: 'center', margin: '0 auto', display: 'block' }}>
							<h1>Checkout</h1>
							<div
								className={`container mt-30 mb-30`}
								style={{
									display: 'flex',
									alignItems: 'start',
									justifyContent: 'space-between',
									gap: '20px',
								}}
							>
								<div style={{ flexBasis: '50%' }}>
									<PaymentForm
										bookingData={bookingData}
										setBookingData={setBookingData}
										bookingDataTotal={bookingDataTotal}
										userData={user}
										onNextStep={showSuccess}
										membershipData={membershipData}
									/>
								</div>
								<div style={{ flexBasis: '50%' }}>
									<CartSummary
										membershipData={membershipData}
										hideRemove
										onPrevStep={goToMostRecentPage}
									/>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="content success-page-cont mt-50 mb-50">
							<div className="container-fluid">
								<div className="row justify-content-center">
									<div className="col-lg-6">
										<div className="card success-card" style={{ border: '0' }}>
											<div className="card-body">
												<div className="success-cont">
													<i className="fas fa-check"></i>
													<h3>Thank you for your order</h3>
													<p>
														You will now receive an email with further instructions and the details
														of your appointment/order. Please make sure to check your spam folder if
														you cannot find our email.
													</p>
													<p>
														If you have any problems, please email us at medsolutions@sirkkaltd.com.
													</p>
													<Link to="/customer-dashboard">
														<Button
															onClick={() => {
																console.log('clicked');
															}}
															variant="primary"
															label="View my dashboard"
															fullWidth
															large
														/>
													</Link>
													<span className={styles.divider}>or</span>
													<Link to="/booking">
														<Button
															onClick={() => {
																console.log('clicked');
															}}
															variant="secondary"
															label="Continue shopping"
															fullWidth
															large
														/>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<Footer />
		</>
	);
}
