import moment from 'moment-timezone';
import bookingObject from './bookingObject';
import calculateTotalAppointmentDuration from './calculateTotalAppointmentDuration';

// Handler for single time format
const handleSingleTime = (appointmentTime, appointmentDate, totalDuration) => {
	const apptTime = appointmentTime.length === 4 ? '0' + appointmentTime : appointmentTime;
	const apptDateTime = `${appointmentDate}T${apptTime}`;
	const dtObject = moment.tz(apptDateTime, 'Europe/London');
	return {
		startDateTime: dtObject.isValid()
			? dtObject.clone().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: null,
		endDateTime: dtObject.isValid()
			? dtObject.clone().add(totalDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: null,
	};
};

// Handler for time range format
const handleTimeRange = (appointmentTime, appointmentDate) => {
	const [startTime, endTime] = appointmentTime.split('-').map((time) => time.trim());
	const startApptTime = startTime.length === 4 ? '0' + startTime : startTime;
	const endApptTime = endTime.length === 4 ? '0' + endTime : endTime;
	const startApptDateTime = `${appointmentDate}T${startApptTime}`;
	const endApptDateTime = `${appointmentDate}T${endApptTime}`;

	const startDtObject = moment.tz(startApptDateTime, 'Europe/London');
	const endDtObject = moment.tz(endApptDateTime, 'Europe/London');

	return {
		startDateTime: startDtObject.isValid()
			? startDtObject.clone().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: null,
		endDateTime: endDtObject.isValid()
			? endDtObject.clone().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: null,
	};
};

const handleAppointmentTime = (appointmentTime, appointmentDate, totalDuration) => {
	if (appointmentTime.includes('-')) {
		return handleTimeRange(appointmentTime, appointmentDate);
	} else {
		return handleSingleTime(appointmentTime, appointmentDate, totalDuration);
	}
};

const appointmentObject = (booking, entity, service, customerDetails, clinician) => {
	const totalDuration = calculateTotalAppointmentDuration();

	const { startDateTime, endDateTime } = handleAppointmentTime(
		booking.appointmentTime,
		booking.appointmentDate,
		totalDuration
	);

	booking['startDateTime'] = startDateTime;
	booking['endDateTime'] = endDateTime;

	// Validate date time
	booking.startDateTime = booking.startDateTime ? booking.startDateTime : null;
	booking.endDateTime = booking.endDateTime ? booking.endDateTime : null;

	const requestBooking = {
		...bookingObject(booking, entity, service, customerDetails, clinician),
	};

	return {
		requestBooking,
		service: service.serviceId,
		staffName: null,
		customerId: customerDetails.customerId,
	};
};

export default appointmentObject;
