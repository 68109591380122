/* eslint-disable no-unused-vars */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactSession } from 'react-client-session';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import config from '../../config';
import { createAppointment, createPostalDelivery } from '../../services/booking';
import { updateStripeTables } from './helper';
import appointmentObject from '../../helpers/appointmentObject';
import postalObject from '../../helpers/postalObject';

const PaymentDetailsForm = ({ customerDetails, submitButton, membershipData, price }) => {
	const { setBookingData, bookingDataTotal } = useContext(BookingContext);
	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	// Helper for displaying status messages.
	const [messages, _setMessages] = useState();
	const setMessage = (message) => _setMessages(`${messages}\n\n${message}`);

	// Track payment intent
	const [paymentIntent, setPaymentIntent] = useState();

	// Stripe.js has not loaded yet. Make sure to disable
	// form until Stripe.js has loaded.
	if (!stripe || !elements) {
		return '';
	}

	// If user is member && uses benefit, update remaining benefits upon checkout success
	const updateMemberBenefits = async (bookingDetails) => {
		let updates = {};

		bookingDetails.forEach((item) => {
			if (item.memberBenefitUsed) {
				if (item.memberBenefitUsed === 'service_discount') {
					updates['service_discount_used'] = (updates['service_discount_used'] || 0) + 1;
				} else if (item.memberBenefitUsed === 'free_gp_consultation') {
					updates['gp_consultation_used'] = (updates['gp_consultation_used'] || 0) + 1;
				}
			}
		});

		// Convert price to reward points
		const reward_points = Math.floor(price / 100);

		// PATCH request for membership benefits
		const patchResponseBenefits = await fetch(
			`${config.REACT_APP_API_URL}/memberships/apply-benefits/${membershipData.membership_id}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(updates),
			}
		);

		if (!patchResponseBenefits.ok) {
			console.error('Error updating membership benefits');
			return false;
		}

		// PATCH request for updating reward points
		const patchResponsePoints = await fetch(
			`${config.REACT_APP_API_URL}/memberships/update-points/${customerDetails.customerId}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ reward_points }),
			}
		);

		if (!patchResponsePoints.ok) {
			console.error('Error updating reward points');
			return false;
		}

		return true;
	};

	// When the subscribe-form is submitted:
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!ReactSession.get('form_validity')) {
			return false;
		}
		let returnUrl = window.location.protocol + '//' + window.location.hostname;
		returnUrl = returnUrl + '/checkout/complete';

		const confirmPayment = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Payment completion page
				return_url: returnUrl,
				payment_method_data: {
					billing_details: {
						name: customerDetails.firstname + ' ' + customerDetails.lastname,
						email: customerDetails.email,
					},
				},
			},
			redirect: 'if_required',
		});

		const { error, paymentIntent } = confirmPayment;

		if (error) {
			// show error and collect new card details.
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, customer will be redirected to `return_url`.
			// For some payment methods like iDEAL, customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (error.type === 'card_error' || error.type === 'validation_error') {
				setMessage(error.message);
			} else {
				setMessage('An unexpected error occurred.');
				console.log(error.message);
			}

			return;
		}
		// const orderID = ReactSession.get('order_ids');
		// const orderId = orderID ? orderID.orderId : null;
		setPaymentIntent(paymentIntent);
		// getPaymentIntentDetails(paymentIntent.id, customerDetails.notes, orderId);
		//getStripeSubscription();
	};

	if (paymentIntent && paymentIntent.status === 'succeeded') {
		const bookingDetails = JSON.parse(localStorage.getItem('bookingData'));
		if (bookingDetails) {
			bookingDetails.forEach((cartItem) => {
				// Handle gift card purchase
				if (cartItem.service.serviceType === 'giftcardPurchase') {
					const giftcardPostBody = {
						customerId: customerDetails.customerId,
						amount: cartItem.service.price,
						recipientName: cartItem.service.giftcardRecipientName,
						recipientEmail: cartItem.service.giftcardRecipientEmail,
						senderName: cartItem.service.giftcardSenderName,
						senderEmail: cartItem.service.giftcardSenderEmail,
						giftMessage: cartItem.service.giftcardMessage,
					};

					// Create gift card
					fetch(`${config.REACT_APP_API_URL}/products/gift-card`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(giftcardPostBody),
					})
						.then((res) => {
							if (res.ok) {
								return res.json();
							} else {
								throw new Error('Network response was not ok.');
							}
						})
						.then((data) => {
							if (data.status === 'success') {
								console.log('Gift card created');
							} else {
								console.error('Gift card creation was not successful:', data.message);
							}
						})
						.catch((err) => {
							console.error('Error creating gift card:', err.message);
						});
					return;
				}

				// Handle postal orders
				if (cartItem.service.serviceType === 'Postal') {
					const postageData = postalObject(cartItem, customerDetails.customerId);
					createPostalDelivery(postageData);
				} else {
					// Handle appointment booking
					let requestData = appointmentObject(
						cartItem.booking,
						cartItem.entity || null,
						cartItem.service,
						customerDetails,
						cartItem.clinician || null
					);
					createAppointment(requestData);
				}
			});
			// updateMemberBenefits(bookingDetails);
			updateStripeTables();
			setBookingData([]);
		}
		return navigate('/checkout/complete', {
			state: { paymentIntent },
		});
	}

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			{messages && <div id="payment-message">{messages}</div>}
			{submitButton}
		</form>
	);
};

export default PaymentDetailsForm;
