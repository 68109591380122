import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import PaymentSuccess from '../../PaymentSuccess/index.js';

const Complete = () => {
	const location = useLocation(null);
	// const stripe = useStripe();

	const urlPaymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
	const [paymentIntent] = useState(location.state?.paymentIntent || urlPaymentIntent);

	const sessionId = location.search.replace('?session_id=', '');
	const [, setSession] = useState({});

	// Helper for displaying status messages.
	const [messages, _setMessages] = useState('');

	const cleanup = () => {
		if (ReactSession.get('membership')) return ReactSession.remove('membership');
		ReactSession.remove('cart');
		ReactSession.remove('form_validity');
		ReactSession.remove('referralId');
		ReactSession.remove('billing_details');
		ReactSession.remove('billing_address');
		localStorage.removeItem('bookingData');
		if (ReactSession.get('booking_collection')) return ReactSession.remove('booking_collection');
	};

	useEffect(() => {
		const fetchSession = async () => {
			const checkoutSession = await fetch('/checkout-session?sessionId=' + sessionId).then((res) =>
				res.json()
			);
			setSession(checkoutSession);
		};

		if (sessionId) fetchSession();
		const setMessage = (message) => _setMessages(`${messages}\n\n${message}`);

		const getStripePaymentIntent = async () => {
			// if(!paymentIntent && paymentIntentClientSecret) {
			//     return await stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
			//         setPaymentIntent(paymentIntent);
			//     });
			// }
			if (!paymentIntent) {
				return (
					<>
						<p>Blank</p>
					</>
				);
			}

			switch (paymentIntent.status) {
				case 'succeeded':
					cleanup();
					setMessage('Payment succeeded!');
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					break;
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.');
					break;
				default:
					setMessage('Something went wrong.');
					break;
			}
		};
		getStripePaymentIntent();
	}, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

	// if (!paymentIntent) {
	//     return (
	//         <>
	//         <p>Blank</p>
	//         </>
	//     );
	// }

	return (
		<div>
			<PaymentSuccess message={messages} />
			{/* <pre>{JSON.stringify(session, null, 2)}</pre> */}
		</div>
	);
};

export default Complete;
