import { Navigate, useLocation } from 'react-router-dom';
import { ReactNode, ElementType, FC } from 'react';
import useAuth from '../../hooks/useAuth';

interface ProtectedRouteProps {
	children?: ReactNode;
	component?: ElementType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, component }) => {
	const { isAuthenticated, user } = useAuth();
	const location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to="/login" replace state={{ from: location }} />;
	}

	const verified = user ? user.set_verified : false;

	if (!verified && location.pathname !== '/verifyaccount') {
		return <Navigate to="/verifyaccount" replace state={{ from: location }} />;
	}

	if (verified && location.pathname === '/verifyaccount') {
		return <Navigate to="/customer-dashboard" replace state={{ from: location }} />;
	}

	if (component) {
		const Component = component;
		return <Component />;
	}

	return <>{children}</>;
};

export default ProtectedRoute;
