import { useEffect, useState } from 'react';
import PaymentDetailsForm from './PaymentDetailsForm';
import { getStripePrice } from './helper';
import { ReactSession } from 'react-client-session';

const Checkout = ({ submitButton, transactionInfo }) => {
	let customerDetails = useState(ReactSession.get('personal_details'));

	if (customerDetails !== undefined && transactionInfo) {
		const customerDetailsObject = {
			firstname: transactionInfo[0],
			lastname: transactionInfo[1],
			email: transactionInfo[2],
			notes: transactionInfo[3],
		};
		customerDetails = customerDetailsObject;
	}

	useEffect(() => {
		getStripePrice();
	}, []);

	return (
		<div className="price-list">
			<PaymentDetailsForm customerDetails={customerDetails} submitButton={submitButton} />
		</div>
	);
};

export default Checkout;
