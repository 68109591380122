import React from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

const NewPatientModal = ({ showAddModal, onClose, newPatient, onNewPatientChange, onSubmit }) => {
	if (!showAddModal) return null;

	if (showAddModal)
		return (
			<div
				className="modal"
				style={{
					display: 'block',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					cursor: 'pointer',
				}}
				onClick={onClose}
			>
				<div
					className="modal-content"
					style={{
						backgroundColor: 'white',
						padding: '30px',
						margin: '20px auto',
						width: '100%',
						maxWidth: '600px',
					}}
					onClick={(e) => e.stopPropagation()}
				>
					<span className="close" onClick={onClose}>
						&times;
					</span>
					<h2>Create new patient record</h2>
					<form onSubmit={onSubmit}>
						<div>
							<label>
								Title <span>*</span>
							</label>
							<select name="nameTitle" value={newPatient.nameTitle} onChange={onNewPatientChange}>
								<option value="" disabled>
									Select Title
								</option>
								<option value="Mr">Mr</option>
								<option value="Mrs">Mrs</option>
								<option value="Miss">Miss</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div>
							<label>
								First Name <span>*</span>
							</label>
							<input
								required
								type="text"
								name="firstName"
								value={newPatient.firstName}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Last Name <span>*</span>
							</label>
							<input
								required
								type="text"
								name="lastName"
								value={newPatient.lastName}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Gender <span>*</span>
							</label>

							<select name="gender" value={newPatient.gender} onChange={onNewPatientChange}>
								<option value="" disabled>
									Select Gender
								</option>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div>
							<label>
								Date of Birth <span>*</span>
							</label>
							<input
								required
								type="date"
								name="dateOfBirth"
								value={newPatient.dateOfBirth}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Phone Number <span>*</span>
							</label>
							<input
								required
								type="tel"
								name="phoneNumber"
								value={newPatient.phoneNumber}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>Company</label>
							<input
								type="text"
								name="company"
								value={newPatient.company}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>Occupation</label>
							<input
								type="text"
								name="occupation"
								value={newPatient.occupation}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Address Line 1 <span>*</span>
							</label>
							<input
								required
								type="text"
								name="address_line_1"
								value={newPatient.address_line_1}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								City <span>*</span>
							</label>
							<input
								required
								type="text"
								name="city"
								value={newPatient.city}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Postcode <span>*</span>
							</label>
							<input
								required
								type="text"
								name="postcode"
								value={newPatient.postcode}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Country <span>*</span>
							</label>
							<input
								required
								type="text"
								name="country"
								value={newPatient.country}
								onChange={onNewPatientChange}
							/>
						</div>
						<div>
							<label>
								Address Type <span>*</span>
							</label>
							<select
								required
								name="address_type"
								value={newPatient.address_type}
								onChange={onNewPatientChange}
							>
								<option value="" disabled>
									Select address type
								</option>
								<option value="Home">Home</option>
								<option value="Work">Work</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div>
							<label>
								Email Address <span>*</span>
							</label>
							<input
								required
								type="email"
								name="emailAddress"
								value={newPatient.emailAddress}
								onChange={onNewPatientChange}
							/>
						</div>
						<div className="mt-3">
							<Button label="Add patient" type={'submit'} fullWidth large />
						</div>
					</form>
				</div>
			</div>
		);
};

export default NewPatientModal;
