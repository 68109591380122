import React from 'react';

const Completion = ({ data }) => {
	return (
		<div>
			<p>Thank you for registering with Sirkka! </p>
			<p>
				Your information will now be verified by our team. Once approved, you will receive an email
				notification and your business will be live on our website. If we need more information from
				you, we will contact you via email or a phone call.
			</p>
			<p>This usually takes 1-2 business days.</p>
			<p>Don't forget to check your spam folder if you don't see our email after this time.</p>
		</div>
	);
};

export default Completion;
