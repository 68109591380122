const transformLocationCategoryData = (locationCategoryArray) => {
	if (!locationCategoryArray || !Array.isArray(locationCategoryArray)) return [];

	const category = {
		category_id: 1,
		category_name: 'Location',
		parent_id: null,
		parent_category: null,
	};

	const subcategories = locationCategoryArray.map((locationCategory, index) => ({
		category: {
			category_id: index + 1,
			category_name: locationCategory,
			parent_id: 1,
			parent_category: 'Location',
		},
		subcategories: [],
	}));

	return [{ category, subcategories }];
};

export default transformLocationCategoryData;
