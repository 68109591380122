import { Link } from 'react-router-dom';
import styles from './subscriptionPlan.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const SubscriptionPlan = ({ membershipData }) => {
	let planName = 'Free Plan';
	let renewalInfo = (
		<Link to="/membership" className={styles.link}>
			Upgrade to a membership plan
		</Link>
	);

	if (membershipData.membership_type) {
		planName = membershipData.membership_type;
		const renewalDate = new Date(membershipData.membership_end).toLocaleDateString();
		renewalInfo = <>Renews {renewalDate}</>;
	}

	return (
		<div className={styles.subscriptionPlan}>
			<div className={styles.cardContent}>
				<h3>MY SUBSCRIPTION</h3>
				<h2>{planName}</h2>
				<h4>{renewalInfo}</h4>
			</div>

			<a href="/membership" className={styles.secondaryCta}>
				View your member benefits
			</a>
			<a href="/membership" className={styles.secondaryCta}>
				<Button variant="primary" label="Upgrade & Change Membership" fullWidth />
			</a>
		</div>
	);
};

export default SubscriptionPlan;
