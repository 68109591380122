import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';

const ProfileDetails = ({ user, membershipData, switchEditMode }) => {
	const {
		first_name,
		last_name,
		gender,
		email_address,
		contact_number,
		date_of_birth,
		company,
		occupation,
		address_line_1,
		address_line_2,
		city,
		postcode,
		country,
	} = user;
	const dateOfBirth = new Date(date_of_birth);
	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="card-body ">
							<div className="card card-table mb-0">
								<div className="card-body">
									<div className="table-responsive">
										<div className="profile-heading">
											<h2 className="profile-title">Profile</h2>
											<button className="profile-edit" onClick={() => switchEditMode(true)}>
												<span className="profile-edit__title">Edit</span>
												<i className="fas fa-pencil profile-edit__icon"></i>
											</button>
										</div>
										<table className="table table-hover table-center mb-0">
											<tbody>
												{(first_name || last_name) && (
													<tr>
														<td>
															<b>Name</b>
														</td>
														<td>
															{first_name} {last_name}
														</td>
													</tr>
												)}

												{gender && (
													<tr>
														<td>
															<b>Gender</b>
														</td>
														<td>{gender}</td>
														<td></td>
													</tr>
												)}

												{date_of_birth && (
													<tr>
														<td>
															<b>Date of Birth</b>
														</td>
														<td>{dateOfBirth.toLocaleDateString()}</td>
														<td></td>
													</tr>
												)}

												{email_address && (
													<tr>
														<td>
															<b>Email</b>
														</td>
														<td>{email_address}</td>
														<td></td>
													</tr>
												)}

												{contact_number && (
													<tr>
														<td>
															<b>Phone Number</b>
														</td>
														<td>{contact_number}</td>
														<td></td>
													</tr>
												)}

												{/* <tr>
                                              <td><b>Address</b></td>
                                              <td>75 somehwere road</td>
                                              <td>EN4 6HG</td>
                                            </tr> */}

												{company && (
													<tr>
														<td>
															<b>Company</b>
														</td>
														<td>{company}</td>
														<td></td>
													</tr>
												)}

												{occupation && (
													<tr>
														<td>
															<b>Occupation</b>
														</td>
														<td>{occupation}</td>
														<td></td>
													</tr>
												)}

												{address_line_1 && (
													<tr>
														<td>
															<b>Address line 1</b>
														</td>
														<td>{address_line_1}</td>
														<td></td>
													</tr>
												)}

												{address_line_2 && (
													<tr>
														<td>
															<b>Address line 2</b>
														</td>
														<td>{address_line_2}</td>
														<td></td>
													</tr>
												)}

												{city && (
													<tr>
														<td>
															<b>City</b>
														</td>
														<td>{city}</td>
														<td></td>
													</tr>
												)}

												{postcode && (
													<tr>
														<td>
															<b>Postcode</b>
														</td>
														<td>{postcode}</td>
														<td></td>
													</tr>
												)}

												{country && (
													<tr>
														<td>
															<b>Country</b>
														</td>
														<td>{country}</td>
														<td></td>
													</tr>
												)}

												{/* <tr>
                                              <td><b>Membership Type</b></td>
                                              <td>Basic</td>
                                              <td></td>
                                            </tr> */}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileDetails;
