import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ProductDetails from './ProductDetails';
import productData from '../../data/products.json';
import formatHeading from '../../helpers/format-heading';

const KeyHeading = ({ heading }) => {
	return (
		<div className="container">
			<div className="pt-30 pb-20">
				<div className="section-text pos-rel">
					<h1 className="blue-color text-up-case">{heading}</h1>
				</div>
			</div>
		</div>
	);
};

const Product = () => {
	return (
		<>
			<Header title="Products" />
			{Object.keys(productData).map((heading) => {
				return (
					<>
						<KeyHeading key={heading} heading={formatHeading(heading)} />
						{productData[heading].map((product, index) => (
							<ProductDetails product={product} key={index} />
						))}
					</>
				);
			})}
			<Footer />
		</>
	);
};

export default Product;
