import React from 'react';
import styles from './LandingPage.module.scss';
import { Button } from '@sirkka-health/booking-system-ui';

const LandingPage = () => {
	return (
		<>
			<div style={{ background: '#fff' }} className={styles.quickSelection}>
				<div className={`${styles.container} container d-flex flex-wrap justify-content-around`}>
					<div className={`${styles.column} col-lg-3 col-md-6`}>
						<h2>I want a health test or a scan</h2>
						<p>Get results quickly and with in-depth feedback from a medical practitioner</p>
						<img src="/img/home/1-health-test.png" alt="Health test" />
						<a href="/booking/London" className={styles.secondaryCta}>
							<Button variant="primary" label="Book a test" fullWidth />
						</a>
					</div>
					<div className={`${styles.column} col-lg-3 col-md-6`}>
						<h2>I want to see a specialist quickly</h2>
						<p>With or without insurance - Say goodbye to waiting time</p>
						<p>(Exclusive for Sirkka members)</p>
						<img src="/img/home/2-specialist.png" alt="Specialist" />
						<a
							href="/booking/London?specialist=Bowel%2CBreast%2CGenetics%2CGynaecology%2CHead+neck+and+thyroid%2CLung%2CCardiology%2CEndocrinology%2CCounsellor%2CPsychologist%2CNeurology%2FNeurosurgery"
							className={styles.secondaryCta}
						>
							<Button variant="primary" label="Make an appointment" fullWidth />
						</a>
					</div>
					<div className={`${styles.column} col-lg-3 col-md-6`}>
						<h2>I plan to go abroad for treatment</h2>
						<p>Arrange all pre and post op appointments locally to you</p>
						<img src="/img/home/3-abroad.png" alt="Abroad treatment" />
						<a href="/international-treatments" className={styles.secondaryCta}>
							<Button variant="primary" label="Make an appointment" fullWidth />
						</a>
					</div>
					<div className={`${styles.column} col-lg-3 col-md-6`}>
						<h2>I am a business customer or business partner</h2>
						<p>Get medical assistance for a large group or work with us</p>
						<img src="/img/home/4-business.png" alt="Business customer" />
						<a href="/business" className={styles.secondaryCta}>
							<Button variant="primary" label="For Business" fullWidth />
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default LandingPage;
