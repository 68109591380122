import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import config from '../../../../config';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Button } from '@sirkka-health/booking-system-ui';
import { DatePicker } from '@sirkka-health/booking-system-ui';
import { TimePicker } from '@sirkka-health/booking-system-ui';
import styles from './AllAppointments.module.scss';
import Card from '../Card';
import { postRequest } from '../../../../services/api';
import Modal from '../Modal/Modal';

const NoAppointmentMessage = () => (
	<div>
		<h3>All appointments</h3>
		<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
			You have no upcoming appointments
		</div>
	</div>
);

const fetchData = async (setServicesData) => {
	try {
		const response = await fetch(`${config.REACT_APP_API_URL}/bookings/service`);
		const data = await response.json();
		setServicesData(data);
	} catch (error) {
		console.error('Error fetching data:', error);
	}
};

const Map = ({ address }) => {
	const [location, setLocation] = useState(null);

	Geocode.setApiKey(`${config.REACT_APP_GOOGLE_GEOCODING_API_KEY}`);
	Geocode.setLanguage('en');
	Geocode.setRegion('gb');
	Geocode.enableDebug();

	useEffect(() => {
		// Extract the last part of the address (postcode) after the last comma
		const postcode = address.split(',').pop().trim();

		Geocode.fromAddress(`${postcode}`).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				setLocation({ lat, lng });
				console.log(lat, lng);
			},
			(error) => {
				console.error(error);
			}
		);
	}, [address]);

	// useEffect(() => {
	// 	// Extract the last part of the address (postcode) after the last comma
	// 	const postcode = address.split(',').pop().trim();

	// 	fetch(
	// 		`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
	// 			postcode
	// 		)}&key=${config.REACT_APP_GOOGLE_MAPS_API_KEY}`
	// 	)
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			if (data.results && data.results.length > 0 && data.results[0].geometry) {
	// 				const { lat, lng } = data.results[0].geometry.location;
	// 				setLocation({ lat, lng });
	// 			} else {
	// 				console.error('No results found for this address');
	// 			}
	// 		})
	// 		.catch((error) => console.error(error));
	// }, [address]);

	return location ? (
		<LoadScript googleMapsApiKey={config.REACT_APP_GOOGLE_MAPS_API_KEY}>
			<GoogleMap
				mapContainerStyle={{ width: '100%', height: '100%' }}
				center={location}
				zoom={15}
				options={{
					disableDefaultUI: true,
					clickableIcons: false,
				}}
			>
				<Marker position={location} />
			</GoogleMap>
		</LoadScript>
	) : null;
};

const AllAppointments = ({ appointmentsData }) => {
	const [servicesData, setServicesData] = useState(null);
	const [availabilityData, setAvailabilityData] = useState({});
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [appointmentToCancelId, setAppointmentToCancelId] = useState(null);
	const [showRescheduleModal, setShowRescheduleModal] = useState(false);
	const [modalBtnDisabled, setModalBtnDisabled] = useState(true);
	const [bookingDateTime, setBookingDateTime] = useState({});
	const [selectedDate, setSelectedDate] = useState('');
	const [clearSelectedTime, setClearSelectedTime] = useState(false);
	const [locationCategoryId, setLocationCategoryId] = useState(1);
	const [appointmentServiceName, setAppointmentServiceName] = useState('');
	const [appointmentId, setAppointmentId] = useState('');
	const [rescheduleModalStep, setRescheduleModalStep] = useState(1);
	const [cancelModalStep, setCancelModalStep] = useState(1);

	useEffect(() => {
		fetchData(setServicesData);
	}, []);

	//Date and Time availability

	useEffect(() => {
		const getFormattedDate = (daysToAdd) => {
			const today = new Date();
			today.setDate(today.getDate() + daysToAdd);
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0');
			const day = String(today.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		};

		const thirdDay = getFormattedDate(2);

		fetch(`${config.REACT_APP_API_URL}/bookings/availability/${locationCategoryId}`)
			.then((response) => response.json())
			.then((data) => {
				let filteredData;
				// Filter out dates before the third day
				filteredData = Object.fromEntries(
					Object.entries(data).filter(([date]) => new Date(date) >= new Date(thirdDay))
				);

				setAvailabilityData(filteredData);

				// Set selected date to the first date from the third day onwards
				setSelectedDate(Object.keys(filteredData)[0]);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error('Error fetching location data: ', error);
			});
	}, [locationCategoryId]);

	//Make the date and time from form output into the right format for the API call
	const formatDateAndTime = (appointmentDate, appointmentTime) => {
		const dateTimeString = `${appointmentDate}T${appointmentTime}:00.000Z`;
		const dateTime = new Date(dateTimeString);
		const formattedDateTime = dateTime.toISOString();
		return formattedDateTime;
	};

	//Date and Time input change
	const handleInputChange = (input) => {
		if (!input) {
			return false;
		}

		const { name, value, target } = input;

		if (target) {
			if (target.name === 'appointmentDate') {
				setSelectedDate(target.value);
				setBookingDateTime({
					...bookingDateTime,
					appointmentDate: target.value,
					appointmentTime: '',
				});
				setClearSelectedTime(true);
			} else if (target.name === 'appointmentTime') {
				const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
				if (!value.match(timeRegex)) {
					console.error('Invalid time format. Please use HH:mm format.');
					return false;
				}
				setBookingDateTime({
					...bookingDateTime,
					appointmentTime: value,
				});
			} else {
				setBookingDateTime({
					...bookingDateTime,
					[target.name]: target.value,
				});
			}
		} else {
			setBookingDateTime({
				...bookingDateTime,
				[name]: value,
			});

			if (name === 'appointmentDate') {
				setSelectedDate(value);
				setBookingDateTime({
					...bookingDateTime,
					appointmentDate: value,
					appointmentTime: '',
				});
				setClearSelectedTime(true);
			}
		}
		return true;
	};

	const handleModalOpen = (location_category_id, service_name, appointment_id) => {
		setLocationCategoryId(location_category_id);
		setAppointmentServiceName(service_name);
		setAppointmentId(appointment_id);
		setShowRescheduleModal(true);
	};

	const handleModalClose = () => {
		setRescheduleModalStep(1);
		setShowRescheduleModal(false);
	};

	const handleModalSubmit = () => {
		if (rescheduleModalStep === 1) {
			setRescheduleModalStep(2);
		} else if (rescheduleModalStep === 2) {
			rescheduleAppointment(appointmentId);
			setShowRescheduleModal(false);
		}
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				handleModalClose();
			}
		};

		if (showRescheduleModal) {
			document.addEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [showRescheduleModal, handleModalClose]);

	useEffect(() => {
		if (bookingDateTime.appointmentTime && bookingDateTime.appointmentTime.length > 2) {
			setModalBtnDisabled(false);
		} else {
			setModalBtnDisabled(true);
		}
	}, [bookingDateTime.appointmentTime]);

	const futureAppointments = appointmentsData?.filter((appointment) => {
		const appointmentDate = new Date(appointment.appt_start_dt);
		return appointmentDate >= new Date();
	});

	futureAppointments?.sort((a, b) => new Date(a.appt_start_dt) - new Date(b.appt_start_dt));

	if (!appointmentsData || !servicesData) {
		return (
			<div className={styles.upcomingAppointment}>
				<NoAppointmentMessage />
			</div>
		);
	}

	if (
		!futureAppointments ||
		futureAppointments.filter((appointment) => appointment.booking_status === 'booked').length === 0
	) {
		return (
			<div className={styles.upcomingAppointment}>
				<NoAppointmentMessage />
			</div>
		);
	}

	const cancelAppointment = async (appointment_id, service_name) => {
		setAppointmentToCancelId(appointment_id);
		setShowCancelModal(true);
		setAppointmentServiceName(service_name);
		console.log('name', service_name);

		if (cancelModalStep === 2) {
			const response = await fetch(
				`${config.REACT_APP_API_URL}/bookings/appointments/${appointment_id}`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						booking_status: 'cancelled',
						other: `cancelled by user in customer portal on ${new Date()
							.toISOString()
							.slice(0, 10)}`,
					}),
				}
			);

			if (!response.ok) {
				console.error('Error cancelling the appointment');
			}

			if (response.ok) {
				window.location.reload();
			}
		}
		setCancelModalStep(2);
	};

	const rescheduleAppointment = async (appointment_id) => {
		//STEP 1 - Get current appointment
		const response = await fetch(
			`${config.REACT_APP_API_URL}/bookings/appointments/${appointment_id}`
		);
		const originalAppointmentData = await response.json();

		if (response.ok) {
			const customerId = originalAppointmentData.customer_id;

			const newStartDateTime = `${bookingDateTime.appointmentDate}T${bookingDateTime.appointmentTime}:00.000Z`;
			const newEndDateTime = new Date(
				new Date(newStartDateTime).getTime() + 30 * 60000
			).toISOString();

			const booking = {
				'@odata.type': '#microsoft.graph.bookingAppointment',
				selfServiceAppointmentId: '',
				additionalInformation: '',
				isLocationOnline: false,
				customerTimeZone: '',
				serviceId: originalAppointmentData.service_id_booking,
				serviceName: originalAppointmentData.service_name,
				duration: 'PT15M',
				preBuffer: 'PT0S',
				postBuffer: 'PT0S',
				priceType: 'fixedPrice',
				serviceNotes: originalAppointmentData.other || '',
				optOutOfCustomerEmail: false,
				staffMemberIds: [],
				smsNotificationsEnabled: false,
				anonymousJoinWebUrl: null,
				maximumAttendeesCount: 5,
				filledAttendeesCount: 1,
				startDateTime: {
					dateTime: newStartDateTime,
					timeZone: 'UTC',
				},
				endDateTime: {
					dateTime: newEndDateTime,
					timeZone: 'UTC',
				},
				serviceLocation: {
					displayName: originalAppointmentData.appt_location,
					locationEmailAddress: '',
					locationUri: '',
					locationType: 'default',
					uniqueId: null,
					uniqueIdType: null,
					address: {
						street: originalAppointmentData.appt_location,
						city: '',
						state: '',
						countryOrRegion: '',
						postalCode: '',
					},
					coordinates: {
						altitude: 'NaN',
						latitude: 'NaN',
						longitude: 'NaN',
						accuracy: 'NaN',
						altitudeAccuracy: 'NaN',
					},
				},
				reminders: [
					{
						offset: 'P2D',
						recipients: 'customer',
					},
				],
				customers: [
					{
						'@odata.type': '#microsoft.graph.bookingCustomerInformation',
						name: originalAppointmentData.booking_name,
						emailAddress: originalAppointmentData.booking_email,
						notes: originalAppointmentData.other,
					},
				],
			};

			// create a new booking
			let newAppointmentData;

			if (booking === null) {
				console.log('Booking is null. Cannot proceed with appointment creation.');
			} else {
				newAppointmentData = await postRequest('/bookings/appointments', {
					requestBooking: booking,
					customerId,
				});
			}

			if (newAppointmentData) {
				//STEP 3 - Update the original appointment to 'rescheduled' status
				const patchResponse = await fetch(
					`${config.REACT_APP_API_URL}/bookings/appointments/${appointment_id}`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							booking_status: 'rescheduled',
							other: `rescheduled to new booking ID ${newAppointmentData.appointments.appointment_id}`,
						}),
					}
				);

				if (!patchResponse.ok) {
					console.error('Error updating the original appointment');
				}

				if (patchResponse.ok) {
					window.location.reload();
				}
			}
		}
	};

	//Order by start date
	const sortedFutureAppointments = futureAppointments
		.filter((appointment) => appointment.booking_status === 'booked')
		.sort((a, b) => new Date(a.appt_start_dt) - new Date(b.appt_start_dt));

	return (
		<div className="d-flex flex-column">
			<h3>Upcoming appointments</h3>
			{sortedFutureAppointments.map((appointment) => {
				let serviceIcon = servicesData?.find(
					(service) => service.service_code === appointment.service_id_booking
				)?.service_icon;
				const formattedDate = new Date(appointment.appt_start_dt).toLocaleDateString();
				const formattedTime = new Date(appointment.appt_start_dt).toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				});

				return (
					<Card
						key={appointment.appointment_id}
						contents={
							<div className={styles.AllAppointments}>
								<div className={styles.appointmentItem}>
									<ul>
										<li>
											<img
												src={`/img/icon/${serviceIcon}.png`}
												alt={appointment.service_name}
												className={styles.avatar}
											/>
										</li>
										<li>
											<span>Appointment</span>
											<p className={styles.appointmentData}>{appointment.service_name}</p>
										</li>
										<li>
											<span>Patient name</span>
											<p className={styles.appointmentData}>{appointment.booking_name}</p>
										</li>
									</ul>
									<ul>
										<li>
											<span>Time</span>
											<p className={styles.appointmentData}>{formattedTime}</p>
										</li>
										<li>
											<span>Date</span>
											<p className={styles.appointmentData}> {formattedDate}</p>
										</li>
										<li>
											<span>Location</span>
											<p className={styles.appointmentData}>{appointment.appt_location}</p>
										</li>
									</ul>
									<ul>
										<li style={{ width: '100%', height: '100%' }}>
											<Map address={appointment.appt_location} />
										</li>
									</ul>
								</div>
								{/*<div className={styles.appointmentActions}>*/}
								{/*	<a*/}
								{/*		href="#"*/}
								{/*		onClick={() =>*/}
								{/*			cancelAppointment(appointment.appointment_id, appointment.service_name)*/}
								{/*		}*/}
								{/*	>*/}
								{/*		Cancel appointment*/}
								{/*	</a>*/}
								{/*	<Button*/}
								{/*		variant="primary"*/}
								{/*		label="Reschedule appointment"*/}
								{/*		onClick={() =>*/}
								{/*			handleModalOpen(*/}
								{/*				appointment.location_category_id,*/}
								{/*				appointment.service_name,*/}
								{/*				appointment.appointment_id*/}
								{/*			)*/}
								{/*		}*/}
								{/*		// onClick={() => rescheduleAppointment(appointment.appointment_id)}*/}
								{/*	/>*/}
								{/*</div>*/}
							</div>
						}
						fullWidth
					/>
				);
			})}

			{showRescheduleModal && (
				<Modal
					content={
						rescheduleModalStep === 1 ? (
							<div className="mb-5">
								<DatePicker
									name="appointmentDate"
									onDateChange={handleInputChange}
									availabilityData={availabilityData}
									label="Choose a date and time"
								/>
								<TimePicker
									name="appointmentTime"
									onTimeChange={handleInputChange}
									label="Available times"
									selectedDate={selectedDate}
									availabilityData={availabilityData}
									clearSelectedTime={clearSelectedTime}
									setClearSelectedTime={setClearSelectedTime}
								/>
							</div>
						) : (
							<>
								<h3 className="mb-5">Confirm appointment reschedule</h3>

								<div className={styles.appointmentItem}>
									<p>Please confirm you are happy to continue with your new appointment below:</p>
									<ul>
										<li>
											<span>Appointment</span>
											<p style={{ fontWeight: '500' }}>{appointmentServiceName}</p>
										</li>
										<li>
											<span>Date</span>
											<p style={{ fontWeight: '500' }}>
												{' '}
												{new Date(bookingDateTime.appointmentDate).toLocaleDateString()}
											</p>
										</li>
										<li>
											<span>Time</span>
											<p style={{ fontWeight: '500' }}>{bookingDateTime.appointmentTime}</p>
										</li>
									</ul>
								</div>
							</>
						)
					}
					buttonText="Reschedule appointment"
					onClose={handleModalClose}
					onSubmit={handleModalSubmit}
					disabledStatus={modalBtnDisabled}
				/>
			)}
			{showCancelModal && (
				<Modal
					content={
						<div>
							<h3 className="mb-5">Confirm appointment cancellation</h3>
							<div className={`${styles.modalInner} mb-5`}>
								Are you sure you wish to cancel your <b>{appointmentServiceName}</b> appointment?
							</div>
						</div>
					}
					onClose={() => {
						setShowCancelModal(false);
						setCancelModalStep(1);
					}}
					onSubmit={() => {
						cancelAppointment(appointmentToCancelId, appointmentServiceName);
						setShowCancelModal(false);
					}}
					buttonText="Cancel appointment"
				/>
			)}
		</div>
	);
};

export default React.memo(AllAppointments);
