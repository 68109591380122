import styles from './householdBalance.module.scss';

const HouseholdBalance = ({ members, membershipData }) => {
	const totalPoints = () => {
		// If no members in the household, return reward points from membershipData or 0 if not a valid number
		if (members.length === 0) {
			let points = Number(membershipData.reward_points);
			return isNaN(points) ? 0 : points;
		}

		// Calculate total reward points for all household members or 0 if not a valid number
		return members.reduce((sum, member) => {
			let points = Number(member.reward_points);
			return sum + (isNaN(points) ? 0 : points);
		}, 0);
	};

	return (
		<div className={styles.householdBalance}>
			<div className={styles.cardContent}>
				<div>
					<h3>HOUSEHOLD BALANCE</h3>
					<h2>{totalPoints()} Points</h2>
				</div>
			</div>
		</div>
	);
};

export default HouseholdBalance;
