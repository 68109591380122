import React from 'react';

export default function PaymentSuccess() {
	return (
		<div>
			<p>
				On the next steps we will continue to set up your business profile by adding your business
				locations and add your services which will be shown publicly on the website. The next steps
				will take between 5 to 10 minutes to complete.
			</p>

			<p>Press continue to complete your account setup.</p>
		</div>
	);
}
