import style from './loader.module.scss';

export default function Loader() {
	return (
		<span className={style.loaderContainer}>
			<span className={style.loader}></span>
			<span className={style.background}></span>
		</span>
	);
}
