import productData from '../data/products.json';
import faqData from '../data/portal-faqs.json';
import calendarUrls from '../data/calendar-urls.json';
import membershipsData from '../data/memberships.json';
import { deleteRequest, getRequest, postRequest, postRequestFormspree } from './api';
import { ReactSession } from 'react-client-session';
import imagingData from '../data/imagingData.json';
import pageData from '../data/pageData.json';
import eventFaqData from '../data/event-faqs.json';
import { CheckoutData, FaqData, FaqDataMap, Product, ProductData } from '../types';

const faqDataMap: FaqDataMap = faqData as FaqDataMap;

const getJsonData = (type: string): any => {
	const dataMap: { [key: string]: any } = {
		imaging: imagingData,
		pageData,
		faqData: eventFaqData,
	};

	return dataMap[type];
};

const checkLogin = async (data: any) => {
	return await postRequest('/auth/login', data);
};

const sendContact = async (data: any) => {
	return await postRequestFormspree('https://formspree.io/f/mrgwbnoo', data);
};

const requestResetEmail = async (email: string) => {
	return await postRequest('/auth/reset/email', { email });
};

const checkPasswordResetToken = async (token: string) => {
	return await postRequest('/auth/reset/token', { token });
};

const setNewPassword = async (token: string | undefined, password: string) => {
	return await postRequest('/auth/reset/password', { token, password });
};

const sendCheckoutData = async (data: CheckoutData) => {
	return await postRequest('/payments/submit', data);
};

const createAccount = async (data: any) => {
	return await postRequest('/auth/signup', data);
};

const updateProfile = async (data: any) => {
	const updated = await postRequest('/user/profile', data);

	if (updated.status === 'success') {
		const user = ReactSession.get('user');
		const newUser = { ...user, ...data };
		ReactSession.set('user', newUser);
	}

	return updated;
};

const getAllAppointments = async () => {
	return await getRequest('/appointments/all');
};

const getMemberships = () => {
	return membershipsData;
};

const getUserMembership = async (customerId: string) => {
	return await getRequest('/memberships', { userID: customerId });
};

const getAllProducts = (): Product[] => {
	let allProducts: Product[] = [];
	const theData = productData as unknown as ProductData;

	Object.keys(productData).forEach((productGrouping) => {
		const productsInGroup = theData[productGrouping];
		productsInGroup.forEach((product: Product) => (product.grouping = productGrouping));
		allProducts = [...allProducts, ...productsInGroup];
	});

	return allProducts;
};

const getProductById = (id: string | undefined): Product | undefined => {
	const allProducts = getAllProducts();
	return allProducts.find((eachProduct) => eachProduct.id === id);
};

const getCategoryOfProduct = (productId: string) => {
	const product = getProductById(productId);
	return product ? product.grouping : undefined;
};

const getAllFaqs = () => {
	return faqData;
};

const getFaqsForProduct = (productId: string): FaqData[] | null => {
	const productCategory = getCategoryOfProduct(productId);
	return productCategory ? faqDataMap[`${productCategory}_faqs`] || null : null;
};

const getLocationCalendar = (location: string): any => {
	return calendarUrls[location as keyof typeof calendarUrls];
};

const getServiceCalendar = (location = 'london', service = 'default') => {
	const locationCalendar = getLocationCalendar(location);
	return locationCalendar[service];
};

const recordBookingAttempt = async (
	location: string | undefined,
	service = 'unknown',
	url: string
) => {
	const timeNow = Date.now();
	return await postRequest('/bookings/attempt', {
		location,
		service,
		time: timeNow,
		url,
	});
};

const getUserAppointments = async (email: string) => {
	const { appointments } = await getRequest('/appointments', { email });
	return appointments.length ? appointments : null;
};

const getTasks = async (userID: string) => {
	// return await getRequest("/user/tasks", {userID});
};

const getNotifications = async (userID: string) => {
	// return await getRequest("/notifications", {userID});
};

const getDocuments = async (userID: string) => {
	const { documents } = await getRequest('/documents', { userID });
	return documents.length ? documents : null;
};

const getTransactions = async (userID: string) => {
	const { transactions } = await getRequest('/payments/transactions', { userID });
	return transactions && transactions.length ? transactions : null;
};

const queryCustomer = async (data: any) => {
	return await getRequest('/payments/customer', data);
};

const getUserSubscription = async (userID: string) => {
	const membershipsData = await getRequest('/memberships/', { userID });

	if (membershipsData.membership_start) {
		membershipsData.membership_start = new Date(membershipsData.membership_start).toDateString();
	}

	if (membershipsData.membership_end) {
		membershipsData.membership_end = new Date(membershipsData.membership_end).toDateString();
	}

	const { membership_id, membership_type, membership_start, membership_end, membership_status } =
		membershipsData;

	return membership_id
		? [
				{
					id: membership_id,
					plan: membership_type,
					startDate: membership_start,
					expiryDate: membership_end,
					status: membership_status,
				},
		  ]
		: null;
};

const renewSubscription = async (membershipID: string) => {
	const membershipsData = await postRequest('/memberships', { membershipID });

	const { membership_type, membership_start, membership_end } = membershipsData;

	return {
		plan: membership_type,
		startDate: membership_start,
		expiryDate: membership_end,
	};
};

const changeUserSubscription = async (userID: string, membership: string) => {
	await postRequest('/memberships/', { userID, membership });
	const membershipsData = await getRequest('/memberships/', { userID });
	const { membership_type, membership_start, membership_end } = membershipsData;

	return {
		plan: membership_type,
		startDate: membership_start,
		expiryDate: membership_end,
	};
};

const cancelSubscription = async (membershipID: string) => {
	const membershipsData = await deleteRequest('/memberships', { membershipID });

	if (membershipsData.membership_start) {
		membershipsData.membership_start = new Date(membershipsData.membership_start).toDateString();
	}

	if (membershipsData.membership_end) {
		membershipsData.membership_end = new Date(membershipsData.membership_end).toDateString();
	}

	const { membership_id, membership_type, membership_start, membership_end, membership_status } =
		membershipsData;

	return membership_id
		? [
				{
					id: membership_id,
					plan: membership_type,
					startDate: membership_start,
					expiryDate: membership_end,
					status: membership_status,
				},
		  ]
		: null;
};

const checkDiscount = async (discountCode: string) => {
	return await getRequest('/products/discount', { discountCode });
};

const createOrder = async (orderItems: any, customerId: string) => {
	const { orderDetails } = await postRequest('/payments/order', { orderItems, customerId });
	return orderDetails;
};

const createMembership = async (data: {
	customerDetails: any;
	membershipDetails: any;
	paymentIntentId: string;
}) => {
	return await postRequest('/memberships', data);
};

export {
	getProductById,
	getAllFaqs,
	getFaqsForProduct,
	getLocationCalendar,
	getServiceCalendar,
	getUserAppointments,
	getTasks,
	getNotifications,
	getMemberships,
	getUserMembership,
	createMembership,
	getAllAppointments,
	checkLogin,
	requestResetEmail,
	checkPasswordResetToken,
	setNewPassword,
	createAccount,
	createOrder,
	sendContact,
	sendCheckoutData,
	queryCustomer,
	recordBookingAttempt,
	updateProfile,
	getDocuments,
	getTransactions,
	getUserSubscription,
	changeUserSubscription,
	cancelSubscription,
	renewSubscription,
	checkDiscount,
	getJsonData,
};
