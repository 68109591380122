import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AllContext';
import config from '../../config';

import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import Card from './components/Card';
import {
	getUserAppointments,
	getTasks,
	getNotifications,
	getUserMembership,
} from '../../services/data';
import AllAppointments from './components/AllAppointments';
import MedicalDocuments from './components/MedicalDocuments';
import SubscriptionPlan from './components/SubscriptionPlan';
import MyAllowances from './components/MyAllowances';
import Notifications from './components/Notifications';
import LastOrder from './components/LastOrder';
import { getServices } from '../Booking/helpers/getData';

const Appointments = () => {
	const { user, userAuthenticated, isAuthenticated, logout } = useContext(AuthContext);
	const {
		user: { userID, username: email, first_name },
	} = useContext(AuthContext);

	const [tasks, setTasks] = useState();
	const [notifications, setNotifications] = useState();
	const [membershipData, setMembershipData] = useState(null);
	const [appointmentsData, setAppointmentsData] = useState();
	const [servicesData, setServicesData] = useState([]);
	const [documentsData, setDocumentsData] = useState([]);

	// The useEffect hooks below should be converted to a Promise.all() function
	useEffect(() => {
		const fetchData = async () => {
			const data = await getUserMembership(user.customer_id);
			setMembershipData(data);
		};
		fetchData();
	}, [user.customer_id]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getServices();
			setServicesData(data);
		};
		fetchData();
	}, [user.customer_id]);

	// This hook should use an existing function from services/api
	useEffect(() => {
		const fetchAppointments = async () => {
			try {
				const response = await fetch(
					`${config.REACT_APP_API_URL}/bookings/appointments/?filter=%7B"booking_email":"${user.email_address}"%7D`
				);
				const data = await response.json();
				setAppointmentsData(data);
			} catch (error) {
				console.error('Error fetching appointments:', error);
			}
		};

		if (user.email_address) {
			fetchAppointments();
		}
	}, [user.email_address]);

	useEffect(() => {
		const fetchData = async () => {
			setTasks(await getTasks(userID));
			setNotifications(await getNotifications(userID));
		};
		fetchData();
	}, [userID, email]);

	if (!membershipData) {
		return null;
	}

	return (
		<>
			<Header title="Portal" />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} membershipData={membershipData} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="row">
							<AllAppointments appointmentsData={appointmentsData} servicesData={servicesData} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Appointments;
