import { useState, useEffect } from 'react';
import applyFilters from '../helpers/applyFilters';

const useFiltering = (
	services,
	setServices,
	specialists,
	setSpecialists,
	selectedServiceCategories,
	setSelectedServiceCategories,
	serviceCategories,
	setServiceCategories,
	selectedSpecialistCategories,
	setSelectedSpecialistCategories,
	selectedLocationCategory,
	locationCategories,
	locationURLParam,
	navigate,
	setUserHasChosenLocation,
	setDisclaimerHasBeenShown,
	setSelectedLocationCategory
) => {
	const [filteredServices, setFilteredServices] = useState(null);
	const [filteredSpecialists, setFilteredSpecialists] = useState(null);

	const resetFilters = () => {
		const locationObject = locationCategories[0]?.subcategories || [];
		locationObject.forEach(({ category }) => {
			if (typeof category['checked'] !== 'undefined') delete category['checked'];
		});

		if (locationURLParam === 'Virtual' || locationURLParam === 'Home Visit') {
			navigate('/booking/London');
		}

		setSelectedServiceCategories([]);
		setSelectedSpecialistCategories([]);
		setSelectedLocationCategory(locationURLParam);
		setUserHasChosenLocation(false);
		setDisclaimerHasBeenShown(false);
	};

	useEffect(() => {
		const categoryIds = selectedServiceCategories
			.map((categoryName) =>
				serviceCategories
					.filter((category) => category.category_name === categoryName)
					.map(({ category_id }) => category_id)
			)
			.flat();

		if (selectedServiceCategories.length) setFilteredServices(applyFilters(services, categoryIds));
		else setFilteredServices(null);

		if (selectedServiceCategories.length)
			setFilteredSpecialists(
				specialists.filter((item) => {
					const title = item.metadata.title;
					return selectedServiceCategories.some(
						(specialistTitle) => specialistTitle.replace('Consultation', '').trim() === title
					);
				})
			);
		else setFilteredSpecialists(null);
	}, [selectedServiceCategories]);

	useEffect(() => {
		if (selectedSpecialistCategories.length) {
			const filteredByTags = specialists.filter((item) => {
				const tagsString = item.metadata.tags.replace(/'/g, '"');
				const tagsArray = JSON.parse(tagsString);
				return tagsArray.some((tag) => selectedSpecialistCategories.includes(tag));
			});
			const filteredByTitle = specialists.filter((item) => {
				const title = item.metadata.title;
				return selectedSpecialistCategories.some((specialistTitle) => {
					return title
						.replace('Consultation', '')
						.trim()
						.toLowerCase()
						.includes(specialistTitle.replace('Genetics', 'Genetic').toLowerCase());
				});
			});
			const filteredResults = filteredByTags.length ? filteredByTags : filteredByTitle;

			setFilteredSpecialists(filteredResults);
		} else {
			setFilteredSpecialists(null);
		}

		if (selectedSpecialistCategories.length) {
			setFilteredServices([]);
		} else {
			setFilteredServices(null);
		}
	}, [selectedSpecialistCategories]);

	return {
		filteredServices,
		filteredSpecialists,
		setFilteredServices,
		setFilteredSpecialists,
		resetFilters,
	};
};

export default useFiltering;
