import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config';
import DashboardSidebar from './components/DashboardSidebar';
import Header from '../../components/Header';
import VerifyButton from '../../components/Stripe/VerifyButton';
import useAuth from '../../hooks/useAuth';
import { getRequest } from '../../services/api';
import showAlert from '../../helpers/alerts';

const stripePromiseID = loadStripe(config.REACT_APP_STRIPE_PUBLIC_KEY);

const VerifyAccount = () => {
	const { user, userAuthenticated, logout } = useAuth();
	const {
		first_name,
		last_name,
		gender,
		email_address,
		contact_number,
		date_of_birth,
		company,
		occupation,
		address_line_1,
		address_line_2,
		city,
		postcode,
		country,
	} = user;
	const dateOfBirth = new Date(date_of_birth);

	const [show, setShow] = useState(false);
	const navigate = useNavigate();

	const checkVerificationStatus = async (userID) => {
		const response = await getRequest(`/users/verificationstatus/${userID}`);
		if (response.status !== 'failed' && response.userData) {
			const { userData } = response;
			const verificationStatus = userData.set_verified;
			if (verificationStatus && response.verifiedDocument) {
				userAuthenticated(userData);
				navigate('/customer-dashboard');
			} else {
				// Validation of user details failed
				showAlert(
					'Please ensure your registered details such as name and date of birth is the same as the document uploaded. You account will now be deactivated.',
					'Verification Issue',
					'error',
					false,
					false,
					true,
					null,
					null,
					logout
				);
			}
		}
	};

	useEffect(() => {
		checkVerificationStatus(user.user_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<div className="card-body ">
							<div className="profile-heading">
								<h2 className="profile-title">Verify account details</h2>
							</div>

							{!show && <h4>You need to complete ID verification before using your account</h4>}
							{show && (
								<h4>Check your details below and click the button to upload your ID document</h4>
							)}

							{/* {show && <ProfileDetails user={user} />} */}

							{show && (
								<div className="card card-table mb-0">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-hover table-center mb-0">
												<tbody>
													{(first_name || last_name) && (
														<tr>
															<td>
																<b>Name</b>
															</td>
															<td>
																{first_name} {last_name}
															</td>
														</tr>
													)}

													{gender && (
														<tr>
															<td>
																<b>Gender</b>
															</td>
															<td>{gender}</td>
															<td></td>
														</tr>
													)}

													{date_of_birth && (
														<tr>
															<td>
																<b>Date of Birth</b>
															</td>
															<td>{dateOfBirth.toLocaleDateString()}</td>
															<td></td>
														</tr>
													)}

													{email_address && (
														<tr>
															<td>
																<b>Email</b>
															</td>
															<td>{email_address}</td>
															<td></td>
														</tr>
													)}

													{contact_number && (
														<tr>
															<td>
																<b>Phone Number</b>
															</td>
															<td>{contact_number}</td>
															<td></td>
														</tr>
													)}

													{/* <tr>
                                              <td><b>Address</b></td>
                                              <td>75 somehwere road</td>
                                              <td>EN4 6HG</td>
                                            </tr> */}

													{company && (
														<tr>
															<td>
																<b>Company</b>
															</td>
															<td>{company}</td>
															<td></td>
														</tr>
													)}

													{occupation && (
														<tr>
															<td>
																<b>Occupation</b>
															</td>
															<td>{occupation}</td>
															<td></td>
														</tr>
													)}

													{address_line_1 && (
														<tr>
															<td>
																<b>Address line 1</b>
															</td>
															<td>{address_line_1}</td>
															<td></td>
														</tr>
													)}

													{address_line_2 && (
														<tr>
															<td>
																<b>Address line 2</b>
															</td>
															<td>{address_line_2}</td>
															<td></td>
														</tr>
													)}

													{city && (
														<tr>
															<td>
																<b>City</b>
															</td>
															<td>{city}</td>
															<td></td>
														</tr>
													)}

													{postcode && (
														<tr>
															<td>
																<b>Postcode</b>
															</td>
															<td>{postcode}</td>
															<td></td>
														</tr>
													)}

													{country && (
														<tr>
															<td>
																<b>Country</b>
															</td>
															<td>{country}</td>
															<td></td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="card-body">
							<div className="payment-method mt-0">
								{!show && (
									<Button
										className="primary_btn theme-btn"
										onClick={() => setShow(true)}
										style={{ width: '100%' }}
										role="link"
									>
										{' '}
										Begin Verification{' '}
									</Button>
								)}
								{show && user && (
									<VerifyButton type="verify" user={user} stripePromiseID={stripePromiseID} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default VerifyAccount;
