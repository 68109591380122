import { useState } from 'react';
import MembershipPricing from '../../Membership/MembershipPricing';

const UpdateSubscription = ({ membershipsData, setMembership, toggle }) => {
	const [mode, setMode] = useState('monthly');

	return (
		<>
			<section
				id="pricing"
				className="pricing-area overflow-auto"
				style={{
					display: toggle ? 'inherit' : 'none',
					height: '100%',
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-12 d-flex flex-column align-items-center mt-5">
							<h4 className="black-color">Individual Membership</h4>
							<div className="col-12 d-flex flex-column align-items-center">
								<nav className="pricing-subs m-5">
									<div className="nav nav-pills" role="tablist">
										<a
											className="nav-link active"
											id="nav-home-tab"
											data-bs-toggle="pill"
											href="#nav-home"
											role="tab"
											aria-controls="nav-home"
											aria-selected="true"
											onClick={() => setMode('monthly')}
										>
											Monthly
										</a>
										<a
											className="nav-link"
											id="nav-profile-tab"
											data-bs-toggle="tab"
											href="#nav-home"
											role="tab"
											aria-controls="nav-profile"
											aria-selected="false"
											onClick={() => setMode('yearly')}
										>
											Yearly
										</a>
									</div>
								</nav>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div className="pricing-tab wow fadeInUp mb-30" data-wow-delay="0.3s">
								<div className="tab-content" id="nav-tabContent">
									<div
										className="tab-pane fade show active"
										id="nav-home"
										role="tabpanel"
										aria-labelledby="nav-home-tab"
									>
										<div className="row">
											{membershipsData.map((membership, index) => (
												<MembershipPricing
													key={index}
													setMembership={setMembership}
													{...membership}
													mode={mode}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default UpdateSubscription;
