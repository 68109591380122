import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { BookingContext } from '@sirkka-health/booking-system-ui';

const ShoppingCart = () => {
	const { setBookingStep } = useContext(BookingContext);
	const navigate = useNavigate();

	useEffect(() => {
		setBookingStep('orderSummary');
		navigate('/booking');
	}, [setBookingStep, navigate]);
	return (
		<>
			<Header title="Shopping Cart" />
			<div
				className="container mt-30 mb-30"
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'start',
					gap: '20px',
				}}
			></div>
			<Footer />
		</>
	);
};

export default ShoppingCart;
