import { ReactSession } from 'react-client-session';
import config from '../config';
import showAlert from '../helpers/alerts';
import { useNavigate } from 'react-router-dom';

const apiUrl = config.REACT_APP_API_URL || '/api';

const fullAPIURL = (endpoint) => apiUrl + endpoint;

const makeQueryParams = (queryParams) => {
	const queryArray = Object.keys(queryParams);

	if (queryArray.length) {
		queryArray.forEach((key) => queryParams[key] === undefined && delete queryParams[key]);
	}

	const params = new URLSearchParams(queryParams);
	const query = params.toString();
	return query;
};

const getRequest = async (url, queryParams = {}) => {
	url = fullAPIURL(url);

	const query = makeQueryParams(queryParams);
	if (query) {
		url = `${url}?${query}`;
	}

	return await fetch(url, {
		credentials: 'include',
	})
		.then((response) => {
			if (response.status === 401) {
				// Logout user when session expired or token invalid
				logout();
				showAlert(
					'You session has expired. Please login again.',
					'Session Expired',
					'warning',
					false,
					false,
					true,
					'/login',
					useNavigate()
				);

				throw new Error('Unauthorized');
			}
			// handle the response
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.log(error);
			return error.message;
		});
};

const postRequest = async (url, data, headers = null) => {
	url = fullAPIURL(url);

	const fetchOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	};

	if (headers === 'form-data') {
		fetchOptions.body = data; //pure data
		delete fetchOptions.headers;
	}

	return await fetch(url, fetchOptions)
		.then((response) => {
			if (response.status === 401) {
				// Handle 401 Unauthorized error
				logout();
				throw new Error('Unauthorized');
			}
			// if(response.ok)
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.log(error);
			return error.message;
		});
};

const putRequest = async (url, data) => {
	url = fullAPIURL(url);

	return await fetch(url, {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})
		.then((response) => {
			// if(response.ok)
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.log(error);
			return error.message;
		});
};

const patchRequest = async (url, data) => {
	url = fullAPIURL(url);

	return await fetch(url, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})
		.then((response) => {
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.error('Error:', error);
			return error.message;
		});
};

const deleteRequest = async (url, data) => {
	url = fullAPIURL(url);

	return await fetch(url, {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})
		.then((response) => {
			// if(response.ok)
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.log(error);
			return error.message;
		});
};

const postRequestFormspree = async (url, data) => {
	return await fetch(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})
		.then((response) => {
			// if(response.ok)
			return response.json();
		})
		.catch((error) => {
			// handle the error
			console.log(error);
			return error.message;
		});
};

const logout = () => {
	ReactSession.set('user', {});
	ReactSession.remove('user');
	ReactSession.remove('billing_address');
};

export {
	apiUrl,
	getRequest,
	postRequest,
	putRequest,
	patchRequest,
	deleteRequest,
	postRequestFormspree,
};
