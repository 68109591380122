import { useState } from 'react';
import { ReactSession } from 'react-client-session';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import useAuth from '../../hooks/useAuth';
import PaymentForm from './SubscriptionBilling';
import Order from '../Order';
import styles from './CheckoutSubscription.module.scss';
import Login from './Authentication/Login';
import Register from './Authentication/Register';

const CheckoutSubscription = () => {
	const { isAuthenticated } = useAuth();
	const [checkoutStep, setCheckoutStep] = useState('login');
	const user = isAuthenticated ? ReactSession.get('user') : {};
	const membershipSelected = ReactSession.get('membership');

	return (
		<>
			<Header title="Checkout" />
			<div className={`${styles.checkoutContainer} container mt-30 mb-10`}>
				{!isAuthenticated && checkoutStep === 'login' && (
					<Login setCheckoutStep={setCheckoutStep} />
				)}
				{!isAuthenticated && checkoutStep === 'register' && (
					<Register setCheckoutStep={setCheckoutStep} />
				)}
				{isAuthenticated && <PaymentForm membershipData={membershipSelected} userData={user} />}
				<Order />
			</div>
			<Footer />
		</>
	);
};

export default CheckoutSubscription;
