import { useState } from 'react';
import { Button } from '@sirkka-health/booking-system-ui';
import './Card.scss';
import ReadMore from '../ReadMore';

const SpecialistCard = ({ entityItem, onHover, onLeave }) => {
	const [isHovered, setIsHovered] = useState(false);
	const bookingEntity = entityItem.entity_with_services.booking_entity;
	const EntityUserMetadata = entityItem.entity_with_services.booking_user_metadata;

	const parsedBookingEntityTypes = bookingEntity.type
		? JSON.parse(bookingEntity.type.replace(/'/g, '"'))
		: [];

	const handleMouseEnter = () => {
		onHover(bookingEntity.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};
	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	return (
		<>
			<div
				className="px-3 py-3 mb-3"
				style={cardStyle}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{/* Card top */}
				<div className="d-flex flex-column flex-md-row card-content-container">
					<div className="col-12 col-md-3 d-flex flex-column justify-content-center card-content pe-3">
						<div className="circle-image-container">
							<img src={`${bookingEntity.image_url}`} alt={`${bookingEntity.display_name}`} />
						</div>
						<div>
							<h4 className="text-center mb-0 mt-3" style={{ fontSize: '18px' }}>
								{EntityUserMetadata?.full_name}
							</h4>
							<h5 className="text-center mb-0" style={{ fontSize: '16px', color: '#5F5F5F' }}>
								{EntityUserMetadata?.title}
							</h5>
						</div>
					</div>
					<div className="col-12 col-md-4 px-sm-3 pt-4 pt-md-0 d-flex card-content">
						<div className="d-flex flex-column justify-content-center align-items-center w-100">
							<div>
								{EntityUserMetadata?.credentials && (
									<div className="meta-icon">
										<i className="fas fa-briefcase pe-2 d-flex align-items-center"></i>
										<ul>
											{JSON.parse((EntityUserMetadata?.credentials || '[]').replace(/'/g, '"')).map(
												(credential, index) => (
													<li key={index} className="meta-credentials">
														{credential}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{parsedBookingEntityTypes.includes('Virtual') && (
									<div className="meta-icon">
										<i className="fas fa-video pe-2"></i>
										Virtual appointments available
									</div>
								)}
								{parsedBookingEntityTypes.includes('In-Clinic') && (
									<div className="meta-icon">
										<i className="fas fa-user-md pe-2"></i>
										In-clinic appointments available
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-12 col-md-5 ps-sm-3 pt-4 pt-md-0 card-content">
						<ReadMore content={`${bookingEntity.description}`} />
						<div className="d-flex justify-content-end mt-2">
							{bookingEntity && bookingEntity.website_url ? (
								<a href={bookingEntity.website_url} target="_blank" rel="noopener noreferrer">
									<Button
										label={bookingEntity.website_url.startsWith('mailto') ? 'Email' : 'Website'}
										fullWidth
										variant="border"
									/>
								</a>
							) : (
								<a href="/contact">
									<Button label="Contact" fullWidth variant="primary" />
								</a>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SpecialistCard;
