/* eslint-disable no-useless-escape */
const checkEmail = (email) => {
	return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};
const formatEmail = (email) => {
	return email.toLowerCase();
};

export default checkEmail;
export { formatEmail };
