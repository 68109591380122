import { getRequest } from '../services/api';
import { FetchDataCallback } from '../types';

const fetchData = async (url: string, callback: FetchDataCallback): Promise<any> => {
	try {
		const response = await getRequest(url);
		callback(response);
		return response;
	} catch (error) {
		console.error(`Error fetching data from ${url}:`, error);
		throw error;
	}
};

export default fetchData;
