const CopyRight = () => {
	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="footer-copyright footer-copyright-3 text-center">
						<p>
							<i className="far fa-copyright"></i> Sirkka Networks Limited (trading as Sirkka
							Health) 2019 - {new Date().getFullYear()}. All rights reserved.{' '}
							{new Date().getFullYear()}
						</p>
						<p>
							{' '}
							Sirkka Health is a trading name of Sirkka Networks Limited and the company registered
							number is 08886975
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default CopyRight;
