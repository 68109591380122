import { ReactSession } from 'react-client-session';
import appointmentObject from '@/helpers/appointmentObject';
import postalObject from '@/helpers/postalObject';
import { createAppointment, createPostalDelivery } from '@/services/booking';

const BookingUtils = {
	CreatePostal(cartItem, customerId) {
		const postageData = postalObject(cartItem, customerId);
		createPostalDelivery(postageData);
	},
	CreateAppointment(cartItem, customerInfo) {
		const appointmentData = appointmentObject(
			cartItem.booking,
			cartItem.entity || null,
			cartItem.service,
			customerInfo,
			cartItem.clinician || null
		);
		createAppointment(appointmentData);
	},
	ClearBookingCart(setBookingData) {
		setBookingData([]);
		ReactSession.remove('referrer');
	},
};

export { BookingUtils };
