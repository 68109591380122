import { Helmet } from 'react-helmet';

const PageTitle = ({ title, fullTitle }) => {
	return (
		<Helmet>
			{fullTitle && <title>{fullTitle}</title>}
			{title && <title>{title} | Sirkka Health</title>}
		</Helmet>
	);
};

export default PageTitle;
