import showAlert from './alerts.js';
import checkEmail from '../validations/email.js';
import { requestResetEmail } from '../services/data';

const sendPasswordResetEmail = async (email) => {
	if (!checkEmail(email)) {
		return showAlert('Please enter a valid email address');
	}

	const response = await requestResetEmail(email);

	if (response.status === 'success') {
		showAlert(`Password reset email sent to ${email}.`, null, 'success');
	}
};

export default sendPasswordResetEmail;
