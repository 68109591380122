import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import ProductDescription from './ProductDescription';
import urlFriendly from '../../helpers/make-url-friendly';
import { useState } from 'react';
import { ReactSession } from 'react-client-session';
import { Link } from 'react-router-dom';
import formatPrice from '../../helpers/formatPrice';
import DOMPurify from 'dompurify';

const ProductDetails = ({ product, product_key }) => {
	const [cartItem, setCartItem] = useState(ReactSession.get('cart') || []);

	return (
		<>
			<div className="service-details-area pt-120 mb-30">
				<div className="container">
					<div className="row">
						<div className="col-xl-7 col-lg-8">
							<article className="service-details-box">
								<div className="section-title pos-rel mb-45">
									<div className="section-text pos-rel">
										<h5 className="green-color text-up-case">{product.category}</h5>
										<h1>{product.name}</h1>
									</div>
								</div>
								<div className="service-details-text mb-30">
									{product.description.map((singleDescription) => (
										<ProductDescription key={singleDescription} description={singleDescription} />
									))}
								</div>
								{product.location.length > 0 ? (
									<div className="col-xl-12">
										<DropdownButton title="Select Location">
											{product.location.map((location, index) => (
												<DropdownItem
													key={index}
													href={`/booking/${urlFriendly(location)}/${product_key}`}
												>
													{location}
												</DropdownItem>
											))}
										</DropdownButton>
									</div>
								) : (
									<>
										{product.id === 'generalLFTpost' ||
										product.id === 'generalPCRpost' ||
										product.id === 'qFIT' ||
										product.id === 'HPV' ? (
											<Link
												to="/shoppingCart"
												className="btn btn-primary"
												onClick={() => setCartItem()}
											>
												Buy Now
											</Link>
										) : (
											<div className="col-xl-12">
												<Link to="/contact" className="btn btn-primary">
													Make Appointment
												</Link>
											</div>
										)}
									</>
								)}
								<div className="section-text pos-rel mt-45 mb-30">
									<h2>Product Description</h2>
								</div>
								{/* <div className="service-details-text">
                                    <h4>This product is available for:</h4>
                                </div> */}
								<div className="service-details-text mb-30 ser-fea-list">
									<ul>
										{product.bullets.map((bullet, index) => (
											<li key={index}>
												<i className="fas fa-check"></i>
												{bullet}
											</li>
										))}
									</ul>
								</div>
								{product.miscHtml ? (
									<div
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(product.miscHtml),
										}}
									></div>
								) : (
									''
								)}
								<div className="service-details-text">
									{product.description_continued.map((singleDescription, index) => (
										<ProductDescription key={index} description={singleDescription} />
									))}
								</div>
								{product.price && (
									<div className="service-details-text mt-30">
										<h4>
											Price:{' '}
											{formatPrice({
												price: product.price,
											})}
										</h4>
									</div>
								)}
							</article>
						</div>
						{product.category === 'Health Screening' && (
							<div className="col-xl-5 col-lg-4">
								<div className="service-widget mb-50">
									<div className="service-details-thumb mb-20">
										<img className="img" src="/img/services/health-screening.jpg" alt="" />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductDetails;
