import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import nordclinic from './nordclinic.jpeg';
import nordorthopaedics from './nordorthopaedics.png';
import nordbariatric from './nordbariatric.png';
import nordesthetics from './nordesthetics.png';
import './treatmentsAbroad.scss';
import { BookingContext, Button } from '@sirkka-health/booking-system-ui';
import FullPreImg from './Full-pre.png';
import PreImg from './Pre.png';
import FullPostImg from './Full-post.png';
import nordLogo from './nordLogo.png';
import gynaecologyIcon from './Gynaecology.png';
import DiersKlinik from './DiersKlinik.jpeg';
import DiersLogo from './DiersLogo.png';
import { getServices } from '../HealthTestsAndSpecialists/helpers/getData';
import config from '../../config';
import DisclaimerModal from './DisclaimerModal';
// import { NoReferral } from '@sirkka-health/booking-system-ui';

const CardContent = ({ title, paragraphs, content, image, logo, link, selection }) => {
	const logoStyle = logo ? { maxWidth: '300px' } : {};

	return (
		<>
			<div
				className={`col-12 row ${selection === 'default' ? 'flex-column gap-3 company-cards' : ''}`}
			>
				<div
					style={{
						backgroundColor: 'rgb(255, 255, 255)',
						padding: 0,
						paddingBottom: '18px',
						borderRadius: '8px',
						border: '1px solid rgba(203, 205, 215, 0.231372549)',
						flexShrink: 1,
					}}
				>
					{image && (
						<div
							style={{
								height: '200px',
								overflow: 'hidden',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<img
								src={image}
								alt=""
								style={{
									objectFit: 'cover',
									display: 'block',
									transition: 'all 0s ease-out 0s',
									...logoStyle,
								}}
							/>
						</div>
					)}
					<div className={`px-5 pb-4 ${logo ? '' : 'pt-5'}`}>
						<h4 className="mb-15 text-center">{title}</h4>
						{paragraphs.map((paragraph, index) => (
							<p key={index}>{paragraph}</p>
						))}
						{link && (
							<a
								href={link}
								style={{ textAlign: 'right', display: 'block' }}
								target="_blank"
								rel="noopener noreferrer"
							>
								<button className="btn btn-primary" type="button">
									Find out more
								</button>
							</a>
						)}
					</div>
				</div>
				{selection === 'default' && (
					<div
						style={{
							backgroundColor: 'rgb(255, 255, 255)',
							padding: 0,
							paddingBottom: '18px',
							borderRadius: '8px',
							border: '1px solid rgba(203, 205, 215, 0.231372549)',
							flexShrink: 1,
						}}
					>
						{image && (
							<div
								style={{
									height: '200px',
									overflow: 'hidden',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<img
									src={DiersKlinik}
									alt=""
									style={{
										objectFit: 'cover',
										display: 'block',
										transition: 'all 0s ease-out 0s',
									}}
								/>
							</div>
						)}
						<div className={`px-5 pb-4 pt-5`}>
							<h4 className="mb-15 text-center">Diers Klinik - Denmark</h4>
							<p className="">
								At Diers Klinik, you will find a warm and open-minded environment where you are
								truly welcome when you begin your fertility journey. We specialize in Intrauterine
								Insemination (IUI) treatment with donor sperm as a clinic for fertility. Our topmost
								priority is to focus individually on the woman or couple, understanding their unique
								circumstances and desires. The clinic was founded in 2006 in the center of Aarhus by
								Liza Diers. Propelled by a sincere desire to make fertility treatment accessible to
								all, irrespective of their background or orientation, she laid a foundation deeply
								rooted in inclusivity. This founding principle, woven into our fertility clinic’s
								DNA, continues to shape our mission and drive our actions to this day.
							</p>
						</div>
					</div>
				)}
				{content}
			</div>
		</>
	);
};

const TreatmentsAbroad = () => {
	const [selectedCard, setSelectedCard] = useState('default');
	// const [locationAddress, setLocationAddress] = useState(
	// 	'Sirkka Health/Sirkka Networks, 1/F, 144-146 King’s Cross Rd, London, WC1X 9DU'
	// );
	// const [locationCategoryId, setLocationCategoryId] = useState(1);
	const navigate = useNavigate();
	const { bookingData, setBookingData } = useContext(BookingContext);

	const [services, setServices] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(null);
	const [selectedLocationCategoryId, setSelectedLocationCategoryId] = useState(null);
	const [selectedServiceName, setSelectedServiceName] = useState(null);
	const [serviceLocationData, setServiceLocationData] = useState(null);
	const [disclaimerModal, setDisclaimerModal] = useState(true);
	const [disclaimerHasBeenShown, setDisclaimerHasBeenShown] = useState(false);

	const toggleDropdown = (identifier) => {
		setOpenDropdown((currentOpenDropdown) =>
			currentOpenDropdown === identifier ? null : identifier
		);
	};

	// useEffect(() => {
	// 	if (selectedLocationCategory === 'Edinburgh') {
	// 		setLocationAddress(
	// 			'Sirkka Health, Reception Business Centre, 21 Lansdowne Crescent, Edinburgh, EH12 5EH'
	// 		);
	// 		setLocationCategoryId(2);
	// 	} else if (selectedLocationCategory === 'Manchester') {
	// 		setLocationAddress(
	// 			'Sirkka Health/The Manchester Clinic, 19 Ellesmere Road, Manchester, M30 9JY'
	// 		);
	// 		setLocationCategoryId(3);
	// 	} else {
	// 		setLocationAddress(
	// 			'Sirkka Health/Sirkka Networks, 1/F, 144-146 King’s Cross Rd, London, WC1X 9DU'
	// 		);
	// 		setLocationCategoryId(1);
	// 	}
	// }, [selectedLocationCategory]);

	const [formData, setFormData] = useState({
		booking: {
			bookingId: '',
			appointmentLocation: null,
			appointmentRegion: null,
			appointmentFor: 'Myself',
			completed: false,
			virtualOrPhysical: '',
			termsconditions: '',
		},
		customer: {
			nameTitle: '',
			firstName: '',
			lastName: '',
			gender: '',
			dateOfBirth: '',
			phoneNumber: '',
			company: '',
			occupation: '',
			address_type: 'home',
		},
		postal: {
			postageAddressLine1: '',
			postageAddressLine2: '',
			postageCity: '',
			postagePostcode: '',
			postageCountry: '',
			postageSelection: 'no',
		},
		service: {
			serviceType: 'Clinic',
		},
	});

	const handleFilter = (e) => {
		if (selectedCard === e.target.value) {
			setSelectedCard('default');
		} else {
			setSelectedCard(e.target.value);
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const surgeryParam = urlParams.get('treatment');
		if (surgeryParam) {
			setSelectedCard(surgeryParam);
		}
	}, []);

	// const handleLocationFilter = (e) => {
	// 	setSelectedLocationCategory(e.target.value);
	// };

	useEffect(() => {
		const fetchData = async () => {
			try {
				await new Promise((resolve, reject) => {
					getServices((data) => {
						if (data) {
							setServices(data);
							resolve();
						} else {
							reject(new Error('Failed to fetch services'));
						}
					});
				});
			} catch (error) {
				console.error('Error fetching services:', error);
			}
		};

		fetchData();
	}, []);

	//Step 1 - Select a location and get location data
	const handleDropdownSelect = async (
		locationCategory,
		locationCategoryId,
		serviceName,
		serviceId
	) => {
		if (locationCategoryId === 4) {
			window.location.href = 'https://forms.office.com/e/RsmndD1E3v';
			return;
		}

		if (locationCategoryId === 5) {
			// Redirect user to booking page
			const url = `/booking/London?serviceCode=${serviceName}`;
			window.location.href = url;
			return;
		}

		setSelectedLocationCategoryId(locationCategoryId);
		try {
			const response = await fetch(
				`${config.REACT_APP_API_URL}/bookings/service?location=${locationCategory}&serviceId=${serviceId}`
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const serviceData = await response.json();

			setIsDropdownOpen(false);
			setServiceLocationData(serviceData[0].location);
			setSelectedServiceName(serviceName);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error.message);
		}
	};

	//Step 2 - If location data exists, trigger form submit
	useEffect(() => {
		if (selectedLocationCategoryId && serviceLocationData) {
			handleFormSubmit(selectedServiceName, selectedLocationCategoryId);
		}
	}, [serviceLocationData]);

	//Step 3 - Submit form to add to booking data
	const handleFormSubmit = (serviceName, selectedLocationCategoryId) => {
		const service = services.find((serviceItem) => {
			return serviceItem.service.service_code === serviceName;
		});

		const { building_name: buildingName, street, city, postcode } = serviceLocationData;
		const regionId = selectedLocationCategoryId || serviceLocationData.location_category_id;

		if (service.questionnaire_link) {
			window.open(service.questionnaire_link, '_blank');
		} else {
			// Generate unique ID
			const bookingId = Date.now().toString();
			let appointmentAddress = null;
			// City is the indication of region. Address such as buildingName or street or postcode can be blank
			if (city) {
				appointmentAddress = [buildingName, street, city, postcode]
					.filter((value) => value !== null)
					.join(', ');
			}

			setBookingData([
				...bookingData,
				{
					...formData,
					booking: {
						...formData.booking,
						bookingId,
						bookingProduct: serviceName,
						appointmentLocation: appointmentAddress,
						appointmentRegion: city || 'Virtual',
					},
					service: {
						...formData.service,
						serviceId: service.service.service_code,
						serviceName: service.service.service_name,
						regionId,
						icon: 'health_screening',
						emailTemplate: 'generalBloodTest',
						price: service.service.service_price,
						apptStackable: service.service.appt_stackable,
						apptDuration: service.service.appt_duration,
					},
				},
			]);

			navigate('/cart');
		}
	};

	const onDisclaimerModalClose = () => {
		setDisclaimerModal(false);
		setDisclaimerHasBeenShown(true);
	};

	const cards = {
		default: {
			title: 'Nordclinic - Lithuania',
			image: nordclinic,
			logo: false,
			paragraphs: [
				'Nordclinic is a leading medical tourism clinic in Central and Eastern Europe. Around 90% of clinic’s patients come from abroad: the UK, Ireland and Scandinavian countries – this is the area we specialise in and our processes have been adapted to cater specifically for patients from abroad. Clinic is based in the heart of Lithuania, Kaunas, employing a team of 300 specialists and more than 20 surgeons who perform 4.000 surgeries per year, mostly plastic, bariatric, orthopaedic, general and gynecological. Nordclinic unites 3 brands under its umbrella - Nordesthetics, Nordorthopaedics and Nordbariatric.',
			],
		},
		orthopaedicSurgery: {
			title: 'Orthopaedic Surgery',
			image: nordorthopaedics,
			logo: true,
			link: 'https://www.nordorthopaedics.com/en/?utm_source=13&utm_medium=13',
			paragraphs: [
				'Nordorthopaedics - an orthopaedic clinic offering a team of 5 experienced surgeons led by prof. S.Tarasevicius, who has already performed over 5.000 joint replacement surgeries and is a co-author of 150+ scientific articles. Nordorthopaedics also offers exceptional rehabilitation services post surgery in the clinic with a physiotherapist who also takes care of the Lithuanian Men’s National Basketball Team or in a SPA resort. The clinic and its patients’ stories have been featured in major media outlets such as The BBC, The Guardian, MailOnline, The Telegraph and others.',
			],
			content: (
				<>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={FullPreImg} style={{ width: '60px' }} alt="icon" />
								<h5>Full pre-op blood test package for joint replacement surgeries: </h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Full blood count tests</li>
								<li>
									Biochemistry: UREA Sodium Na Potassium K, Creatinine HbA1C - glycated haemoglobin
								</li>
								<li>
									Clotting tests: Prothrombin time PT, INR Activated Partial Thromboplastin Time
									(APTT)
								</li>
							</ul>
							<p className="price">Price: £198</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('fullPreOpBloodTestPackage')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'fullPreOpBloodTestPackage' ? 'show' : ''
									}`}
								>
									<div
										onClick={() =>
											handleDropdownSelect('London', 1, 'fullPreOpBloodTestPackage', 26)
										}
									>
										London
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Edinburgh', 2, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Edinburgh
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Glasgow', 7, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Glasgow
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cardiff', 9, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Cardiff
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Belfast', 10, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Belfast
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cambridge', 11, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Cambridge
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Home Visit', 5, 'fullPreOpBloodTestPackage', 26)
										}
									>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src="/img/icon/health_screening.png" style={{ width: '60px' }} alt="icon" />
								<h5>Resting ECG: </h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">
								Resting ECG is a test used to check the rhythm of your heart and electrical activity
								while you are lying down in a comfortable position.
							</p>

							<p className="price">Price: £135</p>

							<p className="disclaimer">
								Resting ECG can be provided at: London, Glasgow, Cardiff, Belfast, and Home Visit.
							</p>
							<p className="disclaimer">There will be an extra charge for Home Visit.</p>

							<div className="next">
								<Button
									onClick={() => toggleDropdown('restingECG')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'restingECG' ? 'show' : ''
									}`}
								>
									<div onClick={() => handleDropdownSelect('London', 1, 'restingECG', 35)}>
										London
									</div>
									<div onClick={() => handleDropdownSelect('Glasgow', 7, 'restingECG', 35)}>
										Glasgow
									</div>
									<div onClick={() => handleDropdownSelect('Cardiff', 9, 'restingECG', 35)}>
										Cardiff
									</div>
									<div onClick={() => handleDropdownSelect('Belfast', 10, 'restingECG', 35)}>
										Belfast
									</div>
									<div onClick={() => handleDropdownSelect('Home Visit', 5, 'restingECG', 35)}>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="overseas-product">
							<h5>Pre-op imaging tests:</h5>
							<p className="pt-3">Please contact us to discuss your specific requirements</p>
							<a
								href="https://forms.office.com/e/RsmndD1E3v"
								target="_blank"
								rel="noopener noreferrer"
							>
								<button className="btn btn-primary" style={{ width: '100%' }} type="button">
									Enquire now
								</button>
							</a>
						</div>
					</div>
				</>
			),
		},
		bariatricSurgery: {
			title: 'Bariatric Surgery',
			paragraphs: [
				'Nordbariatric - weight loss surgery clinic led by a world-class surgeon who has performed more than 7.000 bariatric surgeries to this date. The only clinic in the world with a specially designed bariatric mobile app for patients after weight loss surgeries.',
			],
			image: nordbariatric,
			logo: true,
			link: 'https://www.nordbariatric.com/en/?utm_source=13&utm_medium=13',
			content: (
				<>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={FullPostImg} style={{ width: '60px' }} alt="icon" />
								<h5>Full Post-op follow-up blood test package for bariatric surgeries: </h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Full blood count tests</li>
								<li>Calcium</li>
								<li>Iron</li>
								<li>Vitamin D</li>
								<li>Folic Acid</li>
								<li>Vitamin B12</li>
							</ul>
							<p className="price">Price: £198</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('fullPostOpBloodTestBariatic')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'fullPostOpBloodTestBariatic' ? 'show' : ''
									}`}
								>
									<div
										onClick={() =>
											handleDropdownSelect('London', 1, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										London
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Edinburgh', 2, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Edinburgh
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Glasgow', 7, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Glasgow
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cardiff', 9, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Cardiff
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Belfast', 10, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Belfast
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cambridge', 11, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Cambridge
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Home Visit', 5, 'fullPostOpBloodTestBariatic', 27)
										}
									>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={PreImg} style={{ width: '60px' }} alt="icon" />
								<h5>
									Pre-operative test package (for patients who have undergone bariatric surgery
									previously):
								</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Full blood count tests</li>
								<li>Iron panel (Serum Iron level, TIBC, Transferrin saturation and Ferritin)</li>
							</ul>
							<p className="price">Price: £168</p>
							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('preOperativeTestBariatric')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'preOperativeTestBariatric' ? 'show' : ''
									}`}
								>
									<div
										onClick={() =>
											handleDropdownSelect('London', 1, 'preOperativeTestBariatric', 28)
										}
									>
										London
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Edinburgh', 2, 'preOperativeTestBariatric', 28)
										}
									>
										Edinburgh
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Glasgow', 7, 'preOperativeTestBariatric', 28)
										}
									>
										Glasgow
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cardiff', 9, 'preOperativeTestBariatric', 28)
										}
									>
										Cardiff
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Belfast', 10, 'preOperativeTestBariatric', 28)
										}
									>
										Belfast
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cambridge', 11, 'preOperativeTestBariatric', 28)
										}
									>
										Cambridge
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Home Visit', 5, 'preOperativeTestBariatric', 28)
										}
									>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			),
		},
		plasticSurgery: {
			title: 'Plastic Surgery',
			paragraphs: [
				'Nordesthetics - leading plastic surgery clinic for medical tourists with a team of 9 plastic surgeons, who perform over 3.000 plastic surgeries a year for patients from the UK, Ireland, Norway, Sweden, Denmark, Spain, Germany, Switzerland and other countries. Clinic has an online community of more than 28.000 former, current and future patients, aimed to build a space for opinions and mutual support.',
			],
			image: nordesthetics,
			logo: true,
			link: 'https://www.nordesthetics.com/en/?utm_source=13&utm_medium=13',
			content: (
				<>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={PreImg} style={{ width: '60px' }} alt="icon" />
								<h5>
									Pre-operative test package (for patients who have undergone weight loss surgery):{' '}
								</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Full blood count tests</li>
								<li>Iron panel (Serum Iron level, TIBC, Transferrin saturation and Ferritin)</li>
							</ul>
							<p className="price">Price: £168</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('preOperativePlasticSurgery')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'preOperativePlasticSurgery' ? 'show' : ''
									}`}
								>
									<div
										onClick={() =>
											handleDropdownSelect('London', 1, 'preOperativePlasticSurgery', 34)
										}
									>
										London
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Edinburgh', 2, 'preOperativePlasticSurgery', 34)
										}
									>
										Edinburgh
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Glasgow', 7, 'preOperativePlasticSurgery', 34)
										}
									>
										Glasgow
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cardiff', 9, 'preOperativePlasticSurgery', 34)
										}
									>
										Cardiff
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Belfast', 10, 'preOperativePlasticSurgery', 34)
										}
									>
										Belfast
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Cambridge', 11, 'preOperativePlasticSurgery', 34)
										}
									>
										Cambridge
									</div>
									<div
										onClick={() =>
											handleDropdownSelect('Home Visit', 5, 'preOperativePlasticSurgery', 34)
										}
									>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			),
		},
		fertilityTreatment: {
			title: 'Fertility Treatment',
			paragraphs: [
				'We take immense pride in our rich, transformative history of assisting diverse clients with fertility services, including single women and lesbian couples, in realizing their dream of starting or expanding their families. Diers Klinik has always been at the forefront of offering inclusive care to LGBTQ+ couples and individuals seeking to embark on the path of parenthood through donor insemination. ',
				'One of our cornerstones is accessibility. We firmly believe that fertility treatment should be available when you need it. That is why our clinic for fertility offers insemination services 365 days a year, ensuring the timing aligns perfectly with your body’s natural cycle. No lengthy waiting lists stand between you and your dreams of parenthood. Presently, we stand as Denmark’s foremost fertility clinic, conducting the highest annual count of IUI treatments. Our remarkable success rates reinforce this achievement, solidifying our position among Denmark’s top-tier fertility clinics.',
			],
			image: DiersLogo,
			logo: true,
			link: 'https://diersklinik.co.uk/',
			content: (
				<>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
								<h5>Test Profile 1</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>HIV (anti-HIV 1+2)</li>
								<li>Hepatitis B (HBsAG + anti-HBc) </li>
								<li>Hepatitis C (Anti-HCV-Ab) </li>
							</ul>
							<p className="price">Price: £148</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('testProfile1')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'testProfile1' ? 'show' : ''
									}`}
								>
									<div onClick={() => handleDropdownSelect('London', 1, 'testProfile1', 48)}>
										London
									</div>
									<div onClick={() => handleDropdownSelect('Edinburgh', 2, 'testProfile1', 48)}>
										Edinburgh
									</div>
									<div onClick={() => handleDropdownSelect('Glasgow', 7, 'testProfile1', 48)}>
										Glasgow
									</div>
									<div onClick={() => handleDropdownSelect('Cardiff', 9, 'testProfile1', 48)}>
										Cardiff
									</div>
									<div onClick={() => handleDropdownSelect('Belfast', 10, 'testProfile1', 48)}>
										Belfast
									</div>
									<div onClick={() => handleDropdownSelect('Cambridge', 11, 'testProfile1', 48)}>
										Cambridge
									</div>
									<div onClick={() => handleDropdownSelect('Home Visit', 5, 'testProfile1', 48)}>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
								<h5>Test Profile 2</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Ferritin</li>
								<li>Vitamin D3 (25-hydroxy-vitamin D)</li>
								<li>Vitamin B12 (cobalamin)</li>
								<li>TSH (thyrotropin)</li>
							</ul>
							<p className="price">Price: £148</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('testProfile2')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'testProfile2' ? 'show' : ''
									}`}
								>
									<div onClick={() => handleDropdownSelect('London', 1, 'testProfile2', 45)}>
										London
									</div>
									<div onClick={() => handleDropdownSelect('Edinburgh', 2, 'testProfile2', 45)}>
										Edinburgh
									</div>
									<div onClick={() => handleDropdownSelect('Glasgow', 7, 'testProfile2', 45)}>
										Glasgow
									</div>
									<div onClick={() => handleDropdownSelect('Cardiff', 9, 'testProfile2', 45)}>
										Cardiff
									</div>
									<div onClick={() => handleDropdownSelect('Belfast', 10, 'testProfile2', 45)}>
										Belfast
									</div>
									<div onClick={() => handleDropdownSelect('Cambridge', 11, 'testProfile2', 45)}>
										Cambridge
									</div>
									<div onClick={() => handleDropdownSelect('Home Visit', 5, 'testProfile2', 45)}>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
								<h5>Test Profile 3</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>AMH (anti-Müllerian hormone)</li>
							</ul>
							<p className="price">Price: £110</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('testProfile3')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'testProfile3' ? 'show' : ''
									}`}
								>
									<div onClick={() => handleDropdownSelect('London', 1, 'testProfile3', 46)}>
										London
									</div>
									<div onClick={() => handleDropdownSelect('Edinburgh', 2, 'testProfile3', 46)}>
										Edinburgh
									</div>
									<div onClick={() => handleDropdownSelect('Glasgow', 7, 'testProfile3', 46)}>
										Glasgow
									</div>
									<div onClick={() => handleDropdownSelect('Cardiff', 9, 'testProfile3', 46)}>
										Cardiff
									</div>
									<div onClick={() => handleDropdownSelect('Belfast', 10, 'testProfile3', 46)}>
										Belfast
									</div>
									<div onClick={() => handleDropdownSelect('Cambridge', 11, 'testProfile3', 46)}>
										Cambridge
									</div>
									<div onClick={() => handleDropdownSelect('Home Visit', 5, 'testProfile3', 46)}>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="overseas-product">
							<div
								className="cardHeader"
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'start',
								}}
							>
								<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
								<h5>Test Profile 4:</h5>
							</div>

							{/* <NoReferral /> */}
							<p className="pt-3">The package includes:</p>
							<ul>
								<li>Progesterone (serum)</li>
							</ul>
							<p className="price">Price: £77</p>

							<p className="disclaimer">
								Includes in-clinic phlebotomy & sample delivery services.
							</p>
							<p className="disclaimer">
								Home visit phlebotomy may incur an extra charge depending on location.
							</p>
							<div className="next">
								<Button
									onClick={() => toggleDropdown('testProfile4')}
									label="Choose location  ▾"
									fullWidth
								/>
								<div
									className={`buttonLocationDropdown ${
										openDropdown === 'testProfile4' ? 'show' : ''
									}`}
								>
									<div onClick={() => handleDropdownSelect('London', 1, 'testProfile4', 47)}>
										London
									</div>
									<div onClick={() => handleDropdownSelect('Edinburgh', 2, 'testProfile4', 47)}>
										Edinburgh
									</div>
									<div onClick={() => handleDropdownSelect('Glasgow', 7, 'testProfile4', 47)}>
										Glasgow
									</div>
									<div onClick={() => handleDropdownSelect('Cardiff', 9, 'testProfile4', 47)}>
										Cardiff
									</div>
									<div onClick={() => handleDropdownSelect('Belfast', 10, 'testProfile4', 47)}>
										Belfast
									</div>
									<div onClick={() => handleDropdownSelect('Cambridge', 11, 'testProfile4', 47)}>
										Cambridge
									</div>
									<div onClick={() => handleDropdownSelect('Home Visit', 5, 'testProfile4', 47)}>
										Home Visit
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			),
		},
		// spineSurgery: {
		// 	title: 'Spine Surgery',
		// 	paragraphs: [
		// 		'Many people suffer from back pain and often seek help from a doctor. In most cases, conservative treatments, such as physical therapy and anti-inflammatory medications, can help relieve pain.',
		// 		'However, for spinal deformities, infections, injuries, tumours or degenerative diseases such as a herniated disc, surgical treatment may be necessary. For the best results, consult an experienced team of spinal surgeons.',
		// 		'The surgeon can perform traditional spinal surgery, which involves cutting along the spine. It moves the muscles and soft tissues to the side to access the bones of the spine and spinal cord. ',
		// 		'Minimally invasive techniques involve making a smaller incision and inserting a tube through which the surgeon inserts small surgical instruments to work on the spine. As a result, minimally invasive techniques can shorten recovery time and reduce the risk of complications. ',
		// 		'With robotic spine surgery, the surgeon can use GPS-like tracking systems to precisely place screws and other hardware while repairing a damaged or deformed spine. ',
		// 	],
		// 	image: kcm1,
		// 	logo: false,
		// 	link: 'https://kcmclinic.com/prices/spine-surgery-prices/#!/ctl',
		// 	content: (
		// 		<>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={spineIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Spine surgery pre-operative test package:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">The package includes:</p>
		// 					<ul>
		// 						<li>Blood type</li>
		// 						<li>Thyroid function (TSH)</li>
		// 						<li>Hepatitis B & Hepatitis C Antibodies</li>
		// 						<li>HIV</li>
		// 						<li>Blood clotting tests (INR, APTT)</li>
		// 						<li>Full blood count</li>
		// 						<li>Biochemistry (Creatinine, Electrolytes, Urea, ALT, AST, ALP, GGT)</li>
		// 						<li>Blood glucose</li>
		// 					</ul>
		// 					<p className="price">Price: £299</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('preOperativeSpineSurgery')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'preOperativeSpineSurgery' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('London', 1, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								London
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Edinburgh', 2, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Edinburgh
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Glasgow', 7, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Glasgow
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cardiff', 9, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Cardiff
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Belfast', 10, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Belfast
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cambridge', 11, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Cambridge
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Home Visit', 5, 'preOperativeSpineSurgery', 39)
		// 								}
		// 							>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</>
		// 	),
		// },
		// urology: {
		// 	title: 'Minimally invasive urology',
		// 	paragraphs: [
		// 		'In KCM Minimally Invasive Center for Urological Surgery our urologists treat a wide range of urologic diseases and disorders and perform advanced urological surgery. With the newest endoscopic, laparoscopic and laser techniques the operative treatment is not only safer and with less pain but also with less complications and shorter hospitalization time.',
		// 	],
		//
		// 	logo: false,
		// 	link: 'https://kcmclinic.com/prices/urology/',
		// 	content: (
		// 		<>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={urologyIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Urology surgery pre-operative test package:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">The package includes:</p>
		// 					<ul>
		// 						<li>Blood type</li>
		// 						<li>Thyroid function (TSH)</li>
		// 						<li>Hepatitis B & Hepatitis C Antibodies</li>
		// 						<li>HIV</li>
		// 						<li>Blood clotting tests (INR, APTT)</li>
		// 						<li>Full blood count</li>
		// 						<li>Biochemistry (Creatinine, Electrolytes, Urea, ALT, AST, ALP, GGT)</li>
		// 						<li>Blood glucose</li>
		// 					</ul>
		// 					<p className="price">Price: £299</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('preOperativeUrologySurgery')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'preOperativeUrologySurgery' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('London', 1, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								London
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Edinburgh', 2, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Edinburgh
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Glasgow', 7, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Glasgow
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cardiff', 9, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Cardiff
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Belfast', 10, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Belfast
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cambridge', 11, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Cambridge
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Home Visit', 5, 'preOperativeUrologySurgery', 40)
		// 								}
		// 							>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</>
		// 	),
		// },
		// gynaecology: {
		// 	title: 'Gynaecology',
		// 	paragraphs: [
		// 		'Gynaecology is a branch of medicine dealing with the treatment of diseases of the female reproductive system, as well as the detection and prevention of diseases. The causes of these diseases can be genetic defects or birth defects, but also acquired diseases, also caused by infection or trauma. Early diagnosis and treatment of diseases of the reproductive system is extremely important and has a huge impact on women’s health and life. Appropriate diagnostics and treatment give women a chance for safe family planning, which for many women can be a very important part of life. ',
		// 		'At KCM Clinic, we use the most modern diagnostic methods and methods of treating even the most dangerous diseases of the reproductive system. Many operations are performed laparoscopically in 3D technology, ensuring safety, high quality of the procedure performed and faster recovery of patients. ',
		// 		'Gynaecologic Centre for 3D Laparoscopic Surgery, Infertility and Endometriosis Treatment.',
		// 		'Modern minimal invasive gynaecologic surgery (MIGS) with comprehensive laparoscopic and hysteroscopic diagnostics, and microsurgical treatment using 3D technology, ensures our patients with a highest success rate and fast recovery time. ',
		// 		'The newest 3D imaging laparoscopic surgery guaranteed accuracy, safety, and micro-precision of the surgical treatment. ',
		// 		'KCIVI Hospital is an education centre and organizer of international workshops and conferences for advanced gynaecologic laparoscopy in 3D technology, with live surgery transmission from operating theatre. ',
		// 	],
		//
		// 	logo: false,
		// 	link: 'https://kcmclinic.com/prices/gynecology/',
		// 	content: (
		// 		<>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Gynaecology surgery pre-operative test package:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">The package includes:</p>
		// 					<ul>
		// 						<li>Blood type</li>
		// 						<li>Thyroid function (TSH)</li>
		// 						<li>Hepatitis B & Hepatitis C Antibodies</li>
		// 						<li>HIV</li>
		// 						<li>Blood clotting tests (INR, APTT)</li>
		// 						<li>Full blood count</li>
		// 						<li>Biochemistry (Creatinine, Electrolytes, Urea, ALT, AST, ALP, GGT)</li>
		// 						<li>Blood glucose</li>
		// 					</ul>
		// 					<p className="price">Price: £315</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('preOperativeGynaecologySurgery')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'preOperativeGynaecologySurgery' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('London', 1, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								London
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Edinburgh', 2, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Edinburgh
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Glasgow', 7, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Glasgow
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cardiff', 9, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Cardiff
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Belfast', 10, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Belfast
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cambridge', 11, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Cambridge
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Home Visit', 5, 'preOperativeGynaecologySurgery', 41)
		// 								}
		// 							>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={gynaecologyIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Cancer markers:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">
		// 						Cancer cells possess (or have certain levels of) very specific markers that healthy
		// 						cells do not have. To assist in the detection, diagnosis, and monitor of cancers (or
		// 						other diseases), finding and measuring these cancer markers have an important role.
		// 						Available tests at Sirkka Health includes these markers (and their common detection
		// 						uses)*
		// 					</p>
		// 					<p>The package includes:</p>
		// 					<ul>
		// 						<li>CA125 (ovarian cancer)</li>
		// 						<li>CA15-3 (breast cancer)</li>
		// 						<li>CA 19-9 (pancreatic cancer)</li>
		// 						<li>
		// 							Carcinoembryonic antigen (CEA) (bowel, gastrointestinal, gallbladder cancer/other
		// 							health related issues)
		// 						</li>
		// 					</ul>
		// 					<p className="price">Price: £145</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('cancerMarkers')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'cancerMarkers' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div onClick={() => handleDropdownSelect('London', 1, 'cancerMarkers', 44)}>
		// 								London
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Edinburgh', 2, 'cancerMarkers', 44)}>
		// 								Edinburgh
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Glasgow', 7, 'cancerMarkers', 44)}>
		// 								Glasgow
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Cardiff', 9, 'cancerMarkers', 44)}>
		// 								Cardiff
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Belfast', 10, 'cancerMarkers', 44)}>
		// 								Belfast
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Cambridge', 11, 'cancerMarkers', 44)}>
		// 								Cambridge
		// 							</div>
		// 							<div onClick={() => handleDropdownSelect('Home Visit', 5, 'cancerMarkers', 44)}>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</>
		// 	),
		// },
		// otolaryngology: {
		// 	title: 'Otolaryngology',
		// 	paragraphs: [
		// 		'Otolaryngology is a medical specialty that is focused on the ears, nose, and throat. ',
		// 		'An otolaryngologist is a doctor who treats conditions and diseases affecting your ears, nose, and throat as well as the head and neck. They are often called ENT (ear, nose, throat) doctors for short. Whether your issue is simple or severe, we can treat you, at all stages of life.',
		// 		'Otolaryngology is also called otolaryngology-head and neck surgery because specialist otolaryngologists are trained in both medicine and surgery. ',
		// 		'When you have a health issue with the ears, nose, or throat it can affect your everyday life. Whether it’s an ear infection, a hearing issue, sinusitis, or speech and swallowing problem, our otolaryngology specialist doctors can help. We would look at your individual needs and choose the best treatment options that are right for you. ',
		// 		'Within our Department of Otolaryngology at KCM Clinic, our experienced, top-class specialist doctors perform a wide range of otolaryngological procedures, for adults and children. ',
		// 	],
		//
		// 	logo: false,
		// 	link: 'https://kcmclinic.com/prices/prices-otolaryngology/',
		// 	content: (
		// 		<>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={otolaryngologyIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Otolaryngology surgery pre-operative test package:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">The package includes:</p>
		// 					<ul>
		// 						{/* List with the following items: */}
		// 						<li>Blood type</li>
		// 						<li>Thyroid function (TSH)</li>
		// 						<li>Hepatitis B & Hepatitis C Antibodies</li>
		// 						<li>HIV</li>
		// 						<li>Blood clotting tests (INR, APTT)</li>
		// 						<li>Full blood count</li>
		// 						<li>Biochemistry (Creatinine, Electrolytes, Urea, ALT, AST, ALP, GGT)</li>
		// 						<li>Blood glucose</li>
		// 						<li>IGE</li>
		// 						<li>CRP</li>
		// 					</ul>
		// 					<p className="price">Price: £338</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<p className="disclaimer">
		// 						KCM customers are elligible for a further 10% discount. Please contact us for the
		// 						coupon code.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('preOperativeOtolaryngologySurgery')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'preOperativeOtolaryngologySurgery' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('London', 1, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								London
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Edinburgh', 2, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Edinburgh
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Glasgow', 7, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Glasgow
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cardiff', 9, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Cardiff
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Belfast', 10, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Belfast
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cambridge', 11, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Cambridge
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Home Visit', 5, 'preOperativeOtolaryngologySurgery', 42)
		// 								}
		// 							>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</>
		// 	),
		// },
		// ophthalmology: {
		// 	title: 'Ophthalmology',
		// 	paragraphs: [
		// 		'At the Ophthalmology Clinic we are committed to offering comprehensive eye care. We are specialized in: ',
		// 		'Cataract minimally invasive surgery (MIS) using phacoemulsification and microincision: Our cataract surgeon can fully evaluate your specific needs and help you choose the best combination of premium lenses for a successful visual outcome. ',
		// 	],
		//
		// 	logo: false,
		// 	link: 'https://kcmclinic.com/prices/ophthalmology/',
		// 	content: (
		// 		<>
		// 			<div className="col-md-6">
		// 				<div className="overseas-product">
		// 					<div
		// 						className="cardHeader"
		// 						style={{
		// 							display: 'flex',
		// 							gap: '10px',
		// 							alignItems: 'start',
		// 						}}
		// 					>
		// 						<img src={ophthalmologyIcon} style={{ width: '60px' }} alt="icon" />
		// 						<h5>Ophthalmology surgery pre-operative test package:</h5>
		// 					</div>
		//
		// 					{/* <NoReferral /> */}
		// 					<p className="pt-3">The package includes:</p>
		// 					<ul>
		// 						<li>Blood type</li>
		// 						<li>Thyroid function (TSH)</li>
		// 						<li>Hepatitis B & Hepatitis C Antibodies</li>
		// 						<li>HIV</li>
		// 						<li>Blood clotting tests (INR, APTT)</li>
		// 						<li>Full blood count</li>
		// 						<li>Biochemistry (Creatinine, Electrolytes, Urea, ALT, AST, ALP, GGT)</li>
		// 						<li>Lipids and cholesterol</li>
		// 						<li>Blood glucose, HBA1C</li>
		// 					</ul>
		// 					<p className="price">Price: £325</p>
		//
		// 					<p className="disclaimer">
		// 						Includes in-clinic phlebotomy & sample delivery services.
		// 					</p>
		// 					<p className="disclaimer">
		// 						Home visit phlebotomy may incur an extra charge depending on location.
		// 					</p>
		// 					<p className="disclaimer">
		// 						KCM customers are elligible for a further 10% discount. Please contact us for the
		// 						coupon code.
		// 					</p>
		// 					<div className="next">
		// 						<Button
		// 							onClick={() => toggleDropdown('preOperativeOphthalmologySurgery')}
		// 							label="Choose location  ▾"
		// 							fullWidth
		// 						/>
		// 						<div
		// 							className={`buttonLocationDropdown ${
		// 								openDropdown === 'preOperativeOphthalmologySurgery' ? 'show' : ''
		// 							}`}
		// 						>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('London', 1, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								London
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Edinburgh', 2, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Edinburgh
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Glasgow', 7, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Glasgow
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cardiff', 9, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Cardiff
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Belfast', 10, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Belfast
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Cambridge', 11, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Cambridge
		// 							</div>
		// 							<div
		// 								onClick={() =>
		// 									handleDropdownSelect('Home Visit', 5, 'preOperativeOphthalmologySurgery', 43)
		// 								}
		// 							>
		// 								Home Visit
		// 							</div>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</>
		// 	),
		// },
	};

	return (
		<>
			<Header title="International Treatments" />
			<div className="container">
				<div className="row">
					{/* Disclaimer Modal */}
					{disclaimerModal && <DisclaimerModal onDisclaimerModalClose={onDisclaimerModalClose} />}
					<div className="col-lg-9 mx-auto">
						<div className="px-5 pos-rel mb-70 mt-70">
							<div className="section-text pos-rel">
								<p className="overseas-hero-text">
									Sirkka offers Pre-/Post-surgery testing and pre-operation health assessment for
									individuals who are planning to have surgeries abroad, along with consultations
									from GPs and specialists.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{/* Filter area */}
					<div className="col-xl-4 col-lg-4 col-md-12">
						{/* <div className="filter-item p-0">
							<ul>
								<li className="filter-category">
									<h5 className="filter-location">Test Location</h5>
									<label data-filter-type="filter-category">
										<h5>London</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="London"
											onChange={handleLocationFilter}
											checked={selectedLocationCategory === 'London'}
										/>
										<span className="checkmark"></span>
									</label>
									<label data-filter-type="filter-category">
										<h5>Manchester</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="Manchester"
											onChange={handleLocationFilter}
											checked={selectedLocationCategory === 'Manchester'}
										/>
										<span className="checkmark"></span>
									</label>
									<label data-filter-type="filter-category">
										<h5>Edinburgh</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="Edinburgh"
											onChange={handleLocationFilter}
											checked={selectedLocationCategory === 'Edinburgh'}
										/>
										<span className="checkmark"></span>
									</label>
									<label data-filter-type="filter-category">
										<h5>Home Visit</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="Home Visit"
											onChange={() => navigate('/contact')}
										/>
										<span className="checkmark"></span>
									</label>
								</li>
							</ul>
						</div> */}
						<div className="filter-item p-0">
							<ul>
								<li className="filter-category">
									<img
										src={nordLogo}
										alt="Nordclinic"
										style={{
											maxWidth: '200px',
											margin: 'auto',
											display: 'block',
											padding: '20px 0',
										}}
									/>
									<label data-filter-type="filter-category">
										<h5>Orthopaedic Surgery</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="orthopaedicSurgery"
											onChange={handleFilter}
											checked={selectedCard === 'orthopaedicSurgery'}
										/>
										<span className="checkmark"></span>
									</label>
									<label data-filter-type="filter-category">
										<h5>Bariatric Surgery</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="bariatricSurgery"
											onChange={handleFilter}
											checked={selectedCard === 'bariatricSurgery'}
										/>
										<span className="checkmark"></span>
									</label>
									<label data-filter-type="filter-category">
										<h5>Plastic Surgery</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="plasticSurgery"
											onChange={handleFilter}
											checked={selectedCard === 'plasticSurgery'}
										/>
										<span className="checkmark"></span>
									</label>
								</li>
							</ul>
						</div>
						<div className="filter-item p-0">
							<ul>
								<li className="filter-category">
									<img
										src={DiersLogo}
										alt="Diers Clinic"
										style={{
											maxWidth: '200px',
											margin: 'auto',
											display: 'block',
											padding: '20px 0',
										}}
									/>
									<label data-filter-type="filter-category">
										<h5>Fertility Treatment</h5>
										<input
											type="checkbox"
											name="cardFilter"
											value="fertilityTreatment"
											onChange={handleFilter}
											checked={selectedCard === 'fertilityTreatment'}
										/>
										<span className="checkmark"></span>
									</label>
								</li>
							</ul>
						</div>
						<div className="alert alert-primary mt-20" role="alert">
							<i className="fas fa-solid fa-info pr-10"></i>Please{' '}
							<a href="/contact" className="alert-link text-decoration-underline fw-normal">
								contact us
							</a>{' '}
							if you need assistance.
						</div>
					</div>
					{/* Product area  */}
					<div className="col-xl-8 col-lg-8 col-md-12">
						<section className="team-area pb-90">
							<div className="container">
								<div className="row">
									<section className="team-area pb-90">
										<div className="container">
											<div className="row">
												<CardContent
													title={cards[selectedCard].title}
													paragraphs={cards[selectedCard].paragraphs}
													content={cards[selectedCard].content}
													image={cards[selectedCard].image}
													logo={cards[selectedCard].logo}
													link={cards[selectedCard].link}
													selection={selectedCard}
												/>
											</div>
										</div>
									</section>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default TreatmentsAbroad;
