import React, { useMemo } from 'react';
import doctorData from '../../../data/doctor-profile.json';
import './styles.css';

const DoctorsFilters = (props) => {
	const { handleFilter, handleClearFilter } = props;

	let byCategoryArrays = {};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useMemo(() => {
		const byCategorySets = doctorData.specialist.reduce((res, { category, subcategory }) => {
			if (!res[category]) res[category] = new Set(subcategory);
			else res[category].add(...subcategory);
			return res;
		}, {});
		byCategoryArrays = Object.fromEntries(
			Object.entries(byCategorySets).map(([key, val]) => [key, [...val]])
		);
	});

	return (
		<>
			<div className="filter-item">
				<h5 className="mt-1">Filter</h5>
				<button onClick={handleClearFilter}>Reset</button>
			</div>
			<div className="filter-item p-0">
				<ul>
					{Object.entries(byCategoryArrays).map(([category, subCategoryArray]) => {
						return (
							<li key={category} className="filter-category">
								<label data-filter-type="filter-category">
									<h5>{category}</h5>
									<input onChange={handleFilter} type="checkbox" value={category} />
									<span className="checkmark"></span>
								</label>
								{subCategoryArray.map((subcategory) => {
									if (subcategory.length) {
										return (
											<label
												key={subcategory}
												className="filter-subcategory"
												data-filter-type="filter-subcategory"
												data-subcategory={subcategory}
											>
												<input onChange={handleFilter} type="checkbox" value={subcategory} />
												<span className="checkmark"></span>
												<span className="description">{subcategory}</span>
											</label>
										);
									} else {
										return null;
									}
								})}
							</li>
						);
					})}
				</ul>
			</div>
			<div className="alert alert-primary mt-20" role="alert">
				<i className="fas fa-solid fa-info pr-10"></i>Please{' '}
				<a href="/contact" className="alert-link text-decoration-underline fw-normal">
					contact us
				</a>{' '}
				if you need assistance.
			</div>
		</>
	);
};

export default DoctorsFilters;
